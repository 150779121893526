<script>
  import { PrimaryButton } from 'src/components/common/button';
  import { InlineLoading } from 'carbon-components-svelte';
  import PaymentInformationModal from 'src/components/modal/payment-information-modal/index.svelte';
  import DifferenceInformationModal from 'src/components/modal/DifferenceInformationModal.svelte';
  import ErrorApi from 'src/components/modal/ErrorApi.svelte';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { getOfferPrice } from 'src/service/reservationTicketing';
  import { screenIsNotEvent } from 'src/store';
  import { formatPriceNumber } from 'src/utils/appHelper.js';

  export let dataRowSelected = EMPTY;

  let isLoadingGetOfferPrice = false;
  let differenceInformation = {
    isOpen: false,
    data: [],
  };
  let paymentInformation = {
    isOpen: false,
    DataLists: [],
    PricedOffer: [],
  };
  let errorApi = {
    isOpen: false,
    message: EMPTY,
  };

  const handleGetOfferPrice = async () => {
    if (dataRowSelected !== EMPTY) {
      const params = {
        Query: dataRowSelected.queryOfferPrice,
      };
      try {
        isLoadingGetOfferPrice = true;
        screenIsNotEvent.set(true);
        const { code, data } = await getOfferPrice(params);
        if (code === 200 && data.ResultMessage.Code !== CODE_SUCCESS) {
          errorApi = {
            isOpen: true,
            message: `[${dataRowSelected.owner} ${data.ResultMessage.Code} ${data.ResultMessage.Message}]`,
          };
        } else {
          const { PricedOffer = {}, DataLists = {} } = data;
          const TotalPrice = PricedOffer.TotalPrice;
          paymentInformation = {
            isOpen: true,
            PricedOffer,
            DataLists,
          };
          if (
            dataRowSelected.detailPrice.baseAmount !== TotalPrice.TotalBaseAmount.Amount ||
            dataRowSelected.detailPrice.totalTax !== TotalPrice.TotalTaxAmount.Amount
          ) {
            differenceInformation = {
              isOpen: true,
              data: [
                {
                  id: 1,
                  division: 'BaseAmount',
                  beforeChange: `${formatPriceNumber(dataRowSelected.detailPrice.baseAmount)} ${
                    dataRowSelected.detailPrice.curCode
                  }`,
                  after: `${formatPriceNumber(TotalPrice.TotalBaseAmount.Amount)} ${
                    TotalPrice.TotalBaseAmount.CurCode
                  }`,
                  variableAmount: `${formatPriceNumber(
                    TotalPrice.TotalBaseAmount.Amount - dataRowSelected.detailPrice.baseAmount
                  )} ${dataRowSelected.detailPrice.curCode}`,
                },
                {
                  id: 2,
                  division: 'TotalTax',
                  beforeChange: `${formatPriceNumber(dataRowSelected.detailPrice.totalTax)} ${
                    dataRowSelected.detailPrice.curCode
                  }`,
                  after: `${formatPriceNumber(TotalPrice.TotalTaxAmount.Amount)} ${TotalPrice.TotalTaxAmount.CurCode}`,
                  variableAmount: `${formatPriceNumber(
                    TotalPrice.TotalTaxAmount.Amount - dataRowSelected.detailPrice.totalTax
                  )} ${dataRowSelected.detailPrice.curCode}`,
                },
                {
                  id: 3,
                  division: 'TotalFare',
                  beforeChange: `${formatPriceNumber(
                    dataRowSelected.detailPrice.baseAmount + dataRowSelected.detailPrice.totalTax
                  )} ${dataRowSelected.detailPrice.curCode}`,
                  after: `${formatPriceNumber(TotalPrice.TotalAmount.Amount)} ${TotalPrice.TotalAmount.CurCode}`,
                  variableAmount: `${formatPriceNumber(
                    TotalPrice.TotalAmount.Amount -
                      (dataRowSelected.detailPrice.baseAmount + dataRowSelected.detailPrice.totalTax)
                  )} ${dataRowSelected.detailPrice.curCode}`,
                },
              ],
            };
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoadingGetOfferPrice = false;
        screenIsNotEvent.set(false);
      }
    }
  };
</script>

{#key paymentInformation}
  <PaymentInformationModal
    open={paymentInformation.isOpen}
    DataLists={paymentInformation.DataLists}
    PricedOffer={paymentInformation.PricedOffer}
  />
{/key}
<DifferenceInformationModal
  bind:open={differenceInformation.isOpen}
  on:close={(_event) => {}}
  bind:data={differenceInformation.data}
/>
<ErrorApi bind:openErrorApi={errorApi.isOpen}>{errorApi.message}</ErrorApi>

<PrimaryButton on:click={handleGetOfferPrice}
  >다음
  {#if isLoadingGetOfferPrice}
    <div class="g-custom-loading-button">
      <InlineLoading description="Loading ..." />
    </div>
  {/if}
</PrimaryButton>
