import request from './request.js';
import qs from 'qs';
const getDsrList = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/daliy-sales-report',
      method: 'GET',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const exportExcelDsr = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/daliy-sales-report/excel',
      method: 'GET',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  getDsrList,
  exportExcelDsr,
};
