<script>
  import { cloneDeep, isEqual } from 'lodash';
  import { createEventDispatcher } from 'svelte';
  import InfoPaymentTooltip from './InfoPaymentTooltip.svelte';
  import { EMPTY } from 'src/constants/app';
  import { formatPriceNumber } from 'src/utils/appHelper';
  import Ticket from 'carbon-icons-svelte/lib/Ticket.svelte';
  import ChevronDown from 'carbon-icons-svelte/lib/ChevronDown.svelte';
  import ChevronUp from 'carbon-icons-svelte/lib/ChevronUp.svelte';
  import './FlightTable.scss';

  export let rowsData;
  export let headers = [];
  export let selected = false;

  export let checkDataSelected = EMPTY;

  const dispatch = createEventDispatcher();

  let expanded = false;
  let expandedSubRowIds = [];
  let rowspan = 1;

  const handleExpandedRow = () => {
    expanded = !expanded;
  };
  const handleExpandedSubRow = (subRowId) => {
    if (expandedSubRowIds.includes(subRowId)) expandedSubRowIds = expandedSubRowIds.filter((id) => id !== subRowId);
    else expandedSubRowIds = [...expandedSubRowIds, subRowId];
  };

  const getHeaderKeys = (_headers) => {
    const handleHeader = cloneDeep(_headers);
    handleHeader.shift();
    handleHeader.pop();
    handleHeader.pop();
    return handleHeader.map((headerItem) => headerItem.key);
  };

  const handleSelect = (dataSelect) => {
    dispatch('select', dataSelect);
  };
  const handleTicketDetailSelect = (dataSelect) => {
    dispatch('detail-ticket', dataSelect);
  };
  $: {
    let _rowspan = rowsData.data.length;
    rowsData.data.forEach((item, index) => {
      if (expandedSubRowIds.includes(index)) {
        _rowspan += item.children.length;
      }
    });
    rowspan = _rowspan;
  }

  $: headerKeys = getHeaderKeys(headers);

  $: {
    selected = isEqual(rowsData, checkDataSelected.data);
  }
</script>

{#each rowsData.data as dataItem, index (index)}
  <!-- render main -->
  <tr>
    {#if index === 0}
      <td
        {rowspan}
        class="flightTable__td flightTable__td-airline"
        class:flightTable__td--border-left-selected={selected}
        class:flightTable__td--border-top-selected={selected && index === 0}
        class:flightTable__td--border-bottom-selected={selected}
      >
        <div class="flightTable__td-container flightTable-row-logo">
          <img src={rowsData.logo} alt="logo" />
          <div>{rowsData.airlineName}</div>
        </div>
      </td>
    {/if}
    <!-- -- -->
    {#each headerKeys as key, keyIndex}
      <td
        class:flightTable__td--border-top-selected={selected && index === 0}
        class:flightTable__td--border-bottom-selected={selected &&
          index === rowsData.data.length - 1 &&
          !expandedSubRowIds.includes(rowsData.data.length - 1)}
      >
        {#if keyIndex === 0}
          <ul class="flightTable__list-content flightTable__list-airline-code">
            <button
              on:click={() => handleExpandedSubRow(index)}
              class="flightTable__icon-arrow flightTable__icon-arrow--sub-row"
              class:flightTable__icon-arrow--collapse={dataItem.children.length < 2}
            >
              {#if expandedSubRowIds.includes(index)}
                <ChevronUp width="16" height="16" />
              {:else}
                <ChevronDown width="16" height="16" />
              {/if}
            </button>
            {#each dataItem.main[key] as item}
              <li>{item}</li>
            {/each}
          </ul>
        {:else}
          <ul class="flightTable__list-content">
            {#each dataItem.main[key] as item}
              <li>{item}</li>
            {/each}
          </ul>
        {/if}
      </td>
    {/each}

    <!-- -- -->

    {#if index === 0}
      <td
        {rowspan}
        class="flightTable__td flightTable__box-select"
        class:flightTable__td--border-top-selected={selected && index === 0}
        class:flightTable__td--border-right-selected={selected}
        class:flightTable__td--border-bottom-selected={selected}
      >
        <div class="flightTable__td-container flightTable__select-action">
          <div class="select-action__layout">
            <p>{rowsData.totalFee}</p>
            <div><InfoPaymentTooltip data={rowsData.infoPayment} /></div>
          </div>
          <div class="select-action__layout">
            <button
              class="flightTable__select-button"
              class:flightTable__select-button--active={selected}
              on:click={() =>
                handleSelect({
                  type: 'GROUP',
                  data: rowsData,
                })}>선택</button
            >
            <button
              on:click|stopPropagation|preventDefault={() =>
                handleTicketDetailSelect({ type: 'GROUP', data: rowsData })}
              class="flightTable__icon-ticket"
            >
              <Ticket width="18" height="18" />
            </button>
          </div>
        </div>
      </td>
      <td {rowspan} class="flightTable__td">
        <div class="flightTable__td-container">
          {#if rowsData.cardsTicket.length > 0}
            <button on:click={handleExpandedRow} class="flightTable__icon-arrow">
              {#if expanded}
                <ChevronUp width="24" height="24" />
              {:else}
                <ChevronDown width="24" height="24" />
              {/if}
            </button>
          {/if}
        </div>
      </td>
    {/if}
  </tr>
  <!-- end render main -->

  {#if expandedSubRowIds.includes(index) && dataItem.children.length > 1}
    {#each dataItem.children as item, childrenIndex (childrenIndex)}
      <tr>
        {#each headerKeys as key, keyIndex}
          <td
            class:flightTable__td--border-bottom-selected={selected &&
              childrenIndex === dataItem.children.length - 1 &&
              index === rowsData.data.length - 1}
          >
            {#if keyIndex === 0}
              <ul class="flightTable__list-content flightTable__list-content-children flightTable__list-airline-code">
                {#each item[key] as item}
                  <li>{item}</li>
                {/each}
              </ul>
            {:else}
              <ul class="flightTable__list-content flightTable__list-content-children">
                {#each item[key] as item}
                  <li>{item}</li>
                {/each}
              </ul>
            {/if}
          </td>
        {/each}
      </tr>
    {/each}
  {/if}
  <!-- --- -->
{/each}

{#if expanded && rowsData.data.length > 0}
  <tr>
    <td colspan="15" class="flightTable__cards-container">
      <ul>
        {#each rowsData.cardsTicket as card, index (index)}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <li
            on:click={handleSelect({
              type: 'COUPON',
              data: card,
            })}
            class:active-selected-card={isEqual(card, checkDataSelected.data)}
          >
            <div class="flightTable__cards-wrapper">
              <div class="paxJourneyDetail">
                {#each card.PaxJourneyDetail as paxJourneyDetailItem}
                  <div>
                    {paxJourneyDetailItem.onPoint} - {paxJourneyDetailItem.offPoint} / {paxJourneyDetailItem.kg} / {paxJourneyDetailItem.priceClass}
                  </div>
                {/each}
              </div>

              <div class="flex">
                <div>
                  <button
                    on:click|stopPropagation|preventDefault={() =>
                      handleTicketDetailSelect({
                        type: 'COUPON',
                        data: card,
                      })}
                    class="flightTable__icon-ticket"
                  >
                    <Ticket width="18" height="18" />
                  </button>
                </div>
                <div class="card-payment-info">
                  <InfoPaymentTooltip data={card.infoPayment} align="center" /> <span>총 금액</span>
                </div>
                <div>{formatPriceNumber(card.totalFee)} {card.detailPrice.curCode}</div>
              </div>
            </div>
          </li>
        {/each}
      </ul>
    </td>
  </tr>
{/if}
