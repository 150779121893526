<svg width="53" height="16" viewBox="0 0 53 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.20043 0.831055H0.113281V14.9927H9.89656V11.6097H4.20043V0.831055Z" fill="#2F303B" />
  <path
    d="M15.3093 9.38511V0.831055H11.2482V9.92657C11.2482 10.9828 11.4992 11.9054 12.0013 12.6943C12.5033 13.4833 13.2107 14.0929 14.1235 14.5232C15.0429 14.9536 16.1056 15.1687 17.3119 15.1687C18.5181 15.1687 19.5808 14.9536 20.5002 14.5232C21.4195 14.0929 22.1302 13.4833 22.6322 12.6943C23.1408 11.9054 23.3951 10.9828 23.3951 9.92657V0.831055H19.35V9.38511C19.35 9.77057 19.2634 10.1169 19.0901 10.4242C18.9169 10.7314 18.6765 10.9716 18.3692 11.1448C18.0618 11.3124 17.7125 11.3962 17.3213 11.3962C16.9356 11.3962 16.5891 11.3096 16.2817 11.1364C15.9799 10.9633 15.7424 10.7258 15.5692 10.4242C15.3959 10.1169 15.3093 9.77057 15.3093 9.38511Z"
    fill="#2F303B"
  />
  <path
    d="M28.5971 0.831055H25.2658V14.9921H29.3095V7.56704H29.4073L34.5155 14.9921H37.7957V0.831055H33.8031V8.25607H33.7053L28.5971 0.831055Z"
    fill="#2F303B"
  />
  <path
    d="M47.8229 14.9855L46.2755 11.3164L43.5392 4.85887L45.2983 0.831055H46.3081L52.4974 14.9855H47.8229Z"
    fill="#2F303B"
  />
  <path
    d="M42.0496 15.1646C43.5208 15.1646 44.7135 13.9705 44.7135 12.4975C44.7135 11.0246 43.5208 9.83048 42.0496 9.83048C40.5784 9.83048 39.3857 11.0246 39.3857 12.4975C39.3857 13.9705 40.5784 15.1646 42.0496 15.1646Z"
    fill="#2F303B"
  />
</svg>
