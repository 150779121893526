<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { ContentSwitcher, Switch, Button, InlineLoading, ModalBody, ModalFooter } from 'carbon-components-svelte';
  import { isEmpty, map, find, isNumber, keys, orderBy, has } from 'lodash';
  import ToolBar from './ToolBar.svelte';
  import WrapperItineraryInquiry from './WrapperItineraryInquiry.svelte';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app.js';
  import { message, screenIsNotEvent } from 'src/store';
  import { actionOrderReshop, actionOrderReshopReprice } from 'src/service/reservation.js';
  import FormChangeItinerary from './FormChangeItinerary.svelte';
  import { CustomLoading } from 'src/components/common/loading';
  import { convertFlightTime, splitConvertArrayId } from 'src/utils/appHelper.js';

  import './index.scss';

  export let stepId;
  export let orderRetrieve;
  export let stepData;
  const dispatch = createEventDispatcher();
  let selectedIndex = 0;
  let delServiceOrder = [];
  let dataItineraryInquiry = [];
  let selectWrapperItineraryInquiryId = EMPTY;
  let tabsData = [];
  let currentTabData;
  let currentTabIndexData = 1;
  let toolbarData = [];
  let isLoading = false;
  let isLoadingNextDataStepTwo = false;
  async function searchItinerary() {
    isLoading = true;
    selectWrapperItineraryInquiryId = EMPTY;
    const params = {
      originDestList: tabsData.map(({ searchWord }) => {
        return {
          OriginCode: searchWord.onPoint.toUpperCase(),
          DestCode: searchWord.offPoint.toUpperCase(),
          DepartureDate: searchWord.departureDate,
        };
      }),
      cabin: tabsData[0]?.searchWord.cabinCode,
      delServiceOrder,
      orderId: orderRetrieve?.data?.Order?.OrderID || EMPTY,
    };
    const response = await actionOrderReshop(params);
    isLoading = false;
    if (response?.data?.ResultMessage?.Code !== CODE_SUCCESS) {
      message.update(() => [
        {
          type: 'error',
          title: 'Error',
          subtitle: `${response?.data?.ResultMessage?.Code ?? EMPTY} ${
            response?.data?.ResultMessage?.Message ?? EMPTY
          }`,
          caption: EMPTY,
        },
      ]);
      dataItineraryInquiry = [];
      toolbarData = [];
    } else {
      dataItineraryInquiry = handleDataReshop(response?.data);
      toolbarData = getToolBarData(response?.data?.DataLists);
    }
  }

  function handleDataReshop(responReshops) {
    const { ReshopOffers, DataLists } = responReshops;
    const handle = (itemReshopOffers) => {
      const { OfferID, JourneyOverview, AddOfferItem, ResponseID } = itemReshopOffers;
      let commonMoney = 0;
      let differenceMoney = 0;
      let isHasCommonMoney = AddOfferItem.find((item) => has(item, 'NewOfferItem'));
      let isHasDifferenceMoney = AddOfferItem.find((item) => has(item, 'ReshopDue'));
      let curCode = '';

      for (const addOfferItem of AddOfferItem) {
        const paxRefID = splitConvertArrayId(addOfferItem.PaxRefID);
        if (['SQ', 'QR'].includes(addOfferItem.Owner)) {
          commonMoney += addOfferItem?.NewOfferItem?.Total?.Amount ?? 0;
          differenceMoney += addOfferItem?.ReshopDue?.Total?.Amount ?? 0;
        } else {
          commonMoney += (addOfferItem?.NewOfferItem?.Total?.Amount ?? 0) * paxRefID.length;
          differenceMoney += (addOfferItem?.ReshopDue?.Total?.Amount ?? 0) * paxRefID.length;
        }
        curCode = addOfferItem?.NewOfferItem?.Total?.CurCode ?? addOfferItem?.ReshopDue?.Total?.CurCode ?? EMPTY;
      }
      const items = map(JourneyOverview, (JourneyOverviewItem) => {
        const { PaxJourneyList, PaxSegmentList } = DataLists;
        const paxJourney = find(
          PaxJourneyList,
          (paxJourneyItem) => paxJourneyItem?.PaxJourneyID === JourneyOverviewItem?.PaxJourneyRefID
        );

        return map(paxJourney.PaxSegmentRefID, (paxSegmentRefID, index) => {
          const paxSegment = find(PaxSegmentList, (PaxSegmentItem) => PaxSegmentItem.PaxSegmentID === paxSegmentRefID);
          const { Departure, Arrival, MarketingCarrier, OperatingCarrier, Legs } = paxSegment;
          const getValName = (params) => {
            if (
              isEmpty(params?.Terminal) ||
              params?.Terminal === EMPTY ||
              params?.Terminal?.Name === 'T0' ||
              params?.Terminal?.Name === EMPTY ||
              params?.Terminal?.Name === '0'
            ) {
              return params?.AirportCode || EMPTY;
            }
            return (
              params?.AirportCode +
              '-' +
              `${isNumber(Number(params?.Terminal?.Name)) ? 'T' : EMPTY}` +
              params?.Terminal?.Name
            );
          };

          const getCabinCode = (type = 'ONLY_CODE') => {
            const fareComponent = find(AddOfferItem[0]?.FareDetail[0]?.FareComponent, (it) =>
              it.PaxSegmentRefID.includes(paxSegmentRefID)
            );
            const cabinCode = fareComponent?.FareBasis?.CabinType[0]?.CabinCode;
            if (type === 'FULL') return { cabinCode, airlineID: MarketingCarrier?.AirlineID };
            return cabinCode;
          };
          const getRbd = () => {
            const fareComponent = find(AddOfferItem[0]?.FareDetail[0]?.FareComponent, (it) =>
              it.PaxSegmentRefID.includes(paxSegmentRefID)
            );
            return fareComponent?.FareBasis?.CabinType[0]?.Rbd;
          };
          const getOperatingCarrierAirlineID = () => {
            if (!OperatingCarrier) return EMPTY;
            return OperatingCarrier?.AirlineID;
          };
          return {
            journeyCount: index + 1,
            airlineName: MarketingCarrier?.Name,
            airlineID: MarketingCarrier?.AirlineID,
            flightNumber: MarketingCarrier?.FlightNumber.padStart(4, '0'),
            departureTime: Departure?.Time || EMPTY,
            arrivalTime: Arrival?.Time || EMPTY,
            onPointDepartureName: getValName(Departure),
            offPointArrivalName: getValName(Arrival),
            flightTime: convertFlightTime(paxJourney?.FlightTime),
            countRefId: paxJourney.PaxSegmentRefID.length,
            arrivalDate: Arrival?.Date || EMPTY,
            departureDate: Departure?.Date || EMPTY,
            cabinCode: getCabinCode(),
            cabinItem: getCabinCode('FULL'),
            rbd: getRbd(),
            operatingCarrierAirlineID: getOperatingCarrierAirlineID(),
            legs: Legs[0]?.Arrival?.AirportCode ?? EMPTY,
            priceClassInfoName: JourneyOverviewItem.PriceClassInfo.Name,
          };
        });
      });
      return {
        id: OfferID,
        offerID: OfferID,
        responseID: ResponseID,
        items,
        commonMoney,
        differenceMoney,
        isHasCommonMoney,
        isHasDifferenceMoney,
        curCode,
      };
    };
    return orderBy(map(ReshopOffers, handle), ['age'], ['asc']);
  }
  function getToolBarData(DataLists) {
    const { PaxJourneyList, PaxSegmentList, OriginDestList } = DataLists;
    let toolBarData = [];
    for (let i = 0; i < OriginDestList.length; i++) {
      const { PaxJourneyRefID, OriginCode, DestCode } = OriginDestList[i];
      let dataItem = {
        id: i + 1,
        onPoint: OriginCode,
        offPoint: DestCode,
      };
      if (PaxJourneyRefID.length) {
        const findPaxJourney = find(
          PaxJourneyList,
          (PaxJourneyListItem) => PaxJourneyListItem.PaxJourneyID === PaxJourneyRefID[0]
        );
        if (findPaxJourney) {
          const PaxSegmentRefID = findPaxJourney?.PaxSegmentRefID ?? EMPTY;
          if (PaxSegmentRefID) {
            const findPaxSegment = find(PaxSegmentList, (PaxSegmentListItem) =>
              PaxSegmentRefID?.includes(PaxSegmentListItem?.PaxSegmentID)
            );
            dataItem.departureDate = findPaxSegment?.Departure?.Date || EMPTY;
          }
        }
      }
      toolBarData.push(dataItem);
    }
    return toolBarData;
  }
  function updateParams(event) {
    const { itemSearch, paxJourneyID, id } = event.detail;
    const tabDataNeedUpdate = find(tabsData, (tabDataItem) => tabDataItem.id === id);
    //  need to update ui
    tabDataNeedUpdate.searchWord = itemSearch;
    tabsData = [...tabsData];
  }
  function checkEmpty(_tabsData) {
    let enableBtn = true;
    for (const _tabsDataItem of _tabsData) {
      if (enableBtn === false) break;
      for (const oneKey of keys(_tabsDataItem.searchWord)) {
        if (_tabsDataItem.searchWord[oneKey] === EMPTY || _tabsDataItem.searchWord[oneKey] === undefined) {
          enableBtn = false;
          break;
        }
      }
    }
    return enableBtn;
  }
  function setCurrentTabData(id) {
    currentTabData = find(tabsData, (tabDataItem) => tabDataItem.id === id);
    currentTabIndexData = id;
  }
  function closeModalItinerary() {
    dispatch('close-modal-itinerary');
  }

  function handlePrevStep() {
    dispatch('prev-step-two', {
      currentStepId: stepId,
    });
  }

  async function handleNextStep() {
    isLoadingNextDataStepTwo = true;
    const currentWrapperItineraryInquiry = find(
      dataItineraryInquiry,
      (dataItineraryInquiryItem) => dataItineraryInquiryItem.id === selectWrapperItineraryInquiryId
    );
    const params = {
      delServiceOrder,
      shoppingResponse: {
        ResponseRefID: currentWrapperItineraryInquiry.responseID,
        OfferRefID: currentWrapperItineraryInquiry.offerID,
      },
      orderId: orderRetrieve?.data?.Order?.OrderID || EMPTY,
    };
    const resData = await actionOrderReshopReprice(params);
    isLoadingNextDataStepTwo = false;
    if (resData?.data?.ResultMessage?.Code !== CODE_SUCCESS && resData?.code === 200) {
      const code = resData?.data?.ResultMessage?.Code ?? EMPTY;
      const msg = resData?.data?.ResultMessage?.Message ?? EMPTY;
      message.update(() => [
        {
          type: 'error',
          title: 'Error',
          subtitle: `${code} ${msg}`,
          caption: EMPTY,
        },
      ]);
      return;
    }
    const dataLastStep = resData?.data;
    dispatch('next-step-two', {
      currentStepId: stepId,
      dataItineraryInquiry: dataItineraryInquiry,
      dataLastStep,
      toolbarData,
    });
  }

  onMount(() => {
    const orderItems = orderRetrieve?.data?.Order?.OrderItem || [];
    const paxJourneyListNotChoose = orderRetrieve?.data?.DataLists?.PaxJourneyList.filter(
      (paxJourneyItem) => !stepData.tabsData.map((elm) => elm.PaxJourneyID).includes(paxJourneyItem.PaxJourneyID)
    );
    const paxSegmentIds = paxJourneyListNotChoose.map((elm) => elm.PaxSegmentRefID).flat();
    let delServiceOrderItem = [];
    if (paxSegmentIds.length) {
      for (const orderItem of orderItems) {
        const retainServiceIdWithOrderItem = orderItem?.Service.filter((itemService) =>
          paxSegmentIds.includes(itemService.PaxSegmentRefID)
        ).map((item) => item.ServiceID);
        delServiceOrderItem = [
          ...delServiceOrderItem,
          {
            OrderItemRefID: orderItem.OrderItemID,
            RetainServiceID: retainServiceIdWithOrderItem,
          },
        ];
      }
    }

    if (delServiceOrderItem.length > 0) delServiceOrder = [...delServiceOrder, ...delServiceOrderItem];
    else delServiceOrder = [{ OrderItemRefID: orderItems[0]?.OrderItemID ?? EMPTY, RetainServiceID: [] }];

    /* ----- INIT DATA STEP TWO------ */
    if (stepData) {
      dataItineraryInquiry = stepData?.dataItineraryInquiry;
    }

    tabsData = stepData.tabsData;
    currentTabData = tabsData[0];
    toolbarData = stepData.toolbarData;
  });
  $: screenIsNotEvent.set(isLoading || isLoadingNextDataStepTwo);
</script>

<ModalBody class="step_two">
  <div class="itineraryInquiry" class:step={stepId}>
    <div class="left-layout">
      <h4>여정 변경</h4>
      <div class="layout-content">
        <ContentSwitcher bind:selectedIndex class="content-switcher">
          {#if stepData.tabsData.length > 1}
            {#each stepData.tabsData as { id, PaxJourneyID } (id)}
              <Switch
                text="여정{id}"
                id={PaxJourneyID}
                on:click={(e) => {
                  setCurrentTabData(id);
                }}
              />
            {/each}
          {/if}
        </ContentSwitcher>
        <div class="form-preview">
          {#if currentTabData}
            {#key currentTabData && currentTabIndexData}
              <FormChangeItinerary on:update-params-search={updateParams} data={currentTabData} />
            {/key}
          {/if}
        </div>
        <div class="check-btn">
          {#key checkEmpty(tabsData)}
            <Button on:click={searchItinerary} disabled={!checkEmpty(tabsData)}>조회</Button>
          {/key}
        </div>
      </div>
    </div>
    <div class="right-layout">
      {#key toolbarData}
        <ToolBar data={toolbarData} />
      {/key}
      <div class="layout-content" class:active-border={!dataItineraryInquiry.length || isLoading}>
        {#if isLoading}
          <div class="loading-getdata-steptwo"><CustomLoading /></div>
        {:else if dataItineraryInquiry && dataItineraryInquiry.length}
          {#each dataItineraryInquiry.sort((itemA, itemB) => itemA.commonMoney - itemB.commonMoney) as itemData (itemData.id)}
            <WrapperItineraryInquiry
              data={itemData}
              on:selected={(event) => (selectWrapperItineraryInquiryId = event?.detail.id)}
              activeBtn={selectWrapperItineraryInquiryId === itemData.id}
            />
          {/each}
        {:else}
          <div class="notify-result">변경할 여정을 조회해 주세요.</div>
        {/if}
      </div>
    </div>
  </div>
</ModalBody>
<ModalFooter>
  <footer>
    <div class="step-two">
      <Button kind="secondary" on:click={closeModalItinerary}>취소</Button>
      <Button on:click={handlePrevStep} kind="secondary">이전</Button>
      <Button on:click={handleNextStep} disabled={!selectWrapperItineraryInquiryId}
        >다음
        <div class="loading-steptwo">
          {#if isLoadingNextDataStepTwo}
            <InlineLoading description="Loading ..." />
          {/if}
        </div>
      </Button>
    </div>
  </footer>
</ModalFooter>
