<script>
  import { ListBoxMenuItem } from 'carbon-components-svelte';
  import CustomMultiSelectV2 from './CustomMultiSelect.svelte';
  import AddAlt from 'carbon-icons-svelte/lib/AddAlt.svelte';
  import SubtractAlt from 'carbon-icons-svelte/lib/SubtractAlt.svelte';

  export let label = '';
  export let items = [];

  const ACTION_TYPE = { add: 'add', subtract: 'subtract' };
  const itemToString = (item) => item.text || item.id;
  const MAX_GUESTS = 9;
  let isAlert = false;
  let open = false;
  const checkLimitMaxGuest = (_items) => {
    let checkGuestCount = 0;
    for (let { value } of items) {
      checkGuestCount += value;
    }
    return checkGuestCount < MAX_GUESTS;
  };

  export const handleReset = () => {
    isAlert = false;
  };
  function actionBtnClick(type, item) {
    let currentGuests = {
      ADT: 0,
      CHD: 0,
      INF: 0,
    };
    for (let { value, key } of items) {
      currentGuests[key] = value;
    }
    if (type === ACTION_TYPE.add) {
      if (!checkLimitMaxGuest(items)) return;
      if (currentGuests['ADT'] <= currentGuests['INF'] && item.key === 'INF') {
        isAlert = true;
        open = false;
        return;
      }
      currentGuests[item.key] += 1;
    }
    if (type === ACTION_TYPE.subtract) {
      if (currentGuests[item.key] === 0) return;
      if (item.key === 'ADT' && currentGuests['ADT'] === currentGuests['INF']) {
        currentGuests[item.key] -= 1;
        currentGuests['INF'] -= 1;
      } else {
        currentGuests[item.key] -= 1;
      }
    }
    items = items.map((el) => {
      return {
        ...el,
        value: currentGuests[el.key],
      };
    });
  }

  $: {
    const totalSelectedItems = items.reduce((acc, item) => +acc + Number(item.value), 0);
    label = totalSelectedItems + (totalSelectedItems > 1 ? ' guests' : ' guest');
  }
  $: if (open) {
    isAlert = false;
  }
</script>

<CustomMultiSelectV2
  titleText="승객"
  bind:items
  bind:label
  bind:open
  invalidText="유아 탑승객은 성인 탑승객 수를 초과할 수 없습니다"
  invalid={isAlert}
>
  <svelte:fragment slot="expanded-list">
    {#each items as item}
      <ListBoxMenuItem>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <p>{itemToString(item)}</p>
          <div class="g-btn-action" style="display: flex; gap: 10px; align-items: center;">
            <button style="all: unset" on:click={() => actionBtnClick(ACTION_TYPE.subtract, item)}>
              <SubtractAlt size={18} />
            </button>
            <p>{item.value}</p>
            <button style="all: unset" on:click={() => actionBtnClick(ACTION_TYPE.add, item)}>
              <AddAlt size={18} />
            </button>
          </div>
        </div>
      </ListBoxMenuItem>
    {/each}
  </svelte:fragment>
  <div>xxx</div>
</CustomMultiSelectV2>
