<script>
  import { EMPTY } from 'src/constants/app.js';
  import { isEqual, cloneDeep } from 'lodash';
  import FlightRow from './FlightRow.svelte';
  import { createEventDispatcher } from 'svelte';

  import './FlightTable.scss';

  const dispatch = createEventDispatcher();

  export let tableHeight = '100vh';
  export let headerHeight = '38px';
  export let headers = [];
  export let tableData = [];
  let tableBoxRef;
  let rowSelectData = EMPTY;

  $: _headers = headers.map((item, index) => {
    let style = item?.width ? `width:calc(${item.width});` : '';
    if (index === 0) return `<th class="flightTable__head-airline" style="${style}">${item.value}</th>`;
    if (index === headers.length - 2) return `<th style="text-align: center;${style}" >${item.value}</th>`;
    if (index === headers.length - 1)
      return `<th class="flightTable__head-toggle-arrow" style="text-align: center; ${style}">${item.value}</th>`;
    return `<th style="${style}">${item.value}</th>`;
  });

  export const handleScrollTop = () => {
    tableBoxRef?.scrollTo({ top: 0, behavior: 'smooth' });
  };
  export const handleResetSelected = () => {
    rowSelectData = EMPTY;
    handleRowGroupSelect(rowSelectData);
  };
  const handleRowGroupSelect = (currentData) => {
    if (isEqual(currentData, rowSelectData)) rowSelectData = EMPTY;
    else rowSelectData = cloneDeep(currentData);
    dispatch('row-group-select', rowSelectData);
  };
  const handleDetailTicket = (ticketSelect) => {
    dispatch('detail-ticket', ticketSelect);
  };
</script>

<div
  id="flightTable"
  class="flightTable__scroll"
  style="height:calc({tableHeight});padding-bottom: calc({headerHeight});"
  bind:this={tableBoxRef}
  on:scroll={(event) => dispatch('scroll', event.target)}
>
  <table>
    <thead style="height:calc({headerHeight});">
      <tr>
        {#each _headers as th}
          {@html th}
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each tableData as item}
        <FlightRow
          rowsData={item}
          {headers}
          on:select={(event) => handleRowGroupSelect(event.detail)}
          on:detail-ticket={(event) => handleDetailTicket(event.detail)}
          checkDataSelected={rowSelectData}
        />
      {/each}
    </tbody>
  </table>
</div>
