<!-- svelte-ignore a11y-click-events-have-key-events -->
<script>
  import { DataTable, InlineLoading } from 'carbon-components-svelte';
  import { Close } from 'carbon-icons-svelte/lib';
  import { CustomTooltip } from 'src/components/common/tooltip';
  import { PrimaryButton } from 'src/components/common/button';
  import { isEqual, has, intersection, uniqWith, isEmpty } from 'lodash';
  import { onMount, createEventDispatcher } from 'svelte';
  import { getPassengerNamePtc, sortPaxListByPtc, formatPriceNumber } from 'src/utils/appHelper';
  import { SeatOutLeft, SeatOutRight } from 'src/assets/icons';
  import TrashCan from 'carbon-icons-svelte/lib/TrashCan.svelte';
  import { EMPTY, SEAT_CLASS, SEAT_CHARACTERISTICS_CODE } from 'src/constants/app';

  export let paxList = [];
  export let PaxSegmentID = EMPTY;
  export let AlaCarteOfferItem = [];
  export let SeatMap = [];
  export let airlineID = EMPTY;
  export let priceAmount;
  export let disabledCalculatePrice = true;
  export let loadingCalculatePrice = false;

  const headersPtc = [
    {
      key: 'no',
      value: '순번',
      width: '10%',
    },
    {
      key: 'passengerName',
      value: '탑승객명 (PTC)',
      width: '30%',
    },
    {
      key: 'seat',
      value: '좌석',
      width: '15%',
    },
    {
      key: 'price',
      value: '금액',
      width: '25%',
    },
    {
      key: 'action',
      value: EMPTY,
      width: '10%',
    },
  ];
  let ptcList = [];
  let selectedPtc = EMPTY;
  let selectedSeat = EMPTY;
  let mappingSeat = EMPTY;
  let seatClasses = [];

  const dispatch = createEventDispatcher();

  const getSeatClassByKey = (keyName) => {
    return SEAT_CLASS.find(({ key }) => key === keyName);
  };
  const getMappingSeat = (PaxSegmentID, currentPtc) => {
    const seatMapItem = SeatMap.find((item) => item.PaxSegmentRefID === PaxSegmentID);
    if (!seatMapItem) return EMPTY;
    const newMappingSeat = seatMapItem.Cabins.map(({ Layout, RowInfo }) => {
      let headers = [
        {
          key: 'stt',
          value: EMPTY,
          width: '32px',
        },
      ];

      for (let i = 0; i < Layout.Columns.length; i++) {
        const { Column, Position } = Layout.Columns[i];
        headers.push({
          key: Column,
          value: Column,
          position: Position,
          width: '32px',
        });

        if (Position === 'A' && Layout.Columns?.[i + 1]?.Position === 'A') {
          headers.push({
            key: `space${i}`,
            value: '',
            width: '32px',
          });
        }
      }
      const firstRowCount = Number(Layout.Rows.First);
      const lastRowCont = Number(Layout.Rows.Last);
      let rows = [];
      for (let count = firstRowCount; count <= lastRowCont; count++) {
        const rowInfoItem = RowInfo.find((item) => Number(item.Number) === count);
        if (!rowInfoItem) {
          rows.push({
            id: count,
            stt: count,
            firstCol: EMPTY,
            lastCol: EMPTY,
            isEmpty: true,
          });
          continue;
        }
        let row = {
          id: rowInfoItem.Number,
          stt: rowInfoItem.Number,
          firstCol: Layout.Columns?.[0]?.Column ?? EMPTY,
          lastCol: Layout.Columns[Layout.Columns.length - 1].Column,
          isEmpty: false,
        };

        rowInfoItem.Seats.forEach((seat) => {
          const seatCharacteristicsCode = seat.SeatCharacteristics.map(({ Code }) => Code);
          let exitRightIcon = EMPTY;
          let exitLeftIcon = EMPTY;
          if (seatCharacteristicsCode.includes('E')) {
            exitRightIcon = SeatOutRight;
            exitLeftIcon = SeatOutLeft;
          }
          const joinOfferItemRefID = intersection(seat.OfferItemRefID, currentPtc.offerItemIDWhiteList);
          const { UnitPriceDetail } =
            AlaCarteOfferItem?.find(({ OfferItemID }) => joinOfferItemRefID.includes(OfferItemID)) ?? {};
          row[seat.Column] = {
            row: rowInfoItem.Number,
            column: seat.Column,
            SeatStatus: seat.SeatStatus,
            seatCharacteristicsCode,
            totalAmount: UnitPriceDetail?.TotalAmount,
            exitRightIcon,
            exitLeftIcon,
            OfferItemRefID: seat.OfferItemRefID,
          };
          if (checkCanSeeSeat(row[seat.Column], currentPtc))
            seatClasses.push(getSeatClass(row[seat.Column], EMPTY, currentPtc));
        });
        rows.push(row);
      }
      seatClasses = uniqWith([...seatClasses, getSeatClassByKey('selected')], isEqual).filter(
        (itemSeat) => !isEmpty(itemSeat)
      );
      return {
        info: Layout.Rows,
        headers,
        rows,
      };
    });
    return newMappingSeat;
  };
  const checkCanSeeSeat = (seat, selectedPtc) => {
    if (isEmpty(intersection(seat.OfferItemRefID, selectedPtc.offerItemIDWhiteList))) return false;
    if (['N', 'N/A'].includes(seat.SeatStatus)) return false;
    return true;
  };

  const checkEnableSeat = (seat) => {
    const restrictedCodes = ['R', '1'];
    if (!isEmpty(intersection(seat.seatCharacteristicsCode, [...restrictedCodes]))) return false;
    if (['T', 'O'].includes(seat.SeatStatus)) return false;
    if (['A', 'F', 'VACANT'].includes(seat.SeatStatus)) return true;
    return false;
  };

  const getSeatClass = (currentSeat, selectedSeat, currentPtc) => {
    if (isEqual(currentSeat, selectedSeat)) return getSeatClassByKey('selected');

    if (
      !isEmpty(intersection(['R', '1'], currentSeat.seatCharacteristicsCode)) ||
      ['R', '1'].includes(currentSeat.SeatStatus)
    )
      return getSeatClassByKey('restricted');
    if (['SQ', 'QR'].includes(airlineID)) {
      if (currentSeat.seatCharacteristicsCode.includes('L')) return getSeatClassByKey('extra_legroom_seat');
    }
    if (['T', 'O'].includes(currentSeat.SeatStatus)) return getSeatClassByKey('occupied');
    if (['N', 'N/A'].includes(currentSeat.SeatStatus)) return {};

    const { Service = [] } =
      AlaCarteOfferItem.find(({ OfferItemID }) =>
        intersection(currentSeat.OfferItemRefID, currentPtc.offerItemIDWhiteList).includes(OfferItemID)
      ) ?? {};

    if (!isEmpty(Service)) {
      const seatClassWithName = SEAT_CLASS.find(
        (item) => has(item, 'names') && item.names.includes(Service[0]?.Definition?.Name ?? EMPTY)
      );
      if (seatClassWithName) return seatClassWithName;
    }
    return getSeatClassByKey('available');
  };
  const getPtcList = (paxList) => {
    return paxList
      .filter((item) => item.Ptc !== 'INF')
      .map((paxItem, index) => {
        let offerItemIDWhiteList = [];
        for (const { OfferItemID, PaxRefID } of AlaCarteOfferItem) {
          if (PaxRefID.includes(paxItem.PaxID)) offerItemIDWhiteList.push(OfferItemID);
        }
        return {
          no: index + 1,
          id: paxItem.PaxID,
          passengerName: getPassengerNamePtc(paxItem),
          seat: EMPTY,
          offerItemIDWhiteList,
          paxId: paxItem.PaxID,
          type: paxItem.Ptc,
          totalAmount: EMPTY,
        };
      });
  };

  const handleSelectedSeat = (currentSeat) => {
    if (isEqual(currentSeat, selectedSeat)) {
      ptcList = ptcList.map((ptc) => {
        if (isEqual(ptc.seat, currentSeat)) {
          ptc.seat = EMPTY;
        }
        return ptc;
      });
      selectedSeat = EMPTY;
    } else selectedSeat = currentSeat;
  };

  const handleClearSelectedSeat = (ptc) => {
    ptcList = ptcList.map((ptcItem) => {
      if (isEqual(ptc, ptcItem)) {
        selectedSeat = EMPTY;
        ptcItem.seat = EMPTY;
        ptcItem.totalAmount = EMPTY;
        selectedPtc = ptcItem;
      }
      return ptcItem;
    });
  };
  const handleSelectRowPtc = (ptc) => {
    ptcList = ptcList.map((ptcItem) => {
      if (isEqual(ptc, ptcItem)) {
        selectedPtc = ptcItem;
        selectedSeat = ptcItem.seat;
      }
      return ptcItem;
    });
  };

  const handleSelectSeatOrPtc = (ptc, seat) => {
    if ([ptc, seat].includes(EMPTY)) return;
    ptcList = ptcList.map((ptcItem) => {
      if (ptcItem.id === ptc.id) {
        ptcItem.totalAmount = seat.totalAmount;
        ptcItem.seat = seat;
      }
      return ptcItem;
    });
  };

  onMount(() => {
    ptcList = getPtcList(sortPaxListByPtc(paxList));
    selectedPtc = ptcList?.[0] ?? EMPTY;
    mappingSeat = getMappingSeat(PaxSegmentID, selectedPtc);
  });
  $: if (selectedPtc !== EMPTY) {
    mappingSeat = getMappingSeat(PaxSegmentID, selectedPtc);
  }
  $: handleSelectSeatOrPtc(selectedPtc, selectedSeat);

  $: dispatch('change', ptcList);
  $: console.log('prc', selectedPtc);
</script>

<div class="seats-map">
  <div class="seats-map--class">
    {#each seatClasses.sort((seatClassA, searClassB) => seatClassA.id - searClassB.id) as { id, label, color }}
      <div class="seat-class">
        <div class="seat-class--color" style="background-color: {color};">
          {#if id === 5}
            <div class="seat-class--icon">
              <Close size="14" color="#FFFFFF" />
            </div>
          {/if}
        </div>
        <div class="seat-class--name">{label}</div>
      </div>
    {/each}
  </div>
  <div class="seats-map--area">
    <div class="seats-area--left">
      <div class="seats-area--container">
        {#if mappingSeat === EMPTY}
          <div class="seat-empty">해당 SEGMENT는 사전좌석지정이 불가합니다</div>
        {:else}
          {#each mappingSeat as { headers, rows, info }}
            <div class="layout-seat">
              {info.First}- {info.Last}
            </div>
            <DataTable {headers} {rows} size="short" useStaticWidth id="seat-table">
              <div slot="cell" let:cell let:row>
                {#if cell.key === 'stt'}
                  {cell.value}
                {:else if cell.key.includes('space') || !cell.value}
                  <div />
                {:else}
                  {@const { seatCharacteristicsCode = [], totalAmount, exitRightIcon, exitLeftIcon } = cell.value}
                  {@const { firstCol, lastCol, isEmpty } = row}
                  {#if !isEmpty}
                    <!-- content here -->
                    <div class="seat-cell" class:seat-cell--hidden={!checkCanSeeSeat(cell.value, selectedPtc)}>
                      {#if exitRightIcon !== EMPTY && cell.key === lastCol}
                        <div class="seat-exit-row-right">
                          <svelte:component this={exitRightIcon} />
                        </div>
                      {/if}
                      {#if exitLeftIcon !== EMPTY && cell.key === firstCol}
                        <div class="seat-exit-row-left">
                          <svelte:component this={exitLeftIcon} />
                        </div>
                      {/if}
                      <CustomTooltip align="center">
                        <ul class="seat-tooltip" slot="content">
                          {#each seatCharacteristicsCode as code}
                            <li>
                              {SEAT_CHARACTERISTICS_CODE[code]}
                            </li>
                          {/each}
                          <li>
                            {formatPriceNumber(totalAmount?.Amount)}
                            {totalAmount?.CurCode ?? EMPTY}
                          </li>
                        </ul>
                        <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                        <button
                          class="seat-cell-content"
                          slot="btn"
                          style="background-color: {getSeatClass(cell.value, selectedSeat, selectedPtc).color};"
                          on:click={() => handleSelectedSeat(cell.value)}
                          on:mouseover={() => {
                            const { Service = [] } =
                              AlaCarteOfferItem.find(({ OfferItemID }) =>
                                intersection(cell.value.OfferItemRefID, selectedPtc.offerItemIDWhiteList).includes(
                                  OfferItemID
                                )
                              ) ?? {};
                            console.log({ ...cell.value, name: Service?.[0]?.Definition?.Name });
                          }}
                          disabled={!checkEnableSeat(cell.value)}
                        >
                          {#if getSeatClass(cell.value, selectedSeat, selectedPtc).id === 5}
                            <Close size="14" color="#FFFFFF" />
                          {/if}
                        </button>
                      </CustomTooltip>
                    </div>
                  {:else}
                    <div />
                  {/if}
                {/if}
              </div>
            </DataTable>
          {/each}
        {/if}
      </div>
    </div>
    <div class="seats-area--right">
      {#key ptcList}
        <DataTable
          id="seat-ptc-table"
          selectable
          on:click:row={(event) => handleSelectRowPtc(event.detail)}
          headers={headersPtc}
          size="medium"
          rows={ptcList}
          selectedRowIds={[selectedPtc?.id]}
        >
          <div slot="cell" let:cell let:row>
            {#if cell.key === 'price'}
              {#if row.totalAmount !== EMPTY}
                {formatPriceNumber(row.totalAmount?.Amount ?? EMPTY)}
                {row.totalAmount?.CurCode ?? EMPTY}
              {/if}
            {:else if cell.key === 'seat'}
              {#if row.seat !== EMPTY}
                {row.seat.row} {row.seat.column}
              {/if}
            {:else if cell.key == 'action'}
              {#if row.seat !== EMPTY}
                <button class="g-button" on:click={() => handleClearSelectedSeat(row)}>
                  <TrashCan />
                </button>
              {/if}
            {:else}
              {cell.value}
            {/if}
          </div>
        </DataTable>
      {/key}
      <div class="seats-area--right-bottom">
        <div>총 금액</div>
        <div>{formatPriceNumber(priceAmount.Amount)} {priceAmount.CurCode}</div>
      </div>
      {#if ['AF', 'KL'].includes(airlineID)}
        <div class="calculate-price">
          <PrimaryButton
            width="140px"
            size="small"
            on:click={() => dispatch('calculate-price')}
            disabled={disabledCalculatePrice}
          >
            {#if loadingCalculatePrice}
              <div class="g-custom-loading-button loading-box">
                <InlineLoading description="Loading ..." />
              </div>
            {:else}
              금액 재계산 하기
            {/if}
          </PrimaryButton>
        </div>
      {/if}
    </div>
  </div>
</div>
