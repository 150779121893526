import { writable } from 'svelte/store';

const createWritableStore = (key, startValue) => {
  const { subscribe, set, update } = writable(startValue);
  return {
    subscribe,
    set,
    update,
    useSessionStorage: () => {
      const json = sessionStorage.getItem(key);
      if (json) {
        set(JSON.parse(json));
      }

      subscribe((current) => {
        if (current === 'LOGOUT') sessionStorage.clear();
        else sessionStorage.setItem(key, JSON.stringify(current));
      });
    },
  };
};

export const user = createWritableStore('user', JSON.parse(sessionStorage.getItem('user')));
export const bookingReference = writable({});
export const message = writable([]);
export const screenIsNotEvent = writable(false);
export const eventStore = writable(null);
export const currentTab = writable({
  tabName: '',
  orderId: '',
  airlineId: '',
  type: '',
  isDeleted: false,
  orderStatus: '',
  cancelDate: '',
  isPayLater: '',
});
