<!-- svelte-ignore a11y-click-events-have-key-events -->
<script>
  import { CustomTextInput } from 'src/components/common/input';
  import { cloneDeep } from 'lodash';
  import { EMPTY, InputOptions } from 'src/constants/app.js';
  import { DatePicker, DatePickerInput } from 'src/components/common/date-picker/custom-date-picker/index';
  import { formatArialLabelDate } from 'src/utils/DateTime';
  import { AddAlt, SubtractAlt } from 'carbon-icons-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getDateTimeNow } from 'src/utils/DateTime';
  import ArrowsHorizontal from 'carbon-icons-svelte/lib/ArrowsHorizontal.svelte';

  export let type = 'TWO_WAY';

  const dispatch = createEventDispatcher();
  const minDate = getDateTimeNow();
  let multiWayCount = 2;

  export const defaultData = {
    TWO_WAY: {
      DepartureDate1: EMPTY,
      DepartureDate2: EMPTY,
    },
    ONE_WAY: {
      DepartureDate1: EMPTY,
    },
    MULTI_WAY: {
      DepartureDate1: EMPTY,
      DepartureDate2: EMPTY,
      DepartureDate3: EMPTY,
      DepartureDate4: EMPTY,
    },
    OriginCode1: EMPTY,
    DestCode1: EMPTY,
    OriginCode2: EMPTY,
    DestCode2: EMPTY,
    OriginCode3: EMPTY,
    DestCode3: EMPTY,
    OriginCode4: EMPTY,
    DestCode4: EMPTY,
  };
  let data = cloneDeep(defaultData);

  const handleAdd = () => {
    if (multiWayCount > 4) {
      multiWayCount = 4;
      return;
    }
    multiWayCount += 1;
  };
  const handleDelete = () => {
    for (const key in data[type]) {
      if (key.includes(multiWayCount)) data[type][key] = EMPTY;
    }

    if (multiWayCount < 2) {
      multiWayCount = 2;
      return;
    }
    multiWayCount -= 1;
  };

  export const handleReset = () => {
    data = cloneDeep(defaultData);
  };
  const handleSwap = (key1, key2) => {
    const cache = data[key1];
    data[key1] = data[key2];
    data[key2] = cache;
  };

  const getFocusDates = (dates) => {
    const KEYS = ['DepartureDate1', 'DepartureDate2', 'DepartureDate3', 'DepartureDate4'];
    return KEYS.map((key) => formatArialLabelDate(dates[key])).filter(Boolean);
  };

  const handleChangeData = (data) => {
    dispatch('change', { ...data, multiWayCount });
  };

  const handleChangeTypeWay = (type) => {
    multiWayCount = 2;
  };

  onMount(() => {
    handleChangeData(data);
  });
  $: handleChangeData(data);
  $: handleChangeTypeWay(type);
</script>

<div class="flight-schedule">
  {#if type === 'TWO_WAY'}
    <div class="flight-schedule__item">
      <div class="destination-departure">
        <CustomTextInput
          labelText="출발지"
          placeholder="영문 3코드"
          bind:value={data['OriginCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
        <div class="destination-departure__swap-button">
          <span
            class="destination-departure__swap-arrow-icon"
            on:click|stopPropagation|preventDefault={() => handleSwap('OriginCode1', 'DestCode1')}
          >
            <ArrowsHorizontal />
          </span>
        </div>
        <CustomTextInput
          labelText="도착지"
          placeholder="영문 3코드"
          bind:value={data['DestCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
      </div>
      <div class="flight-schedule__box-dateTime">
        {#key data[type].DepartureDate1 && data[type].DepartureDate2}
          <DatePicker
            flatpickrProps={{
              allowInput: false,
            }}
            {minDate}
            datePickerType="range"
            dateFormat="d/m/Y"
            bind:valueFrom={data[type].DepartureDate1}
            bind:valueTo={data[type].DepartureDate2}
          >
            <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
            <DatePickerInput labelText="오는날" placeholder="dd/mm/yyyy" disabled={!data[type].DepartureDate2} />
          </DatePicker>
        {/key}
      </div>
    </div>
  {:else if type === 'ONE_WAY'}
    <div class="flight-schedule__item">
      <div class="destination-departure">
        <CustomTextInput
          labelText="출발지"
          placeholder="영문 3코드"
          bind:value={data['OriginCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
        <div class="destination-departure__swap-button">
          <span
            class="destination-departure__swap-arrow-icon"
            on:click|stopPropagation|preventDefault={() => handleSwap('OriginCode1', 'DestCode1')}
          >
            <ArrowsHorizontal />
          </span>
        </div>
        <CustomTextInput
          labelText="도착지"
          placeholder="영문 3코드"
          bind:value={data['DestCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
      </div>
      <div class="flight-schedule__box-dateTime">
        <DatePicker
          datePickerType="single"
          bind:value={data[type].DepartureDate1}
          dateFormat="d/m/Y"
          {minDate}
          flatpickrProps={{
            allowInput: false,
            mode: 'multiple',
            autoFillDefaultTime: true,
          }}
        >
          <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
        </DatePicker>
      </div>
    </div>
  {:else if type === 'MULTI_WAY'}
    <div class="flight-schedule__item">
      <div class="destination-departure">
        <CustomTextInput
          labelText="출발지"
          placeholder="영문 3코드"
          bind:value={data['OriginCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
        <div class="destination-departure__swap-button">
          <span
            class="destination-departure__swap-arrow-icon"
            on:click|stopPropagation|preventDefault={() => handleSwap('OriginCode1', 'DestCode1')}
          >
            <ArrowsHorizontal />
          </span>
        </div>
        <CustomTextInput
          labelText="도착지"
          placeholder="영문 3코드"
          bind:value={data['DestCode1']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
      </div>
      <div class="flight-schedule__box-dateTime">
        <DatePicker
          datePickerType="single"
          bind:value={data[type].DepartureDate1}
          dates={getFocusDates(data[type])}
          dateFormat="d/m/Y"
          {minDate}
          flatpickrProps={{
            allowInput: false,
          }}
        >
          <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
        </DatePicker>
      </div>
    </div>

    <div class="flight-schedule__item">
      <div class="destination-departure">
        <CustomTextInput
          labelText="출발지"
          placeholder="영문 3코드"
          bind:value={data['OriginCode2']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
        <div class="destination-departure__swap-button">
          <span
            class="destination-departure__swap-arrow-icon"
            on:click|stopPropagation|preventDefault={() => handleSwap('OriginCode2', 'DestCode2')}
          >
            <ArrowsHorizontal />
          </span>
        </div>
        <CustomTextInput
          labelText="도착지"
          placeholder="영문 3코드"
          bind:value={data['DestCode2']}
          options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
          maxlength={3}
        />
      </div>
      <div class="flight-schedule__box-dateTime">
        <DatePicker
          datePickerType="single"
          bind:value={data[type].DepartureDate2}
          dates={getFocusDates(data[type])}
          dateFormat="d/m/Y"
          {minDate}
          flatpickrProps={{
            allowInput: false,
          }}
        >
          <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
        </DatePicker>
      </div>
    </div>

    {#if multiWayCount >= 3}
      <div class="flight-schedule__item">
        <div class="destination-departure">
          <CustomTextInput
            labelText="출발지"
            placeholder="영문 3코드"
            bind:value={data['OriginCode3']}
            options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
            maxlength={3}
          />
          <div class="destination-departure__swap-button">
            <span
              class="destination-departure__swap-arrow-icon"
              on:click|stopPropagation|preventDefault={() => handleSwap('OriginCode3', 'DestCode3')}
            >
              <ArrowsHorizontal />
            </span>
          </div>
          <CustomTextInput
            labelText="도착지"
            placeholder="영문 3코드"
            bind:value={data['DestCode3']}
            options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
            maxlength={3}
          />
        </div>
        <div class="flight-schedule__box-dateTime">
          <DatePicker
            datePickerType="single"
            bind:value={data[type].DepartureDate3}
            dates={getFocusDates(data[type])}
            dateFormat="d/m/Y"
            {minDate}
            flatpickrProps={{
              allowInput: false,
            }}
          >
            <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
          </DatePicker>
        </div>
      </div>
    {/if}

    {#if multiWayCount === 4}
      <div class="flight-schedule__item">
        <div class="destination-departure">
          <CustomTextInput
            labelText="출발지"
            placeholder="영문 3코드"
            bind:value={data['OriginCode4']}
            options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
            maxlength={3}
          />
          <div class="destination-departure__swap-button">
            <span
              class="destination-departure__swap-arrow-icon"
              on:click|stopPropagation|preventDefault={() => {
                handleSwap('OriginCode4', 'DestCode4');
              }}
            >
              <ArrowsHorizontal />
            </span>
          </div>
          <CustomTextInput
            labelText="도착지"
            placeholder="영문 3코드"
            bind:value={data['DestCode4']}
            options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
            maxlength={3}
          />
        </div>
        <div class="flight-schedule__box-dateTime">
          <DatePicker
            datePickerType="single"
            bind:value={data[type].DepartureDate4}
            dates={getFocusDates(data[type])}
            dateFormat="d/m/Y"
            {minDate}
            flatpickrProps={{
              allowInput: false,
            }}
          >
            <DatePickerInput labelText="가는날" placeholder="dd/mm/yyyy" />
          </DatePicker>
        </div>
      </div>
    {/if}

    <div class="btn-toggle-wrapper" class:btn-toggle-wrapper--trick={multiWayCount === 3}>
      {#if multiWayCount < 4}
        <button class="btn-toggle" on:click|preventDefault|stopPropagation={handleAdd}>
          <AddAlt />
        </button>
      {/if}
      {#if multiWayCount > 2}
        <button class="btn-toggle" on:click|preventDefault|stopPropagation={handleDelete}>
          <SubtractAlt />
        </button>
      {/if}
    </div>
  {/if}
</div>
