<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { DataTable, Checkbox, Button, ModalBody, ModalFooter } from 'carbon-components-svelte';
  import { reduce, map, keys, forEach, has, find, isEmpty, intersection } from 'lodash';
  import { getMaxWeightAllowance, formatDate, getCabinName, splitConvertArrayId } from 'src/utils/appHelper.js';
  import { EMPTY } from 'src/constants/app.js';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';

  import './index.scss';

  export let stepId;
  export let orderRetrieve;
  export let stepData;
  const dispatch = createEventDispatcher();

  const headers = [
    {
      key: 'action',
      value: EMPTY,
    },
    {
      key: 'airlineId',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.airlineId', {
        default: '항공사',
      }),
    },
    {
      key: 'flightNumber',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.flightNumber', {
        default: '편명',
      }),
    },
    {
      key: 'aircraftCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.aircraftCode', {
        default: '기종',
      }),
    },
    {
      key: 'departureAirportCode',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.departureAirportCode',
        {
          default: '출발지',
        }
      ),
    },
    {
      key: 'arrivalAirportCode',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.arrivalAirportCode',
        {
          default: '도착지',
        }
      ),
    },
    {
      key: 'departureTime',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.departureTime', {
        default: '출발일시',
      }),
    },
    {
      key: 'arrivalTime',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.arrivalTime', {
        default: '도착일시',
      }),
    },
    {
      key: 'cabinCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.cabinCode', {
        default: '좌석등급',
      }),
    },
    {
      key: 'name',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.name', {
        default: 'PriceClass',
      }),
    },
    {
      key: 'rbd',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.rbd', {
        default: 'RBD',
      }),
    },
    {
      key: 'maxWeightAllowance',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.maxWeightAllowance',
        {
          default: '수하물',
        }
      ),
    },
    {
      key: 'typeAirlineId',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.chooseAChangeItinerary.typeAirlineId', {
        default: '코드쉐어',
      }),
    },
  ];
  let selection = [];
  let paxJourneyListStepOne = [];

  function convertData(_data) {
    const handle = (items) => {
      let initValue = {
        airlineId: [],
        flightNumber: [],
        aircraftCode: [],
        departureAirportCode: [],
        arrivalAirportCode: [],
        departureTime: [],
        arrivalTime: [],
        cabinCode: [],
        name: [],
        rbd: [],
        maxWeightAllowance: [],
        typeAirlineId: [],
      };
      return reduce(
        items,
        (result, value) => {
          forEach(keys(value), (currentKey) => {
            result[`${currentKey}`]?.push(value[`${currentKey}`]);
          });
          return result;
        },
        initValue
      );
    };
    const result = map(_data, (_dataItem) => {
      const { id, action, items, PaxJourneyID } = _dataItem;
      return {
        id,
        action,
        PaxJourneyID,
        ...handle(items),
      };
    });
    return result;
  }

  function handleSelectCheckBox(e, row) {
    const findIndex = selection.findIndex((select) => select === row.PaxJourneyID);
    if (findIndex === -1) selection = [...selection, row.PaxJourneyID];
    else selection = selection.filter((select) => select !== row.PaxJourneyID);
  }

  function closeModalItinerary() {
    dispatch('close-modal-itinerary');
  }

  function handleNextStep() {
    dispatch('next-step-one', {
      currentStepId: stepId,
      rowSelected: paxJourneyListStepOne.filter((item) => selection.includes(item.PaxJourneyID)),
      paxJourneyTable: paxJourneyListStepOne,
    });
  }

  onMount(() => {
    if (stepData) {
      const rowSelected = stepData.rowSelected;
      selection = rowSelected.map((item) => item.PaxJourneyID);
    }
    if (orderRetrieve && orderRetrieve?.code === 200) {
      const paxSegmentList = orderRetrieve?.data?.DataLists?.PaxSegmentList || [];
      let OrderItem = orderRetrieve?.data?.Order?.OrderItem;
      let fareComponent = [];
      const paxIdListADTorCHD = orderRetrieve?.data?.DataLists?.PaxList?.filter((item) =>
        ['ADT', 'CHD'].includes(item.Ptc)
      ).map(({ PaxID }) => PaxID);
      for (let i = 0; i < OrderItem?.length; i++) {
        const check = find(OrderItem[i].Service, (sv) => !has(sv, 'Definition'));
        if (check) {
          const PaxRefID = splitConvertArrayId(OrderItem[i].PaxRefID);
          if (isEmpty(intersection(paxIdListADTorCHD, PaxRefID))) continue;
          OrderItem[i]?.FareDetail.forEach((item) => (fareComponent = [...fareComponent, ...item.FareComponent]));
        }
      }
      fareComponent = fareComponent.filter((item) => has(item, 'FareBasis'));

      const journeyOverview = orderRetrieve?.data?.Order?.JourneyOverview;
      const baggageAllowance = orderRetrieve?.data?.Order?.BaggageAllowance;
      const paxJourneyList = orderRetrieve?.data?.DataLists?.PaxJourneyList || [];
      paxJourneyListStepOne = paxJourneyList.map((paxJourneyItem, index) => {
        const paxSegmentRefIds = paxJourneyItem?.PaxSegmentRefID || [];
        const currentPaxJourneyID = paxJourneyItem?.PaxJourneyID || EMPTY;
        const items = paxSegmentRefIds.map((paxSegmentId) => {
          const findPaxSegment = paxSegmentList.find((item) => item.PaxSegmentID === paxSegmentId);
          const findFareComponent = fareComponent?.find((fare) => fare?.PaxSegmentRefID?.includes(paxSegmentId));
          const findJourneyOverview = journeyOverview.find(
            (journey) => journey?.PaxJourneyRefID === currentPaxJourneyID
          );
          let typeAirlineId = EMPTY;
          if (findPaxSegment?.OperatingCarrier) {
            const airlineIdOperatingCarrier = findPaxSegment?.OperatingCarrier?.AirlineID || EMPTY;
            const airlineIdMarketingCarrier = findPaxSegment?.MarketingCarrier?.AirlineID || EMPTY;
            if (airlineIdOperatingCarrier !== airlineIdMarketingCarrier) {
              if (airlineIdOperatingCarrier) typeAirlineId = airlineIdOperatingCarrier;
              else typeAirlineId = findPaxSegment?.OperatingCarrier?.Name || EMPTY;
            } else {
              typeAirlineId = EMPTY;
            }
          }
          const getAircraftCode = () => {
            const Equipment = findPaxSegment?.Equipment;
            if (Equipment) return Equipment?.AircraftCode ?? EMPTY;
            if (has(findPaxSegment, 'Legs')) {
              const findEquipment = find(findPaxSegment?.Legs, (leg) => has(leg, 'Equipment'));
              if (findEquipment) return findEquipment?.Equipment?.AircraftCode ?? EMPTY;
            }
          };
          return {
            paxSegmentId,
            airlineId: findPaxSegment?.MarketingCarrier?.AirlineID || EMPTY,
            flightNumber: findPaxSegment?.MarketingCarrier?.FlightNumber.padStart(4, '0') || EMPTY,
            aircraftCode: getAircraftCode(),
            departureAirportCode: findPaxSegment?.Departure?.AirportCode || EMPTY,
            arrivalAirportCode: findPaxSegment?.Arrival?.AirportCode || EMPTY,
            departureTime: formatDate(
              `${findPaxSegment?.Departure?.Date || EMPTY} ${findPaxSegment?.Departure?.Time || EMPTY}`,
              'datetime'
            ),
            arrivalTime: formatDate(
              `${findPaxSegment?.Arrival?.Date || EMPTY} ${findPaxSegment?.Arrival?.Time || EMPTY}`,
              'datetime'
            ),
            cabinCode: getCabinName(
              findPaxSegment?.MarketingCarrier?.AirlineID,
              findFareComponent?.FareBasis?.CabinType.find((cabin) => cabin.PaxSegmentRefID === paxSegmentId)
                ?.CabinCode || EMPTY
            ),
            name: findJourneyOverview?.PriceClassInfo?.Name || EMPTY,
            rbd:
              findFareComponent?.FareBasis?.CabinType.find((cabin) => cabin.PaxSegmentRefID === paxSegmentId)?.Rbd ||
              EMPTY,
            maxWeightAllowance: getMaxWeightAllowance(baggageAllowance, currentPaxJourneyID),
            typeAirlineId: typeAirlineId,
          };
        });
        return {
          id: index + 1,
          action: false,
          ...paxJourneyItem,
          items,
        };
      });
    }
  });
</script>

<ModalBody>
  <div class="chooseAChangeItinerary">
    <h4>변경할 여정</h4>
    <DataTable {headers} rows={convertData(paxJourneyListStepOne)}>
      <svelte:fragment slot="cell" let:row let:cell>
        {#if cell.key === 'action'}
          <div class="checkbox-step">
            <Checkbox
              checked={selection.includes(row.PaxJourneyID)}
              on:check={(e) => {
                handleSelectCheckBox(e, row);
              }}
            />
          </div>
        {:else}
          <ul>
            {#each cell.value as item, i}
              <li class={`${!item && 'space-incognito'}`}>
                {item ? item : 'none text'}
              </li>
              {#if i !== cell.value.length - 1}
                <div class="delimiter" />
              {/if}
            {/each}
          </ul>
        {/if}
      </svelte:fragment>
    </DataTable>
  </div>
</ModalBody>
<ModalFooter>
  <footer>
    <div class="step-one">
      <Button kind="secondary" on:click={closeModalItinerary}>취소</Button>
      <Button on:click={handleNextStep} disabled={!selection.length}>다음</Button>
    </div>
  </footer>
</ModalFooter>
