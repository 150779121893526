<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2485_171242)">
    <path
      d="M26 2L14 2C13.4698 2.00061 12.9614 2.21152 12.5865 2.58646C12.2115 2.9614 12.0006 3.46975 12 4L12 7L14 7L14 4L26 4L26 28L14 28L14 25L12 25L12 28C12.0006 28.5302 12.2115 29.0386 12.5865 29.4135C12.9614 29.7885 13.4698 29.9994 14 30L26 30C26.5302 29.9994 27.0386 29.7885 27.4135 29.4135C27.7885 29.0386 27.9994 28.5302 28 28L28 4C27.9994 3.46975 27.7885 2.9614 27.4135 2.58646C27.0386 2.21152 26.5302 2.00061 26 2Z"
      fill="black"
    />
    <path d="M11.414 11.414L7.828 15L22 15L22 17L7.828 17L11.414 20.586L10 22L4 16L10 10L11.414 11.414Z" fill="black" />
  </g>
  <defs>
    <clipPath id="clip0_2485_171242">
      <rect width="32" height="32" fill="white" />
    </clipPath>
  </defs>
</svg>
