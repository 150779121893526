<script>
  import {
    Form,
    FormGroup,
    Button,
    DataTable,
    DatePicker,
    DatePickerInput,
    Pagination,
    Toolbar,
    ToolbarContent,
    MultiSelect,
    ComboBox,
  } from 'carbon-components-svelte';
  import { AddAlt, SubtractAlt, Download } from 'carbon-icons-svelte';
  import { sortBy, map, cloneDeep, reduce, find, filter, has } from 'lodash';
  import { onMount } from 'svelte';
  import { getDateTimeNow } from 'src/utils/DateTime.js';
  import { message } from 'src/store';
  import { getAgencyProviderAirlineCode } from 'src/service/reservation.js';
  import { EMPTY, PAGE_TABLE_SIZES_OCN_BOOKING, InputOptions, InputOptionsInvalid } from 'src/constants/app.js';
  import { bookingReference } from 'src/store';
  import {
    formatQueryParams,
    exportExcelFileName,
    standardObj,
    formatDate,
    getMainBookingReference,
    getOtherPnrByBookingReference,
  } from 'src/utils/appHelper.js';
  import { bookingService } from 'src/service';
  import { _ } from 'src/lib/i18n.js';
  import { CustomLoading } from 'src/components/common/loading';
  import { TextOverflowTooltip } from 'src/components/common/tooltip';
  import { CustomTextInput } from 'src/components/common/input';

  const INIT_FORM_OPTION = {
    create_effective: getDateTimeNow(),
    create_expiration: getDateTimeNow(),
    ticketing_effective: EMPTY,
    ticketing_expiration: EMPTY,
    departure_effective: EMPTY,
    departure_expiration: EMPTY,
    booking_reference: EMPTY,
    airline_id: [],
    order_id: EMPTY,
    pax_individual_given_name: EMPTY,
    pax_individual_sur_name: EMPTY,
    pax_individual_ptc: EMPTY,
    order_status: [],
    itinerary: EMPTY,
  };

  const listOrderStatus = [
    { id: 0, text: 'OK' },
    { id: 1, text: 'X' },
  ];

  const headers = [
    {
      key: 'createDate',
      value: $_('layout.pages.bookingContent.headerTable.createDate', {
        default: '예약일시',
      }),
    },
    {
      key: 'ticketingDate',
      value: $_('layout.pages.bookingContent.headerTable.ticketingDate', {
        default: '발권일시',
      }),
    },
    {
      key: 'orderStatus',
      value: $_('layout.pages.bookingContent.headerTable.orderStatus', {
        default: '예약상태',
      }),
    },
    {
      key: 'airlineID',
      value: $_('layout.pages.bookingContent.headerTable.airlineID', {
        default: '항공사',
      }),
    },
    {
      key: 'bookingReference',
      value: $_('layout.pages.bookingContent.headerTable.bookingReference', {
        default: 'PNR',
      }),
    },
    {
      key: 'otherPnr',
      value: $_('layout.pages.bookingContent.headerTable.otherPnr', {
        default: 'Other PNR',
      }),
    },
    {
      key: 'orderID',
      value: $_('layout.pages.bookingContent.headerTable.OrderID', {
        default: 'OrderID',
      }),
    },
    {
      key: 'surGiveName',
      value: $_('layout.pages.bookingContent.headerTable.surGiveName', {
        default: '탑승객명',
      }),
    },
    {
      key: 'itinerary',
      value: $_('layout.pages.bookingContent.headerTable.itinerary', {
        default: '여정요약',
      }),
    },
    {
      key: 'departureDate',
      value: $_('layout.pages.bookingContent.headerTable.departureDate', {
        default: '출발일',
      }),
    },
  ];

  const checkDateTime = [
    'create_effective',
    'create_expiration',
    'ticketing_effective',
    'ticketing_expiration',
    'departure_effective',
    'departure_expiration',
  ];

  const listAckFromAgency = [
    { id: 1, text: 'Y' },
    { id: 2, text: 'N' },
  ];
  const textInputValidOptions = [
    {
      condition: InputOptionsInvalid.MATCH_KOREAN,
      notify: '*한글은 입력할 수 없습니다',
    },
  ];
  let formOption = { ...INIT_FORM_OPTION };
  let airlineCode = [];
  let queryDateTimeType = 'CREATE'; // 'CREATE_TICKETING' or 'CREATE_TICKETING_DEPARTURE'
  let pageSize = PAGE_TABLE_SIZES_OCN_BOOKING[0];
  let page = 1;
  let selectedOrderStatus = [];
  let selectedIdsAirlineId = [];
  let dataTable = [];
  let isLoading = false;

  let selectedAckFromAgency;

  const handleNotifyEmpty = (dataTable) => {
    if (!dataTable.length) {
      message.set([
        {
          subtitle: '검색결과가 없습니다',
          caption: EMPTY,
        },
      ]);
    }
  };

  const updateDataTableBookingContent = async (option) => {
    const params = formatQueryParams(option, checkDateTime);
    const res = await bookingService.getManagementOrder(params);
    let MatchedOrder = res?.data?.Response?.MatchedOrder || [];
    if (!!selectedAckFromAgency) {
      if (selectedAckFromAgency == 1)
        MatchedOrder = filter(MatchedOrder, (item) => has(item, 'TicketingDate') && item?.TicketingDate);
      if (selectedAckFromAgency == 2) {
        MatchedOrder = filter(MatchedOrder, (item) => !has(item, 'TicketingDate') || !item.TicketingDate);
      }
    }
    let data = map(MatchedOrder, (it, index) => {
      const {
        TicketingDate,
        OrderStatus,
        BookingReference,
        OrderID,
        PaxIndividual,
        Itinerary,
        DepartureDate,
        CancelDate,
        CreateDate,
      } = it;
      let findB = getMainBookingReference(BookingReference);
      let getItinerary = () => {
        const arrStr = Itinerary.split(EMPTY);
        return reduce(
          arrStr,
          (total, it, index) => {
            if (index === 0) return it;
            if (index % 3 === 0) {
              return total + '-' + it;
            }
            return total + it;
          },
          EMPTY
        );
      };
      const getSurname = () => {
        const surnameHasADT = find(PaxIndividual, (PaxIndividualItem) => 'ADT' === PaxIndividualItem.Ptc);
        if (surnameHasADT) return `${surnameHasADT?.Surname}/${surnameHasADT?.GivenName}`;
        const surnameHasCHD = find(PaxIndividual, (PaxIndividualItem) => 'CHD' === PaxIndividualItem.Ptc);
        if (surnameHasCHD) return `${surnameHasCHD?.Surname}/${surnameHasCHD?.GivenName}`;
        return EMPTY;
      };
      return standardObj({
        id: index,
        createDate: CreateDate,
        ticketingDate: formatDate(TicketingDate, 'datetime'),
        orderStatus: OrderStatus,
        airlineID: findB?.AirlineID || EMPTY,
        otherPnr: getOtherPnrByBookingReference(BookingReference),
        bookingReference: findB?.Id || EMPTY,
        orderID: OrderID,
        surGiveName: getSurname(),
        itinerary: getItinerary(),
        departureDate: formatDate(DepartureDate, 'datetime'),
        cancelDate: CancelDate || EMPTY,
      });
    });
    data = sortBy(data, (it) => it.createDate).reverse();
    data = map(data, (dataItem) => {
      dataItem.createDate = formatDate(dataItem.createDate, 'datetime');
      return dataItem;
    });

    return data;
  };

  const handleGetDataTable = async () => {
    isLoading = true;
    dataTable = [];
    dataTable = await updateDataTableBookingContent(formOption);
    isLoading = false;
    handleNotifyEmpty(dataTable);
  };
  const handleResetData = async () => {
    selectedOrderStatus = [];
    selectedIdsAirlineId = [];
    formOption = cloneDeep(INIT_FORM_OPTION);
    queryDateTimeType = 'CREATE';
    selectedAckFromAgency = null;
  };

  const handleClickOderId = (row) => {
    bookingReference.set({
      booking_reference: row.bookingReference,
      order_id: row.orderID,
    });
  };

  function handleMoreDateTimeQuery() {
    if (queryDateTimeType === 'CREATE') {
      queryDateTimeType = 'CREATE_TICKETING';
      formOption.ticketing_effective = getDateTimeNow();
      formOption.ticketing_expiration = getDateTimeNow();
    } else if (queryDateTimeType === 'CREATE_TICKETING') {
      queryDateTimeType = 'CREATE_TICKETING_DEPARTURE';
      formOption.departure_effective = getDateTimeNow();
      formOption.departure_expiration = getDateTimeNow();
    }
  }
  function handleLessDateTimeQuery() {
    if (queryDateTimeType === 'CREATE_TICKETING') {
      queryDateTimeType = 'CREATE';
      formOption.ticketing_effective = EMPTY;
      formOption.ticketing_expiration = EMPTY;
    } else if (queryDateTimeType === 'CREATE_TICKETING_DEPARTURE') {
      queryDateTimeType = 'CREATE_TICKETING';
      formOption.departure_effective = EMPTY;
      formOption.departure_expiration = EMPTY;
    }
  }
  async function handleClickExportExcel() {
    let params = formatQueryParams(formOption, checkDateTime);
    const dataExcel = await bookingService.exportExcelBooking(params);
    exportExcelFileName(dataExcel, '예약리스트');
  }

  $: {
    const matchedAirlineCode = [];
    for (const item of airlineCode) {
      if (selectedIdsAirlineId.includes(item.id)) {
        if (['HA', 'LX'].includes(item.text)) {
          matchedAirlineCode.push(item.text + 'A');
        }
        matchedAirlineCode.push(item.text);
      }
    }
    const matchedOrderStatus = [];
    for (const item of listOrderStatus) {
      if (selectedOrderStatus.includes(item.id)) {
        matchedOrderStatus.push(item.text);
      }
    }
    formOption = {
      ...formOption,
      order_status: matchedOrderStatus,
      airline_id: matchedAirlineCode,
    };
  }

  onMount(async () => {
    const listAirline = await getAgencyProviderAirlineCode();
    const Airline = listAirline?.data?.Response?.AgencyProvider[0]?.Airline || [];
    const airlineCodeApi = map(
      sortBy(Airline, (itAirline) => itAirline.AirlineCode),
      (it, index) => {
        const { AirlineCode } = it;
        return {
          id: index + 1,
          text: AirlineCode,
        };
      }
    );
    airlineCode = [...airlineCodeApi];
  });
</script>

<div id="booking-content-wrapper">
  <Form class="form-wrapper">
    <FormGroup legendText="">
      <DatePicker
        datePickerType="range"
        bind:valueFrom={formOption.create_effective}
        bind:valueTo={formOption.create_expiration}
        dateFormat="d/m/Y"
      >
        <DatePickerInput labelText="예약일" placeholder="dd/mm/yyyy" />
        <DatePickerInput labelText=" " placeholder="dd/mm/yyyy" />
      </DatePicker>
    </FormGroup>
    <FormGroup class="input-wrapper">
      <div class="ocn--w-160">
        <MultiSelect
          filterable
          titleText="항공사"
          label="전체"
          placeholder="전체"
          items={airlineCode}
          sortItem={(a, b) => a.id - b.id}
          bind:selectedIds={selectedIdsAirlineId}
        />
      </div>
      <div class="ocn--w-150">
        <CustomTextInput
          labelText="PNR"
          bind:value={formOption.booking_reference}
          options={[InputOptions.ONLY_LATIN_NUMBER]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <div class="ocn--w-160">
        <CustomTextInput
          labelText="OrderID"
          bind:value={formOption.order_id}
          options={[InputOptions.PATTERN_ORDER_ID]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <div class="box-flex-c">
        <div class="ocn--w-160" id="passenger-name">
          <CustomTextInput
            labelText="탑승객명"
            placeholder="영문 성"
            bind:value={formOption.pax_individual_sur_name}
            options={[InputOptions.ONLY_LATIN_NUMBER]}
            invalidOptions={textInputValidOptions}
          />
        </div>
        <div class="ocn--w-160 hide-label">
          <CustomTextInput
            labelText="ds "
            placeholder="영문이름"
            bind:value={formOption.pax_individual_given_name}
            options={[InputOptions.ONLY_LATIN_NUMBER]}
            invalidOptions={textInputValidOptions}
          />
        </div>
      </div>
      <div class="ocn--w-160">
        <MultiSelect
          filterable
          titleText="예약상태"
          label="전체"
          placeholder="전체"
          items={listOrderStatus}
          sortItem={(a, b) => a.id - b.id}
          bind:selectedIds={selectedOrderStatus}
        />
      </div>

      <ComboBox
        class="ocn--w-160"
        titleText="발권여부"
        placeholder="전체"
        items={listAckFromAgency}
        bind:selectedId={selectedAckFromAgency}
      />
    </FormGroup>
    <FormGroup class="form-action">
      <Button class="action__btn" on:click={handleGetDataTable}>
        {$_('layout.pages.bookingContent.btnGetData', {
          default: '검색',
        })}
      </Button>
      <Button kind="secondary" class="action__btn" on:click={handleResetData}>
        {$_('layout.pages.bookingContent.btnResetFilter', {
          default: '초기화',
        })}
      </Button>
    </FormGroup>
    <FormGroup class="form-toggle--datetime">
      {#if queryDateTimeType === 'CREATE'}
        <Button class="btn-toggle" kind="ghost" on:click={handleMoreDateTimeQuery}>
          <AddAlt />
        </Button>
      {/if}
      {#if queryDateTimeType.includes('CREATE_TICKETING')}
        <div class="datetime-wrapper">
          <DatePicker
            datePickerType="range"
            dateFormat="d/m/Y"
            bind:valueFrom={formOption.ticketing_effective}
            bind:valueTo={formOption.ticketing_expiration}
          >
            <DatePickerInput labelText="발권일" placeholder="dd/mm/yyyy" />
            <DatePickerInput labelText=" " placeholder="dd/mm/yyyy" />
          </DatePicker>
          {#if queryDateTimeType === 'CREATE_TICKETING'}
            <div class="btn-toggle-wrapper">
              <Button class="btn-toggle" kind="ghost" on:click={handleMoreDateTimeQuery}>
                <AddAlt />
              </Button>
              <Button class="btn-toggle" kind="ghost" on:click={handleLessDateTimeQuery}>
                <SubtractAlt />
              </Button>
            </div>
          {/if}
        </div>
      {/if}
      {#if queryDateTimeType === 'CREATE_TICKETING_DEPARTURE'}
        <div class="datetime-wrapper">
          <DatePicker
            datePickerType="range"
            dateFormat="d/m/Y"
            bind:valueFrom={formOption.departure_effective}
            bind:valueTo={formOption.departure_expiration}
          >
            <DatePickerInput labelText="출발일" placeholder="dd/mm/yyyy" />
            <DatePickerInput labelText=" " placeholder="dd/mm/yyyy" />
          </DatePicker>
          <div class="btn-toggle-wrapper">
            <Button class="btn-toggle" kind="ghost" on:click={handleLessDateTimeQuery}>
              <SubtractAlt />
            </Button>
          </div>
        </div>
      {/if}
    </FormGroup>
  </Form>

  <div
    class="booking-content-table"
    class:booking-content-table-mt120={queryDateTimeType === 'CREATE_TICKETING_DEPARTURE'}
  >
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <DataTable {headers} rows={dataTable} stickyHeader {pageSize} {page} id="booking_content">
      <svelte:fragment slot="cell" let:cell let:row>
        {#if cell.key === 'orderID'}
          <TextOverflowTooltip
            text={cell.value}
            on:click={() => handleClickOderId(row)}
            class="g-button color-outstand-cell"
          >
            {cell.value}
          </TextOverflowTooltip>
        {:else}
          <TextOverflowTooltip text={cell.value}>
            {cell.value}
          </TextOverflowTooltip>
        {/if}
      </svelte:fragment>
      <Toolbar class="table-toolbar">
        <ToolbarContent>
          <div class="title-table">
            <h4 class="title-table-content">
              {$_('layout.pages.bookingContent.titleTable', {
                default: '검색결과',
              })}
            </h4>
          </div>
          <Button
            size="small"
            kind="secondary"
            class="custom-button-download ocn--w-160"
            disabled={!dataTable.length}
            on:click={handleClickExportExcel}
          >
            <span>
              {$_('layout.pages.bookingContent.btnDownload', {
                default: '엑셀 다운로드',
              })}
            </span>
            <span>
              <Download />
            </span>
          </Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    {#if dataTable?.length}
      <Pagination
        pageSizes={PAGE_TABLE_SIZES_OCN_BOOKING}
        bind:page
        totalItems={dataTable.length}
        bind:pageSize
        itemsPerPageText=""
      />
    {/if}
    {#if isLoading}
      <div class="loading-booking_ocn">
        <CustomLoading />
      </div>
    {/if}
  </div>
</div>
