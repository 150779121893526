<script>
  import {
    ComposedModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Tabs,
    Tab,
    DataTable,
    ComboBox,
    InlineLoading,
  } from 'carbon-components-svelte';
  import { onMount } from 'svelte';
  import { TrashCan } from 'carbon-icons-svelte/lib/index';
  import { v4 as uuidv4 } from 'uuid';
  import { isEmpty, compact, difference, isEqual, intersection } from 'lodash';
  import Portal from 'src/components/common/Portal.svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { formatPriceNumber, sortPaxListByPtc } from 'src/utils/appHelper.js';
  import { getOfferPrice } from 'src/service/reservationTicketing';
  import AddAlt from 'carbon-icons-svelte/lib/AddAlt.svelte';
  import SubtractAlt from 'carbon-icons-svelte/lib/SubtractAlt.svelte';
  import ChangePaymentPnrModal from 'src/components/modal/ChangePaymentPnrModal.svelte';
  import 'src/styles/modal/baggage-information.scss';

  export let open;
  export let orderId = EMPTY;
  export let airlineID = EMPTY;
  export let orderRetrieve;
  export let serviceList;

  let loading = 'NONE';
  let statusStep = 'NONE';
  let totalAmountServices = 0;
  let curCode = EMPTY;
  let headerTabs = [];
  let ptcList = [];
  let selectedTab = 0;
  let selectedServices = [];
  let isOpenPaymentPnrModal = false;

  const baggageTableHeaders = [
    { key: 'ptc', value: '탑승객명 (PTC)', width: '25%' },
    { key: 'serviceType', value: '서비스선택', width: '25%' },
    { key: 'serviceAmount', value: '서비스/금액', width: '50%' },
  ];

  const SERVICE_TYPE = { BAGGAGE: 'baggage', SEAT: 'seat' };
  const SERVICE_HAS_MULTIPLE = ['ONE KILOGRAM BAGGAGE', 'WEIGHT SYSTEM CHARGE'];

  function handleCloseModal() {
    open = false;
  }

  function createServiceTypeOptions(row) {
    const tab = headerTabs[selectedTab];
    const serviceBaggage = tab.serviceAmountOptions[0].filter((el) => el.PaxRefID.includes(row.paxId));
    const serviceSeatUpgrade = tab.serviceAmountOptions[1].filter((el) => el.PaxRefID.includes(row.paxId));
    const hasSeatUpgrade = ['LH', 'LX', 'LXA', 'OS'].includes(airlineID);
    const serviceOptions = isEmpty(serviceBaggage) ? [] : [{ id: '0', text: '위탁수하물', value: 'baggage' }];
    if (hasSeatUpgrade && !isEmpty(serviceSeatUpgrade))
      serviceOptions.push({ id: '1', text: '좌석승급', value: 'seat' });
    return serviceOptions;
  }

  function changeServiceType(e, indexRow, cell) {
    const tab = headerTabs[selectedTab];
    const { paxId } = ptcList[selectedTab][indexRow];
    const selectedValue = e.detail?.selectedId;
    ptcList[selectedTab][indexRow][cell.key] = selectedValue;
    ptcList[selectedTab][indexRow].serviceAmount = EMPTY;
    if (selectedValue) {
      const filteredServiceByPax = tab.serviceAmountOptions[Number(selectedValue)].filter((el) =>
        el.PaxRefID.includes(paxId)
      );
      const filteredSeatServiceBySelected = filteredServiceByPax.map((el) => {
        if (el.name === 'CASH UPGRADE' && !isEmpty(selectedServices)) {
          el.disabled = selectedServices.some(
            (service) =>
              isEqual(service.PaxSegmentRefID, el.PaxSegmentRefID) && service?.nameOriginal === 'CASH UPGRADE'
          );
        }
        if (isEmpty(selectedServices)) el.disabled = false;
        return el;
      });
      ptcList[selectedTab][indexRow].serviceAmountOptions = filteredSeatServiceBySelected;
    }
  }

  function transformServiceAmountOptions(options) {
    const { PaxList = [], PaxSegmentList = [] } = orderRetrieve?.data?.DataLists;
    return options.map((item, i) => {
      const combinedTxt = [
        item.Service[0].Definition.Name,
        formatPriceNumber(item.UnitPriceDetail.TotalAmount.Amount) + ' ' + item.UnitPriceDetail.TotalAmount.CurCode,
      ];
      if (['EK'].includes(airlineID)) combinedTxt.splice(1, 0, item.Service[0].Definition.Desc[0].Text);
      if (item.Service[0].Definition.Name === 'CASH UPGRADE') {
        const segments = PaxSegmentList.find((el) => el.PaxSegmentID === item.PaxSegmentRefID[0]);
        if (segments) combinedTxt[0] = segments.Departure.AirportCode + ' - ' + segments.Arrival.AirportCode;
        const additionalTxt = item.Service[0].Definition.Desc.find((el) => el.Text.includes('UPGRADE TO CABIN'));
        if (additionalTxt) combinedTxt.splice(1, 0, additionalTxt.Text.substring('UPGRADE TO CABIN'.length + 1).trim());
      }
      return {
        id: i,
        text: combinedTxt.join(' / '),
        name: item.Service[0].Definition.Name,
        amount: item.UnitPriceDetail.TotalAmount.Amount,
        curCode: item.UnitPriceDetail.TotalAmount.CurCode,
        ...item,
      };
    });
  }

  function clearServiceType(indexRow) {
    ptcList[selectedTab][indexRow].serviceAmountOptions = [];
    ptcList[selectedTab][indexRow].serviceAmount = EMPTY;
  }

  async function reCalculatePrice() {
    const { PaxList = [] } = orderRetrieve?.data?.DataLists;
    const { ResponseID } = serviceList?.data;
    const { OfferID, Owner } = serviceList?.data?.AlaCarteOffer;
    const paxListPayload = PaxList.map(({ LoyaltyProgramAccount, PaxID, Ptc }) => ({
      LoyaltyProgramAccount,
      PaxID,
      Ptc,
    }));
    const mappedServices = selectedServices.map((el) => {
      const nameService = el?.Service?.[0]?.Definition?.Name || EMPTY;
      const bookingInstructions = el.Service?.[0]?.Definition?.BookingInstructions;
      if (SERVICE_HAS_MULTIPLE.includes(nameService) && bookingInstructions) {
        bookingInstructions.Text[0] = `TTL${el.count}KG`;
      }
      return el;
    });

    const offerPayload = compact(mappedServices).map((el) => {
      const item = { OfferItemID: el.OfferItemID, PaxRefID: el.PaxRefID };
      const bookingInstructions = el.Service?.[0]?.Definition?.BookingInstructions;
      if (bookingInstructions) item.BookingInstructions = bookingInstructions;
      return item;
    });
    const payload = {
      Query: {
        Offers: [
          {
            OfferID,
            Owner,
            ResponseID,
            OfferItems: offerPayload,
          },
        ],
        PaxList: paxListPayload,
      },
    };

    if (['AF', 'KL'].includes(airlineID)) {
      payload.Query.Criteria = {
        ExistingOrderCriteria: {
          OrderID: orderId,
          PaxRefID: paxListPayload.map((el) => el.PaxID),
        },
      };
    }

    try {
      loading = 'RECALCULATE';
      const response = await getOfferPrice(payload);
      if (response?.data?.ResultMessage?.Code === CODE_SUCCESS) {
        const { TotalAmount = {} } = response?.data.PricedOffer?.TotalPrice;
        totalAmountServices = TotalAmount?.Amount || 0;
        curCode = TotalAmount.CurCode || EMPTY;
        statusStep = 'ORDERCHANGE';
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading = 'NONE';
    }
  }

  function getServiceAmountOptions(segments) {
    let baggageList = [];
    let seatUpgradeList = [];
    const { AlaCarteOfferItem = [] } = serviceList?.data?.AlaCarteOffer;
    const filteredBySegment = AlaCarteOfferItem.filter((offerItem) => {
      if (offerItem.PaxSegmentRefID.length !== segments.length) return false;
      if (
        difference(offerItem.PaxSegmentRefID, segments).length === 0 &&
        difference(segments, offerItem.PaxSegmentRefID).length === 0
      ) {
        return true;
      } else return false;
    });
    const filteredSeatServicesBySegment = AlaCarteOfferItem.filter((offerItem) => {
      return !isEmpty(intersection(offerItem.PaxSegmentRefID, segments));
    });
    if (['LH', 'LX', 'LXA', 'OS'].includes(airlineID)) {
      baggageList = filteredBySegment.filter((el) => el.Service[0].Definition.Name === 'ADDITIONAL BAGGAGE');
      seatUpgradeList = filteredSeatServicesBySegment.filter((el) => el.Service[0].Definition.Name === 'CASH UPGRADE');
    } else if (['AF', 'KL'].includes(airlineID)) {
      baggageList = filteredBySegment.filter((el) => el.Service[0].Definition.Name.includes('LUGGAGE'));
    } else if (['EK'].includes(airlineID)) {
      baggageList = filteredBySegment.filter((el) => el.Service[0].Definition.Name.includes('PrePaid Bags'));
    } else if (['SQ', 'QR'].includes(airlineID)) {
      const acceptedListService = ['ONE KILOGRAM BAGGAGE', 'EXCESS SIZE', 'EXCESS PIECE', 'EXCESS WEIGHT'];
      if (['QR'].includes(airlineID)) acceptedListService.push('WEIGHT SYSTEM CHARGE');
      baggageList = filteredBySegment.filter((el) => acceptedListService.includes(el.Service[0].Definition.Name));
    }
    baggageList = baggageList.map((el) => ({ ...el, type: SERVICE_TYPE.BAGGAGE }));
    seatUpgradeList = seatUpgradeList.map((el) => ({ ...el, type: SERVICE_TYPE.SEAT }));
    return {
      result: [transformServiceAmountOptions(baggageList), transformServiceAmountOptions(seatUpgradeList)],
      totalService:
        transformServiceAmountOptions(baggageList).length + transformServiceAmountOptions(seatUpgradeList).length,
    };
  }

  function handleSelectService(e, indexRow, cell) {
    const value = e.detail.selectedId;
    const selectedItem = e.detail.selectedItem;
    if (value === EMPTY) return;
    const { ptc, paxId, serviceAmountOptions } = ptcList[selectedTab][indexRow];
    const selectedService = serviceAmountOptions.find((el) => el.id === Number(value));
    const { name, amount, curCode } = selectedService;
    const findItem = selectedServices.find(
      (el) =>
        el.text === selectedService.text &&
        isEqual(el.PaxRefID, selectedService.PaxRefID) &&
        isEqual(el.PaxSegmentRefID, selectedService.PaxSegmentRefID)
    );
    if (findItem) {
      if (SERVICE_HAS_MULTIPLE.includes(name)) {
        findItem.count = findItem.count + 1;
        findItem.warn = EMPTY;
      } else {
        findItem.count = 0;
        findItem.warn = '이미 선택한 서비스는 추가할 수 없습니다';
      }
      selectedServices = [...selectedServices];
    } else {
      const item = {
        ...selectedService,
        id: uuidv4(),
        ptc,
        name,
        amount,
        paxId,
        curCode,
        tab: `구간 ${selectedTab + 1}`,
        selectedTab,
        count: 1,
        warn: EMPTY,
      };
      if (item.name === 'CASH UPGRADE') {
        const items = ptcList[selectedTab].map((el) => {
          const foundSeatService = headerTabs[selectedTab].serviceAmountOptions[1].find(
            (service) =>
              isEqual([el.paxId], service.PaxRefID) && isEqual(selectedService.PaxSegmentRefID, service.PaxSegmentRefID)
          );
          if (!foundSeatService) return EMPTY;
          return {
            ...item,
            ...foundSeatService,
            PaxRefID: [el.paxId],
            ptc: el.ptc,
            name: selectedService.text.split('/').slice(0, -1).join(' / '),
            nameOriginal: name,
            id: item.id,
          };
        });
        selectedServices = [...compact(items), ...selectedServices];
        setStatusDisableForAllService(selectedService, true);
      } else {
        selectedServices = [item, ...selectedServices];
      }
    }
    ptcList[selectedTab][indexRow][cell.key] = EMPTY;
    if (['SQ', 'QR', 'AF', 'KL'].includes(airlineID)) statusStep = 'RECALCULATE';
    else statusStep = 'ORDERCHANGE';

    if (['AF', 'KL'].includes(airlineID) || ['EK'].includes(airlineID)) {
      ptcList[selectedTab][indexRow].serviceAmountOptions = ptcList[selectedTab][indexRow].serviceAmountOptions.map(
        (el) => ({ ...el, disabled: true })
      );
    }
    if (['LH', 'LX', 'LXA', 'OS'].includes(airlineID)) {
      const typeServiceSelected = selectedItem.type;
      ptcList.forEach((ptc) => {
        ptc.forEach((el) => {
          el.serviceTypeOptions = el.serviceTypeOptions.map((opt) => {
            if (opt.value !== typeServiceSelected) opt.disabled = true;
            return opt;
          });
        });
      });
    }
  }

  function setStatusDisableForAllService(service, disable) {
    ptcList.forEach((ptc) => {
      ptc.forEach((el) => {
        el.serviceAmountOptions = el.serviceAmountOptions.map((el) => {
          if (isEqual(el.PaxSegmentRefID, service.PaxSegmentRefID)) el.disabled = disable;
          return el;
        });
      });
    });
  }

  function clearService(service) {
    const indexRow = ptcList[service.selectedTab].findIndex((el) => el.paxId === service.paxId);
    selectedServices = selectedServices.filter((el) => el.id !== service.id);
    if (service.Service[0].Definition.Name === 'CASH UPGRADE') {
      setStatusDisableForAllService(service, false);
    }
    if (selectedServices.length > 0) {
      if (['SQ', 'QR', 'AF', 'KL'].includes(airlineID)) statusStep = 'RECALCULATE';
      else statusStep = 'ORDERCHANGE';
    } else {
      statusStep = 'NONE';
    }
    if (['AF', 'KL'].includes(airlineID) || ['EK'].includes(airlineID)) {
      ptcList[service.selectedTab][indexRow].serviceAmountOptions = ptcList[service.selectedTab][
        indexRow
      ].serviceAmountOptions.map((el) => ({ ...el, disabled: false }));
    }
    if (['LH', 'LX', 'LXA', 'OS'].includes(airlineID)) {
      const filteredBaggageServiceSelected = selectedServices.filter((el) => el.type === SERVICE_TYPE.BAGGAGE);
      const filteredSeatServiceSelected = selectedServices.filter((el) => el.type === SERVICE_TYPE.SEAT);
      ptcList.forEach((ptc, index) => {
        ptc.forEach((item, indexRow) => {
          item.serviceTypeOptions = item.serviceTypeOptions.map((serviceItem) => {
            if (serviceItem.value === SERVICE_TYPE.BAGGAGE)
              serviceItem.disabled = !isEmpty(filteredSeatServiceSelected);
            if (serviceItem.value === SERVICE_TYPE.SEAT)
              serviceItem.disabled = !isEmpty(filteredBaggageServiceSelected);
            return serviceItem;
          });
        });
      });
    }
  }

  async function handleSubmit() {
    open = false;
    isOpenPaymentPnrModal = true;
  }

  onMount(() => {
    if (serviceList?.data) {
      if (orderRetrieve?.data?.ResultMessage?.Code === CODE_SUCCESS) {
        const { PaxJourneyList = [], PaxList = [] } = orderRetrieve?.data?.DataLists;
        headerTabs = PaxJourneyList.map(({ OnPoint, OffPoint, PaxSegmentRefID = [] }, i) => ({
          id: i,
          label: `구간${i + 1}: ${OnPoint}-${OffPoint}`,
          segments: PaxSegmentRefID,
          serviceAmountOptions: getServiceAmountOptions(PaxSegmentRefID).result,
          totalServiceAmount: getServiceAmountOptions(PaxSegmentRefID).totalService,
        }));
        ptcList = PaxJourneyList.map((_) => {
          const paxListNotIncloudedInf = sortPaxListByPtc(PaxList).filter((el) => el.Ptc !== 'INF');
          return paxListNotIncloudedInf.map((pax, i) => {
            const { Surname = EMPTY, GivenName = [] } = pax.Individual;
            return {
              id: i,
              paxId: pax.PaxID,
              ptc: `${Surname}/${GivenName?.[0] || EMPTY} (${pax.Ptc})`,
              serviceType: EMPTY,
              serviceAmount: EMPTY,
              serviceAmountOptions: [],
              serviceTypeOptions: createServiceTypeOptions({ paxId: pax.PaxID }),
            };
          });
        });
      }
    }
  });

  $: {
    if (!['SQ', 'QR', 'AF', 'KL'].includes(airlineID)) {
      totalAmountServices = selectedServices.reduce((acc, obj) => acc + obj.amount, 0);
      curCode = selectedServices[0]?.curCode || EMPTY;
    }
  }
</script>

<ChangePaymentPnrModal
  open={isOpenPaymentPnrModal}
  {selectedServices}
  {serviceList}
  {orderId}
  {totalAmountServices}
  {curCode}
  airline={serviceList?.data?.AlaCarteOffer?.Owner}
/>
<Portal>
  <div id="baggage-information">
    <ComposedModal size="lg" {open} preventCloseOnClickOutside={true} on:close={handleCloseModal}>
      <ModalHeader title="서비스" />
      <ModalBody>
        <div class="baggage-container">
          <Tabs type="container">
            {#each headerTabs as tab}
              <Tab label={tab?.label} on:click={() => (selectedTab = tab.id)} />
            {/each}
          </Tabs>
          <div class="baggage-wrapper">
            <div class="baggage-container--left">
              {#if headerTabs[selectedTab]?.totalServiceAmount > 0}
                <div class="baggage-table">
                  <DataTable headers={baggageTableHeaders} rows={ptcList[selectedTab]}>
                    <div slot="cell" let:cell let:row>
                      {@const indexRow = ptcList[selectedTab].findIndex((el) => el.id === row.id)}
                      {#if cell.key === 'serviceType'}
                        <ComboBox
                          size="sm"
                          placeholder="선택"
                          on:clear={() => clearServiceType(indexRow)}
                          bind:selectedId={ptcList[selectedTab][indexRow][cell.key]}
                          on:select={(e) => changeServiceType(e, indexRow, cell)}
                          items={ptcList[selectedTab][indexRow].serviceTypeOptions}
                        />
                      {:else if cell.key === 'serviceAmount'}
                        <ComboBox
                          size="sm"
                          placeholder="선택"
                          bind:selectedId={ptcList[selectedTab][indexRow][cell.key]}
                          items={ptcList[selectedTab][indexRow].serviceAmountOptions}
                          disabled={isEmpty(ptcList[selectedTab][indexRow].serviceAmountOptions)}
                          on:select={(e) => handleSelectService(e, indexRow, cell)}
                        />
                      {:else}
                        {cell.value}
                      {/if}
                    </div>
                  </DataTable>
                </div>
              {:else}
                <div class="baggage-container-empty">해당 구간은 서비스 구매가 불가합니다</div>
              {/if}
            </div>
            <div class="baggage-container--right">
              <div>
                <div class="baggage--right-head">
                  <div class="text-align-l">선택 구간</div>
                  <div class="col-span-3 text-align-l">탑승객명 (PTC)</div>
                  <div class="col-span-3 text-align-l">서비스</div>
                  <div class="col-span-2">금액</div>
                  <div class="col-span-2 text-align-r" />
                  <div />
                </div>
                <div class="baggage--right-middle">
                  {#if !selectedServices.length}
                    <div
                      style="text-align: center;align-items: center;justify-content: center;height: 50px;display: flex;"
                    >
                      선택한 서비스가 없습니다
                    </div>
                  {/if}
                  {#each selectedServices as service, i (i)}
                    {@const serviceName = service.name}
                    <div class="right-middle-item" class:selected={service.selected} role="button" tabindex="0">
                      <div class="text-align-l">
                        {service.tab}
                      </div>
                      <div class="col-span-3 text-align-l">{service.ptc}</div>
                      <div class="col-span-3 text-align-l">
                        {#if ['EK'].includes(airlineID)}
                          {service.name} / {service.Service[0].Definition.Desc[0].Text}
                        {:else}
                          {service.name}
                        {/if}
                      </div>
                      <div class="col-span-2 text-align-r">
                        {service.amount ? `${formatPriceNumber(service.amount)} ${service.curCode}` : EMPTY}
                      </div>
                      <div class="col-span-2 text-align-r">
                        {#if SERVICE_HAS_MULTIPLE.includes(serviceName)}
                          <div
                            class="g-btn-action"
                            style="display: flex; gap: 10px; align-items: center;justify-content: end"
                          >
                            <button
                              style="all: unset"
                              on:click={() => {
                                service.count > 1 ? (service.count = service.count - 1) : (service.count = 1);
                                statusStep = 'RECALCULATE';
                              }}
                            >
                              <SubtractAlt size={18} />
                            </button>
                            <p style="padding-right: 0">{service.count}</p>
                            <button
                              style="all: unset"
                              on:click={() => {
                                service.count = service.count + 1;
                                statusStep = 'RECALCULATE';
                              }}
                            >
                              <AddAlt size={18} />
                            </button>
                          </div>
                        {/if}
                      </div>
                      <div class="btn-delete">
                        {#if service?.amount}
                          <button style="all: unset" on:click={() => clearService(service)}>
                            <TrashCan size="14" />
                          </button>
                        {/if}
                      </div>
                    </div>
                    {#if service.warn.length}
                      {@const deleteTimeout = setTimeout(() => {
                        service.warn = '';
                      }, 3000)}
                      <div style="color: red">{service.warn}</div>
                    {/if}
                  {/each}
                </div>
              </div>
              {#if ['SQ', 'QR', 'AF', 'KL'].includes(airlineID)}
                <div class="btn-recalculate">
                  <PrimaryButton on:click={reCalculatePrice} disabled={!(statusStep === 'RECALCULATE')}
                    >{#if loading === 'RECALCULATE'}
                      <div class="g-custom-loading-button loading-box">
                        <InlineLoading description="Loading ..." />
                      </div>
                    {:else}
                      금액 재계산 하기
                    {/if}
                  </PrimaryButton>
                </div>
              {/if}
              <div class="baggage--right-bottom">
                <div>총 금액</div>
                <div>{formatPriceNumber(totalAmountServices)} {curCode}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <footer>
          <PrimaryButton kind="secondary" width="100%" on:click={handleCloseModal}>취소</PrimaryButton>
          <PrimaryButton width="100%" on:click={handleSubmit} disabled={!(statusStep === 'ORDERCHANGE')}
            >서비스선택
            {#if loading === 'ORDERCHANGE'}
              <div class="g-custom-loading-button loading-box">
                <InlineLoading description="Loading ..." />
              </div>
            {/if}
          </PrimaryButton>
        </footer>
      </ModalFooter>
    </ComposedModal>
  </div>
</Portal>

<style>
  .baggage-container-empty {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cccccc;
    border-radius: 4px;
  }
</style>
