import { find, reduce, isEmpty, has, filter, map, sortBy, uniqWith, isEqual, uniq } from 'lodash';
import { EMPTY, TYPE_TICKET } from '../constants/app.js';
import { formatDateTime } from './DateTime.js';

export function formatPriceNumber(price) {
  try {
    return price.toLocaleString();
  } catch {
    return EMPTY;
  }
}

export function nameTypeTicket(type) {
  let findType = TYPE_TICKET.find((ticket) => ticket.key === type);
  return findType ? findType.value : type;
}

export const standardObj = (obj) => {
  return reduce(
    obj,
    (result, value, key) => {
      if (result[key] === undefined || result[key] === null) return { ...result, [key]: EMPTY };
      return result;
    },
    obj
  );
};

export const statusTickKet = (status, type) => {
  const statusMap = {
    I: {
      T: 'TICKETED',
      J: 'ISSUED',
      Y: 'ISSUED',
    },
    V: 'VOIDED',
    R: 'REFUNDED',
    E: 'EXCHANGED',
  };
  const statusTypeMap = statusMap[status];
  if (statusTypeMap) {
    if (typeof statusTypeMap === 'string') {
      return statusTypeMap;
    } else if (statusTypeMap[type]) {
      return statusTypeMap[type];
    }
  }
  return status;
};

export const createEmptyArray = (length) => {
  const array = [];
  for (let i = 0; i < length; i++) {
    array.push(EMPTY);
  }
  return array;
};

export function convertToFormattedKoreanPhoneNumber(phoneNumber) {
  const digitsOnly = phoneNumber.replace(/\D/g, EMPTY);
  const numberWithCountryCode = digitsOnly.startsWith('82') ? digitsOnly : `82${digitsOnly}`;
  const pattern = /^(82)(\d{2})(\d{3,4})(\d{4})$/;

  return numberWithCountryCode.replace(pattern, '+$1-$2-$3-$4');
}

export function formatDate(dateString, type) {
  if (!dateString?.trim()) return EMPTY;
  let dateDdMmY = EMPTY;
  let time = EMPTY;
  if (dateString) {
    [dateDdMmY, time] = dateString?.split(' ');
  }
  if (!dateString) return EMPTY;
  const date = new Date(dateDdMmY);
  const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  if (type === 'date') return `${('0' + day).slice(-2)}/${month}/${year}`;
  else if (type === 'datetime') {
    return `${('0' + day).slice(-2)}/${month}/${year} ${time || EMPTY}`;
  }
}

export function formatCabinCode(cabinCode) {
  const cabinCodeMap = {
    M: '일반석',
    Y: '일반석',
    5: '일반석',
    W: '프리미엄일반석',
    4: '프리미엄일반석',
    C: '비즈니스석',
    2: '비즈니스석',
    F: '일등석',
    1: '일등석',
  };
  return cabinCodeMap[cabinCode] || cabinCode;
}

export function getCabinName(airline, cabinCode) {
  if (['QR', 'SQ'].includes(airline)) {
    if (cabinCode === 'M' || cabinCode === '5') return '일반석';
    if (cabinCode === 'W' || cabinCode === '4') return '프리미엄일반석';
    if (cabinCode === 'C' || cabinCode === '2') return '비즈니스석';
    if (cabinCode === 'F' || cabinCode === '1') return '일등석';
  }
  if (airline === 'LJ') {
    if (cabinCode === 'Y' || cabinCode === '5') return '일반석';
    if (cabinCode === 'C' || cabinCode === '4') return '프리미엄일반석';
  }
  if (['LH', 'LX', 'OS', 'AA', 'EK', 'HA', 'BA', 'AF', 'KL'].includes(airline)) {
    if (cabinCode === 'Y' || cabinCode === '5') return '일반석';
    if (cabinCode === 'W' || cabinCode === '4') return '프리미엄일반석';
    if (cabinCode === 'C' || cabinCode === '2') return '비즈니스석';
    if (cabinCode === 'F' || cabinCode === '1') return '일등석';
  }
  return EMPTY;
}

export function getMaxWeightAllowance(baggageAllowance, PaxJourneyID) {
  let maximumWeightAllowance;
  let pieceAllowance;

  const baggageAllowanceHasCheckedOrCheckedBag = baggageAllowance.filter(
    ({ TypeCode, PaxJourneyRefID }) =>
      ['Checked', 'CheckedBag'].includes(TypeCode) && PaxJourneyRefID.includes(PaxJourneyID)
  );
  for (const { MaximumWeightAllowance, PieceAllowance } of baggageAllowanceHasCheckedOrCheckedBag) {
    if (
      (!maximumWeightAllowance || maximumWeightAllowance?.Value < MaximumWeightAllowance.Value) &&
      has(MaximumWeightAllowance, 'Value')
    ) {
      maximumWeightAllowance = MaximumWeightAllowance;
    }
    if (!pieceAllowance) pieceAllowance = PieceAllowance;
  }
  if (pieceAllowance && maximumWeightAllowance)
    return `${pieceAllowance}pc(${maximumWeightAllowance.Value ?? EMPTY}${maximumWeightAllowance.UnitCode ?? EMPTY})`;
  if (maximumWeightAllowance)
    return `${maximumWeightAllowance.Value ?? EMPTY}${maximumWeightAllowance.UnitCode ?? EMPTY}`;
  if (pieceAllowance) return `${pieceAllowance}pc`;
  return '불포함';
}

export function isPropertyEmpty(obj) {
  for (let key in obj) {
    if (!obj[key] || obj[key].trim() === EMPTY) {
      return true;
    }
  }
  return false;
}

export function formatQueryParams(option, arrKeys) {
  const params = reduce(
    option,
    (result, value, key) => {
      if (value === EMPTY || value?.length === 0 || value === '전체' || value.includes('전체')) {
        return result;
      }
      if (arrKeys.includes(key)) {
        value = formatDateTime(value);
      }
      return { ...result, [key]: value };
    },
    {}
  );
  return params;
}

export function formatPenaltyDetail(Amounts, Application, codeDetail) {
  let data = EMPTY;
  const findMin = Amounts.find((elm) => elm.AmountApplication === 'MIN');
  const findMax = Amounts.find((elm) => elm.AmountApplication === 'MAX');
  if (findMin) {
    const currencyAmountCodeMin = formatPriceNumber(findMin.CurrencyAmountValue) + findMin.Code;
    data = `${codeDetail[Application.Code]} 최소 ${currencyAmountCodeMin}`;
  }
  if (findMax) {
    const currencyAmountCodeMax = formatPriceNumber(findMax.CurrencyAmountValue) + findMax.Code;
    data = `${codeDetail[Application.Code]} 최대 ${currencyAmountCodeMax}`;
  }
  if (findMin && findMax) {
    const currencyAmountCodeMin = formatPriceNumber(findMin.CurrencyAmountValue) + findMin.Code;
    const currencyAmountCodeMax = formatPriceNumber(findMax.CurrencyAmountValue) + findMax.Code;
    if (currencyAmountCodeMin === currencyAmountCodeMax) {
      data = `${codeDetail[Application.Code]} ${currencyAmountCodeMin}`;
    } else {
      data = `${codeDetail[Application.Code]} 최소 ${currencyAmountCodeMin} 최대 ${currencyAmountCodeMax}`;
    }
  }
  if (!findMin && !findMax) {
    data = `${codeDetail[Application.Code]} ${formatPriceNumber(Amounts[0]?.CurrencyAmountValue ?? EMPTY)} ${
      Amounts[0]?.Code ?? EMPTY
    }`;
  }
  return data;
}

export function getDataColsRuleCoveredServices(Penalty, Remarks, airlineID, findJourneyOverview) {
  const codeDetail = {
    1: airlineID !== 'LJ' ? '노쇼' : '수속마감/NO-SHOW',
    2: airlineID !== 'LJ' ? '출발전' : '출발전',
    3: airlineID !== 'LJ' ? '출발후' : '부분환불',
  };
  const sortCodeDetail = {
    1: 3,
    2: 1,
    3: 2,
  };
  let INIT_COL_INDEX = {
    [`${has(Penalty, 'Change') ? 'change' : EMPTY}`]: {
      label: `변경(${Penalty?.Change === 'true' ? '가능' : '불가'})`,
      data: [],
    },
    [`${has(Penalty, 'Refund') ? 'cancel' : EMPTY}`]: {
      label: `취소(${Penalty?.Refund === 'true' ? '가능' : '불가'})`,
      data: [],
    },
    [`${has(Penalty, 'NoShow') ? 'noShow' : EMPTY}`]: {
      label: `NoShow(${Penalty.NoShow === 'true' ? '가능' : '불가'})`,
      data: [],
    },
  };
  let COL_INDEX_ROW_1;
  const COL_INDEX_ROW_2 = filter(Remarks, (itemRemark) => itemRemark.includes('Earliest'));
  const COL_INDEX_ROW_3 = filter(Remarks, (itemRemark) => itemRemark.includes('Latest'));
  const COL_INDEX_ROW_4 = map(findJourneyOverview?.PriceClassInfo?.Descriptions, (it) => {
    const { DescID, Text } = it;
    if (DescID && !Text) return EMPTY;
    if (!DescID && Text) return Text;
    return `${DescID}/${Text}`;
  });
  if (airlineID === 'LJ') {
    INIT_COL_INDEX.lj = {
      label: '예약부도 위약금 (No-Show)',
      data: [],
    };
    COL_INDEX_ROW_1 = INIT_COL_INDEX;
    let detailChange = [];
    let detailCancel = [];

    for (const detail of Penalty?.Detail ?? []) {
      if (detail.Type === 'Change') detailChange.push(detail);
      if (detail.Type === 'Cancel') detailCancel.push(detail);
    }
    // get data COL_INDEX_ROW_1.cancel
    detailCancel = sortBy(detailCancel, (it) => it.Amounts[0]?.AmountApplication * 1).reverse();
    const detailCancelCodeOneAndThree = filter(detailCancel, (detailCancelItem) =>
      ['1', '3'].includes(detailCancelItem?.Application.Code)
    );
    const detailCancelCodeTwo = filter(detailCancel, (detailCancelItem) => detailCancelItem?.Application.Code == 2);
    //Application/Code === 2
    for (let i = 0; i < detailCancelCodeTwo.length; i++) {
      const { Application, Amounts } = detailCancelCodeTwo[i];
      let amountItem = 0;
      let currentData;
      for (const amount of Amounts) {
        amountItem += amount?.CurrencyAmountValue ?? 0;
      }
      if (i === detailCancelCodeTwo.length - 1) {
        currentData = {
          index: sortCodeDetail[Application.Code],
          data: `${codeDetail[Application.Code]} (${Amounts[0]?.AmountApplication}일 전~수속마감) ${amountItem} ${
            Amounts[0]?.Code ?? 'KRW'
          }`,
        };
      } else {
        currentData = {
          index: sortCodeDetail[Application.Code],
          data: `${codeDetail[Application.Code]} (${Amounts[0]?.AmountApplication}일 전~${
            detailCancel[i + 1]?.Amounts[0]?.AmountApplication * 1 + 1
          }일 전) ${amountItem} ${Amounts[0]?.Code ?? 'KRW'}`,
        };
      }
      COL_INDEX_ROW_1.cancel.data.push(currentData);
    }
    //Application/Code === 1,3
    for (const itemDetailCancelCodeOneAndThree of detailCancelCodeOneAndThree) {
      const { Application, Amounts } = itemDetailCancelCodeOneAndThree;
      let amountItem = 0;
      for (const amount of Amounts) {
        amountItem += amount?.CurrencyAmountValue ?? 0;
      }

      COL_INDEX_ROW_1.cancel.data.push({
        index: sortCodeDetail[Application.Code],
        data: `${codeDetail[Application.Code]} ${amountItem} ${Amounts[0]?.Code ?? 'KRW'}`,
      });
    }
    // get data COL_INDEX_ROW_1.change
    for (const detailChangeItem of detailChange) {
      const { Application, Amounts } = detailChangeItem;
      let amountItem = 0;
      for (const amount of Amounts) {
        amountItem += amount?.CurrencyAmountValue ?? 0;
      }
      if (Application.Code === '1') {
        COL_INDEX_ROW_1.lj.data.push(`NO-SHOW 수수료 ${amountItem} ${Amounts[0]?.Code ?? 'KRW'}`);
        break;
      }
      COL_INDEX_ROW_1.change.data.push({
        index: sortCodeDetail[Application.Code],
        data: `${codeDetail[Application.Code]} ${amountItem} ${Amounts[0]?.Code ?? 'KRW'}`,
      });
    }
  } else {
    COL_INDEX_ROW_1 = reduce(
      Penalty?.Detail,
      (result, itDetail) => {
        const { Type, Amounts, Application } = itDetail;
        const data = formatPenaltyDetail(Amounts, Application, codeDetail);
        if (Type === 'Change' && has(result, 'change'))
          result.change.data = sortBy(
            [
              ...result.change.data,
              {
                index: sortCodeDetail[Application.Code],
                data,
              },
            ],
            'index'
          );
        if (['Cancel', 'Refund'].includes(Type) && has(result, 'cancel'))
          result.cancel.data = sortBy(
            [
              ...result.cancel.data,
              {
                index: sortCodeDetail[Application.Code],
                data,
              },
            ],
            'index'
          );
        return result;
      },
      INIT_COL_INDEX
    );
  }

  if (has(COL_INDEX_ROW_1, 'cancel')) {
    COL_INDEX_ROW_1.cancel.data = COL_INDEX_ROW_1.cancel.data.map((item) => item.data);
  }
  COL_INDEX_ROW_1.change.data = COL_INDEX_ROW_1.change.data.map((item) => item.data);
  return [COL_INDEX_ROW_1, COL_INDEX_ROW_2, COL_INDEX_ROW_3, COL_INDEX_ROW_4];
}

export function exportExcelFileName(dataBlob, name) {
  const today = new Date().toJSON();
  const [date] = today.split('T');

  const href = URL.createObjectURL(dataBlob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${name}-${date}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function getMainBookingReference(bookingReference) {
  let findB = find(
    bookingReference || [],
    (itB) => itB?.AirlineID === 'LJ' || (!has(itB, 'OtherID') && !has(itB, 'Type'))
  );
  return findB;
}

export const getWidthOfText = (text, fontSize, fontFamily) => {
  const span = document.createElement('span');
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;
  span.textContent = text;
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
};
export const getDefaultFontFamily = (element) => {
  const defaultFontFamily = window.getComputedStyle(element, null).getPropertyValue('font-family');
  return defaultFontFamily;
};
export const getDefaultFontSize = (element) => {
  const defaultFontSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
  return defaultFontSize;
};

export const getOtherPnrByBookingReference = (bookingReference) => {
  const checkLj = bookingReference?.find((bk) => bk?.AirlineID === 'LJ');
  if (checkLj) return EMPTY;
  const hasOtherId = bookingReference?.find((bk) => has(bk, 'OtherID') && bk?.OtherID);
  if (hasOtherId) return hasOtherId?.Id ?? EMPTY;
  return EMPTY;
};

export const getUrlLogoAirline = (airlineId) => {
  let _airlineId;
  switch (airlineId) {
    case 'HAA':
      _airlineId = 'HA';
      break;
    case 'LXA':
      _airlineId = 'LX';
      break;
    default:
      _airlineId = airlineId;
  }
  return `https://cdn.ndcaggregator.kr/logo/${_airlineId}.png`;
};

export const convertFlightTime = (flightTime) => {
  const durationRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/;
  const timeRegex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
  const match = flightTime.match(durationRegex);

  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (match[3]) days = parseInt(match[3]);

  if (match[4]) {
    hours = parseInt(match[4]);
    if (hours >= 24) {
      days += Math.floor(hours / 24);
      hours = hours % 24;
    }
  }

  if (match[5]) minutes = parseInt(match[5]);

  if (!match[1] && !match[2] && !match[3] && !match[4] && !match[5] && !match[6]) {
    // case PT18H45M
    const timeMatch = flightTime.match(timeRegex);
    if (timeMatch[1]) {
      hours = parseInt(timeMatch[1]);
      if (hours >= 24) {
        days += Math.floor(hours / 24);
        hours = hours % 24;
      }
    }
    if (timeMatch[2]) {
      minutes = parseInt(timeMatch[2]);
    }
  }
  return `${days === 0 ? EMPTY : days + '일'}${hours}시간${minutes}분`;
};

export const isValidEmail = (text) => {
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return !regexEmail.test(text);
};

export function generatePopupCoveredServices(
  PaxSegmentList,
  JourneyOverview,
  PaxJourneyList,
  FareComponent,
  airlineID
) {
  // let fareComponent = FareDetail[0]?.FareComponent ?? [];
  let PaxSegmentRefIDList = uniqWith(
    FareComponent.map((item) => item.PaxSegmentRefID),
    isEqual
  );
  let headers = [
    {
      key: 'LABEL',
      value: {
        label: ['여정'],
        subLabel: ['FareBasis', 'PriceClass'],
      },
    },
  ];
  let rows = [];
  let ROW_1 = {
      id: 'ROW_1',
      LABEL: '페널티',
    },
    ROW_2 = {
      id: 'ROW_2',
      LABEL: '최소 체류일',
    },
    ROW_3 = {
      id: 'ROW_3',
      LABEL: '최대 체류일',
    },
    ROW_4 = {
      id: 'ROW_4',
      LABEL: '포함 서비스',
    };

  for (let index = 0; index < PaxSegmentRefIDList.length; index++) {
    const PaxSegmentRefID = PaxSegmentRefIDList[index];
    const FareBasis = FareComponent.find(
      (item) => isEqual(PaxSegmentRefID, item.PaxSegmentRefID) && has(item, 'FareBasis')
    )?.FareBasis;
    const Penalty = FareComponent.find(
      (item) => isEqual(PaxSegmentRefID, item.PaxSegmentRefID) && has(item, 'Penalty')
    )?.Penalty;
    const Remarks = FareComponent.reduce((RemarksItem, item) => {
      if (isEqual(PaxSegmentRefID, item.PaxSegmentRefID) && has(item, 'Remarks'))
        return [...RemarksItem, ...item.Remarks];
      return RemarksItem;
    }, []);

    if (!PaxSegmentRefID.length || (!has(Penalty, 'Change') && !has(Penalty, 'Refund'))) break;
    const label = map(PaxSegmentRefID, (paxSegRefIdItem) => {
      const PaxSegment = find(PaxSegmentList, (paxSegItem) => paxSegItem.PaxSegmentID === paxSegRefIdItem);
      return `${PaxSegment?.Departure?.AirportCode}-${PaxSegment?.Arrival?.AirportCode}`;
    });
    let subLabel = [];
    const findPaxJourneyID = find(PaxJourneyList, (paxJourneyItem) =>
      paxJourneyItem.PaxSegmentRefID?.includes(PaxSegmentRefID[0])
    );
    const findJourneyOverview = find(
      JourneyOverview,
      (JourneyItem) => JourneyItem.PaxJourneyRefID === findPaxJourneyID?.PaxJourneyID
    );
    subLabel = [...subLabel, FareBasis?.FareBasisCode || EMPTY, findJourneyOverview?.PriceClassInfo?.Name || EMPTY];
    headers = [
      ...headers,
      {
        key: `COL_${index}`,
        value: {
          label,
          subLabel,
        },
      },
    ];
    const [COL_INDEX_ROW_1, COL_INDEX_ROW_2, COL_INDEX_ROW_3, COL_INDEX_ROW_4] = getDataColsRuleCoveredServices(
      Penalty,
      Remarks,
      airlineID,
      findJourneyOverview
    );

    ROW_1 = {
      ...ROW_1,
      [`COL_${index}`]: COL_INDEX_ROW_1,
    };
    ROW_2 = {
      ...ROW_2,
      [`COL_${index}`]: COL_INDEX_ROW_2,
    };
    ROW_3 = {
      ...ROW_3,
      [`COL_${index}`]: COL_INDEX_ROW_3,
    };
    ROW_4 = {
      ...ROW_4,
      [`COL_${index}`]: COL_INDEX_ROW_4,
    };
  }

  rows = [ROW_1, ROW_2, ROW_3, ROW_4];
  return {
    headers,
    rows,
  };
}

export function sortPaxListByPtc(PaxList) {
  const sortByPtc = { ADT: 1, CHD: 2, CNN: 2, INF: 3 };
  return PaxList.sort((a, b) => sortByPtc[a.Ptc] - sortByPtc[b.Ptc]);
}

export function splitConvertArrayId(modelsId) {
  let arrId = [];
  if (typeof modelsId === 'string') {
    arrId = modelsId.split('');
  } else {
    for (const item of modelsId) {
      arrId = [...arrId, ...item.split(' ')];
    }
  }
  return uniq(arrId);
}

export const formatInfoSegment = (segment) => {
  if (!segment) return EMPTY;
  return `${segment?.MarketingCarrier?.AirlineID}${segment?.MarketingCarrier?.FlightNumber.padStart(4, '0')} ${
    segment?.Departure?.AirportCode
  }-${segment?.Arrival?.AirportCode} ${formatDate(segment?.Departure?.Date, 'date')}`;
};

export const getPassengerNamePtc = (paxListItem) => {
  if (!paxListItem) return EMPTY;
  if (paxListItem?.Individual)
    return `${paxListItem?.Individual?.Surname ?? EMPTY}/${paxListItem?.Individual?.GivenName ?? EMPTY}(${
      paxListItem?.Ptc ?? EMPTY
    })`;
  return paxListItem?.Ptc ?? EMPTY;
};

export const sleep = (s) => new Promise((r) => setTimeout(r, s));
