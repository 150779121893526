<script>
  import { screenIsNotEvent } from 'src/store/index.js';
  import { Button, InlineLoading } from 'carbon-components-svelte';
  import PurchaseSeats from 'src/components/modal/PurchaseSeatsModal/PurchaseSeats.svelte';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { postSeatAvailability } from 'src/service/reservation';
  import ErrorApi from 'src/components/modal/ErrorApi.svelte';
  import Portal from 'src/components/common/Portal.svelte';

  export let orderRetrieve;
  export let orderId = EMPTY;
  export let airlineID = EMPTY;
  let isOpenPurchaseSeats = false;
  let isLoading = false;
  let seatData = EMPTY;
  let errorApi = {
    isOpen: false,
    message: EMPTY,
  };
  const handleAction = async () => {
    isLoading = true;
    try {
      const { code, data } = await postSeatAvailability({
        query: {
          Order: {
            OrderID: orderId,
          },
        },
      });
      if (code === 200) {
        if (data?.ResultMessage?.Code === CODE_SUCCESS) {
          isOpenPurchaseSeats = true;
          seatData = data;
        } else {
          errorApi = {
            isOpen: true,
            message: `[${airlineID} ${data.ResultMessage.Code} ${data.ResultMessage.Message}]`,
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
    isLoading = false;
  };
  $: screenIsNotEvent.set(isLoading);
</script>

<Button size="small" kind="secondary" on:click={handleAction}>
  {#if isLoading}
    <div class="g-custom-loading-button">
      <InlineLoading description="Loading" />
    </div>
  {:else}
    좌석
  {/if}
</Button>

{#if seatData !== EMPTY}
  <PurchaseSeats bind:open={isOpenPurchaseSeats} {orderRetrieve} {orderId} bind:seatData />
{/if}

<Portal>
  <ErrorApi bind:openErrorApi={errorApi.isOpen}>{errorApi.message}</ErrorApi>
</Portal>
