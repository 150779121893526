<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="trash">
    <rect width="64" height="64" transform="translate(0 0.5)" fill="white" style="mix-blend-mode:multiply" />
    <g id="Vector">
      <path
        d="M52 63.2198H12C11.602 63.2198 11.28 62.8978 11.28 62.4998V11.2198H4V9.77979H21.28V2.49979C21.28 2.10179 21.602 1.77979 22 1.77979H42C42.398 1.77979 42.72 2.10179 42.72 2.49979V9.77979H60V11.2198H52.72V62.4998C52.72 62.8978 52.398 63.2198 52 63.2198ZM12.72 61.7798H51.28V11.2198H12.72V61.7798ZM22.72 9.77979H41.28V3.21979H22.72V9.77979Z"
        fill="#161616"
      />
      <path
        d="M42 55.2198C41.602 55.2198 41.28 54.8978 41.28 54.4998V18.4998C41.28 18.1018 41.602 17.7798 42 17.7798C42.398 17.7798 42.72 18.1018 42.72 18.4998V54.4998C42.72 54.8978 42.398 55.2198 42 55.2198ZM32 55.2198C31.602 55.2198 31.28 54.8978 31.28 54.4998V18.4998C31.28 18.1018 31.602 17.7798 32 17.7798C32.398 17.7798 32.72 18.1018 32.72 18.4998V54.4998C32.72 54.8978 32.398 55.2198 32 55.2198ZM22 55.2198C21.602 55.2198 21.28 54.8978 21.28 54.4998V18.4998C21.28 18.1018 21.602 17.7798 22 17.7798C22.398 17.7798 22.72 18.1018 22.72 18.4998V54.4998C22.72 54.8978 22.398 55.2198 22 55.2198Z"
        fill="#161616"
      />
    </g>
  </g>
</svg>
