import { EMPTY } from 'src/constants/app';

export const getDateTimeNow = () => {
  const today = new Date().toJSON();
  const [date] = today.split('T');
  const [year, month, day] = date.split('-');
  return day + '/' + month + '/' + year;
};

export const formatDateTime = (txt) => {
  if (!txt) return txt;
  const [day, month, year] = txt.split('/');
  return year + '-' + month + '-' + day;
};

export const formatArialLabelDate = (dateString) => {
  if (dateString === '') return '';
  const dateParts = dateString.split('/');
  const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const result = `${month} ${day}, ${year}`;
  return result;
};

export function convertToKST(utcDateStr) {
  if (!utcDateStr) return EMPTY;

  const utcDate = new Date(utcDateStr);
  const kstOffset = 9 * 60;
  const localOffset = utcDate.getTimezoneOffset();
  const totalOffset = kstOffset + localOffset;
  const kstDate = new Date(utcDate.getTime() + totalOffset * 60 * 1000);
  const kstDateStr = kstDate.toISOString().replace('T', ' ').substring(0, 19);
  return kstDateStr;
}
