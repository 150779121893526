<script>
  import { Modal } from 'carbon-components-svelte';
  import { EMPTY, POPUP_TYPE_TICKET } from '../../../constants/app.js';
  import { _ } from '../../../lib/i18n.js';

  export let openModal;
  export let dataPopup;
  export let type;
  export let closeModal;
  const POPUP_HEADER = [
    {
      id: 1,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.passengerName', {
        default: '탑승객명(PTC)',
      }),
      key: 'PassengerName',
    },
    {
      id: 2,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.ticketingDate', {
        default: '발권일시',
      }),
      key: 'TicketingDate',
    },
    {
      id: 3,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.id', {
        default: 'PNR',
      }),
      key: 'ID',
    },
    {
      id: 4,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.reportingType', {
        default: '정산타입',
      }),
      key: 'ReportingType',
    },
    {
      id: 5,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.ticketDocNbr', {
        default: '티켓번호',
      }),
      key: 'TicketDocNbr',
    },
    {
      id: 6,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.airlineID', {
        default: '발권항공사',
      }),
      key: 'AirlineID',
    },
    {
      id: 7,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.orderID', {
        default: 'OrderID',
      }),
      key: 'OrderID',
    },
    {
      id: 8,
      label: $_('layout.pages.reservationManagement.ticketInformationTable.popupHeader.ticketStatus', {
        default: '티켓상태',
      }),
      key: 'TicketStatus',
    },
  ];

  $: if (dataPopup.popupType === 'E' && !dataPopup.dataTable.length && openModal.type === 'TicketDocNbr') {
    dataPopup.dataTable = [
      {
        CouponNumber: EMPTY,
        AirlineID: EMPTY,
        ID: EMPTY,
        FlightNumber: EMPTY,
        DepartureAirportCode: EMPTY,
        ArrivalAirportCode: EMPTY,
        DepartureDateTime: EMPTY,
        ArrivalDateTime: EMPTY,
        CabinCode: EMPTY,
        Name: EMPTY,
        FareBasisCode: EMPTY,
        Baggage: EMPTY,
        Rbd: EMPTY,
        CodeShare: EMPTY,
        AircraftCode: EMPTY,
        Status: dataPopup.popupType,
      },
    ];
  }
</script>

<Modal
  open={openModal.isOpen && openModal.type === 'TicketDocNbr'}
  modalHeading={dataPopup.title}
  class="management-custom-popup table-management__ticket-popup"
  primaryButtonText="닫기"
  on:click:button--primary={closeModal}
>
  <div class="popup-header">
    <h2>발권정보</h2>
    <ul>
      {#each POPUP_HEADER as { id, label, key }, i (id)}
        <li>
          <div class="item-label" class:g-visible-collapse={key === 'TicketStatus'}>
            {label}
          </div>
          <div class:g-visible-collapse={key === 'TicketStatus'}>{dataPopup.dataPopupHeader[key] || EMPTY}</div>
        </li>
      {/each}
    </ul>
  </div>
  <div class="popup-table">
    <svelte:component
      this={dataPopup.render}
      data={dataPopup.dataTable}
      InConnectionDocNbr={dataPopup.InConnectionDocNbr}
    />
  </div>
  <div class="popup-footer">
    <h2>결제정보</h2>
    <div class="popup-footer__container">
      <div class="popup-footer__container--col">
        {#if type === POPUP_TYPE_TICKET}
          <div class="popup-footer__container--col-item">
            <div class="item-label">base amount</div>
            <div class="item-content text-align">
              {dataPopup.dataPopupFooter.BaseAmount || EMPTY}
            </div>
          </div>
          <div class="popup-footer__container--col-item">
            <div class="item-label">total tax</div>
            <div class="item-content text-align">
              {dataPopup.dataPopupFooter.TaxTotal || EMPTY}
            </div>
          </div>
          <div class="popup-footer__container--col-item">
            <div class="item-label">tax breakdown</div>
            <ul class="item-content">
              {#each dataPopup.dataPopupFooter.Tax as { TaxCode, Amount }, i (i)}
                <li>
                  <div class="tax-code">
                    {TaxCode}
                  </div>
                  <div class="amount-money text-align">
                    {Amount}
                  </div>
                </li>
              {/each}
            </ul>
          </div>
        {/if}
        <div class="popup-footer__container--col-item">
          <div class="item-label">total fare</div>
          <div class="item-content text-align">
            {dataPopup.dataPopupFooter.TotalFare}
          </div>
        </div>
      </div>
      <div class="popup-footer__container--col">
        <div class="popup-footer__container--col-item">
          <div class="item-label">결제수단(FOP)</div>
          <div class="item-content">
            {dataPopup.dataPopupFooter.PaymentType}
          </div>
        </div>
        {#if type === POPUP_TYPE_TICKET}
          <div class="popup-footer__container--col-item">
            <div class="item-label">commission</div>
            <div class="item-content">
              {dataPopup.dataPopupFooter.Commission}
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</Modal>
