<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" transform="translate(0 0.78125)" fill="white" style="mix-blend-mode:multiply" />
  <path
    d="M14.9997 8.78125C14.9997 12.6311 11.8499 15.7811 8 15.7812V11.7812C9.64985 11.7811 10.9997 10.4311 10.9997 8.78125C10.9997 7.13136 9.64985 5.78142 8 5.78125V1.78125C11.8499 1.78142 14.9997 4.93136 14.9997 8.78125Z"
    fill="#161616"
  />
  <path
    d="M7 8.78125C7 10.4381 5.65685 11.7812 4 11.7812C2.34315 11.7812 1 10.4381 1 8.78125C1 7.1244 2.34315 5.78125 4 5.78125C5.65685 5.78125 7 7.1244 7 8.78125Z"
    fill="#161616"
  />
</svg>
