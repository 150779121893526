<script>
  import { CustomTextInput } from 'src/components/common/input';
  import { cloneDeep } from 'lodash';
  import { Grid, ComposedModal, ModalHeader, ModalBody, ModalFooter, Button } from 'carbon-components-svelte';
  import CustomComboBox from 'src/components/common/combobox/CustomComboBox.svelte';
  import { EMPTY, InputOptions } from 'src/constants/app';
  import { message } from 'src/store';

  const defaultFnList = {
    ADT: [],
    CHD: [],
    INF: [],
  };
  export let open = false;
  export let paxList = [];
  export let ffnList = cloneDeep(defaultFnList);
  let allowSave = false;
  let ffnFormValue = [];

  const HEADER = ['승객타입', 'FFN 항공사', '마일리지번호'];
  const FFN_AIRLINES = [
    { id: 'FB', text: 'AFKL (Flying Blue)' },
    { id: 'None', text: 'None' },
  ];

  function openFFNInformationModal() {
    ffnFormValue = paxList.map((item) => {
      const hasSaveFfn = ffnList[item.Ptc].find((ffnIt) => ffnIt.PaxID === item.PaxID);
      if (hasSaveFfn) return { ...item, ...hasSaveFfn };
      return { ...item, ffnType: 'None', ffnCode: '' };
    });
  }

  async function saveFFNInformation() {
    ffnList = cloneDeep(defaultFnList);
    ffnFormValue.map((item) => {
      if (item.ffnType !== 'None' && item.ffnCode !== EMPTY) {
        ffnList[item.Ptc].push(item);
      }
    });
    message.set([
      {
        type: 'info',
        title: 'Success',
        subtitle: 'FFN 정보가 입력 되었습니다',
      },
    ]);
    open = false;
  }

  $: allowSave = !ffnFormValue.some((el) => el.ffnCode !== EMPTY && el.ffnType !== 'None');
</script>

<ComposedModal id="modal-ffn" bind:open preventCloseOnClickOutside={true} size="sm" on:open={openFFNInformationModal}>
  <ModalHeader title="FFN 정보" />
  <ModalBody>
    <div class="modal-ffn__container">
      <Grid class="modal-ffn__grid">
        <div class="modal-ffn__header">
          {#each HEADER as item, i (i)}
            <div>{item}</div>
          {/each}
        </div>
        <div class="modal-ffn__content">
          {#each ffnFormValue as ffnItem (ffnItem)}
            <div class="modal-ffn__item">
              <div class="ptc-center">
                <p>{ffnItem.Ptc} {ffnItem.count}</p>
              </div>
              <div>
                <CustomComboBox
                  class="cb-not-clear"
                  placeholder="선택"
                  items={FFN_AIRLINES}
                  disabled={ffnItem.Ptc === 'INF'}
                  bind:selectedId={ffnItem.ffnType}
                />
              </div>
              <div>
                <CustomTextInput
                  disabled={ffnItem.Ptc === 'INF' || ffnItem.ffnType === 'None'}
                  maxlength={15}
                  bind:value={ffnItem.ffnCode}
                  options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
                />
              </div>
            </div>
          {/each}
        </div>
      </Grid>
    </div>
  </ModalBody>

  <ModalFooter>
    <footer>
      <Button kind="secondary" on:click={() => (open = false)}>닫기</Button>
      <Button kind="primary" disabled={allowSave} on:click={saveFFNInformation}>입력</Button>
    </footer>
  </ModalFooter>
</ComposedModal>
