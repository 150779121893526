<script>
  import { ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { DataTable } from 'carbon-components-svelte';
  import { createEventDispatcher } from 'svelte';
  import Portal from 'src/components/common/Portal.svelte';
  import 'src/styles/modal/difference-information-modal.scss';
  export let open = false;
  export let data = [];

  const dispatch = createEventDispatcher();
  const headers = [
    { key: 'division', value: '구분' },
    { key: 'beforeChange', value: '변경 전' },
    { key: 'after', value: '변경 후' },
    { key: 'variableAmount', value: '변동 금액' },
  ];

  const handleClose = (event) => {
    dispatch('close', event);
    open = false;
  };
</script>

<Portal idBox="box-modal-children">
  <div id="differenceInformationModal">
    <ComposedModal {open} size="sm" preventCloseOnClickOutside={true} on:close={handleClose}>
      <ModalHeader>
        <h4>차액정보</h4>
      </ModalHeader>
      <ModalBody>
        <div class="description">
          <div>변동 금액이 있습니다.</div>
          <div>확인 버튼 클릭 시 변동된 금액으로 예약 됩니다.</div>
        </div>
        <div>
          <DataTable selectedRowIds={[3]} size="short" {headers} rows={data} class="g-custom-table">
            <div slot="cell" let:row let:cell>
              <div
                class:text-bold={row.id === 3}
                class:-margin-l-5={cell.value.includes('-')}
                class:text-red={cell.key === 'variableAmount' &&
                  !cell.value.includes('-') &&
                  cell.value.match(/\d+(\.\d+)?/g) != 0}
              >
                {cell.value}
              </div>
            </div>
          </DataTable>
        </div>
      </ModalBody>
      <ModalFooter>
        <footer>
          <div class="check-button">
            <PrimaryButton kind="secondary" width="100%" on:click={handleClose}>확인</PrimaryButton>
          </div>
        </footer>
      </ModalFooter>
    </ComposedModal>
  </div>
</Portal>
