<script>
  import { ToastNotification } from 'carbon-components-svelte';
  import { remove, map, filter } from 'lodash';
  import { message } from 'src/store';
  import { v4 as uuidv4 } from 'uuid';
  import { onMount } from 'svelte';

  let messageList = [];

  const handleDeleteMessage = (currentMessage) => {
    remove(messageList, currentMessage);
  };

  onMount(() => {
    const unSubscribe = message.subscribe((value) => {
      const currentMessage = map(value, (valueItem) => ({
        id: uuidv4(),
        ...valueItem,
        initTime: new Date().getTime(),
      }));
      messageList = [...currentMessage, ...messageList];
      messageList = filter(messageList, (messageItem) => {
        return new Date().getTime() - messageItem.initTime <= 6000;
      });
    });
    return () => unSubscribe();
  });
</script>

<div id="app-toast-notification">
  {#key messageList}
    {#each messageList as messageItem}
      <ToastNotification
        class="toast-content"
        lowContrast
        timeout={-new Date().getTime() + messageItem.initTime + 6000}
        role="alert"
        kind={messageItem.type || 'error'}
        title={messageItem.title || 'Search'}
        subtitle={messageItem.subtitle}
        caption={messageItem.caption}
        on:close={() => handleDeleteMessage(messageItem)}
      />
    {/each}
  {/key}
</div>
