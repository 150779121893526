<script>
  import { EMPTY } from 'src/constants/app';
  import { onMount } from 'svelte';
  export let idBox = EMPTY;
  let ref;

  onMount(() => {
    if (ref) {
      let nodeParent = idBox === EMPTY ? document.body : document.querySelector(`#${idBox}`);
      nodeParent.children.length > 0
        ? nodeParent.insertBefore(ref, nodeParent.children[0])
        : nodeParent.appendChild(ref);
      return () => {
        nodeParent.removeChild(ref);
      };
    }
  });
</script>

<div bind:this={ref}>
  <slot />
</div>
