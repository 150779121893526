<script>
  import { createEventDispatcher } from 'svelte';
  import ItemItineraryInquiry from './ItemItineraryInquiry.svelte';
  import { formatPriceNumber } from 'src/utils/appHelper';
  export let data = [];
  export let activeBorder = false;
  export let activeBtn = false;
  const dispatch = createEventDispatcher();

  function handleClickSelected() {
    dispatch('selected', {
      id: data?.id,
    });
  }
</script>

<div class="wrapperItineraryInquiry" class:active-border={activeBorder} class:active-selected={activeBtn}>
  <div class="box-item-itinerary">
    {#each data.items as item, index}
      <ItemItineraryInquiry data={item} activeBorder={data.items.length > 1 && index < data.items.length - 1} />
    {/each}
  </div>
  <div class="box-selection-itinerary">
    <h4>
      {#if data.isHasCommonMoney}
        {formatPriceNumber(data.commonMoney)} {data.curCode}
      {:else}
        항공사 미제공
      {/if}
    </h4>
    <h6>
      {#if data.isHasDifferenceMoney}
        ({formatPriceNumber(data.differenceMoney)} {data.curCode})
      {:else}
        항공사 미제공
      {/if}
    </h6>
    <button class:active-btn={activeBtn} on:click|preventDefault|stopPropagation={handleClickSelected}> 선택 </button>
  </div>
</div>
