<script>
  import { Calendar } from 'src/assets/icons';
  import { SEAT_CLASS_COLOR } from 'src/constants/itineraryInquiry.js';
  import { formatDate } from 'src/utils/appHelper.js';
  import './index.scss';
  export let data = [];
</script>

<div class="toolbar-wrapper">
  <div class="info-date">
    <h4>조회 결과:</h4>
    {#if data && data.length}
      {#each data as { id, onPoint, offPoint, departureDate } (id)}
        <div class="info-date-item">
          <div>
            <div class="point-count">
              {id}
            </div>
            <span>{onPoint}-{offPoint}</span>
          </div>
          <div>
            <Calendar />
            <span>{formatDate(departureDate, 'date')}</span>
          </div>
        </div>
      {/each}
    {/if}
  </div>
  <div class="Seat-class-color">
    <h4>좌석 등급 컬러:</h4>
    <div class="box-class-color">
      {#each SEAT_CLASS_COLOR as { id, name, color } (id)}
        <div class="seat-item">
          <div class="circle" style:--color-circle={color} />
          <div class="seat-item-name">
            {name}
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
