<script>
  import { isEmpty, intersection, uniqWith, isEqual, cloneDeep } from 'lodash';
  import { Tabs, Tab, TabContent, ComposedModal, ModalBody, ModalHeader, ModalFooter } from 'carbon-components-svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import Portal from 'src/components/common/Portal.svelte';
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app';
  import { onMount } from 'svelte';
  import SelectSeatContent from './SelectSeatContent.svelte';
  import ChangePaymentPnr from 'src/components/modal/ChangePaymentPnr.svelte';
  import { getOfferPrice } from 'src/service/reservationTicketing';
  import { screenIsNotEvent } from 'src/store';
  import 'src/styles/modal/purchase-seats.scss';

  export let open;
  export let orderRetrieve;
  export let seatData;
  export let orderId;

  let paxList = [];
  let tabs = [];
  let tabsContent = {};
  let priceAmount = {
    Amount: 0,
    CurCode: EMPTY,
  };
  let ptcSelectedSeat = {};
  let ptcHasSeat = [];
  let ptcCacheHasSeat = [];
  let isLoadingCalculatePrice = false;
  let changePaymentPnrData = {
    open: false,
    payload: {},
  };
  let offerPriceData = EMPTY;

  function handleCloseModal() {
    open = false;
    seatData = EMPTY;
  }

  const getHeaderTabs = (PaxSegmentList) => {
    return PaxSegmentList.map((paxSegment) => ({
      id: paxSegment.PaxSegmentID,
      label: `${paxSegment.PaxSegmentID}: ${paxSegment.Departure.AirportCode}-${paxSegment.Arrival.AirportCode}`,
      PaxSegmentID: paxSegment.PaxSegmentID,
    }));
  };

  const handleOpenPaymentPnrModal = () => {
    const OfferItems = ptcHasSeat.map((ptc) => {
      return {
        OfferItemID: intersection(ptc.offerItemIDWhiteList, ptc.seat.OfferItemRefID)[0],
        PaxRefID: [ptc.paxId],
        SeatSelection: {
          Column: ptc.seat.column,
          Row: ptc.seat.row,
        },
      };
    });

    let payload = {
      query: {
        OrderID: orderId,
        ChangeOrderChoice: {
          AcceptSelectedQuotedOfferList: {
            SelectedPricedOffer: [
              {
                OfferID: ['AF', 'KL'].includes(seatData.AlaCarteOffer.Owner)
                  ? offerPriceData.PricedOffer.OfferID
                  : seatData.AlaCarteOffer.OfferID,
                Owner: seatData.AlaCarteOffer.Owner,
                ResponseID: ['AF', 'KL'].includes(seatData.AlaCarteOffer.Owner)
                  ? offerPriceData.PricedOffer.ResponseID
                  : seatData.ResponseID,
                OfferItems,
              },
            ],
          },
        },
      },
    };

    if (!['AF', 'KL'].includes(seatData.AlaCarteOffer.Owner)) {
      payload['query']['PaymentList'] = [
        {
          Type: 'Cash',
          Amount: priceAmount.Amount,
          CurCode: priceAmount.CurCode,
          OfferItemID: OfferItems.map((item) => item.OfferItemID),
          OrderItemID: [],
          PaxRefID: [],
        },
      ];
    }
    changePaymentPnrData = {
      open: true,
      payload,
    };
  };
  const handleCalculatePrice = async () => {
    const OfferItems = ptcHasSeat.map((ptc) => {
      return {
        OfferItemID: intersection(ptc.offerItemIDWhiteList, ptc.seat.OfferItemRefID)[0],
        PaxRefID: [ptc.paxId],
        SeatSelection: {
          Column: ptc.seat.column,
          Row: ptc.seat.row,
        },
      };
    });
    let PaxList = ptcHasSeat.map((ptc) => ({
      PaxID: ptc.paxId,
      Ptc: ptc.type,
      LoyaltyProgramAccount: [],
    }));
    PaxList = uniqWith(PaxList, isEqual);
    const Criteria = {
      ExistingOrderCriteria: {
        OrderID: orderRetrieve?.data?.Order?.OrderID,
        PaxRefID: PaxList.map((pax) => pax.PaxID),
      },
    };
    const Query = {
      Offers: [
        {
          OfferID: seatData.AlaCarteOffer.OfferID,
          Owner: seatData.AlaCarteOffer.Owner,
          ResponseID: seatData.ResponseID,
          OfferItems,
        },
      ],
      PaxList,
      Criteria,
    };
    try {
      isLoadingCalculatePrice = true;
      const { code, data } = await getOfferPrice({ Query });
      if (code === 200 && data?.ResultMessage?.Code === CODE_SUCCESS) {
        ptcCacheHasSeat = cloneDeep(ptcHasSeat);
        priceAmount = data.PricedOffer.TotalPrice.TotalAmount;
        offerPriceData = data;
      }
    } catch (error) {
      console.log(error);
    }
    isLoadingCalculatePrice = false;
  };

  const getDisablePaymentSeat = (ptcHasSeat, ptcCacheHasSeat) => {
    if (['AF', 'KL'].includes(seatData.AlaCarteOffer.Owner)) {
      return !isEqual(ptcHasSeat, ptcCacheHasSeat) || isEmpty(ptcHasSeat);
    }
    return isEmpty(ptcHasSeat);
  };
  onMount(() => {
    paxList = orderRetrieve?.data?.DataLists?.PaxList ?? [];
    tabs = getHeaderTabs(orderRetrieve?.data?.DataLists?.PaxSegmentList ?? []);
  });

  $: if (!isEmpty(ptcSelectedSeat)) {
    ptcHasSeat = [];
    for (const key in ptcSelectedSeat) {
      ptcHasSeat = [...ptcHasSeat, ...ptcSelectedSeat[key].filter((ptc) => ptc.seat !== EMPTY)];
    }
    if (!['AF', 'KL'].includes(seatData.AlaCarteOffer.Owner)) {
      priceAmount = ptcHasSeat.reduce(
        (acc, currentPtc) => {
          acc.Amount += currentPtc?.totalAmount?.Amount ?? 0;
          if (!!currentPtc.totalAmount.CurCode) acc.CurCode = currentPtc.totalAmount.CurCode;
          return acc;
        },
        {
          Amount: 0,
          CurCode: EMPTY,
        }
      );
    }
  }

  $: screenIsNotEvent.set(isLoadingCalculatePrice);
</script>

<Portal>
  <ChangePaymentPnr
    {orderId}
    bind:open={changePaymentPnrData.open}
    airline={seatData.AlaCarteOffer.Owner}
    payload={changePaymentPnrData.payload}
    totalAmount={priceAmount}
  />
  <div id="purchase-seats" style="--tabs-cols:{tabs.length > 4 ? 'auto-fit' : 4}">
    <ComposedModal bind:open size="lg" preventCloseOnClickOutside={true} on:close={handleCloseModal}>
      <ModalHeader />
      <ModalBody>
        <Tabs type="container">
          {#each tabs as tab}
            <Tab label={tab.label} />
          {/each}
          <div slot="content">
            {#each tabs as { PaxSegmentID }}
              <TabContent>
                <svelte:component
                  this={SelectSeatContent}
                  bind:this={tabsContent[PaxSegmentID]}
                  {paxList}
                  {PaxSegmentID}
                  airlineID={seatData.AlaCarteOffer.Owner}
                  SeatMap={seatData?.SeatMap ?? []}
                  AlaCarteOfferItem={seatData?.AlaCarteOffer?.AlaCarteOfferItem ?? []}
                  bind:priceAmount
                  on:change={(event) => {
                    ptcSelectedSeat[PaxSegmentID] = event.detail;
                  }}
                  on:calculate-price={handleCalculatePrice}
                  disabledCalculatePrice={isEqual(ptcCacheHasSeat, ptcHasSeat)}
                  loadingCalculatePrice={isLoadingCalculatePrice}
                />
              </TabContent>
            {/each}
          </div>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <footer>
          <PrimaryButton kind="secondary" width="100%" on:click={handleCloseModal}>취소</PrimaryButton>
          <PrimaryButton
            width="100%"
            disabled={getDisablePaymentSeat(ptcHasSeat, ptcCacheHasSeat)}
            on:click={handleOpenPaymentPnrModal}>좌석선택</PrimaryButton
          >
        </footer>
      </ModalFooter>
    </ComposedModal>
  </div>
</Portal>
