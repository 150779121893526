import request from './request.js';

export const signIn = async (params) => {
  try {
    const response = await request({
      url: '/auth/sign-in',
      method: 'POST',
      data: params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
