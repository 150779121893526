<!-- svelte-ignore a11y-click-events-have-key-events -->

<script>
  import { getWidthOfText, getDefaultFontSize, getDefaultFontFamily } from 'src/utils/appHelper';
  import { createEventDispatcher } from 'svelte';

  import { tooltip } from 'svooltip';
  import 'svooltip/styles.css';
  import 'src/styles/custom-tooltip.scss';

  export let align = 'center';
  export let direction = 'top';

  export let text;
  export { className as class };

  let className = '';
  let ref;
  let contentWidth = 0;
  let offsetWidth;
  let html;

  const dispatch = createEventDispatcher();

  function handleClick(e) {
    dispatch('click', e);
  }
  $: {
    if (ref) {
      const font = getDefaultFontFamily(ref);
      const fontSize = getDefaultFontSize(ref);
      contentWidth = getWidthOfText(text, fontSize, font);
    }
  }
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->

<div
  class="text-overflow btn {className}"
  use:tooltip={{
    content: `${html}`,
    html: true,
    placement: `${direction}-${align}`,
    delay: 0,
    visibility: offsetWidth < contentWidth,
  }}
  on:click={handleClick}
  bind:offsetWidth
  bind:this={ref}
>
  {text}
</div>

<div class="content-trick" bind:innerHTML={html} contenteditable="true">
  <p class="context"><slot /></p>
</div>

<style>
  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .content-trick {
    display: none;
  }
  p {
    font-size: 14px;
    padding: 4px;
  }
</style>
