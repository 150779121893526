import request from './request.js';
import { generateUUID, getTransactionId } from 'src/utils/requestHelper.js';
import { user } from 'src/store';
import { get } from 'svelte/store';

const agencyID = get(user)?.agencyId;

export const getAirShopping = async (params) => {
  const transactionID = generateUUID();
  sessionStorage.setItem('transactionID', transactionID);
  try {
    const response = await request({
      url: '/v1/ndc/air-shopping',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: transactionID,
        Query: params.Query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOfferPrice = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/offer/price',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
            ContactInfoRefID: [],
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: params.Query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOffer = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: params.Query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getServiceList = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/serviceList',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: params,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
