<script>
  import { onMount } from 'svelte';
  import { map } from 'lodash';
  import { DataTable, Toolbar, ToolbarContent, Button } from 'carbon-components-svelte';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';
  import { EMPTY } from 'src/constants/app.js';
  import { formatDate } from 'src/utils/appHelper.js';
  import { OcnConsentButton } from 'src/components/common/button';

  export let ocnData;
  export let airlineID;
  export let pnr = EMPTY;
  export let orderId = EMPTY;
  let data = [];
  function getOcnTableData(ocnData) {
    const MatchedOcn = ocnData?.data?.Response?.MatchedOcn || [];
    const data = map(MatchedOcn, (it, index) => {
      const { OcnReceiveDateTime, BookingReference, IsAckFromAgency, Type, OcnSendDateTime, Amendment } = it;
      const getActionTypeOrContext = () => {
        return map(Amendment, (it) => {
          if (!it.ActionType && !it.Context) {
            return EMPTY;
          }
          if (it.ActionType && it.Context) return `${it.ActionType}(${it.Context})`;
          if (it.ActionType && !it.Context) return it.ActionType;
          if (it.Context && !it.ActionType) return `(${it.Context})`;
        });
      };
      return {
        id: index,
        OcnReceiveDateTime,
        OcnSendDateTime: IsAckFromAgency === 'Y' ? OcnSendDateTime : EMPTY,
        AirlineID: BookingReference[0]?.AirlineID || EMPTY,
        Type,
        ActionTypeOrContext: getActionTypeOrContext(),
        Remark: map(Amendment, (it) => it.Remark),
      };
    });

    return data.sort((a, b) => {
      const dateA = new Date(a.OcnReceiveDateTime);
      const dateB = new Date(b.OcnReceiveDateTime);
      return dateB - dateA;
    });
  }

  onMount(() => {
    data = getOcnTableData(ocnData);
  });
</script>

{#if $isLocaleLoaded}
  {#if data?.length}
    <DataTable
      id="table-management"
      class="ocn-table"
      headers={[
        {
          key: 'OcnReceiveDateTime',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.receiveAirline', {
            default: '항공사 OCN 수신일시',
          }),
        },
        {
          key: 'OcnSendDateTime',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.travelAgencyReception', {
            default: '여행사 OCN 수신일시',
          }),
        },
        {
          key: 'AirlineID',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.airline', { default: '항공사' }),
        },
        {
          key: 'Type',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.type', { default: 'OCN유형' }),
        },
        {
          key: 'ActionTypeOrContext',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.actionTypeOrContext', {
            default: 'OCN상세',
          }),
        },
        {
          key: 'Remark',
          value: $_('layout.pages.reservationManagement.ocnTable.dataTable.headers.description', {
            default: 'Description',
          }),
        },
      ]}
      rows={data}
    >
      <svelte:fragment slot="cell" let:cell let:row>
        {#if cell.key === 'ActionTypeOrContext'}
          <table class="carbon-table">
            <tbody>
              {#each cell.value as item}
                <tr>
                  <td>
                    {item || EMPTY}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        {:else if ['OcnReceiveDateTime', 'OcnSendDateTime'].includes(cell.key)}
          {formatDate(cell.value, 'datetime')}
        {:else if cell.key === 'Remark'}
          {#each cell.value as val}
            {#if val.length}
              <table class="carbon-table">
                <tbody>
                  <tr>
                    <td>
                      {#each val as item}
                        <span>{item || EMPTY}</span><br />
                      {/each}
                    </td>
                  </tr>
                </tbody>
              </table>
            {/if}
          {/each}
        {:else}
          {cell.value || EMPTY}
        {/if}
      </svelte:fragment>
      <Toolbar size="sm" class="table-toolbar">
        <ToolbarContent>
          <div class="title-table">
            <h4 class="title-table-content">
              {$_('layout.pages.reservationManagement.ocnTable.toolbar.titleTable', { default: 'OCN 정보' })}
            </h4>
          </div>

          <!-- not delete  -->
          <!-- doing -->
          {#if ['AF', 'KL'].includes(airlineID)}
            <OcnConsentButton {pnr} {orderId} />
          {/if}
        </ToolbarContent>
      </Toolbar>
    </DataTable>
  {/if}
{:else}
  <p>Loading...</p>
{/if}

<style itemscope>
  .carbon-table {
    width: 100%;
  }

  .carbon-table td {
    text-align: left;
    border-bottom: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .carbon-table tr:hover {
    background-color: #f5f5f5;
  }
</style>
