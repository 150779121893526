export const SEAT_CLASS_COLOR = [
  {
    id: 1,
    name: '일반석',
    color: '#D1D1D1',
  },
  {
    id: 2,
    name: '프리미엄일반석',
    color: '#74E792',
  },
  {
    id: 3,
    name: '비즈니스석',
    color: '#DCC7FF',
  },
  {
    id: 4,
    name: '일등석',
    color: '#B8D3FF',
  },
];
