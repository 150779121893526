<script>
  import { Modal } from 'carbon-components-svelte';
  import Portal from 'src/components/common/Portal.svelte';
  import { formatPriceNumber } from 'src/utils/appHelper';
  export let open;
  export let data = [];
</script>

<Portal idBox="box-modal-children">
  <Modal passiveModal bind:open modalHeading="Tax Breakdown" size="xs" preventCloseOnClickOutside>
    <div>
      <ul>
        {#each data as { TaxCode, Amount, CurCode }}
          <li>
            <div class="tax-code">
              {TaxCode}
            </div>
            <div class="amount-money text-align">
              {formatPriceNumber(Amount)}
              {CurCode}
            </div>
          </li>
        {/each}
      </ul>
    </div>
  </Modal>
</Portal>

<style>
  li {
    display: flex;
    justify-content: space-between;
    padding: 8px 4px;
    border-top: 1px solid #e0e0e0 !important;
  }
  .text-align {
    text-align: right;
  }
</style>
