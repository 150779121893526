<script>
  import {
    DataTable,
    Header,
    HeaderNav,
    HeaderNavItem,
    HeaderNavMenu,
    HeaderUtilities,
    Modal,
    Search,
  } from 'carbon-components-svelte';
  import { isEmpty, chunk } from 'lodash';
  import { push, querystring, location } from 'svelte-spa-router';
  import { v4 as uuidv4 } from 'uuid';
  import { EMPTY } from 'src/constants/app.js';
  import { _, isLocaleLoaded } from 'src/lib/i18n.js';
  import { bookingService } from 'src/service';
  import { user, message, bookingReference, currentTab, screenIsNotEvent } from 'src/store';
  import { formatDate, getMainBookingReference } from 'src/utils/appHelper.js';
  import { Logo, LogoLess } from 'src/assets/icons';
  import { AppToastNotification } from 'src/components/common/Notify';
  import { onMount } from 'svelte';
  import 'src/styles/header.scss';
  user.useSessionStorage();
  let strSearch = EMPTY;
  let matchedOrders = [];
  let rows = [];
  let openModalChoosePnr = false;
  let selectedRowIds = [];
  let reservationOcnType = EMPTY;
  let notifySearch = EMPTY;

  const headers = [
    { key: 'id', value: '순번' },
    { key: 'airlineID', value: '항공사' },
    { key: 'bookingReference', value: 'PNR' },
    { key: 'createDate', value: '예약일시' },
  ];

  const handleOnKeyEnterSearch = async (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    let pnrSearch = strSearch.trim().toLocaleUpperCase();
    notifySearch = EMPTY;
    if (keycode === 13) {
      if (!pnrSearch.length) {
        notifySearch = '* PNR 을 입력해주세요';
        return;
      }
      if (Number(pnrSearch)) {
        strSearch = pnrSearch = chunk(pnrSearch, 4)
          .map((chunk) => chunk.join(''))
          .join('-');
      }
      await loadDataSearchPnr({ booking_reference: pnrSearch });
    }
  };
  const handleInputSearch = (event) => {
    const text = event.data;
    if (Boolean(text?.match(/^[^-a-zA-Z0-9]*$/))) {
      strSearch = strSearch.replaceAll(/[^a-zA-Z0-9-]/g, EMPTY);
    }
    if ((strSearch.length === 9 && !strSearch.includes('-')) || strSearch.length > 9) {
      strSearch = strSearch.slice(0, -1);
    }
  };
  const handleClearSearch = () => {
    notifySearch = EMPTY;
  };
  async function loadDataSearchPnr(params) {
    const res = await bookingService.getManagementOrder(params);
    matchedOrders = res?.data?.Response?.MatchedOrder || [];
    if (matchedOrders.length === 0) {
      message.set([
        {
          subtitle: 'LUNA에서 검색 불가한 PNR입니다.ATS [Aggregator Tech Support] 로 문의해주세요',
          caption: EMPTY,
        },
      ]);
      return;
    }
    if (matchedOrders.length > 1) {
      openModalChoosePnr = true;
      return;
    }
    await updateCurrentTab({
      ...params,
      order_id: matchedOrders[0].OrderID,
    });
  }
  async function updateCurrentTab(params) {
    const resGetPnr = await bookingService.getManagementOrder(params);
    const matchedOrders = resGetPnr?.data?.Response?.MatchedOrder[0];
    if (
      (reservationOcnType === 'reservationManagement' &&
        resGetPnr?.data.ResultCode === 'SUCCESS' &&
        resGetPnr?.data?.Response?.MatchedOrderCount === 0) ||
      !matchedOrders
    ) {
      message.set([
        {
          subtitle: 'LUNA에서 검색할 수 없는 PNR입니다.',
          caption: '고객센터 0000-0000로 문의 주시기 바랍니다.',
        },
        {
          subtitle: 'PNR 조회에 실패했습니다.',
          caption: EMPTY,
        },
      ]);
      return;
    }
    const { BookingReference, OrderID, OrderStatus, CancelDate } = matchedOrders;
    const { AirlineID = EMPTY, Id = EMPTY } = getMainBookingReference(BookingReference);

    currentTab.set({
      orderId: OrderID,
      type: 'reservationManagement',
      isDeleted: false,
      orderStatus: OrderStatus,
      cancelDate: CancelDate,
      airlineId: AirlineID,
      bookingReference: BookingReference,
      tabName: Id,
    });
    strSearch = EMPTY;
  }

  async function handleSelectedRow() {
    if (selectedRowIds.length) {
      const findSelectRow = matchedOrders.find((item, index) => index + 1 === selectedRowIds[0]);
      if (findSelectRow) {
        await updateCurrentTab(findSelectRow);
        selectedRowIds = [];
      }
    }
  }

  function setTabWithReservationOcn($querystring) {
    const currentOrderId = new URLSearchParams($querystring)
      ? new URLSearchParams($querystring).get('orderId')
      : uuidv4();
    const reservationOcnType = new URLSearchParams($querystring)
      ? new URLSearchParams($querystring).get('type')
      : EMPTY;
    if (
      reservationOcnType &&
      ['listReservation', 'listOcn', 'listDsr', 'reservationTicketing'].includes(reservationOcnType)
    ) {
      currentTab.set({
        tabName: $_(`layout.gnb.mgmtReservation.subMenus.${reservationOcnType}`),
        orderId: currentOrderId,
        airlineId: EMPTY,
        type: reservationOcnType,
        isDeleted: false,
        orderStatus: EMPTY,
      });
    }
  }

  function changeRoute(type) {
    const newQueryString = `?type=${type || 'reservationManagement'}&orderId=${uuidv4()}`;
    push($location + newQueryString);
  }
  onMount(() => {
    const unBookingReference = bookingReference.subscribe(async (value) => {
      if (!isEmpty(value)) {
        screenIsNotEvent.set(true);
        await updateCurrentTab(value);
        screenIsNotEvent.set(false);
        bookingReference.set({});
      }
    });
    const unQuerystring = querystring.subscribe((value) => {
      reservationOcnType = new URLSearchParams(value) ? new URLSearchParams(value).get('type') : EMPTY;
    });
    return () => {
      unBookingReference();
      unQuerystring();
    };
  });
  $: {
    if (matchedOrders.length > 0) {
      rows = matchedOrders.map((item, index) => {
        return {
          id: index + 1,
          airlineID: item.BookingReference[0]?.AirlineID || EMPTY,
          bookingReference: item.BookingReference[0]?.Id || EMPTY,
          createDate: formatDate(item?.CreateDate || EMPTY, 'datetime'),
        };
      });
    }
  }

  $: {
    setTabWithReservationOcn($querystring);
  }
</script>

{#if $isLocaleLoaded}
  <header class="app-header">
    <Header>
      <button class="g-button logo-box" on:click={() => push('/')}>
        <div class="logo-icon"><Logo /></div>
        <div class="logo-icon-less">
          <LogoLess />
        </div>
      </button>
      <HeaderNav>
        <Search
          placeholder={$_('layout.gnb.searchPnr.placeHolder', { default: 'PNR 입력 후 엔터' })}
          size="lg"
          bind:value={strSearch}
          light
          on:clear={handleClearSearch}
          on:input={handleInputSearch}
          on:keyup={handleOnKeyEnterSearch}
        />
        {#if !!notifySearch}
          <div class="search-notify"><p>{notifySearch}</p></div>
        {/if}
      </HeaderNav>
      <HeaderNav>
        <HeaderNavItem
          on:click={() => changeRoute('reservationTicketing')}
          text={$_('layout.gnb.mgmtReport.subMenus.reservationTicketing', { default: '예약/발권' })}
        />
        <HeaderNavMenu text={$_('layout.gnb.mgmtReservation.title', { default: '예약관리' })}>
          <HeaderNavItem
            on:click={() => changeRoute('listReservation')}
            text={$_('layout.gnb.mgmtReservation.subMenus.listReservation', { default: '예약리스트' })}
          />
          <HeaderNavItem
            on:click={() => changeRoute('listOcn')}
            text={$_('layout.gnb.mgmtReservation.subMenus.listOcn', { default: 'OCN 리스트' })}
          />
        </HeaderNavMenu>
        <HeaderNavItem
          on:click={() => changeRoute('listDsr')}
          text={$_('layout.gnb.mgmtReport.subMenus.listDsr', { default: 'DSR 리스트' })}
        />
      </HeaderNav>

      <HeaderUtilities>
        <HeaderNav class="userID">
          <HeaderNavItem text={`${$user?.agencyId || EMPTY}(${$user?.username || EMPTY})`} />
        </HeaderNav>
        <HeaderNav>
          <HeaderNavItem
            text="Logout"
            on:click={(event) => {
              event.preventDefault();
              user.set('LOGOUT');
              window.location.reload();
            }}
          />
        </HeaderNav>
      </HeaderUtilities>
    </Header>
    <div class="toast-header">
      <AppToastNotification />
    </div>
  </header>
  <Modal
    id="modal-search-pnr"
    bind:open={openModalChoosePnr}
    modalHeading="PNR"
    primaryButtonText="확인"
    primaryButtonDisabled={selectedRowIds.length === 0}
    secondaryButtons={[{ text: 'hidden' }, { text: '취소' }]}
    on:click:button--secondary={({ detail }) => {
      if (detail.text === '취소') {
        openModalChoosePnr = false;
        selectedRowIds = [];
      }
    }}
    on:click:button--primary={async () => {
      await handleSelectedRow();
      openModalChoosePnr = false;
    }}
    size="sm"
    on:close={() => {
      openModalChoosePnr = false;
      selectedRowIds = [];
    }}
  >
    <p>항공사 PNR “{strSearch}”을 {matchedOrders.length}개 조회했습니다.</p>
    <p>열람할 PNR을 선택 후 [확인]을 선택해주십시오.</p>
    <DataTable radio bind:selectedRowIds {headers} {rows} />
  </Modal>
{:else}
  <p>Loading...</p>
{/if}
