<script>
  import { screenIsNotEvent } from 'src/store';
  import { ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading, Button } from 'carbon-components-svelte';
  import Portal from 'src/components/common/Portal.svelte';
  import { EMPTY } from 'src/constants/app';
  import { postOrderChange } from 'src/service/reservation';

  export let open = false;
  export let pnr = EMPTY;
  export let orderId = EMPTY;
  let isLoading = false;
  const handleChangeOcnConsent = async () => {
    try {
      isLoading = true;
      screenIsNotEvent.set(true);
      const { code, data } = await postOrderChange({
        query: {
          OrderID: orderId,
          ChangeOrderChoice: {
            AcceptChange: {
              OrderItemRefID: ['NOT_USED'],
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      isLoading = false;
      screenIsNotEvent.set(false);
      open = false;
    }
  };
</script>

<Button size="small" kind="secondary" on:click={() => (open = true)}>OCN 동의</Button>
<Portal>
  <ComposedModal bind:open size="xs">
    <ModalHeader title="OCN 동의" />
    <ModalBody>
      <div>PNR {pnr} 가 항공사에 의해 비자발적 변경되었습니다.</div>
      <div>‘OCN동의’를 클릭하면, 변경된 스케줄을 수락합니다.</div>
      <div>스케줄 변경으로 재발행이 필요한 경우 자동 재발행이 됩니다.</div>
    </ModalBody>
    <ModalFooter>
      <Button kind="secondary" on:click={() => (open = false)}>확인</Button>
      <Button kind="danger" on:click={handleChangeOcnConsent}
        >OCN 동의
        {#if isLoading}
          <div class="g-custom-loading-button loading-box">
            <InlineLoading description="Loading ..." />
          </div>
        {/if}</Button
      >
    </ModalFooter>
  </ComposedModal>
</Portal>
