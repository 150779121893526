<script>
  import { onMount } from 'svelte';
  import {
    Form,
    FormGroup,
    Button,
    DataTable,
    DatePicker,
    DatePickerInput,
    Pagination,
    Toolbar,
    ToolbarContent,
    MultiSelect,
  } from 'carbon-components-svelte';
  import { Download } from 'carbon-icons-svelte';
  import { map, filter, isEmpty, cloneDeep, reduce, sortBy } from 'lodash';
  import { EMPTY, PAGE_TABLE_SIZES_OCN_BOOKING, InputOptions, InputOptionsInvalid } from 'src/constants/app.js';
  import { _ } from 'src/lib/i18n.js';
  import { getAgencyProviderAirlineCode } from 'src/service/reservation.js';
  import { drsListService } from 'src/service';
  import { formatDate, formatQueryParams, exportExcelFileName } from 'src/utils/appHelper.js';
  import { getDateTimeNow } from 'src/utils/DateTime.js';
  import { bookingReference, message } from 'src/store';
  import { CustomLoading } from 'src/components/common/loading';
  import { TextOverflowTooltip } from 'src/components/common/tooltip';
  import { CustomTextInput } from 'src/components/common/input';

  const headers = [
    {
      key: 'releaseDateAndTime',
      value: $_('layout.pages.dsrList.headerTable.releaseDateAndTime', {
        default: '처리일시',
      }),
    },
    {
      key: 'ticketType',
      value: $_('layout.pages.dsrList.headerTable.ticketType', {
        default: '티켓유형',
      }),
    },
    {
      key: 'airline',
      value: $_('layout.pages.dsrList.headerTable.airline', {
        default: '항공사',
      }),
    },
    {
      key: 'ticketNumber',
      value: $_('layout.pages.dsrList.headerTable.ticketNumber', {
        default: '티켓번호',
      }),
    },
    {
      key: 'passengerName',
      value: $_('layout.pages.dsrList.headerTable.passengerName', {
        default: '탑승객명',
      }),
    },
    {
      key: 'pnr',
      value: $_('layout.pages.dsrList.headerTable.pnr', {
        default: 'PNR',
      }),
    },
    {
      key: 'orderId',
      value: $_('layout.pages.dsrList.headerTable.orderId', {
        default: 'OrderID',
      }),
    },
    {
      key: 'totalFare',
      value: $_('layout.pages.dsrList.headerTable.totalFare', {
        default: 'Total Fare',
      }),
    },
    {
      key: 'baseAmount',
      value: $_('layout.pages.dsrList.headerTable.baseAmount', {
        default: 'Base Amount',
      }),
    },
    {
      key: 'totalTax',
      value: $_('layout.pages.dsrList.headerTable.totalTax', {
        default: 'Total Tax',
      }),
    },
    {
      key: 'rfndPenalty',
      value: $_('layout.pages.dsrList.headerTable.rfndPenalty', {
        default: 'RFND Penalty',
      }),
    },
    {
      key: 'currency',
      value: $_('layout.pages.dsrList.headerTable.currency', {
        default: 'Currency',
      }),
    },
    {
      key: 'fop',
      value: $_('layout.pages.dsrList.headerTable.fop', {
        default: 'FOP',
      }),
    },
    {
      key: 'fareBasis',
      value: $_('layout.pages.dsrList.headerTable.fareBasis', {
        default: 'Fare Basis',
      }),
    },
    {
      key: 'emd',
      value: $_('layout.pages.dsrList.headerTable.emd', {
        default: 'EMD상세',
      }),
    },
  ];

  const ticketType = [
    {
      id: 1,
      text: 'TKTT',
    },
    {
      id: 2,
      text: 'EMDA',
    },
    {
      id: 3,
      text: 'EMDS',
    },
    {
      id: 4,
      text: 'CANX',
    },
    {
      id: 5,
      text: 'RFND',
    },
    {
      id: 6,
      text: 'EXCH',
    },
  ];
  const textInputValidOptions = [
    {
      condition: InputOptionsInvalid.MATCH_KOREAN,
      notify: '*한글은 입력할 수 없습니다',
    },
  ];
  let airlineType;
  let pageSize = PAGE_TABLE_SIZES_OCN_BOOKING[0];
  let page = 1;
  let formOption;
  let selectedIdsAirlineId;
  let selectedIdsTicketId;
  let data = {
    dsrSum: EMPTY,
    dataTable: [],
    dsrTicketTTL: EMPTY,
  };
  let isLoading = false;

  const FORM_OPTION = {
    ticketing_effective: getDateTimeNow(),
    ticketing_expiration: getDateTimeNow(),
    ticket_type: [],
    airline_id: [],
    order_id: EMPTY,
    ticket_nbr: EMPTY,
    booking_reference: EMPTY,
  };
  const handleNotifyEmpty = (dataTable) => {
    if (!dataTable.length) {
      message.update(() => [
        {
          subtitle: '검색결과가 없습니다',
          caption: EMPTY,
        },
      ]);
    }
  };
  async function getDataDsrList() {
    data = { dsrSum: EMPTY, dataTable: [], dsrTicketTTL: EMPTY };
    isLoading = true;
    const params = formatQueryParams(formOption, ['ticketing_effective', 'ticketing_expiration']);

    const res = await drsListService.getDsrList(params);
    isLoading = false;
    const ticketData = res?.data?.Response?.TicketData || [];
    const negativeType = ['CANX', 'RFND', 'EXCH'];
    const positiveType = ['TKTT', 'EMDA', 'EMDS'];

    const getNumberWithTypeTicket = (countNumber, ticketType) => {
      if (negativeType.includes(ticketType)) return -countNumber;
      if (positiveType.includes(ticketType)) return countNumber;
      return EMPTY;
    };
    const getMoney = (money, ticketType) => {
      if (!money || isEmpty(money)) return EMPTY;
      return `${getNumberWithTypeTicket(money?.Amount, ticketType)}`;
    };

    const getCurrency = (money) => {
      if (!money || isEmpty(money)) return EMPTY;
      return money?.CurCode;
    };

    const getFareBasis = (fareBasis) => {
      if (!fareBasis || !fareBasis?.length) return EMPTY;
      return fareBasis[0];
    };
    let dataTable = sortBy(
      map(ticketData, (ticketItem, index) => {
        const {
          OrderID,
          TicketingDate,
          TicketType,
          TicketingAirlineCode,
          TicketingAirlineNbr,
          TicketNbr,
          PaxName,
          BookingReference,
          BaseAmount,
          TotalTax,
          Fop,
          FareBasis,
          SsrName,
          PenaltyAmount,
        } = ticketItem;

        return {
          id: index,
          releaseDateAndTime: TicketingDate,
          ticketType: TicketType,
          airline: TicketingAirlineCode,
          ticketNumber: `${TicketingAirlineNbr}-${TicketNbr}`,
          passengerName: PaxName,
          pnr: BookingReference,
          orderId: OrderID,
          totalFare:
            getMoney(BaseAmount, TicketType) * 1 +
            getMoney(TotalTax, TicketType) * 1 +
            (PenaltyAmount?.Amount ?? 0) * 1,
          rfndPenalty: PenaltyAmount?.Amount ?? EMPTY,
          baseAmount: getMoney(BaseAmount, TicketType),
          totalTax: getMoney(TotalTax, TicketType),
          currency: getCurrency(BaseAmount),
          fop: Fop || EMPTY,
          fareBasis: getFareBasis(FareBasis),
          emd: SsrName || EMPTY,
        };
      }),
      (it) => it.releaseDateAndTime
    ).reverse();
    dataTable = map(dataTable, (dataTableItem) => {
      dataTableItem.releaseDateAndTime = formatDate(dataTableItem.releaseDateAndTime, 'datetime');
      return dataTableItem;
    });

    const dsrSum = reduce(
      dataTable,
      (total, dataTableItem) => {
        return total + dataTableItem.totalFare * 1;
      },
      0
    );
    const dsrTicketTTL = reduce(
      dataTable,
      (total, dataTableItem) => {
        if (['TKTT', 'EMDS', 'EMDA'].includes(dataTableItem.ticketType))
          return total + dataTableItem.baseAmount * 1 + dataTableItem.totalTax * 1;
        return total;
      },
      0
    );
    handleNotifyEmpty(dataTable);
    data = {
      dataTable,
      dsrSum,
      dsrTicketTTL,
    };
  }

  function resetDataDsrList() {
    formOption = cloneDeep(FORM_OPTION);
    selectedIdsAirlineId = [];
    selectedIdsTicketId = [];
  }
  function handleClickOderId(row) {
    bookingReference.set({
      booking_reference: row.pnr,
      order_id: row.orderId,
    });
  }

  async function handleClickExportExcel() {
    let params = formatQueryParams(formOption, ['ticketing_effective', 'ticketing_expiration']);
    const dataExcel = await drsListService.exportExcelDsr(params);
    exportExcelFileName(dataExcel, 'DSR리스트');
  }
  $: if (selectedIdsTicketId && selectedIdsAirlineId) {
    const getTicketType = filter(ticketType, (ticketTypeItem) => selectedIdsTicketId.includes(ticketTypeItem.id));
    const getAirlineType = filter(airlineType, (airlineTypeItem) => selectedIdsAirlineId.includes(airlineTypeItem.id));
    formOption.ticket_type = map(getTicketType, (ticketTypeItem) => ticketTypeItem.text);
    formOption.airline_id = map(getAirlineType, (airlineTypeItem) => airlineTypeItem.text);
  }

  onMount(async () => {
    formOption = cloneDeep(FORM_OPTION);

    const listAirline = await getAgencyProviderAirlineCode();
    const Airline = listAirline?.data?.Response?.AgencyProvider[0]?.Airline || [];
    const airlineCodeApi = map(
      sortBy(Airline, (itAirline) => itAirline.AirlineCode),
      (it, index) => {
        const { AirlineCode } = it;
        return {
          id: index + 1,
          text: AirlineCode,
        };
      }
    );
    airlineType = [...airlineCodeApi];
  });
</script>

{#if formOption}
  <div id="dsr-list-wrapper">
    <Form class="form-wrapper">
      <FormGroup>
        <DatePicker
          datePickerType="range"
          bind:valueFrom={formOption.ticketing_effective}
          bind:valueTo={formOption.ticketing_expiration}
          dateFormat="d/m/Y"
          minDate={new Date(2023, 10, 17)}
        >
          <DatePickerInput labelText="처리일" placeholder="dd/mm/yyyy" />
          <DatePickerInput labelText=" " placeholder="dd/mm/yyyy" />
        </DatePicker>
      </FormGroup>
      <div class="m-l-32 w-160">
        <MultiSelect
          filterable
          titleText="티켓유형"
          label="전체"
          placeholder="전체"
          items={ticketType}
          sortItem={(a, b) => a.id - b.id}
          bind:selectedIds={selectedIdsTicketId}
        />
      </div>
      <div class="m-l-32 w-160">
        <MultiSelect
          filterable
          titleText="항공사"
          label="전체"
          placeholder="전체"
          items={airlineType}
          sortItem={(a, b) => a.id - b.id}
          bind:selectedIds={selectedIdsAirlineId}
        />
      </div>
      <div class="m-l-32 w-160">
        <CustomTextInput
          labelText="티켓번호"
          placeholder="티켓번호 10자리 입력"
          bind:value={formOption.ticket_nbr}
          maxlength="10"
          options={[InputOptions.ONLY_NUMBER]}
        />
      </div>
      <!-- new  -->
      <div class="m-l-32 w-160">
        <CustomTextInput
          labelText="항공사PNR"
          placeholder="항공사PNR만 조회가능"
          bind:value={formOption.booking_reference}
          options={[InputOptions.ONLY_LATIN_NUMBER]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <div class="m-l-32 w-160">
        <CustomTextInput
          labelText="OrderID"
          placeholder=""
          bind:value={formOption.order_id}
          options={[InputOptions.PATTERN_ORDER_ID]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <!-- end -->
      <div class="wrapper-action">
        <Button kind="secondary" on:click={resetDataDsrList}>초기화</Button>
        <Button on:click={getDataDsrList}>검색</Button>
      </div>
    </Form>

    <div class="dsr-list-table">
      <!-- svelte-ignore missing-declaration -->
      <DataTable {headers} rows={data.dataTable} {pageSize} {page} stickyHeader id="drsList">
        <svelte:fragment slot="cell" let:cell let:row>
          {#if ['totalFare', 'baseAmount', 'totalTax', 'rfndPenalty'].includes(cell.key)}
            <div class="text-totalFare" class:color-outstand-cell-red={cell.value < 0}>
              <TextOverflowTooltip
                text={cell.value === EMPTY ? EMPTY : (cell.value * 1).toLocaleString()}
                class="align-money"
              >
                {cell.value === EMPTY ? EMPTY : (cell.value * 1).toLocaleString()}
              </TextOverflowTooltip>
            </div>
          {:else if cell.key === 'orderId'}
            <TextOverflowTooltip
              text={cell.value}
              on:click={() => handleClickOderId(row)}
              class="g-button color-outstand-cell">{cell.value}</TextOverflowTooltip
            >
          {:else}
            <TextOverflowTooltip text={cell.value}>
              {cell.value}
            </TextOverflowTooltip>
          {/if}
        </svelte:fragment>
        <svelte:fragment slot="cell-header" let:header>
          {#if ['totalFare', 'baseAmount', 'totalTax'].includes(header.key)}
            <div class="text-totalFare-header">
              <div class="align-money g-cell-text-overflow">{header.value}</div>
            </div>
          {:else}
            <div class="g-cell-text-overflow">{header.value}</div>
          {/if}
        </svelte:fragment>

        <Toolbar class="table-toolbar">
          <ToolbarContent>
            <div class="title-table">
              <h4 class="title-table-content">
                {$_('layout.pages.dsrList.titleTable', {
                  default: '검색결과',
                })}
              </h4>
            </div>
            <Button
              size="small"
              kind="secondary"
              class="custom-button-download"
              disabled={!data.dataTable.length}
              on:click={handleClickExportExcel}
            >
              <span style="margin-right: 4px;">
                {$_('layout.pages.dsrList.btnDownload', {
                  default: '엑셀 다운로드',
                })}</span
              >
              <Download />
            </Button>
          </ToolbarContent>
        </Toolbar>
      </DataTable>
      {#if data.dataTable?.length}
        <Pagination
          pageSizes={PAGE_TABLE_SIZES_OCN_BOOKING}
          bind:page
          totalItems={data?.dataTable.length}
          bind:pageSize
          itemsPerPageText={EMPTY}
          itemRangeText={(min, max, total) => {
            return `${min} - ${max} of ${total} items Grand TTL:${data.dsrSum.toLocaleString()} KRW  Ticket TTL:${data.dsrTicketTTL.toLocaleString()} KRW`;
          }}
        />
      {/if}
      {#if isLoading}
        <div class="loading-dsr-list">
          <CustomLoading />
        </div>
      {/if}
    </div>
  </div>
{/if}
