<script>
  import { DataTable, Toolbar } from 'carbon-components-svelte';
  import Ticket from 'carbon-icons-svelte/lib/Ticket.svelte';
  import { _ } from 'src/lib/i18n.js';
  import { EMPTY, POPUP_TYPE_TICKET } from 'src/constants/app.js';
  import { generatePopupCoveredServices } from 'src/utils/appHelper.js';
  import RuleCoveredServicesModal from 'src/components/modal/RuleCoveredServicesModal.svelte';

  export let handleClickCellOpenModal;
  export let ticketList;
  export let airlineID = EMPTY;
  const COLUMNS = [
    {
      key: 'TicketDocNbr',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.ticketDocNbr', {
        default: '티켓번호',
      }),
    },
    {
      key: 'Type',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.type', {
        default: '티켓유형',
      }),
    },
    {
      key: 'PassengerName',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.passengerName', {
        default: '탑승객명(PTC)',
      }),
    },
    {
      key: 'TicketingDate',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.ticketingDate', {
        default: '발권일시',
      }),
    },
    {
      key: 'segments',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.flightNumberAirportCodeDates', {
        default: 'Segments',
      }),
    },
    {
      key: 'Edm',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.edm', {
        default: 'EMD상세',
      }),
      width: '30%',
    },
    {
      key: 'TotalFareTicket',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.dataTable.totalFareTicket', {
        default: 'Total Fare',
      }),
    },
    {
      key: 'Action',
      value: EMPTY,
      width: '5%',
    },
  ];
  let openRuleCoveredServicesModal = false;
  let ruleCoveredServicesModalData = {
    headers: [],
    rows: [],
  };

  const handleClickTicket = (rowData) => {
    const { PaxSegmentList, JourneyOverview, PaxJourneyList, FareDetail } = rowData;
    const fareComponent = FareDetail?.FareComponent ?? [];
    ruleCoveredServicesModalData = generatePopupCoveredServices(
      PaxSegmentList,
      JourneyOverview,
      PaxJourneyList,
      fareComponent,
      airlineID
    );
    openRuleCoveredServicesModal = open;
  };
</script>

<DataTable class="table-management__ticket" id="table-management" headers={COLUMNS} rows={ticketList}>
  <div slot="cell" let:cell let:row>
    {#if ['Edm', 'segments'].includes(cell.key)}
      {#each cell.value as val}
        {#if val.length}
          <span>{val}</span><br />
        {/if}
      {/each}
    {:else if cell.key === 'TicketDocNbr'}
      <button on:click={() => handleClickCellOpenModal(cell, row)} class="g-button color-outstand-cell">
        {cell.value}
      </button>
    {:else if cell.key === 'Action'}
      {#if row.Type === POPUP_TYPE_TICKET && row.CouponInfos[0]?.Status !== 'E'}
        <button class="g-button" on:click={() => handleClickTicket(row)}>
          <Ticket size={16} />
        </button>
      {/if}
    {:else}
      {cell.value}
    {/if}
  </div>
  <Toolbar size="sm" class="table-toolbar">
    <div class="title-table">
      <h4 class="title-table-content">
        {$_('layout.pages.reservationManagement.ticketInformationTable.toolbar.titleTable', {
          default: '티켓 정보',
        })}
      </h4>
    </div>
  </Toolbar>
</DataTable>
<RuleCoveredServicesModal
  bind:open={openRuleCoveredServicesModal}
  rows={ruleCoveredServicesModalData.rows}
  headers={ruleCoveredServicesModalData.headers}
/>
