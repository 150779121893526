<script>
  import appEvent from 'src/store/appEventStore';
  import { ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading, Button } from 'carbon-components-svelte';
  import { EMPTY } from 'src/constants/app';
  import ErrorApi from 'src/components/modal/ErrorApi.svelte';
  import { createEventDispatcher } from 'svelte';
  import { APP_EVENT_NAME } from 'src/constants/app';

  export let pnr = EMPTY;
  export let orderId = EMPTY;
  let open = false;
  let errorApi = {
    isOpen: false,
    message: EMPTY,
  };
  let isLoading = false;
  const handleAction = async () => {
    open = true;
  };
  const handleCancelReservation = async () => {
    open = false;
    appEvent.action(`${APP_EVENT_NAME.CANCEL_PNR}${orderId}`, { orderId, typeCancel: 'cancel' });
  };
</script>

<button class="g-button button" on:click={handleAction}>CANCEL</button>

<ComposedModal bind:open size="xs">
  <ModalHeader title="PNR취소" />
  <ModalBody>
    <div>PNR {pnr}의 예약을 취소합니다.</div>
    <div>티켓이 발권된 경우, 티켓을 VOID 하고 예약을 취소합니다.</div>
  </ModalBody>
  <ModalFooter>
    <Button kind="secondary" on:click={() => (open = false)}>확인</Button>
    <Button kind="danger" on:click={handleCancelReservation}
      >예약 취소
      {#if isLoading}
        <div class="g-custom-loading-button loading-box">
          <InlineLoading description="Loading ..." />
        </div>
      {/if}</Button
    >
  </ModalFooter>
</ComposedModal>

<ErrorApi bind:openErrorApi={errorApi.isOpen}>{errorApi.message}</ErrorApi>

<style>
  .loading-box {
    padding-right: 16px;
  }
</style>
