<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { find, has, isEmpty } from 'lodash';
  import { Button, DataTable, Toolbar, ToolbarContent, Modal, Checkbox } from 'carbon-components-svelte';
  import ChangePassengerInformation from 'src/components/modal/ChangePassengerInformation.svelte';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';
  import { actionOrderChangeDivide } from 'src/service/reservation.js';
  import { message } from 'src/store';
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app.js';
  import { SeparationPnr } from 'src/components/common/loading';
  import { formatDate, sortPaxListByPtc } from 'src/utils/appHelper.js';
  import Portal from 'src/components/common/Portal.svelte';
  import { PostalAddressButton } from 'src/components/common/button';

  export let orderId;
  export let orderRetrieve;
  export let airlineID = EMPTY;
  export let isHiddenBtn = false;
  const dispatch = createEventDispatcher();
  let passengerInfo;
  let loading = false;
  const headers = [
    {
      key: 'Action',
      value: EMPTY,
    },
    { key: 'id', value: '순번' },
    { key: 'ptc', value: 'PTC' },
    { key: 'nameTitle', value: 'TITLE' },
    { key: 'passengerName', value: '탑승객명' },
    { key: 'accompanyingInfant', value: '동반유아' },
  ];
  let rows = [];
  let selectedRowIds = [];
  let showModal = false;
  let showModalChangePassengerInfo = false;
  async function handleSeparationPassengers() {
    showModal = true;
    const dataLists = orderRetrieve?.data?.DataLists || {};
    const paxList = dataLists?.PaxList || [];
    const paxListADTCHD = paxList.filter((paxItem) => paxItem.Ptc !== 'INF');
    rows = paxListADTCHD.map((item, index) => {
      const findPaxItemINF = paxList.find((paxItem) => paxItem?.PaxRefID === item?.PaxID);
      const getAccompanyingInfant = () => {
        let accompanyingInfant = `${findPaxItemINF?.Individual?.Surname || EMPTY}/${
          findPaxItemINF?.Individual?.GivenName || EMPTY
        }`;
        if (!findPaxItemINF?.Individual?.Surname || !findPaxItemINF?.Individual?.GivenName)
          return accompanyingInfant.replace('/', EMPTY);
        return accompanyingInfant;
      };
      return {
        id: index + 1,
        ptc: item?.Ptc || EMPTY,
        nameTitle: item?.Individual?.NameTitle || EMPTY,
        passengerName: `${item?.Individual?.Surname || EMPTY}/${item?.Individual?.GivenName || EMPTY}`,
        accompanyingInfant: getAccompanyingInfant(),
        paxId: item?.PaxID || EMPTY,
      };
    });
  }

  async function handleSelectedRow() {
    if (selectedRowIds.length) {
      const arrSelectRow = rows.filter((_item, index) => selectedRowIds.includes(index + 1));
      const params = {
        orderId,
        newPaxRefIds: arrSelectRow.map((item) => {
          return {
            NewPaxRefID: item.paxId,
          };
        }),
        paxList: orderRetrieve?.data?.DataLists.PaxList ?? [],
      };
      loading = true;
      const response = await actionOrderChangeDivide(params);
      loading = false;
      const code = response?.data?.ResultMessage?.Code || EMPTY;
      const mess = response?.data?.ResultMessage?.Message || EMPTY;
      if (code !== CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'error',
            title: 'Error',
            subtitle: `API 호출에 실패했습니다. 다시 시도해주세요\n[${airlineID} ${code} ${mess}]`,
            caption: EMPTY,
          },
        ]);
      }
      if (code == CODE_SUCCESS) {
        const warns = response?.data.ResultMessage?.MesageDetail?.Warnnings || [];
        if (warns.length) {
          const msgWarns = warns.map((war) => `${war.Code} ${war.Message}\n`);
          message.update(() => [
            {
              type: 'warning',
              title: 'Warn',
              subtitle: msgWarns,
              caption: EMPTY,
            },
          ]);
        }
        // Qua trinh A
        dispatch('reload-current-pnr', { resOrderChange: response?.data, endMessage: '탑승객 분리가 완료되었습니다' });
      }
      selectedRowIds = [];
    }
  }

  const handleSelectRowTable = (e, row) => {
    const status = e.detail;
    const id = row.id;
    if (status) {
      if (selectedRowIds.length <= rows.length - 1) {
        selectedRowIds = [...selectedRowIds, id];
      }
    } else {
      selectedRowIds = selectedRowIds.filter((selected) => selected !== id);
    }
  };

  function getPassengerInfo(resOrderRetrieve) {
    let passengerInfo = {
      dataTable: [],
      isDisableSeparationPassengers: false,
    };
    const contactInfoList = resOrderRetrieve?.data?.DataLists?.ContactInfoList;
    const dataLists = resOrderRetrieve?.data?.DataLists?.PaxList ?? [];
    if (dataLists.length > 0) {
      passengerInfo.dataTable = sortPaxListByPtc(dataLists).map((item, index) => {
        const { PaxID, PaxRefID, Ptc } = item;
        let phoneNumber = EMPTY;
        let emailAddress = EMPTY;
        const isCheckGetContactInfo =
          airlineID === 'LJ' && ['ADT', 'CHD'].includes(Ptc) && isEmpty(item?.ContactInfoRefID);
        const contactInfo = isCheckGetContactInfo
          ? contactInfoList[0]
          : find(contactInfoList, (elm) => item?.ContactInfoRefID.includes(elm?.ContactInfoID));
        if (contactInfo) {
          phoneNumber = `${contactInfo?.Phone[0]?.CountryDialingCode ?? EMPTY} ${
            contactInfo?.Phone[0]?.PhoneNumber ?? EMPTY
          }`;
          emailAddress = contactInfo?.EmailAddress?.[0] || EMPTY;
        }

        const getSurnAndGivenPaxRef = (_PaxRefID) => {
          const currentHasPaxRefId = find(dataLists, (it) => it.PaxID === PaxRefID);
          let surnAndGivenPaxRefText = `${currentHasPaxRefId?.Individual?.Surname || EMPTY}/${
            currentHasPaxRefId?.Individual?.GivenName[0] || EMPTY
          }`;
          if (!currentHasPaxRefId?.Individual?.GivenName[0] || !currentHasPaxRefId?.Individual?.Surname)
            return surnAndGivenPaxRefText.replace('/', EMPTY);
          return surnAndGivenPaxRefText;
        };

        const getGivenNameAndPtc = () => {
          if (item?.Individual && has(item?.Individual, 'Surname') && has(item?.Individual, 'GivenName')) {
            return `${item?.Individual?.Surname}/${item?.Individual?.GivenName}(${item?.Ptc || EMPTY})`;
          }
          return EMPTY;
        };
        const ffn =
          has(item, 'LoyaltyProgramAccount') &&
          item.LoyaltyProgramAccount.length > 0 &&
          item['LoyaltyProgramAccount'][0]?.ProgramCode
            ? `${item['LoyaltyProgramAccount'][0]?.ProgramCode || EMPTY}/${
                item['LoyaltyProgramAccount'][0].AccountNumber || EMPTY
              }`
            : EMPTY;
        return {
          id: index + 1,
          nameTitle: item?.Individual?.NameTitle || EMPTY,
          givenNameAndPtc: getGivenNameAndPtc(),
          surName: item?.Individual?.Surname || EMPTY,
          middleName: item?.Individual?.GivenName[0] || EMPTY,
          givenName: item?.Individual?.GivenName[0] || EMPTY,
          birthDate: formatDate(item?.Individual?.Birthdate || EMPTY, 'date'),
          countryDialCode: contactInfo?.Phone[0]?.CountryDialingCode ?? EMPTY,
          rawPhoneNumber: contactInfo?.Phone[0]?.PhoneNumber ?? EMPTY,
          phoneNumber,
          emailAddress,
          identityDocument: item?.IdentityDoc[0]?.IdentityDocumentNumber || EMPTY,
          issuingCountryCode: item?.IdentityDoc[0]?.IssuingCountryCode || EMPTY,
          expiryDate: formatDate(item?.IdentityDoc[0]?.ExpiryDate || EMPTY, 'date'),
          citizenshipCountryCode: item?.IdentityDoc[0]?.CitizenshipCountryCode || EMPTY,
          surnAndGiven: getSurnAndGivenPaxRef(PaxRefID),
          countryCode: item?.IdentityDoc[0]?.ResidenceCountryCode || EMPTY,
          ptc: item?.Ptc,
          paxID: item?.PaxID,
          ffn,
          paxList: dataLists,
          contactInfoList,
          contactInfoRefID: item?.ContactInfoRefID,
        };
      });
      passengerInfo.isDisableSeparationPassengers = dataLists.filter((paxItem) => paxItem.Ptc !== 'INF').length < 2;
    } else {
      passengerInfo = { ...passengerInfo, dataTable: [], isDisableSeparationPassengers: true };
    }
    return passengerInfo;
  }

  $: if (!showModal) {
    selectedRowIds = [];
  }

  onMount(() => {
    passengerInfo = getPassengerInfo(orderRetrieve);
  });
</script>

{#if $isLocaleLoaded && passengerInfo}
  <DataTable
    id="table-management"
    headers={[
      {
        key: 'id',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.id', {
          default: '순번',
        }),
      },
      {
        key: 'nameTitle',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.nameTitle', {
          default: 'TITLE',
        }),
      },
      {
        key: 'givenNameAndPtc',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.givenNameAndPtc', {
          default: '탑승객명(PTC)',
        }),
      },
      {
        key: 'birthDate',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.birthDate', {
          default: '생년월일',
        }),
      },

      {
        key: 'phoneNumber',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.phoneNumber', {
          default: '모바일',
        }),
      },
      {
        key: 'emailAddress',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.emailAddress', {
          default: '이메일',
        }),
      },
      {
        key: 'identityDocument',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.identityDocument', {
          default: '여권번호',
        }),
      },
      {
        key: 'issuingCountryCode',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.issuingCountryCode', {
          default: '여권발행국',
        }),
      },
      {
        key: 'expiryDate',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.expiryDate', {
          default: '여권만료일',
        }),
      },
      {
        key: 'citizenshipCountryCode',
        value: $_(
          'layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.citizenshipCountryCode',
          {
            default: '국적',
          }
        ),
      },
      {
        key: 'countryCode',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.countryCode', {
          default: '거주국가',
        }),
      },
      {
        key: 'surnAndGiven',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.surnAndGiven', {
          default: '동반유아/성인',
        }),
      },
      {
        key: 'ffn',
        value: $_('layout.pages.reservationManagement.passengerInformationTable.dataTable.headers.ffn', {
          default: 'FFN',
        }),
      },
    ]}
    rows={passengerInfo?.dataTable}
  >
    <Toolbar size="sm" class="table-toolbar">
      <ToolbarContent>
        <div class="title-table">
          <h4 class="title-table-content">
            {$_('layout.pages.reservationManagement.passengerInformationTable.toolbar.titleTable', {
              default: '탑승객 정보',
            })}

            {#if ['BA'].includes(airlineID)}
              <PostalAddressButton contactInfoList={orderRetrieve?.data?.DataLists?.ContactInfoList ?? []} />
            {/if}
          </h4>
        </div>
        <div class="g-wrapper-toolbar-button">
          {#if ['AF', 'KL'].includes(airlineID) && !isHiddenBtn}
            <!-- doing  -->
            <Button size="small" kind="secondary" on:click={() => (showModalChangePassengerInfo = true)}>
              {$_(
                'layout.pages.reservationManagement.passengerInformationTable.toolbar.buttonPassengerInformationTable',
                {
                  default: '정보변경',
                }
              )}
            </Button>
          {/if}
          {#if ['SQ', 'QR'].includes(airlineID) && !isHiddenBtn}
            <Button
              size="small"
              kind="secondary"
              on:click={handleSeparationPassengers}
              disabled={passengerInfo.isDisableSeparationPassengers}
            >
              {$_('layout.pages.reservationManagement.passengerInformationTable.toolbar.buttonAgreeOcn', {
                default: '탑승객 분리',
              })}
            </Button>
          {/if}
        </div>
      </ToolbarContent>
    </Toolbar>
  </DataTable>
{:else}
  <p>Loading...</p>
{/if}

{#if loading}
  <SeparationPnr />
{/if}
{#key showModalChangePassengerInfo}
  <ChangePassengerInformation
    on:reload-current-pnr
    bind:open={showModalChangePassengerInfo}
    {passengerInfo}
    {orderId}
    {airlineID}
  />
{/key}
{#if showModal}
  <Portal>
    <Modal
      id="modal-separation-passengers"
      bind:open={showModal}
      modalHeading="탑승객분리"
      primaryButtonText="탑승객 분리"
      primaryButtonDisabled={selectedRowIds.length === 0}
      secondaryButtons={[{ text: 'hidden' }, { text: '취소' }]}
      on:click:button--secondary={({ detail }) => {
        if (detail.text === '취소') {
          showModal = false;
        }
      }}
      on:click:button--primary={() => {
        showModal = false;
        handleSelectedRow();
      }}
      size="sm"
      on:close={() => {
        showModal = false;
      }}
    >
      <p>분리할 탑승객을 선택해주세요.</p>
      <p>유아탑승객은 지정된 보호자와 함께 분리됩니다.</p>
      <DataTable bind:selectedRowIds {headers} {rows}>
        <svelte:fragment slot="cell" let:cell let:row>
          {#if cell.key === 'Action'}
            <div class:pointer-none={selectedRowIds.length === rows.length - 1 && !selectedRowIds.includes(row.id)}>
              <Checkbox
                class="checkbox"
                on:check={(e) => handleSelectRowTable(e, row)}
                on:click={() => {
                  if (selectedRowIds.length === rows.length - 1 && !selectedRowIds.includes(row.id)) {
                    message.update(() => [
                      {
                        title: 'Error',
                        subtitle: '전체 탑승객을 선택할 수 없습니다',
                        caption: EMPTY,
                      },
                    ]);
                  }
                }}
              />
            </div>
          {:else}{cell.value}{/if}
        </svelte:fragment>
      </DataTable>
    </Modal>
  </Portal>
{/if}
