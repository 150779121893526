<script>
  import { createEventDispatcher } from 'svelte';
  import { Modal } from 'carbon-components-svelte';
  export let openModalCancellationRequest;
  const dispatch = createEventDispatcher();
</script>

{#if openModalCancellationRequest}
  <Modal
    danger
    id="modal-cancellation-request"
    modalHeading="취소요청"
    primaryButtonText="예약취소"
    bind:open={openModalCancellationRequest}
    secondaryButtons={[{ text: 'hidden' }, { text: '닫기' }]}
    on:click:button--secondary={({ detail }) => {
      if (detail.text === '닫기') {
        dispatch('close-modal-cancellation-request');
      }
    }}
    on:click:button--primary={(e) => {
      dispatch('primary-modal-cancellation-request');
    }}
    on:close={(e) => {
      dispatch('close-modal-cancellation-request');
    }}
    size="sm"
    preventCloseOnClickOutside={true}
  >
    <p>
      PNR {openModalCancellationRequest.Id} 예약을 취소합니다.
    </p>
    <p>페널티와 환불불가금액이 존재하는 경우 해당 금액을 차감하고 환불받습니다.</p>

    <div class="content-layout">
      <div class="total-amount">
        <div class="title-total">총 금액</div>
        <div class="amount">
          {openModalCancellationRequest.totalAmount}
        </div>
      </div>
      <div class="total-amount">
        <div class="title-total">페널티</div>
        <div class="amount">
          {openModalCancellationRequest.penalty}
        </div>
      </div>

      <div class="total-amount">
        <div class="title-total">환불불가금액</div>
        <div class="amount">
          {openModalCancellationRequest.nonRefundableAmount}
        </div>
      </div>
      <div class="total-amount bg-e0e0e0">
        <div class="title-total">총 환불 금액</div>
        <div class="amount">
          {openModalCancellationRequest?.refundAmount}
        </div>
      </div>
    </div>
  </Modal>
{/if}
