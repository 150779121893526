<script>
  import { AmountInformationTable } from 'src/components/common/table';
  import { getOffer } from 'src/service/reservationTicketing.js';
  import { has, isEmpty } from 'lodash';
  import { ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { InlineLoading } from 'carbon-components-svelte';
  import PassengerInformation from './passenger-information/index.svelte';
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app';
  import { getMainBookingReference } from 'src/utils/appHelper';
  import PaymentInformationTable from './PaymentInformationTable.svelte';
  import BaggageManageTable from './BaggageManageTable.svelte';
  import SeatManageTable from './SeatManageTable.svelte';
  import { bookingReference, screenIsNotEvent, message } from 'src/store';
  import Portal from 'src/components/common/Portal.svelte';
  import ItineraryInformationTable from './ItineraryInformationTable.svelte';
  import 'src/styles/modal/payment-information-modal.scss';

  export let open = false;
  export let DataLists = {};
  export let PricedOffer = {};

  let isLoadingOffer = false;

  let paramsOfferQuery = {};
  let refPassengerInformation;
  const handleActionPaymentOrIssuance = async () => {
    if (refPassengerInformation?.checkInValid()) return;

    try {
      isLoadingOffer = true;
      screenIsNotEvent.set(true);
      const response = await getOffer({ Query: paramsOfferQuery });
      isLoadingOffer = false;
      screenIsNotEvent.set(false);
      const { code, data } = response;
      if (code === 200 && data?.ResultMessage?.Code === CODE_SUCCESS) {
        message.set([
          {
            type: 'success',
            title: 'Success',
            subtitle: paramsOfferQuery.PaymentList.length === 0 ? '예약이 완료되었습니다' : '발권이 되었습니다',
          },
        ]);
        let findB = getMainBookingReference(data?.Order?.BookingReference);
        bookingReference.set({
          booking_reference: findB?.Id ?? EMPTY,
          order_id: data?.Order?.OrderID,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      open = false;
      return;
    }
  };

  const handleUpdateParamsOfferQuery = (event) => {
    paramsOfferQuery = { ...paramsOfferQuery, ...event.detail };
  };

  $: if (!isEmpty(PricedOffer)) {
    const { OfferID, Owner, ResponseID, OfferItem } = PricedOffer;
    const Order = [
      {
        OfferID,
        Owner,
        ResponseID,
        OfferItems: OfferItem.map((item) => ({
          OfferItemID: item.OfferItemID,
          Owner: item.Owner,
          PaxRefID: item.PaxRefID,
        })),
      },
    ];
    paramsOfferQuery = { ...paramsOfferQuery, Order };
  }
</script>

<Portal>
  <div id="PassengerInformationModal">
    <ComposedModal bind:open size="lg" preventCloseOnClickOutside={true}>
      <ModalHeader>
        <h4>결제 정보</h4>
      </ModalHeader>
      <ModalBody>
        <div class="wrapper-layout-content">
          <ItineraryInformationTable {DataLists} {PricedOffer} />
        </div>
        <div class="wrapper-layout-content">
          <PassengerInformation
            PaxList={DataLists.PaxList}
            on:change={handleUpdateParamsOfferQuery}
            bind:this={refPassengerInformation}
            arline={PricedOffer?.Owner}
          />
        </div>
        <!-- penning because be  -->
        <!-- {#if ['AF', 'KL'].includes(PricedOffer?.Owner)}
          <div class="wrapper-layout-content">
            <BaggageManageTable />
          </div>
          <div class="wrapper-layout-content">
            <SeatManageTable />
          </div>
        {/if} -->
        <div class="wrapper-layout-content">
          <AmountInformationTable {DataLists} {PricedOffer} />
        </div>
        <div class="wrapper-layout-content">
          <PaymentInformationTable pricedOffer={PricedOffer} on:change={handleUpdateParamsOfferQuery} />
        </div>
      </ModalBody>
      <ModalFooter>
        <footer>
          <div class="action-button">
            <PrimaryButton
              width="100%"
              disabled={!has(paramsOfferQuery, 'PaymentList')}
              on:click={handleActionPaymentOrIssuance}
              >결제&발행

              {#if isLoadingOffer}
                <div class="g-custom-loading-button">
                  <InlineLoading description="Loading ..." />
                </div>
              {/if}
            </PrimaryButton>
          </div>
          <div class="action-button">
            <PrimaryButton kind="secondary" on:click={() => (open = false)} width="100%">닫기</PrimaryButton>
          </div>
        </footer>
      </ModalFooter>
    </ComposedModal>
  </div>
</Portal>
