<script>
  import { createEventDispatcher, setContext, onMount } from 'svelte';
  import { isEmpty, omitBy, has } from 'lodash';
  import OcnTable from './ocn-table/index.svelte';
  import ReservationInfoTable from './reservation-information-table/index.svelte';
  import PassengerInformation from './passenger-information-table/index.svelte';
  import ItineraryInformation from './itinerary-information-table/index.svelte';
  import TicketInformation from './ticket-infomation-table/index.svelte';
  import Ssr from './ssr-table/index.svelte';
  import { getOrderRetrieve, getOrderCancel, postOrderReshop } from 'src/service/reservation.js';
  import { ocnService, bookingService } from 'src/service';
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app.js';
  import { formatPriceNumber, getMainBookingReference } from 'src/utils/appHelper.js';
  import { message } from 'src/store';
  import ErrorVoidRefund from 'src/components/modal/ErrorVoidRefund.svelte';
  import CancellationRequest from 'src/components/modal/CancellationRequest.svelte';
  import CancelRequestOrder from './CancelRequestOrder.svelte';
  import ErrorApi from 'src/components/modal/ErrorApi.svelte';
  import { CustomLoading } from 'src/components/common/loading';
  import { AmountInformationTable } from 'src/components/common/table';
  import appEvent from 'src/store/appEventStore';
  import { APP_EVENT_NAME } from 'src/constants/app';

  export let orderId;
  export let tab;
  setContext('tab-info', tab);
  const dispatch = createEventDispatcher();
  const listPoint = ['GMP', 'PUS', 'CJU', 'MWX', 'YNY', 'CJJ', 'TAE', 'WJU', 'KPO', 'USN', 'HIN', 'KUV', 'KWJ', 'RSU'];
  let loading = false;
  let isOpenErrorVoidRefund = EMPTY;
  let orderRetrieve;
  let managementOrderRes;
  let ocnData;
  let openModalErrorApi = false;
  let notifyError;
  let dataTicket = {
    ticketList: [],
    dataLists: EMPTY,
    order: EMPTY,
    managementOrder: EMPTY,
  };
  let openModalCancellationRequest = EMPTY;
  let saveResultReshop = {
    totalAmount: EMPTY,
    penalty: EMPTY,
    refundAmount: EMPTY,
  };

  let mainAirlineID = EMPTY;
  let isPayLater = EMPTY;

  export function reloadDataPnr(currenTab) {
    if (tab.orderStatus !== 'OK') return;
    const currentOrderId = currenTab.orderId;
    if (currentOrderId === orderId) {
      getData(currentOrderId);
    }
  }

  export function handleActionVoidRefund(currenTab) {
    const currentOrderId = currenTab.orderId;
    if (currentOrderId !== orderId) {
      return;
    }

    const dataLists = orderRetrieve?.data.DataLists || {};
    const { PaxSegmentList = [], PaxJourneyList = [], TicketDocList } = dataLists;
    const orderItems = orderRetrieve?.data.Order.OrderItem || [];
    const ticketingDate = managementOrderRes?.data?.Response?.MatchedOrder?.[0]?.TicketingDate || EMPTY;

    const isTicketAndStatusE = TicketDocList?.some((item) =>
      item.TicketDocument[0].CouponInfos?.some((coupon) => coupon.Status === 'E')
    );

    const isNotEmptyAirlineLJ = PaxSegmentList.some((paxSegment) => paxSegment?.MarketingCarrier?.AirlineID === 'LJ');

    const findOnAndOffPoint = PaxJourneyList?.filter(
      (paxJourney) => listPoint.includes(paxJourney.OnPoint) && listPoint.includes(paxJourney.OffPoint)
    );

    const isTicketNotInDay = checkDateNotInDayOrExceed1Day(ticketingDate, 'notInDay');
    const isExceed1DayTicket = checkDateNotInDayOrExceed1Day(ticketingDate, 'exceed1Day');

    if (
      isTicketAndStatusE ||
      !isNotEmptyAirlineLJ ||
      (findOnAndOffPoint.length && !isTicketNotInDay) ||
      isExceed1DayTicket
    ) {
      handleOrderReshop(orderId, orderItems, 'caculator');
    } else {
      const bookingReference = orderRetrieve?.data?.Order.BookingReference || [];
      isOpenErrorVoidRefund = bookingReference.find((booking) => !booking.OtherID && !booking.Type);
    }
  }
  function handleCancellationRequest(type) {
    const orderItems = orderRetrieve?.data.Order.OrderItem;
    if (type === 'cancelRequest') {
      handleOrderReshop(orderId, orderItems, 'reviewResult');
      return;
    }

    const dataLists = orderRetrieve?.data.DataLists || {};
    const { PaxSegmentList = [], PaxJourneyList = [], TicketDocList } = dataLists;

    const isNotEmptyAirlineLJ = PaxSegmentList.some((paxSegment) => paxSegment?.MarketingCarrier?.AirlineID === 'LJ');

    if (!isNotEmptyAirlineLJ) {
      handleOrderReshop(orderId, orderItems, 'reviewResult');
      return;
    }

    const findOnAndOffPoint = PaxJourneyList.filter(
      (paxJourney) => listPoint.includes(paxJourney.OnPoint) && listPoint.includes(paxJourney.OffPoint)
    );

    const ticketNotInDay = checkDateNotInDayOrExceed1Day(TicketDocList, 'notInDay');
    const exceed1DayTicket = checkDateNotInDayOrExceed1Day(TicketDocList, 'exceed1Day');

    const errorMessage = {
      type: 'error',
      title: 'Error',
      subtitle: '패널티 재계산이 필요한 예약입니다.\n취소를 다시 진행해주세요',
      caption: EMPTY,
    };

    if ((findOnAndOffPoint.length && ticketNotInDay.length) || exceed1DayTicket.length) {
      message.update(() => [errorMessage]);
    } else {
      handleOrderCancel(orderId);
    }
  }

  function checkDateNotInDayOrExceed1Day(ticketingDate, type) {
    const targetDate = new Date(ticketingDate);
    const today = new Date();
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;

    if (type === 'notInDay') {
      return targetDate.toDateString() !== today.toDateString();
    }

    if (type === 'exceed1Day') {
      return today - targetDate > twentyFourHoursInMillis;
    }

    return false;
  }

  function showNoti(type, title, subtitle) {
    message.update(() => [
      {
        type,
        title,
        subtitle,
        caption: EMPTY,
      },
    ]);
  }

  function handleReshopError(resOrderReshop) {
    const { Code, Message } = resOrderReshop?.data?.ResultMessage || {};
    const errorMessage = `API 호출에 실패했습니다.다시 시도해주세요 [${Code} ${Message}]`;
    showNoti('error', 'Error', errorMessage);
  }

  async function handleOrderReshop(orderId, orderItems, type) {
    loading = true;
    const resOrderReshop = await postOrderReshop({
      OrderID: orderId,
      UpdateOrder: {
        CancelOrderRefID: orderId,
      },
    });
    loading = false;

    if (resOrderReshop?.data?.ResultMessage?.Code !== CODE_SUCCESS) {
      handleReshopError(resOrderReshop);
      return;
    } else {
      const warns = resOrderReshop?.data.ResultMessage?.MesageDetail?.Warnnings || [];
      if (warns.length) {
        const msgWarns = warns.map((war) => `${war.Code} ${war.Message}\n`);
        showNoti('warning', 'Warn', msgWarns);
      } else {
        const bookingReference = orderRetrieve?.data?.Order.BookingReference;
        const findBookingReference = bookingReference.find((booking) => !booking.OtherID && !booking.Type);
        const reshopOffers = resOrderReshop?.data.ReshopOffers || [];
        const airlineIdsOne = ['AA', 'HA', 'HAA', 'LH', 'EK', 'OS', 'LX', 'LXA'];
        const airlineIdsTwo = ['LJ', 'QR', 'SQ', 'AF', 'KL', 'BA'];
        let totalAmount = 0;
        let penalty = 0;
        let nonRefundableAmount = 0;
        let refundAmount = 0;
        for (const reshopOffer of reshopOffers) {
          for (const item of reshopOffer?.DeleteOrderItem) {
            const OrderItemID = item.OrderItemID || EMPTY;
            const findOrderItem = orderItems.find((elm) => elm.OrderItemID === OrderItemID);
            const multiplicer = findOrderItem?.PaxRefID?.length || 1;

            if (airlineIdsOne.includes(findBookingReference?.AirlineID)) {
              totalAmount += (item.OriginalOrderItem?.Total?.Amount || 0) * multiplicer;
              penalty += (item?.PenaltyAmount?.Total?.Amount || 0) * multiplicer;
              refundAmount += (item?.ReshopDue?.Total?.Amount || 0) * multiplicer;
            } else if (airlineIdsTwo.includes(findBookingReference?.AirlineID)) {
              totalAmount += item.OriginalOrderItem?.Total?.Amount || 0;
              penalty += item.PenaltyAmount?.Total?.Amount || 0;
              refundAmount += item.ReshopDue?.Total?.Amount || 0;
            }
          }
        }
        if (penalty === 0) {
          refundAmount = totalAmount;
        }
        nonRefundableAmount = (totalAmount ?? 0) - (refundAmount ?? 0) - (penalty ?? 0);

        totalAmount = `${formatPriceNumber(totalAmount) || 0} ${
          reshopOffers[0]?.DeleteOrderItem[0]?.OriginalOrderItem?.Total?.CurCode || 'KRW'
        }`;

        penalty = `${formatPriceNumber(penalty) || 0} ${
          reshopOffers[0]?.DeleteOrderItem[0]?.PenaltyAmount?.Total?.CurCode || 'KRW'
        }`;

        refundAmount = `${formatPriceNumber(Math.abs(refundAmount)) || 0} ${
          reshopOffers[0]?.DeleteOrderItem[0]?.ReshopDue?.Total?.CurCode || 'KRW'
        }`;

        nonRefundableAmount = `${formatPriceNumber(nonRefundableAmount) || 0} ${
          reshopOffers[0]?.DeleteOrderItem[0]?.OriginalOrderItem?.Total?.CurCode || 'KRW'
        }`;

        if (type === 'reviewResult') {
          if (penalty === saveResultReshop.penalty) {
            handleOrderCancel(orderId);
          } else {
            message.update(() => [
              {
                type: 'error',
                title: 'Error',
                subtitle: '패널티 재계산이 필요한 예약입니다.\n취소를 다시 진행해주세요',
                caption: EMPTY,
              },
            ]);
          }
        }
        if (type === 'caculator') {
          saveResultReshop = {
            totalAmount,
            penalty,
            refundAmount,
          };

          openModalCancellationRequest = {
            Id: findBookingReference.Id,
            nonRefundableAmount,
            ...saveResultReshop,
          };
        }
      }
    }
  }

  export async function handleOrderCancel(orderId, type) {
    loading = true;
    const resOrderCancel = await getOrderCancel({ orderId });
    loading = false;
    if (resOrderCancel?.data?.ResultMessage?.Code !== CODE_SUCCESS) {
      const code = resOrderCancel?.data?.ResultMessage?.Code;
      const msg = resOrderCancel?.data?.ResultMessage?.Message;
      message.update(() => [
        {
          type: 'error',
          title: 'Error',
          subtitle: `취소에 실패했습니다.\n${code} ${msg}`,
          caption: EMPTY,
        },
      ]);
    }
    if (resOrderCancel?.data?.ResultMessage?.Code == CODE_SUCCESS) {
      const warns = resOrderCancel?.data.ResultMessage?.MesageDetail?.Warnnings || [];
      if (warns.length) {
        const msgWarns = warns.map((war) => `${war.Code} ${war.Message}\n`);
        message.update(() => [
          {
            type: 'warning',
            title: 'Warn',
            subtitle: msgWarns,
            caption: EMPTY,
          },
        ]);
      } else {
        message.update(() => [
          {
            type: 'success',
            title: 'Success',
            subtitle: type === 'cancel' ? 'PNR CANCEL이 완료되었습니다' : 'VOID/REFUND가완료되었습니다.',
          },
        ]);
        updateStatusOrderTab(orderId);
      }
    }
  }

  async function updateStatusOrderTab(orderId) {
    const resManagementOrder = await bookingService.getManagementOrder({
      order_id: orderId,
    });
    const MatchedOrder = resManagementOrder?.data?.Response?.MatchedOrder;
    const orderStatus = MatchedOrder[0]?.OrderStatus || EMPTY;
    const cancelDate = MatchedOrder[0]?.CancelDate || EMPTY;
    const bookingReference = MatchedOrder[0]?.BookingReference || [];
    tab = { ...tab, orderStatus, cancelDate, bookingReference };
    dispatch('primary-modal-cancellation-success', tab);
  }

  function getTicketInformation(resOrderRetrieve, managementOrder) {
    const { DataLists, Order } = resOrderRetrieve;
    return { MatchedOrder: managementOrder.data.Response?.MatchedOrder[0], Order, DataLists };
  }

  // reload-current-pnr PassengerInformation
  async function reloadCurrentPnr(event) {
    const { resOrderChange, endMessage = EMPTY } = event.detail;
    const orderIdOrderChange = resOrderChange?.Order?.OrderID || EMPTY;
    await getData(orderId, 'toast');
    const code = orderRetrieve?.data?.ResultMessage?.Code || EMPTY;
    if (code !== CODE_SUCCESS && orderIdOrderChange.length) {
      dispatch('add-tab', {
        closeCurrentTab: true,
        orderId: orderIdOrderChange,
        tab,
      });
    }
    if (code === CODE_SUCCESS) {
      const warns = orderRetrieve?.data.ResultMessage?.MesageDetail?.Warnnings || [];
      if (warns.length) {
        const msgWarns = warns.map((war) => `${war.Code} ${war.Message}\n`);
        message.update(() => [
          {
            type: 'warning',
            title: 'Warn',
            subtitle: msgWarns,
            caption: EMPTY,
          },
        ]);
      }
      dispatch('add-tab', {
        closeCurrentTab: false,
        orderId: orderIdOrderChange,
      });
    }
    if (endMessage !== EMPTY) {
      message.update(() => [
        {
          type: 'success',
          title: 'Success',
          subtitle: endMessage,
        },
      ]);
    }
  }

  function processMatchedOrders(matchedOrders) {
    const processedOrders = [];
    for (const item of matchedOrders) {
      const orderId = item.OrderID;
      const tabName = getMainBookingReference(item?.BookingReference)?.Id || EMPTY;
      processedOrders.push({ tabName, orderId });
    }
    return processedOrders;
  }

  async function getInfoPnrParentOrChildOrderId(managementOrderRes) {
    const infoOrderId = managementOrderRes?.data?.Response?.MatchedOrder[0];
    let params = {
      order_id: EMPTY,
      parent_order_id: EMPTY,
    };
    if (infoOrderId?.ParentOrderID) {
      params.order_id = infoOrderId.ParentOrderID;
    }
    if (infoOrderId?.OrderID) {
      params.parent_order_id = infoOrderId.OrderID;
    }
    params = omitBy(params, isEmpty);
    let resParent;
    let resChild;
    if (params?.order_id) {
      resParent = await bookingService.getManagementOrder({
        order_id: params.order_id,
      });
    }

    if (params?.parent_order_id) {
      resChild = await bookingService.getManagementOrder({
        parent_order_id: params.parent_order_id,
      });
    }
    let resultParent;
    let resultChild;
    if (resParent && resParent?.data?.Response?.MatchedOrderCount) {
      resultParent = processMatchedOrders(resParent?.data?.Response?.MatchedOrder);
    }

    if (resChild?.data?.Response?.MatchedOrderCount) {
      resultChild = processMatchedOrders(resChild?.data?.Response?.MatchedOrder);
    }
    const notDividing = !(resultParent?.length || resultChild?.length);
    const orderId = infoOrderId?.OrderID;
    const pnrMain = getMainBookingReference(infoOrderId?.BookingReference)?.Id || EMPTY;
    return {
      parent: resultParent,
      child: resultChild,
      notDividing,
      orderId,
      pnrMain,
    };
  }

  const getData = async (orderId, typeErr = 'popup') => {
    try {
      loading = true;
      [orderRetrieve, managementOrderRes, ocnData] = await Promise.all([
        getOrderRetrieve({
          order_id: orderId,
        }),
        bookingService.getManagementOrder({
          order_id: orderId,
        }),
        ocnService.getOcnData({
          order_id: orderId,
        }),
      ]);
      const result = await getInfoPnrParentOrChildOrderId(managementOrderRes);
      isPayLater = getIsPayLater(managementOrderRes);
      loading = false;
      dispatch('update-info-tab', { ...result, isPayLater });
      mainAirlineID =
        getMainBookingReference(managementOrderRes?.data?.Response?.MatchedOrder[0]?.BookingReference)?.AirlineID ??
        EMPTY;
      if (orderRetrieve && managementOrderRes && ocnData) {
        const Code = orderRetrieve?.data?.ResultMessage?.Code || EMPTY;
        const MessageError = orderRetrieve?.data?.ResultMessage?.Message || EMPTY;
        if (Code !== CODE_SUCCESS) {
          if (typeErr === 'popup') {
            openModalErrorApi = true;
            notifyError = { Code, MessageError, ArlineID: mainAirlineID };
            return;
          }
          if (typeErr === 'toast') {
            message.update(() => [
              {
                type: 'error',
                title: 'Error',
                subtitle: `${mainAirlineID} ${Code} ${MessageError}`,
                caption: EMPTY,
              },
            ]);
            return;
          }
        }
        dataTicket = getTicketInformation(orderRetrieve.data, managementOrderRes);
      }
    } catch (e) {
      loading = false;
      console.error('Error fetching data Order Retrieve' + orderId, e);
    }
  };
  const getIsPayLater = (refManagementOrderRes) => {
    if (refManagementOrderRes) {
      return !has(refManagementOrderRes?.data?.Response?.MatchedOrder[0], 'TicketingDate');
    }
    return false;
  };
  onMount(async () => {
    if (tab.orderStatus === 'OK') {
      getData(orderId, 'popup');
    } else {
      managementOrderRes = await bookingService.getManagementOrder({
        order_id: orderId,
      });
      const result = await getInfoPnrParentOrChildOrderId(managementOrderRes);
      dispatch('update-info-tab', result);
    }
    appEvent.listen(`${APP_EVENT_NAME.CANCEL_PNR}${orderId}`, (payload) => {
      handleOrderCancel(payload.orderId, payload.typeCancel);
    });

    appEvent.listen(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_CHANGEPAYMENTPNRMODAL}${orderId}`, (event) => {
      reloadCurrentPnr(event);
    });
    appEvent.listen(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_SSRTABLE}${orderId}`, (event) => {
      reloadCurrentPnr(event);
    });
    return () => {
      appEvent.remove(`${APP_EVENT_NAME.CANCEL_PNR}${orderId}`);
      appEvent.remove(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_CHANGEPAYMENTPNRMODAL}${orderId}`);
      appEvent.remove(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_SSRTABLE}${orderId}`);
    };
  });
</script>

{#if tab.orderStatus === 'OK'}
  {#if loading}
    <div class="loading g-screen-transparent-isnot-event">
      <CustomLoading title="Loading PNR" />
    </div>
  {:else}
    <OcnTable {ocnData} airlineID={mainAirlineID} pnr={tab.tabName} {orderId} />
    <ReservationInfoTable {orderRetrieve} {managementOrderRes} {isPayLater} />
    <PassengerInformation
      {orderRetrieve}
      {orderId}
      on:reload-current-pnr={reloadCurrentPnr}
      airlineID={mainAirlineID}
    />
    <ItineraryInformation
      {isPayLater}
      {orderRetrieve}
      on:reload-current-tab={() => getData(orderId, 'popup')}
      airlineID={mainAirlineID}
      {orderId}
    />

    {#if isPayLater}
      <AmountInformationTable
        {orderRetrieve}
        {orderId}
        Order={orderRetrieve?.data?.Order}
        DataLists={orderRetrieve?.data?.DataLists}
        {isPayLater}
        airlineID={mainAirlineID}
        pnr={tab.tabName}
      />
    {:else}
      <TicketInformation {dataTicket} />
    {/if}

    <Ssr resOrderRetrieve={orderRetrieve} airlineID={mainAirlineID} {isPayLater} />
  {/if}
{:else}
  <CancelRequestOrder {tab} />
{/if}

<ErrorVoidRefund
  {isOpenErrorVoidRefund}
  on:primary-modal-error-void-refund={() => {
    handleCancellationRequest('error');
    isOpenErrorVoidRefund = EMPTY;
  }}
  on:close-modal-error-void-refund={() => (isOpenErrorVoidRefund = EMPTY)}
/>

<CancellationRequest
  {openModalCancellationRequest}
  on:primary-modal-cancellation-request={() => {
    handleCancellationRequest('cancelRequest');
    openModalCancellationRequest = EMPTY;
  }}
  on:close-modal-cancellation-request={() => (openModalCancellationRequest = EMPTY)}
/>

{#if notifyError}
  <ErrorApi
    openErrorApi={openModalErrorApi}
    size="sm"
    on:close-modal-api-error={() => {
      openModalErrorApi = false;
      notifyError = null;
    }}
  >
    <div>
      [{notifyError?.ArlineID}
      {notifyError?.Code}
      {notifyError?.MessageError}]
    </div>
  </ErrorApi>
{/if}

<style>
  .loading {
    top: 0;
    background-color: transparent;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
