<script>
  import {
    DataTable,
    ComposedModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TextInput,
    ComboBox,
    InlineLoading,
  } from 'carbon-components-svelte';
  import { createEventDispatcher } from 'svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app';
  import { isEmpty, uniq, find, compact } from 'lodash';
  import { isValidEmail, sleep } from 'src/utils/appHelper';
  import { screenIsNotEvent } from 'src/store';
  import { postOrderChange } from 'src/service/reservation';
  import 'src/styles/modal/change-passenger-information.scss';

  export let open = false;
  export let orderId = EMPTY;
  export let airlineID = E;
  export let passengerInfo = {};

  const dispatch = createEventDispatcher();

  let isLoading = false;
  let hasEdit = false;
  let passengerInformations = [];
  let selectedDropdown = undefined;
  const headers = [
    { key: 'id', value: '순번', width: '5%' },
    { key: 'ptc', value: 'PTC', width: '5%' },
    { key: 'nameTitle', value: 'Title', width: '8%' },
    { key: 'surName', value: 'SurName', width: '12%' },
    { key: 'givenName', value: 'GivenName', width: '12%' },
    { key: 'rawPhoneNumber', value: 'Mobile', width: '15%' },
    { key: 'emailAddress', value: 'Email', width: '20%' },
  ];

  const dropdownChangeList = [
    { id: 'Contact Info', value: 'Contact Info' },
    { id: 'Pax Info', value: 'Pax Info' },
  ];

  function onChangePassengerInformation(e, row, key) {
    let value = e?.detail || EMPTY;
    switch (key) {
      case 'surName':
      case 'givenName':
      case 'middleName':
        value = value?.replace(/[^a-zA-Z\s]/g, EMPTY);
        break;
      case 'countryDialCode':
        value = value?.replace(/\D/g, EMPTY).slice(0, 4);
        break;
      case 'rawPhoneNumber':
        value = value?.replace(/\D/g, EMPTY);
      case 'emailAddress':
        value = value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣\s]/g, EMPTY);
        if (isValidEmail(value)) {
        } else {
        }
    }
    passengerInformations = passengerInformations.map((el) => {
      if (el.id === row.id) {
        el[key] = value;
        const initialValue = passengerInfo?.dataTable.find((el) => el.id === row.id)?.[key] ?? '';
        if (value == initialValue) {
          el.editList = el.editList.filter((item) => item !== key);
        } else {
          el.editList = uniq([...el.editList, key]);
        }
      }
      return el;
    });
  }

  function handleCloseModal() {
    open = false;
  }

  async function updatePassengerInformation() {
    const { contactInfoList, paxList } = passengerInfo.dataTable[0];
    const selectedPaxInfo = selectedDropdown === 'Pax Info';
    const updatedPaxRefId = [];
    const updatedPaxList = paxList.map((paxItem) => {
      const foundPassengerItem = passengerInformations.find((passengerItem) => passengerItem.paxID === paxItem.PaxID);
      const { nameTitle = EMPTY, surName = EMPTY, givenName = EMPTY } = foundPassengerItem;
      const { NameTitle = EMPTY, Surname = EMPTY, GivenName = EMPTY } = paxItem.Individual;
      if (nameTitle !== NameTitle || surName !== Surname || givenName !== GivenName[0]) {
        updatedPaxRefId.push(paxItem.PaxID);
        return {
          ...paxItem,
          Individual: {
            ...paxItem.Individual,
            NameTitle: nameTitle,
            Surname: surName,
            GivenName: [givenName],
          },
        };
      }
      return undefined;
    });

    const updatedContactRefId = [];
    const updatedContactInfoList = paxList.map((paxItem) => {
      const isCheckGetContactInfo =
        airlineID === 'LJ' && ['ADT', 'CHD'].includes(Ptc) && isEmpty(paxItem?.ContactInfoRefID);
      const foundContactItem = isCheckGetContactInfo
        ? contactInfoList[0]
        : find(contactInfoList, (elm) => paxItem?.ContactInfoRefID.includes(elm?.ContactInfoID));

      const foundPassengerItem = passengerInformations.find((passengerItem) => passengerItem.paxID === paxItem.PaxID);
      const { rawPhoneNumber = EMPTY, emailAddress = EMPTY } = foundPassengerItem;
      if (!foundContactItem) {
        if (paxItem.ContactInfoRefID.length > 0) {
          updatedContactRefId.push(paxItem.PaxID);
          return {
            ContactInfoID: paxItem.ContactInfoRefID[0],
            EmailAddress: [emailAddress],
            Phone: [{ PhoneNumber: rawPhoneNumber }],
            PostalAddress: [],
          };
        }
        return undefined;
      }
      const { Phone, EmailAddress } = foundContactItem;
      if (rawPhoneNumber !== (Phone?.[0]?.PhoneNumber || EMPTY) || emailAddress !== (EmailAddress?.[0] || EMPTY)) {
        updatedContactRefId.push(paxItem.PaxID);
        return {
          ...foundContactItem,
          Phone: [{ PhoneNumber: rawPhoneNumber }],
          EmailAddress: [emailAddress],
        };
      }
      return undefined;
    });

    const queryBase = {
      OrderID: orderId,
      PaymentList: [],
      ChangeOrderChoice: {},
      ContactInfoList: [],
      PaxList: [],
    };

    if (selectedPaxInfo) {
      queryBase.PaxList = compact(updatedPaxList);
      queryBase.ChangeOrderChoice.UpdatePax = updatedPaxRefId.map((el) => ({ CurrentPaxRefID: el }));
    } else {
      queryBase.ContactInfoList = compact(updatedContactInfoList);
      queryBase.ChangeOrderChoice.UpdatePax = updatedContactRefId.map((el) => ({ CurrentPaxRefID: el }));
    }

    try {
      isLoading = true;
      screenIsNotEvent.set(true);
      const { code, data } = await postOrderChange({
        query: queryBase,
      });
      if (code === 200 && data.ResultMessage?.Code === CODE_SUCCESS) {
        await sleep(2000);
        dispatch('reload-current-pnr', { resOrderChange: data, endMessage: '' });
        isLoading = false;
        screenIsNotEvent.set(false);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      isLoading = false;
      screenIsNotEvent.set(false);
    }
  }

  function selectChangedItem(e) {
    const { selectedId = '' } = e?.detail;
    const disabledList =
      selectedId === 'Contact Info' ? ['nameTitle', 'surName', 'givenName'] : ['rawPhoneNumber', 'emailAddress'];
    setInitialPassengerInformations(disabledList);
  }

  function setInitialPassengerInformations(disabledList = []) {
    passengerInformations = passengerInfo?.dataTable.map((el) => ({
      ...el,
      disabledList,
      hasEdit: false,
      editList: [],
    }));
  }

  $: {
    if (!isEmpty(passengerInfo?.dataTable)) {
      setInitialPassengerInformations();
    }
  }

  $: hasEdit =
    !!passengerInformations.find((passenger) => !isEmpty(passenger?.editList)) &&
    !(selectedDropdown === EMPTY || selectedDropdown === undefined);
</script>

<div id="changePassengerInformation">
  <ComposedModal {open} size="lg" preventCloseOnClickOutside={true} on:close={handleCloseModal}>
    <ModalHeader>
      <h4>탑승객정보변경</h4>
    </ModalHeader>
    <ModalBody>
      <div class="description">
        <div>탑승객의 정보를 변경합니다.</div>
        <div>유아탑승객의 모바일/이메일은 지정된 보호자의 정보를 노출하며 별도 변경이 불가합니다.</div>
        <div class="passenger-information-dropdown">
          <p>변경항목 선택</p>
          <ComboBox
            placeholder="Select"
            bind:selectedId={selectedDropdown}
            items={dropdownChangeList}
            on:select={selectChangedItem}
            on:clear={() => {
              setInitialPassengerInformations();
            }}
          />
        </div>
      </div>
      <div>
        <DataTable size="short" {headers} rows={passengerInformations}>
          <svelte:fragment slot="cell" let:cell let:row>
            <div class:edited={!!row.editList.find((el) => el === cell.key)}>
              {#if ['countryDialCode', 'rawPhoneNumber', 'emailAddress'].includes(cell.key) && ['INF'].includes(row.ptc)}
                {cell.value}
              {:else if !['id', 'ptc', 'birthDate'].includes(cell.key)}
                {@const disabledRow = row.disabledList.length === 0 || row.contactInfoRefID?.length === 0}
                <TextInput
                  disabled={disabledRow ? true : row.disabledList.includes(cell.key)}
                  value={cell.value}
                  on:input={(e) => {
                    onChangePassengerInformation(e, row, cell.key);
                  }}
                />
              {:else}
                {cell.value}
              {/if}
            </div>
          </svelte:fragment>
        </DataTable>
      </div>
    </ModalBody>
    <ModalFooter>
      <footer>
        <PrimaryButton kind="secondary" width="100%" on:click={handleCloseModal}>취소</PrimaryButton>
        <PrimaryButton width="100%" disabled={!hasEdit} on:click={updatePassengerInformation}
          >탑승객정보변경
          {#if isLoading}
            <div class="g-custom-loading-button">
              <InlineLoading description="Loading ..." />
            </div>
          {/if}
        </PrimaryButton>
      </footer>
    </ModalFooter>
  </ComposedModal>
</div>
