import {
  getMaxWeightAllowance,
  getUrlLogoAirline,
  convertFlightTime,
  formatCabinCode,
  getCabinName,
} from 'src/utils/appHelper.js';
import { cloneDeep, isEqual, sortBy, remove, uniq } from 'lodash';
import { EMPTY, SPECIAL_AIRLINE, AIRLINES_MARKETING_CARRIER } from 'src/constants/app';

const getFlightTime = (PaxJourneyRefIDItem, DataLists) => {
  const { PaxJourneyList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  const strFlightTime = paxJourneyItem.FlightTime;
  return convertFlightTime(strFlightTime);
};

const getSeatClass = (PaxJourneyRefIDItem, offer, DataLists) => {
  const OfferItem = getMainOfferItem(offer, DataLists);
  const PaxJourneyList = DataLists?.PaxJourneyList ?? [];
  const { FareDetail = [] } = OfferItem;
  const firstFareDetail = FareDetail.length > 0 ? FareDetail[0] : [];
  const FareComponent = firstFareDetail?.FareComponent;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  if (SPECIAL_AIRLINE.includes(OfferItem.Owner)) {
    const Fare = FareComponent.find((item) => {
      let check = true;
      for (const elm of paxJourneyItem.PaxSegmentRefID) {
        if (!item.PaxSegmentRefID.includes(elm)) {
          check = false;
          break;
        }
      }
      return check;
    });
    const result =
      Fare?.FareBasis?.CabinType?.filter((elm) => {
        return paxJourneyItem.PaxSegmentRefID.includes(elm.PaxSegmentRefID);
      }).map((item) => getCabinName(OfferItem.Owner, item.CabinCode)) ?? [];
    return [...new Set(result)];
  } else {
    const fareComponentWithPaxSeg = FareComponent?.filter((item) => {
      return paxJourneyItem.PaxSegmentRefID.includes(item.PaxSegmentRefID[0]);
    });

    if (!fareComponentWithPaxSeg) return [];
    const result = fareComponentWithPaxSeg.reduce((result, item) => {
      const CabinType = item?.FareBasis?.CabinType || [];
      return [...result, ...CabinType.map((item) => getCabinName(OfferItem.Owner, item.CabinCode))];
    }, []);
    return [...new Set(result)];
  }
};

const getAirline = (PaxJourneyRefIDItem, DataLists) => {
  const { PaxJourneyList = [], PaxSegmentList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  const paxSegments = paxJourneyItem.PaxSegmentRefID.map((paxSegmentId) => {
    const itemPaxSegment = PaxSegmentList.find((item) => item.PaxSegmentID === paxSegmentId);
    return itemPaxSegment;
  });
  return paxSegments.map((elm) => {
    return (
      (elm?.OperatingCarrier?.AirlineID ? elm?.OperatingCarrier?.AirlineID : elm?.MarketingCarrier?.AirlineID) +
      elm.MarketingCarrier.FlightNumber.padStart(4, '0')
    );
  });
};

const getDepartureTime = (PaxJourneyRefIDItem, DataLists) => {
  const { PaxJourneyList = [], PaxSegmentList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  const firstPaxJourneyRefID = PaxJourneyRefIDItem;
  const firstPaxJourney = PaxJourneyList.find((item) => item.PaxJourneyID === firstPaxJourneyRefID);
  const firstPaxSegment = firstPaxJourney.PaxSegmentRefID[0];
  const firstPaxSeg = PaxSegmentList.find((elm) => elm.PaxSegmentID === firstPaxSegment);
  let terminalName = firstPaxSeg?.Departure?.Terminal?.Name || EMPTY;
  return {
    onPoint: `${paxJourneyItem.OnPoint || EMPTY} ${
      terminalName && terminalName !== '0' ? `(T${terminalName})` : EMPTY
    }`,
    date: firstPaxSeg?.Departure?.Date || EMPTY,
    time: firstPaxSeg?.Departure?.Time || EMPTY,
  };
};

const getArrivalTime = (PaxJourneyRefIDItem, DataLists) => {
  const { PaxJourneyList = [], PaxSegmentList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  const firstPaxJourneyRefID = PaxJourneyRefIDItem;
  const firstPaxJourney = PaxJourneyList.find((item) => item.PaxJourneyID === firstPaxJourneyRefID);
  const lastPaxSegment = firstPaxJourney.PaxSegmentRefID[firstPaxJourney.PaxSegmentRefID.length - 1];
  const lastPaxSeg = PaxSegmentList.find((elm) => elm.PaxSegmentID === lastPaxSegment);
  const terminalName = lastPaxSeg?.Arrival?.Terminal?.Name || EMPTY;
  return {
    offPoint: `${paxJourneyItem.OffPoint || EMPTY}${
      terminalName && terminalName !== '0' ? `(T${terminalName})` : EMPTY
    }`,
    date: lastPaxSeg?.Arrival?.Date || EMPTY,
    time: lastPaxSeg?.Arrival?.Time || EMPTY,
  };
};

const getVia = (PaxJourneyRefIDItem, DataLists) => {
  const { PaxJourneyList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  return paxJourneyItem.PaxSegmentRefID.length === 1
    ? '직항'
    : paxJourneyItem.PaxSegmentRefID.length > 1
    ? `경유${paxJourneyItem.PaxSegmentRefID.length - 1}`
    : EMPTY;
};

const getRbdFareBasis = (PaxJourneyRefIDItem, Offer, DataLists) => {
  const { FareDetail = [], Owner } = getMainOfferItem(Offer, DataLists);
  const { PaxJourneyList = [] } = DataLists;
  const firstFareDetail = FareDetail.length > 0 ? FareDetail[0] : [];
  const FareComponent = firstFareDetail?.FareComponent;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  if (SPECIAL_AIRLINE.includes(Owner)) {
    const Fare = FareComponent.find((item) => {
      let check = true;
      for (const elm of paxJourneyItem.PaxSegmentRefID) {
        if (!item.PaxSegmentRefID.includes(elm)) {
          check = false;
          break;
        }
      }
      return check;
    });
    return (
      Fare?.FareBasis?.CabinType?.filter((elm) => {
        return paxJourneyItem.PaxSegmentRefID.includes(elm.PaxSegmentRefID);
      })
        .map((item) => item.Rbd)
        .join(',') ?? []
    );
  } else {
    const fareComponentWithPaxSeg = FareComponent?.filter((item) => {
      return paxJourneyItem.PaxSegmentRefID.includes(item.PaxSegmentRefID[0]);
    });
    if (!fareComponentWithPaxSeg) return [];
    return fareComponentWithPaxSeg
      .reduce((result, item) => {
        const CabinType = item?.FareBasis?.CabinType || [];
        return [...result, ...CabinType.map((item) => item.Rbd)];
      }, [])
      .join(',');
  }
};

const getPriceClass = (PaxJourneyRefIDItem, Offer) => {
  const { JourneyOverview } = Offer;
  const findJourneyOverviewItem = JourneyOverview.find((elm) => elm.PaxJourneyRefID === PaxJourneyRefIDItem);
  return findJourneyOverviewItem?.PriceClassInfo?.Name ?? EMPTY;
};

const getBaggage = (PaxJourneyRefIDItem, offer) => {
  return getMaxWeightAllowance(offer.BaggageAllowance, PaxJourneyRefIDItem);
};

const getEmphysema = (PaxJourneyRefIDItem, DataLists) => {
  const PaxSegmentRefID = DataLists.PaxJourneyList.find(
    (PaxJourneyListItem) => PaxJourneyRefIDItem === PaxJourneyListItem.PaxJourneyID
  )?.PaxSegmentRefID;

  const PaxSegmentList = PaxSegmentRefID.map((elm) =>
    DataLists.PaxSegmentList.find((PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === elm)
  );
  let results = [];
  for (const PaxSegment of PaxSegmentList) {
    let code = PaxSegment?.Equipment?.AircraftCode ?? EMPTY;
    if (code !== EMPTY) results.push(code);
    else {
      PaxSegment.Legs.forEach((leg) => {
        if (leg?.Equipment?.AircraftCode) results.push(leg.Equipment.AircraftCode);
      });
    }
  }

  return results.join(',');
};

const getCodeShare = (PaxJourneyRefIDItem, DataLists) => {
  let codeShareArr = [];
  const PaxSegmentRefID = DataLists.PaxJourneyList.find(
    (PaxJourneyListItem) => PaxJourneyRefIDItem === PaxJourneyListItem.PaxJourneyID
  )?.PaxSegmentRefID;
  const PaxSegment = PaxSegmentRefID.map((elm) =>
    DataLists.PaxSegmentList.find((PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === elm)
  );
  PaxSegment.forEach(({ OperatingCarrier, MarketingCarrier }) => {
    if (OperatingCarrier?.AirlineID && OperatingCarrier?.AirlineID !== MarketingCarrier?.AirlineID)
      codeShareArr.push(`공동운항 ${OperatingCarrier.AirlineID}`);
  });
  return codeShareArr;
};

const getHiddenStopover = (PaxJourneyRefIDItem, DataLists) => {
  const PaxSegmentRefID = DataLists.PaxJourneyList.find(
    (PaxJourneyListItem) => PaxJourneyRefIDItem === PaxJourneyListItem.PaxJourneyID
  )?.PaxSegmentRefID;

  const PaxSegment = DataLists.PaxSegmentList.find((PaxSegmentListItem) =>
    PaxSegmentRefID.includes(PaxSegmentListItem.PaxSegmentID)
  );
  let code = EMPTY;

  if (PaxSegment?.Legs.length > 0) code = PaxSegment?.Legs[0]?.Arrival?.AirportCode ?? EMPTY;
  return code;
};

const getInfoPayment = (offer, DataLists) => {
  let infoPayment = {
    ADT: EMPTY,
    CHD: EMPTY,
    INF: EMPTY,
  };
  for (const FareDetailItem of getFareDetailList(offer)) {
    let checkType = EMPTY;
    const arrPaxRefID = formatPaxRefID(FareDetailItem.PaxRefID);
    for (const PaxListItem of DataLists.PaxList) {
      if (checkType !== EMPTY) break;
      for (const itemPaxRefID of arrPaxRefID) {
        if (PaxListItem?.PaxID === itemPaxRefID) {
          checkType = PaxListItem?.Ptc ?? EMPTY;
          break;
        }
      }
    }

    const data = {
      baseAmount: FareDetailItem.BaseAmount?.Amount ?? 0,
      totalTax: FareDetailItem.TaxTotal?.Amount ?? 0,
      curCode: FareDetailItem.BaseAmount?.CurCode ?? offer?.TotalPrice?.TotalAmount?.CurCode ?? EMPTY,
      ptcCount: arrPaxRefID.length,
    };

    if (infoPayment.ADT === EMPTY && checkType === 'ADT') {
      infoPayment.ADT = data;
    }
    if (infoPayment.CHD === EMPTY && checkType === 'CHD') {
      infoPayment.CHD = data;
    }
    if (infoPayment.INF === EMPTY && checkType === 'INF') {
      infoPayment.INF = data;
    }
  }
  return infoPayment;
};

const getTotalFee = (offer) => {
  let totalFee = 0;
  for (const { BaseAmount, TaxTotal, PaxRefID } of getFareDetailList(offer)) {
    const countPaxRef = formatPaxRefID(PaxRefID).length;
    totalFee = totalFee + (BaseAmount?.Amount ?? 0) * countPaxRef + (TaxTotal?.Amount ?? 0) * countPaxRef;
  }
  return totalFee;
};

const getDetailPrice = (offer) => {
  let totalTax = 0;
  let baseAmount = 0;
  let curCode = EMPTY;
  for (const { BaseAmount, TaxTotal, PaxRefID } of getFareDetailList(offer)) {
    const countPaxRef = formatPaxRefID(PaxRefID).length;
    totalTax += (TaxTotal?.Amount ?? 0) * countPaxRef;
    baseAmount += (BaseAmount?.Amount ?? 0) * countPaxRef;
    curCode = BaseAmount?.CurCode ?? TaxTotal?.CurCode ?? offer?.TotalPrice?.TotalAmount?.CurCode ?? EMPTY;
  }
  return {
    totalTax: parseFloat(totalTax.toFixed(2)),
    baseAmount: parseFloat(baseAmount.toFixed(2)),
    curCode,
  };
};

const getAllCouponByPaxJourneyId = (PaxJourneyRefID, offerList, DataLists, mainOfferID) => {
  let offerItemsMapping = [];
  for (let offer of offerList) {
    if (isEqual(PaxJourneyRefID, getMainOfferItem(offer, DataLists)?.PaxJourneyRefID)) {
      let PaxJourneyDetail = [];
      for (let { PaxJourneyID, OnPoint, OffPoint } of DataLists.PaxJourneyList) {
        if (PaxJourneyRefID.includes(PaxJourneyID)) {
          PaxJourneyDetail.push({
            onPoint: OnPoint,
            offPoint: OffPoint,
            kg: getBaggage(PaxJourneyID, offer, DataLists),
            priceClass: getPriceClass(PaxJourneyID, offer),
          });
        }
      }

      if (offer.OfferID !== mainOfferID) {
        offerItemsMapping.push({
          offerID: offer.OfferID,
          responseID: offer.ResponseID,
          totalFee: getTotalFee(offer),
          isMain: offer.OfferID === mainOfferID,
          infoPayment: getInfoPayment(offer, DataLists),
          detailPrice: getDetailPrice(offer),
          PaxJourneyDetail,
          dataListDetail: {
            paxList: DataLists.PaxList,
          },
          owner: getAirlineOffer(offer, DataLists)?.AirlineID || EMPTY,
          queryOfferPrice: getQueryOfferPrice(offer, DataLists),
        });
      }
    }
  }
  return sortBy(offerItemsMapping, ({ totalFee }) => totalFee);
};

export const getAirlineOffer = (offer, DataLists) => {
  const OfferItem = getMainOfferItem(offer, DataLists);
  const { PaxJourneyRefID } = OfferItem;
  const PaxSegmentRefID = DataLists.PaxJourneyList.find(
    (PaxJourneyListItem) => PaxJourneyRefID[0] === PaxJourneyListItem.PaxJourneyID
  )?.PaxSegmentRefID;
  const PaxSegmentItem = DataLists.PaxSegmentList.find(
    (PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === PaxSegmentRefID[0]
  );

  if (AIRLINES_MARKETING_CARRIER.includes(PaxSegmentItem?.MarketingCarrier.AirlineID))
    return PaxSegmentItem?.MarketingCarrier;
  return {
    AirlineID: offer.Owner,
    Name:
      DataLists.PaxSegmentList.find(({ MarketingCarrier }) => MarketingCarrier.AirlineID === offer.Owner)
        ?.MarketingCarrier.Name || EMPTY,
  };
};

const getUrlLogoByOffer = (offer, DataLists) => {
  return getUrlLogoAirline(getAirlineOffer(offer, DataLists)?.AirlineID || EMPTY);
};
/* ----------------------- GET CHILDREN ------------------------- */

const getAirlineChildren = (PaxSegmentRefIDItem, PaxSegmentList) => {
  const itemPaxSegment = PaxSegmentList.find((item) => item.PaxSegmentID === PaxSegmentRefIDItem);
  return itemPaxSegment.MarketingCarrier.AirlineID + itemPaxSegment.MarketingCarrier.FlightNumber.padStart(4, '0');
};

const getFlightTimeChildren = (PaxSegmentRefIDItem, PaxSegmentList) => {
  const itemPaxSegment = PaxSegmentList.find((item) => item.PaxSegmentID === PaxSegmentRefIDItem);
  return itemPaxSegment?.FlightDuration ? convertFlightTime(itemPaxSegment?.FlightDuration) : EMPTY;
};

const getEmphysemaChildren = (PaxSegmentRefIDItem, PaxSegmentList) => {
  const itemPaxSegment = PaxSegmentList.find((item) => item.PaxSegmentID === PaxSegmentRefIDItem);
  let result = EMPTY;

  if (itemPaxSegment) {
    result =
      itemPaxSegment?.Equipment?.AircraftCode ??
      (itemPaxSegment.Legs.length ? itemPaxSegment.Legs.map((leg) => leg?.Equipment?.AircraftCode).join(',') : EMPTY);
  }

  return result;
};

const getCodeShareChildren = (PaxSegmentRefIDItem, PaxSegmentList) => {
  const { OperatingCarrier = {}, MarketingCarrier = {} } = PaxSegmentList.find(
    (item) => item.PaxSegmentID === PaxSegmentRefIDItem
  );
  if (OperatingCarrier?.AirlineID && OperatingCarrier?.AirlineID !== MarketingCarrier?.AirlineID)
    return `공동운항 ${OperatingCarrier.AirlineID}`;
  return EMPTY;
};

const getHiddenStopoverChildren = (PaxSegmentRefIDItem, PaxSegmentList) => {
  const itemPaxSegment = PaxSegmentList.find((item) => item.PaxSegmentID === PaxSegmentRefIDItem);
  if (itemPaxSegment?.Legs?.length) {
    return itemPaxSegment?.Legs[0]?.Arrival?.AirportCode ?? EMPTY;
  }
  return EMPTY;
};

const getDepartureTimeChildren = (PaxSegmentIdItem, PaxSegmentList) => {
  const departure = PaxSegmentList.find(
    (PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === PaxSegmentIdItem
  )?.Departure;
  const terminalName = departure?.Terminal?.Name ?? EMPTY;
  return {
    onPoint: departure?.AirportCode
      ? `${departure?.AirportCode}${terminalName && terminalName !== '0' ? `(T${terminalName})` : EMPTY}`
      : EMPTY,
    date: departure?.Date ?? EMPTY,
    time: departure?.Time ?? EMPTY,
  };
};

const getArrivalTimeChildren = (PaxSegmentIdItem, PaxSegmentList) => {
  const arrival = PaxSegmentList.find(
    (PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === PaxSegmentIdItem
  )?.Arrival;
  const terminalName = arrival?.Terminal?.Name ?? EMPTY;
  return {
    offPoint: arrival?.AirportCode
      ? `${arrival?.AirportCode}${terminalName && terminalName !== '0' ? `(T${terminalName})` : EMPTY}`
      : EMPTY,
    date: arrival?.Date ?? EMPTY,
    time: arrival?.Time ?? EMPTY,
  };
};
const getRbdFareBasisChildren = (PaxSegmentIdItem, offer, DataLists) => {
  let fare;
  for (const { FareComponent, PaxRefID } of getFareDetailList(offer)) {
    if (fare) break;
    for (const { Ptc, PaxID } of DataLists.PaxList) {
      if (Ptc === 'ADT' && formatPaxRefID(PaxRefID).includes(PaxID)) {
        fare = FareComponent?.find((FareComponentItem) => FareComponentItem.PaxSegmentRefID.includes(PaxSegmentIdItem));
      }
    }
  }
  return {
    rbd: fare?.FareBasis?.CabinType[0]?.Rbd || EMPTY,
    // fareBasisCode: fare.FareBasis.FareBasisCode,
  };
};

const getSeatClassChildren = (PaxSegmentIdItem, offer, DataLists) => {
  let fare;
  for (const { FareComponent, PaxRefID } of getFareDetailList(offer)) {
    if (fare) break;
    for (const { Ptc, PaxID } of DataLists.PaxList) {
      if (Ptc === 'ADT' && formatPaxRefID(PaxRefID).includes(PaxID) && FareComponent) {
        fare = FareComponent?.find((FareComponentItem) => FareComponentItem.PaxSegmentRefID.includes(PaxSegmentIdItem));
      }
    }
  }
  return fare?.FareBasis?.CabinType[0]?.CabinCode ?? EMPTY;
};

const getChildren = (PaxJourneyRefIDItem, offer, DataLists) => {
  let result = [];
  const { PaxJourneyList = [], PaxSegmentList = [] } = DataLists;
  const paxJourneyItem = PaxJourneyList.find((item) => item.PaxJourneyID === PaxJourneyRefIDItem);
  for (const paxSegmentId of paxJourneyItem.PaxSegmentRefID) {
    const airline = getAirlineChildren(paxSegmentId, PaxSegmentList);
    const flightTime = getFlightTimeChildren(paxSegmentId, PaxSegmentList);
    const emphysema = getEmphysemaChildren(paxSegmentId, PaxSegmentList);
    const codeShare = getCodeShareChildren(paxSegmentId, PaxSegmentList);
    const hiddenStopover = getHiddenStopoverChildren(paxSegmentId, PaxSegmentList);
    const departureTime = getDepartureTimeChildren(paxSegmentId, PaxSegmentList);
    const arrivalTime = getArrivalTimeChildren(paxSegmentId, PaxSegmentList);
    const rbdFareBasis = getRbdFareBasisChildren(paxSegmentId, offer, DataLists);
    const seatClass = getSeatClassChildren(paxSegmentId, offer, DataLists);
    result = [
      ...result,
      {
        airline,
        departureTime,
        arrivalTime,
        rbdFareBasis,
        seatClass,
        emphysema,
        codeShare,
        hiddenStopover,
        flightTime,
      },
    ];
  }
  return result;
};

/* ---------------------------------------------------------------- */

/* ----------------------- FILTER CLIENT ------------------------- */

export const filterDirectOrViaFlights = (data, directFilters) => {
  const isDirect = directFilters.find((elm) => elm.value === 'directFlight');
  const isVia = directFilters.find((elm) => elm.value === 'via');

  if (isDirect && !isVia) {
    return data.map((obj) => {
      obj.data = obj.data.filter((child) => child.children.length === 1);
      return obj;
    });
  }
  if (!isDirect && isVia) {
    return data.map((obj) => {
      obj.data = obj.data.filter((child) => child.children.length > 1);
      return obj;
    });
  }

  return data;
};

export const filterByAirline = (data, airlineFilters) => {
  return data.filter((item) => {
    return airlineFilters.find((elm) => elm.value === item.owner);
  });
};

export const filterByTimeRange = (data, timeFilters, getTimeValue) => {
  const dataFilter = cloneDeep(data);
  const result = dataFilter.filter((el) => {
    const timeChild = getTimeValue(el);
    if (timeChild) {
      const timeValue = timeChild.replace(':', '');
      const isValueExist = timeFilters.some((item) => {
        const firstFourDigits = item.value[0];
        const lastFourDigits = item.value[1];
        return timeValue >= firstFourDigits && timeValue <= lastFourDigits;
      });
      return isValueExist;
    }
    return false;
  });
  return result;
};

/* ---------------------------------------------------------------- */

export const filterOfferByMatchResult = (offerList, matchResultType = 'Full') => {
  return offerList?.filter((offerItem) => offerItem?.MatchResult === matchResultType);
};

export function getMainOfferItem(offer, DataLists) {
  const airline = offer?.OfferItem[0]?.Owner || EMPTY;
  const PaxList = DataLists.PaxList;
  const ptcCheck = PaxList.filter((paxListItem) => paxListItem.Ptc === 'ADT').length > 0 ? 'ADT' : 'CHD';

  if (SPECIAL_AIRLINE.includes(airline)) {
    for (const offerItem of offer.OfferItem) {
      for (const { PaxID, Ptc } of PaxList) {
        if (formatPaxRefID(offerItem.PaxRefID).includes(PaxID) && Ptc === ptcCheck) {
          return offerItem;
        }
      }
    }
  }
  return offer.OfferItem[0];
}

function getFareDetailList(offer) {
  const airline = offer?.OfferItem[0]?.Owner || EMPTY;
  if (SPECIAL_AIRLINE.includes(airline)) {
    let fareDetailList = [];
    for (const offerItem of offer.OfferItem) {
      fareDetailList = [...fareDetailList, ...offerItem.FareDetail];
    }
    return fareDetailList;
  }
  return offer.OfferItem[0].FareDetail;
}

export function formatPaxRefID(modelsPaxRefID) {
  let arrPaxRefID = [];
  for (const item of modelsPaxRefID) {
    arrPaxRefID = [...arrPaxRefID, ...item.split(' ')];
  }
  return uniq(arrPaxRefID);
}

function getQueryOfferPrice(offer, DataLists) {
  const { Owner, OfferID, ResponseID, OfferItem } = offer;
  let OfferItems = [];
  let PaxList = [];
  for (const { OfferItemID, PaxRefID } of OfferItem) {
    OfferItems.push({ OfferItemID, PaxRefID });
    for (const PaxRefIDItem of formatPaxRefID(PaxRefID)) {
      for (let { PaxID, Ptc } of DataLists.PaxList) {
        if (PaxID === PaxRefIDItem) {
          PaxList.push({
            PaxID,
            Ptc,
          });
          break;
        }
      }
    }
  }

  return {
    Offers: [
      {
        OfferID,
        Owner,
        ResponseID,
        OfferItems,
      },
    ],
    PaxList,
  };
}

export function formatDataTableAirShopping(DataLists, Offer) {
  let data = [];
  for (const item of Offer) {
    const itemData = {
      logo: EMPTY,
      airlineName: EMPTY,
      totalFee: 0,
      data: [],
      cardsTicket: [],
      infoPayment: {},
      offerID: EMPTY,
    };
    const { OfferItem } = item;
    const { PaxJourneyRefID } = OfferItem[0];
    for (const itemId of PaxJourneyRefID) {
      let dataPaxJourney = {
        main: {},
        children: [],
      };
      const airline = getAirline(itemId, DataLists);

      const departureTime = getDepartureTime(itemId, DataLists);

      const arrivalTime = getArrivalTime(itemId, DataLists);

      const via = getVia(itemId, DataLists);

      const flightTime = getFlightTime(itemId, DataLists);

      const rbdFareBasis = getRbdFareBasis(itemId, item, DataLists);

      const seatClass = getSeatClass(itemId, item, DataLists);

      const priceClass = getPriceClass(itemId, item);

      const baggage = getBaggage(itemId, item, DataLists);

      const emphysema = getEmphysema(itemId, DataLists);

      const codeShare = getCodeShare(itemId, DataLists);

      const hiddenStopover = getHiddenStopover(itemId, DataLists);

      const children = getChildren(itemId, item, DataLists);

      dataPaxJourney = {
        main: {
          airline: airline,
          departureTime,
          arrivalTime,
          via,
          flightTime,
          priceClass,
          rbdFareBasis,
          seatClass,
          baggage,
          emphysema,
          codeShare,
          hiddenStopover,
        },
        children: [...dataPaxJourney.children, ...children],
      };
      itemData.data = [...itemData.data, dataPaxJourney];
    }
    itemData.offerID = item.OfferID;
    itemData.logo = getUrlLogoByOffer(item, DataLists);
    itemData.airlineName = getAirlineOffer(item, DataLists)?.Name || EMPTY;
    itemData.infoPayment = getInfoPayment(item, DataLists);
    itemData.totalFee = getTotalFee(item);
    itemData.detailPrice = getDetailPrice(item);
    itemData.owner = getAirlineOffer(item, DataLists)?.AirlineID || EMPTY;
    itemData.cardsTicket = getAllCouponByPaxJourneyId(PaxJourneyRefID, Offer, DataLists, item.OfferID);
    itemData.paxJourneyRefID = PaxJourneyRefID;
    itemData.queryOfferPrice = getQueryOfferPrice(item, DataLists);
    data = [...data, itemData];
  }
  let dataUniqPaxJourneyRefID = [];

  for (const dataItem of data) {
    const checkItem = dataUniqPaxJourneyRefID.find((it) => isEqual(it.paxJourneyRefID, dataItem.paxJourneyRefID));
    if (!checkItem) {
      dataUniqPaxJourneyRefID.push(dataItem);
      continue;
    }
    if (checkItem.totalFee > dataItem.totalFee) {
      remove(dataUniqPaxJourneyRefID, checkItem);
      dataUniqPaxJourneyRefID.push(dataItem);
    }
  }

  return dataUniqPaxJourneyRefID;
}
