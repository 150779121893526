<script>
  import { has } from 'lodash';
  import { Modal } from 'carbon-components-svelte';
  import Portal from 'src/components/common/Portal.svelte';
  import PrimaryButton from './PrimaryButton.svelte';

  export let Warnings = [];
  export let pattern = ['Owner', 'Message'];
  export let sizeModal = 'sm';
  let open;

  const getListWarning = (Warnings) => {
    return Warnings.map((warn) => {
      const listTextWarn = [];
      pattern.forEach((p) => {
        if (has(warn, p)) {
          listTextWarn.push(warn[p]);
        }
      });
      return listTextWarn.join(' / ');
    });
  };
</script>

<PrimaryButton kind="ghost" width="auto" on:click={() => (open = true)} disabled={Warnings.length === 0}>
  <span class="underline-button">
    Warnings : {Warnings.length}
  </span>
</PrimaryButton>

<Portal idBox="box-modal-children">
  <Modal passiveModal bind:open size={sizeModal} preventCloseOnClickOutside>
    <h4 slot="heading">
      Warnings : {Warnings.length}
    </h4>
    <div>
      <ul>
        {#each getListWarning(Warnings) as warn}
          <!-- content here -->
          <!-- {warn.join(', ')} -->
          <li>
            {warn}
          </li>
        {/each}
      </ul>
    </div>
  </Modal>
</Portal>

<style>
  h4 {
    font-weight: 500;
  }
  .underline-button {
    text-decoration: underline;
    font-size: 16px;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 8px 4px;
    border-top: 1px solid #e0e0e0 !important;
  }
</style>
