<script>
  import { isEmpty } from 'lodash';
  import { EMPTY } from 'src/constants/app.js';
  import PassengerInformationItem from './PassengerInformationItem.svelte';
  import { createEventDispatcher } from 'svelte';
  import { sortPaxListByPtc } from 'src/utils/appHelper';
  import { Toolbar, ToolbarContent } from 'carbon-components-svelte';
  export let PaxList = [];
  export let arline = EMPTY;
  let allPassengerItemValue = {};
  let refPassengerInformationItems = {};
  let representativeContactInformationPaxId = EMPTY;
  let contactRefusalList = [];
  const dispatch = createEventDispatcher();
  const handleChangeValues = (refAllPassengerItemValue, mainContactInformationPaxId, refContactRefusalList) => {
    if (isEmpty(refAllPassengerItemValue)) return;
    let PaxList = [];
    let ContactInfoList = [];
    let INFmapping = 0;
    // generate default
    for (const key in refAllPassengerItemValue) {
      const { paxListItem, contactInfoListItem } = refAllPassengerItemValue[key];
      if (paxListItem.Ptc === 'INF') {
        PaxList.push({ ...paxListItem, ContactInfoRefID: [`CI${INFmapping}`] });
        INFmapping++;
        continue;
      }
      PaxList.push(paxListItem);
      if (['AF', 'KL'].includes(arline) && contactInfoListItem.ContactRefusedInd) {
        // case ContactRefusedInd=true ,delete property EmailAddress,Phone
        delete contactInfoListItem['EmailAddress'];
        delete contactInfoListItem['Phone'];
      }
      ContactInfoList.push(contactInfoListItem);
    }

    if (mainContactInformationPaxId !== EMPTY) {
      // choice representative contact information
      const targetContactInfoRefID = PaxList.find(
        (paxItem) => paxItem.PaxID === mainContactInformationPaxId
      ).ContactInfoRefID;
      ContactInfoList = [
        ContactInfoList.find((ContactInfoItem) => ContactInfoItem.ContactInfoID === targetContactInfoRefID[0]),
      ];
      PaxList = PaxList.map((paxItem) => ({ ...paxItem, ContactInfoRefID: targetContactInfoRefID }));

      if (['AF', 'KL'].includes(arline)) {
        if (refContactRefusalList.includes(mainContactInformationPaxId)) {
          //  and choice  contact refusal
          delete ContactInfoList[0]['EmailAddress'];
          delete ContactInfoList[0]['Phone'];
        } else {
          const newPaxList = [];
          for (let paxItem of PaxList) {
            if (refContactRefusalList.includes(paxItem.PaxID)) {
              newPaxList.push({
                ...paxItem,
                ContactInfoRefID: [`CI${refAllPassengerItemValue[paxItem.PaxID]['index']}`],
              });
              ContactInfoList.push({
                ContactInfoID: `CI${refAllPassengerItemValue[paxItem.PaxID]['index']}`,
                ContactRefusedInd: true,
              });
              continue;
            }
            newPaxList.push(paxItem);
          }
          PaxList = newPaxList;
        }
      }
    }

    console.log(PaxList, ContactInfoList);
    dispatch('change', { PaxList, ContactInfoList });
  };

  export const checkInValid = () => {
    let check = false;
    for (const key in refPassengerInformationItems) {
      if (refPassengerInformationItems[key]?.getInvalidList().length > 0) check = true;
    }
    return check;
  };

  $: handleChangeValues(allPassengerItemValue, representativeContactInformationPaxId, contactRefusalList);
</script>

<div class="g-custom-table">
  <Toolbar size="sm">
    <ToolbarContent>
      <div class="table-toolbar">
        <div class="wrapper-title">
          <h4 class="title-content">탑승객 정보</h4>
        </div>
        <div class="wapper-actions-button" />
      </div>
    </ToolbarContent>
  </Toolbar>
</div>
<div class="passenger-information">
  {#each sortPaxListByPtc(PaxList) as item, index (index)}
    <div class="passenger-information__box">
      <PassengerInformationItem
        bind:this={refPassengerInformationItems[item.PaxID]}
        bind:representativeContactInformationPaxId
        bind:passengerInformationItem={allPassengerItemValue[item.PaxID]}
        bind:contactRefusalList
        paxList={sortPaxListByPtc(PaxList)}
        paxItem={item}
        {index}
        {arline}
      />
    </div>
  {/each}
</div>
