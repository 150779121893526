<script>
  import RuleCoveredServicesModal from 'src/components/modal/RuleCoveredServicesModal.svelte';
  import Ticket from 'carbon-icons-svelte/lib/Ticket.svelte';
  import { has, isEqual, uniqWith, isEmpty } from 'lodash';
  import { DataTable, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { _ } from 'src/lib/i18n.js';
  import { EMPTY } from 'src/constants/app.js';
  import { formatPriceNumber, sortPaxListByPtc, getPassengerNamePtc } from 'src/utils/appHelper.js';
  import { QuestionTooltip } from 'src/assets/icons';
  import TaxBreakdownModal from 'src/components/modal/TaxBreakdownModal.svelte';
  import { generatePopupCoveredServices, splitConvertArrayId, formatInfoSegment } from 'src/utils/appHelper';
  import PaymentOrIssueButton from 'src/components/common/button/PaymentOrIssueButton.svelte';

  export let DataLists = {};
  export let Order = {};
  export let PricedOffer = {};
  export let RepricedOffer = {};
  export let orderRetrieve = {};
  export let orderId = EMPTY;
  export let airlineID = EMPTY;
  export let isPayLater = false;
  export let pnr = EMPTY;

  let isPayLaterButtonDisable = false;

  const headers = [
    {
      key: 'passengerNamePtc',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.passengerNamePtc', {
        default: '탑승객명 (PTC)',
      }),
    },
    {
      key: 'item',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.item', {
        default: 'Item',
      }),
    },
    {
      key: 'segments',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.segments', {
        default: 'Segments',
      }),
    },
    {
      key: 'baseAmount',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.baseAmount', {
        default: 'BaseAmount',
      }),
    },
    {
      key: 'totalTax',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.totalTax', {
        default: 'TotalTax',
      }),
    },
    {
      key: 'totalFare',
      value: $_('layout.pages.reservationManagement.amountInformationTable.dataTable.headers.totalFare', {
        default: 'TotalFare',
      }),
    },
    {
      key: 'actionTicket',
      value: EMPTY,
    },
  ];
  let rows = [];
  let openTaxBreakDown = false;
  let taxBreakDownData = [];
  let openRuleCoveredServicesModal = false;
  let ruleCoveredServicesModalData = {
    headers: [],
    rows: [],
  };
  let totalFareAll = 0;
  let curCode = EMPTY;
  $: if (
    !isEmpty(DataLists) &&
    ((!isEmpty(Order) && has(Order, 'OrderItem')) ||
      (!isEmpty(PricedOffer) && has(PricedOffer, 'OfferItem')) ||
      (!isEmpty(RepricedOffer) && has(RepricedOffer, 'RepricedOfferItem')))
  ) {
    const { PaxList, PaxJourneyList, PaxSegmentList } = DataLists;
    const JourneyOverview = Order?.JourneyOverview ?? PricedOffer?.JourneyOverview ?? RepricedOffer?.JourneyOverview;
    let ServiceList = [];

    const OrderOrOfferItem = Order?.OrderItem ?? PricedOffer?.OfferItem ?? RepricedOffer?.RepricedOfferItem ?? [];

    for (const { Service } of OrderOrOfferItem) {
      ServiceList = [...ServiceList, ...Service];
    }
    ServiceList = uniqWith(ServiceList, isEqual);

    for (const { Individual, Ptc, PaxID } of sortPaxListByPtc(PaxList)) {
      let passengerNamePtc = EMPTY,
        item = [],
        segments = [],
        baseAmount = EMPTY,
        totalTax = EMPTY,
        totalFare = EMPTY,
        taxData = [],
        actionTicket = {
          headers: [],
          rows: [],
        };
      const ItemByPaxID = OrderOrOfferItem.find((it) => it.PaxRefID.includes(PaxID));
      const ServiceListByPaxID = ServiceList.filter((it) => splitConvertArrayId(it.PaxRefID).includes(PaxID));
      const FareDetail = ItemByPaxID?.FareDetail?.find((FareDetailItem) =>
        splitConvertArrayId(FareDetailItem.PaxRefID).includes(PaxID)
      );
      const ServiceListByPaxIDCheckDefinition = ServiceListByPaxID.filter((it) => has(it, 'Definition'));

      if (Individual) passengerNamePtc = `${Individual?.Surname}/${Individual?.GivenName}(${Ptc})`;
      else passengerNamePtc = `${Ptc}`;
      passengerNamePtc = getPassengerNamePtc({ Individual, Ptc, PaxID });
      if (
        ServiceListByPaxID.length !== ServiceListByPaxIDCheckDefinition.length ||
        ServiceListByPaxIDCheckDefinition.length === 0
      ) {
        item = ['FLIGHT'];
      } else {
        const { SelectedSeat, Definition } = ServiceListByPaxIDCheckDefinition[0];
        if (SelectedSeat) {
          item.push(`${Definition.Name}/${SelectedSeat.Row}/${SelectedSeat.Column}`);
        }
        if (Definition.Desc?.length === 1) {
          item.push(`${Definition.Name}/${Definition.Desc[0]?.Text}`);
        }
      }

      if (isEqual(item, ['FLIGHT'])) {
        if (ItemByPaxID) {
          for (const PaxJourneyRefIDItem of splitConvertArrayId(ItemByPaxID.PaxJourneyRefID)) {
            const PaxSegmentRefIDList =
              PaxJourneyList.find((PaxJourneyListItem) => PaxJourneyListItem.PaxJourneyID === PaxJourneyRefIDItem)
                ?.PaxSegmentRefID ?? [];
            for (const PaxSegmentRefIDItem of PaxSegmentRefIDList) {
              const paxSegment = PaxSegmentList.find(
                (PaxSegmentListItem) => PaxSegmentListItem.PaxSegmentID === PaxSegmentRefIDItem
              );
              if (paxSegment) {
                segments.push(formatInfoSegment(paxSegment));
              }
            }
          }
        }
      }
      if (FareDetail) {
        const { BaseAmount, TaxTotal, Tax } = FareDetail;
        baseAmount = BaseAmount;
        totalTax = TaxTotal;
        totalFare = {
          Amount: BaseAmount?.Amount + TaxTotal.Amount,
          CurCode: BaseAmount.CurCode,
        };
        taxData = Tax.map((taxItem) => ({
          TaxCode: taxItem.TaxCode,
          Amount: taxItem.Amount.Amount,
          CurCode: taxItem.Amount.CurCode,
        }));
        actionTicket = generatePopupCoveredServices(
          PaxSegmentList,
          JourneyOverview,
          PaxJourneyList,
          FareDetail?.FareComponent ?? [],
          airlineID
        );
        totalFareAll += totalFare.Amount;
        curCode = totalFare.CurCode;
      }

      rows.push({
        id: PaxID,
        passengerNamePtc,
        item,
        segments,
        baseAmount,
        totalTax,
        totalFare,
        taxData,
        actionTicket,
      });
    }
  }
  $: if (!isEmpty(Order)) {
    const now = new Date();
    isPayLaterButtonDisable = Order.OrderItem?.[0]?.['PaymentTimeLimit']
      ? now > new Date(Order.OrderItem[0]['PaymentTimeLimit'])
      : false;
  }
</script>

<DataTable {headers} {rows} id="table-management" class="g-custom-table" size="medium">
  <Toolbar size="sm" class="table-toolbar">
    <ToolbarContent>
      <div class="wrapper-title">
        <h4 class="title-content">결제 대상 금액 정보</h4>
      </div>
      <div class="wrapper-actions-button">
        {#if isPayLater}
          <PaymentOrIssueButton
            {isPayLaterButtonDisable}
            {DataLists}
            {PricedOffer}
            {Order}
            {orderRetrieve}
            {orderId}
            {airlineID}
            {pnr}
          />
        {/if}
      </div>
    </ToolbarContent>
  </Toolbar>
  <div slot="cell" let:cell let:row>
    {#if ['item', 'segments'].includes(cell.key)}
      <ul>
        {#each cell.value as valueItem}
          <li>
            {valueItem}
          </li>{/each}
      </ul>
    {:else if ['baseAmount', 'totalTax', 'totalFare'].includes(cell.key)}
      <div class="flex">
        <div>
          {formatPriceNumber(cell.value?.Amount ?? EMPTY)}
          {cell.value?.CurCode ?? EMPTY}
        </div>
        {#if cell.value.Amount > 0 && cell.key === 'totalTax' && row.taxData.length > 0}
          <button
            class="g-button question-icon"
            on:click={() => {
              (openTaxBreakDown = true), (taxBreakDownData = row.taxData);
            }}
          >
            <QuestionTooltip />
          </button>
        {/if}
      </div>
    {:else if cell.key === 'actionTicket'}
      <button
        class="g-button"
        on:click={() => {
          openRuleCoveredServicesModal = true;
          ruleCoveredServicesModalData = cell.value;
        }}
      >
        <Ticket />
      </button>
    {:else}
      {cell.value}
    {/if}
  </div>
</DataTable>
<div class="grand-total-fare">
  Grand Total Fare : {`${formatPriceNumber(totalFareAll)} ${curCode ?? EMPTY}`}
</div>
<TaxBreakdownModal bind:open={openTaxBreakDown} data={taxBreakDownData} />
<RuleCoveredServicesModal
  bind:open={openRuleCoveredServicesModal}
  rows={ruleCoveredServicesModalData.rows}
  headers={ruleCoveredServicesModalData.headers}
/>

<style>
  .flex {
    display: flex;
    align-items: center;
  }
  .question-icon {
    display: flex;
    width: auto !important;
    justify-content: center;
    align-items: center;
  }
  .grand-total-fare {
    background: #e0e0e0;
    text-align: right;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
  }
</style>
