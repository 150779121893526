<script>
  import { cloneDeep, isEqual } from 'lodash';
  import { CustomTextInput } from 'src/components/common/input';
  import { EMPTY, InputOptions } from 'src/constants/app.js';
  import { CustomTooltip } from 'src/components/common/tooltip';
  import { Checkbox, Dropdown } from 'carbon-components-svelte';
  import { isValidEmail } from 'src/utils/appHelper';

  export let paxItem = EMPTY;
  export let index = EMPTY;
  export let arline;
  export let representativeContactInformationPaxId = EMPTY;
  export let passengerInformationItem = {};
  export let contactRefusalList = [];
  export let paxList = [];

  let valuesInvalidWarn = [];
  let isContactRefusalChecked = false;
  let triggerInitialForm = true;

  const airlineSpecial = ['AF', 'KL'];
  const genderOptions = [
    { id: 'MALE', text: 'Male' },
    { id: 'FEMALE', text: 'Female' },
  ];
  const nameTitleOptions = [
    { id: 'MR', text: 'MR' },
    { id: 'MRS', text: 'MRS' },
    { id: 'MSTR', text: 'MSTR' },
    { id: 'MISS', text: 'MISS' },
  ];
  const identityDocumentTypeOptions = [
    { id: 'PT', text: 'PT' },
    { id: 'None', text: 'None' },
  ];
  const ffnOptions = [
    { id: 'FB', text: 'AFKL (Flying Blue)' },
    { id: 'None', text: 'None' },
  ];
  const notifyInvalidWarnInput = {
    NameTitle: 'Title을 선택해 주세요',
    Surname: '성을 입력해 주세요',
    GivenName: '이름을 입력해 주세요',
    MiddleName: EMPTY,
    Gender: '성별을 선택해 주세요',
    Birthdate: '생년월일을 입력해 주세요',
    EmailAddress: {
      empty: '이메일을 입력해 주세요',
      notEmail: '올바른 이메일을 입력해 주세요',
    },
    CountryDialingCode: EMPTY,
    PhoneNumber: '모바일 번호를 입력해 주세요',
    IdentityDocumentType: EMPTY,
    IdentityDocumentNumber: 'ID 번호를 입력해 주세요',
    ExpiryDate: 'ID 만료일을 입력해 주세요',
    CitizenshipCountryCode: '국적을 선택해 주세요',
    IssuingCountryCode: 'ID 발행국을 선택해 주세요',
    ResidenceCountryCode: '거주국가를 선택해 주세요',
    AccountNumber: '마일리지 번호를 입력해 주세요',

    //new
    CountryCode: '2자리까지만 입력 가능',
    CityName: '3자리까지만 입력 가능',
    PostalCode: EMPTY,
    Street: EMPTY,
  };
  let formOption = {
    NameTitle: EMPTY,
    Surname: EMPTY,
    GivenName: EMPTY,
    MiddleName: EMPTY,
    Gender: EMPTY,
    Birthdate: EMPTY,
    EmailAddress: EMPTY,
    CountryDialingCode: EMPTY,
    PhoneNumber: EMPTY,
    IdentityDocumentType: 'PT',
    IdentityDocumentNumber: EMPTY,
    ExpiryDate: EMPTY,
    CitizenshipCountryCode: EMPTY,
    IssuingCountryCode: EMPTY,
    ResidenceCountryCode: EMPTY,
    AreaCode: EMPTY,
    ProgramCode: paxItem?.LoyaltyProgramAccount?.[0]?.['ProgramCode'] ?? 'None',
    AccountNumber: paxItem?.LoyaltyProgramAccount?.[0]?.['AccountNumber'] ?? EMPTY,

    //new
    CountryCode: EMPTY,
    CityName: EMPTY,
    PostalCode: EMPTY,
    Street: EMPTY,
  };
  let cacheFormOption = cloneDeep(formOption);

  function handleFormatValueInput(key) {
    if (isEqual(formOption[key], cacheFormOption[key])) return;
    cacheFormOption[key] = formOption[key];
    if (formOption[key] !== EMPTY) {
      valuesInvalidWarn = valuesInvalidWarn.filter((valueInvalid) => valueInvalid !== key);
    }
    switch (key) {
      case 'EmailAddress':
        if (isValidEmail(formOption[key])) {
          valuesInvalidWarn = [...valuesInvalidWarn, `EmailAddress_notEmail`];
        } else valuesInvalidWarn = valuesInvalidWarn.filter((item) => item !== 'EmailAddress_notEmail');
        break;
      case 'Birthdate':
      case 'ExpiryDate':
        if (formOption[key].length < 8) valuesInvalidWarn.push(key);
        break;
      default:
        break;
    }
  }
  const convertDateYYYYMMDD = (dateString) => {
    if (dateString === EMPTY) return EMPTY;
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return year + '-' + month + '-' + day;
  };
  const handleChangeInfoPassenger = (refFormOption, contactRefusedInd, refDisableSpecialInput) => {
    if (triggerInitialForm) {
      triggerInitialForm = false;
    } else {
      for (const key in refFormOption) {
        handleFormatValueInput(key);
      }
    }
    if (contactRefusedInd || refDisableSpecialInput) {
      const listRefusedIndDisable = [
        'EmailAddress',
        'EmailAddress_notEmail',
        'PhoneNumber',
        'CountryDialingCode',
        'CountryCode',
        'CityName',
        'PostalCode',
        'Street',
      ];
      valuesInvalidWarn = valuesInvalidWarn.filter((item) => !listRefusedIndDisable.includes(item));
      listRefusedIndDisable.forEach((key) => {
        if (key !== 'EmailAddress_notEmail') formOption[key] = EMPTY;
      });
    }

    if (refFormOption['IdentityDocumentType'] === 'None') {
      const listIdentityDocumentTypeDisable = [
        'IdentityDocumentNumber',
        'ExpiryDate',
        'CitizenshipCountryCode',
        'IssuingCountryCode',
        'ResidenceCountryCode',
      ];
      valuesInvalidWarn = valuesInvalidWarn.filter((item) => !listIdentityDocumentTypeDisable.includes(item));
      listIdentityDocumentTypeDisable.forEach((key) => {
        formOption[key] = EMPTY;
      });
    }
    if (refFormOption['ProgramCode'] === 'None') {
      valuesInvalidWarn = valuesInvalidWarn.filter((item) => item !== 'AccountNumber');
      formOption['AccountNumber'] = EMPTY;
    }

    const paxListItem = {
      PaxID: paxItem.PaxID,
      Ptc: paxItem.Ptc,
      Individual: {
        Surname: refFormOption['Surname'],
        MiddleName: [refFormOption['MiddleName']],
        GivenName: [refFormOption['GivenName']],
        NameTitle: refFormOption['NameTitle'],
        Gender: refFormOption['Gender'],
        Birthdate: convertDateYYYYMMDD(refFormOption['Birthdate']),
      },
      ContactInfoRefID: [`CI${index}`],
      IdentityDoc: [
        {
          IdentityDocumentNumber: refFormOption['IdentityDocumentNumber'],
          IdentityDocumentType: refFormOption['IdentityDocumentType'],
          IssuingCountryCode: refFormOption['IssuingCountryCode'],
          ExpiryDate: convertDateYYYYMMDD(refFormOption['ExpiryDate']),
          Gender: refFormOption['Gender'],
          CitizenshipCountryCode: refFormOption['CitizenshipCountryCode'],
          ResidenceCountryCode: refFormOption['ResidenceCountryCode'],
        },
      ],
      Gender: refFormOption['Gender'],
      LoyaltyProgramAccount:
        refFormOption['ProgramCode'] === 'None'
          ? []
          : [
              {
                ProgramCode: refFormOption['ProgramCode'],
                AccountNumber: refFormOption['AccountNumber'],
              },
            ],
    };

    if (refFormOption['MiddleName'] === EMPTY) {
      delete paxListItem['Individual']['MiddleName'];
    }
    if (refFormOption['IdentityDocumentType'] === 'None') {
      delete paxListItem['IdentityDoc'];
    }
    let contactInfoListItem = {
      ContactInfoID: `CI${index}`,
      EmailAddress: [refFormOption['EmailAddress']],
      Phone: [
        {
          Label: 'Mobile',
          CountryDialingCode: refFormOption['CountryDialingCode'],
          AreaCode: EMPTY,
          PhoneNumber: refFormOption['PhoneNumber'],
        },
      ],
    };
    if (airlineSpecial.includes(arline)) contactInfoListItem['ContactRefusedInd'] = contactRefusedInd;
    if (['BA'].includes(arline)) {
      contactInfoListItem['PostalAddress'] = [
        {
          Label: 'AddressAtDestination',
          Street: [formOption['Street']],
          PostalCode: formOption['PostalCode'],
          CityName: formOption['CityName'],
          CountryCode: formOption['CountryCode'],
        },
      ];
    }

    passengerInformationItem = { paxListItem, contactInfoListItem, index };
  };

  export const getInvalidList = () => {
    const ignoreList = ['MiddleName', 'AreaCode', 'CountryCode', 'CityName', 'PostalCode', 'Street'];
    const invalidList = [];
    for (const key in formOption) {
      if (ignoreList.includes(key)) continue;
      switch (key) {
        case 'AccountNumber':
          if (formOption['ProgramCode'] === 'None') continue;
          break;
        case 'EmailAddress':
        case 'CountryDialingCode':
        case 'PhoneNumber':
        case 'EmailAddress_notEmail':
        case 'CountryCode':
        case 'CityName':
        case 'PostalCode':
        case 'Street':
          if (disableSpecialInput || paxItem.Ptc === 'INF') continue;
          if (key === 'EmailAddress' && isValidEmail(formOption[key])) {
            invalidList.push(key);
          }
          break;
        case 'IdentityDocumentNumber':
        case 'ExpiryDate':
        case 'CitizenshipCountryCode':
        case 'IssuingCountryCode':
        case 'ResidenceCountryCode':
          if (formOption['IdentityDocumentType'] === 'None') continue;
          break;
        case 'Birthdate':
        case 'ExpiryDate':
          if (formOption[key].length === 8) continue;
          invalidList.push(key);
          break;
        default:
          break;
      }
      if (formOption[key] === EMPTY) invalidList.push(key);
    }
    valuesInvalidWarn = invalidList;
    return invalidList;
  };
  const isShowRepresentativeContact = (paxItem, paxList) => {
    if (paxItem.Ptc === 'INF') return false;
    if (paxItem.Ptc === 'ADT') {
      return paxList.length > 1;
    }
    if (paxItem.Ptc === 'CHD' && !paxList.find((item) => item.Ptc === 'ADT')) {
      return paxList.length > 1;
    }
    return false;
  };
  $: handleChangeInfoPassenger(formOption, isContactRefusalChecked, disableSpecialInput);
  $: disableSpecialInput =
    (representativeContactInformationPaxId === EMPTY
      ? false
      : representativeContactInformationPaxId != paxItem.PaxID) || contactRefusalList.includes(paxItem.PaxID);

  $: isDisableContactRefusalChecked =
    contactRefusalList.includes(representativeContactInformationPaxId) &&
    representativeContactInformationPaxId !== paxItem.PaxID;
  $: {
    if (!isContactRefusalChecked) contactRefusalList = contactRefusalList.filter((item) => item !== paxItem.PaxID);
    else if (!contactRefusalList.includes(paxItem.PaxID)) contactRefusalList = [...contactRefusalList, paxItem.PaxID];
  }
  $: isDisableFfn = paxItem?.LoyaltyProgramAccount?.length > 0;
</script>

<div class="passengerInformationItem">
  <div class="passengerInformationItem-layout-flex passengerInformationItem__header">
    <h6>{`탑승객${index + 1} (${paxItem.Ptc})`}</h6>
    {#if isShowRepresentativeContact(paxItem, paxList)}
      <div class="passengerInformationItem-tag">
        대표연락처
        <CustomTooltip align="center  " direction="top">
          <p class="tooltip" slot="content">
            대표 연락처로 체크 시 <br />
            체크한 탑승객의 이메일/모바일 정보가 전체 탑승객에게 동일 적용됩니다.
          </p>
        </CustomTooltip>
      </div>
      <Checkbox
        checked={representativeContactInformationPaxId === paxItem.PaxID}
        on:check={(event) => {
          if (event.detail) {
            representativeContactInformationPaxId = paxItem.PaxID;
          }
          if (!event.detail && representativeContactInformationPaxId === paxItem.PaxID) {
            representativeContactInformationPaxId = EMPTY;
          }
        }}
      />
    {/if}
  </div>
  <div class="passengerInformationItem-layout-flex passengerInformationItem__condition">
    <div class="item-w-132">
      <Dropdown
        titleText="Title"
        label="Select"
        items={nameTitleOptions}
        bind:selectedId={formOption['NameTitle']}
        invalid={valuesInvalidWarn.includes('NameTitle')}
        invalidText={notifyInvalidWarnInput['NameTitle']}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="Surname"
        placeholder="ex) HONG"
        bind:value={formOption['Surname']}
        invalid={valuesInvalidWarn.includes('Surname')}
        invalidText={notifyInvalidWarnInput['Surname']}
        options={[InputOptions.ONLY_LATIN]}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="GivenName"
        placeholder="ex) GILDONG"
        bind:value={formOption['GivenName']}
        invalid={valuesInvalidWarn.includes('GivenName')}
        invalidText={notifyInvalidWarnInput['GivenName']}
        options={[InputOptions.ONLY_LATIN]}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="MiddleName"
        placeholder="선택사항"
        bind:value={formOption['MiddleName']}
        options={[InputOptions.ONLY_LATIN]}
      />
    </div>
    <div class="item-w-132">
      <Dropdown
        titleText="성별"
        label="Select"
        items={genderOptions}
        bind:selectedId={formOption['Gender']}
        invalid={valuesInvalidWarn.includes('Gender')}
        invalidText={notifyInvalidWarnInput['Gender']}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="생년월일"
        placeholder="ex) YYYYMMDD"
        maxlength="8"
        bind:value={formOption['Birthdate']}
        invalid={valuesInvalidWarn.includes('Birthdate')}
        invalidText={notifyInvalidWarnInput['Birthdate']}
        options={[InputOptions.ONLY_NUMBER]}
      />
    </div>
    {#if paxItem.Ptc !== 'INF'}
      <div class="item-w-200">
        <CustomTextInput
          disabled={disableSpecialInput}
          labelText="이메일"
          placeholder="ex) email@email.com"
          bind:value={formOption['EmailAddress']}
          invalid={valuesInvalidWarn.includes('EmailAddress') || valuesInvalidWarn.includes('EmailAddress_notEmail')}
          invalidText={valuesInvalidWarn.includes('EmailAddress_notEmail')
            ? notifyInvalidWarnInput['EmailAddress']['notEmail']
            : notifyInvalidWarnInput['EmailAddress']['empty']}
        />
      </div>
      <div class="passengerInformationItem-layout-flex passengerInformationItem__mobile-phone">
        <div class="item-w-200">
          <CustomTextInput
            labelText="모바일"
            placeholder="국가번호"
            bind:value={formOption['CountryDialingCode']}
            disabled={disableSpecialInput}
            maxlength="4"
            options={[InputOptions.ONLY_NUMBER]}
          />
        </div>
        <div class="item-w-200">
          <CustomTextInput
            hideLabel
            placeholder="ex) 01012341234"
            disabled={disableSpecialInput}
            bind:value={formOption['PhoneNumber']}
            invalid={valuesInvalidWarn.includes('PhoneNumber')}
            invalidText={notifyInvalidWarnInput['PhoneNumber']}
            options={[InputOptions.ONLY_NUMBER]}
          />
        </div>
      </div>
    {/if}
  </div>
  <div class="passengerInformationItem-layout-flex passengerInformationItem__condition">
    <div class="item-w-132">
      <Dropdown
        titleText="ID 타입"
        label="Select"
        items={identityDocumentTypeOptions}
        bind:selectedId={formOption['IdentityDocumentType']}
        invalid={valuesInvalidWarn.includes('IdentityDocumentType')}
        invalidText={notifyInvalidWarnInput['IdentityDocumentType']}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="ID 번호"
        bind:value={formOption['IdentityDocumentNumber']}
        invalid={valuesInvalidWarn.includes('IdentityDocumentNumber')}
        invalidText={notifyInvalidWarnInput['IdentityDocumentNumber']}
        disabled={formOption['IdentityDocumentType'] === 'None'}
        options={[InputOptions.ONLY_LATIN_NUMBER]}
      />
    </div>
    <div class="item-w-132">
      <CustomTextInput
        labelText="ID 만료일"
        placeholder="ex) YYYYMMDD"
        maxlength="8"
        bind:value={formOption['ExpiryDate']}
        invalid={valuesInvalidWarn.includes('ExpiryDate')}
        invalidText={notifyInvalidWarnInput['ExpiryDate']}
        disabled={formOption['IdentityDocumentType'] === 'None'}
        options={[InputOptions.ONLY_NUMBER]}
      />
    </div>
    <div class="item-w-200">
      <CustomTextInput
        labelText="국적"
        invalid={valuesInvalidWarn.includes('CitizenshipCountryCode')}
        invalidText={notifyInvalidWarnInput['CitizenshipCountryCode']}
        bind:value={formOption['CitizenshipCountryCode']}
        disabled={formOption['IdentityDocumentType'] === 'None'}
        options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
        placeholder="2자리 국가코드"
        maxlength="2"
      />
    </div>
    <div class="item-w-200">
      <CustomTextInput
        labelText="ID 발행국"
        bind:value={formOption['IssuingCountryCode']}
        invalid={valuesInvalidWarn.includes('IssuingCountryCode')}
        invalidText={notifyInvalidWarnInput['IssuingCountryCode']}
        disabled={formOption['IdentityDocumentType'] === 'None'}
        options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
        placeholder="2자리 국가코드"
        maxlength="2"
      />
    </div>
    <div class="item-w-200">
      <CustomTextInput
        labelText="거주국가"
        bind:value={formOption['ResidenceCountryCode']}
        invalid={valuesInvalidWarn.includes('ResidenceCountryCode')}
        invalidText={notifyInvalidWarnInput['ResidenceCountryCode']}
        disabled={formOption['IdentityDocumentType'] === 'None'}
        options={[InputOptions.ONLY_LATIN, InputOptions.UPPER_CASE]}
        placeholder="2자리 국가코드"
        maxlength="2"
      />
    </div>
    {#if paxItem.Ptc !== 'INF'}
      <div class="passengerInformationItem-layout-flex passengerInformationItem__ffn-airlines">
        <div class="item-w-200">
          <Dropdown
            titleText="FFN 항공사"
            placeholder="FFN 항공사"
            bind:selectedId={formOption['ProgramCode']}
            items={ffnOptions}
            disabled={isDisableFfn}
          />
        </div>
        <div class="item-w-200">
          <CustomTextInput
            labelText="마일리지 번호"
            placeholder="ex) 01012341234"
            bind:value={formOption['AccountNumber']}
            invalid={valuesInvalidWarn.includes('AccountNumber') && formOption['ProgramCode'] !== 'None'}
            invalidText={notifyInvalidWarnInput['AccountNumber']}
            disabled={formOption['ProgramCode'] === 'None' || isDisableFfn}
            options={[InputOptions.ONLY_NUMBER]}
          />
        </div>
      </div>
    {/if}
  </div>
  <!-- doing -->
  {#if paxItem.Ptc !== 'INF' && arline === 'BA'}
    <div class="passengerInformationItem-layout-flex passengerInformationItem__condition">
      <div class="item-w-132">
        <CustomTextInput
          labelText="CountryCode"
          placeholder="2자리 국가코드"
          maxlength="2"
          options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
          disabled={disableSpecialInput}
          bind:value={formOption['CountryCode']}
        />
      </div>
      <div class="item-w-132">
        <CustomTextInput
          labelText="CityName"
          placeholder="3자리 도시코드"
          maxlength="3"
          options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
          disabled={disableSpecialInput}
          bind:value={formOption['CityName']}
        />
      </div>
      <div class="item-w-200">
        <CustomTextInput
          labelText="PostalCode"
          options={[InputOptions.ONLY_NUMBER]}
          disabled={disableSpecialInput}
          bind:value={formOption['PostalCode']}
        />
      </div>
      <div class="item-w-200">
        <CustomTextInput
          labelText="Street"
          options={[InputOptions.UPPER_CASE]}
          disabled={disableSpecialInput}
          bind:value={formOption['Street']}
          ignoreRmSpace={true}
        />
      </div>
      <div style="color:red;">* 체류지 정보는 미주 국가일 경우에만 작성해주세요</div>
    </div>
  {/if}
</div>
{#if paxItem.Ptc !== 'INF' && airlineSpecial.includes(arline)}
  <div class="passenger-information__footer">
    <div>
      <Checkbox labelText="직항" bind:checked={isContactRefusalChecked} disabled={isDisableContactRefusalChecked}>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div slot="labelText" style="font-size: 14px;" on:click|preventDefault>
          <div class="contact-refusal">Contact Refusal</div>
        </div>
      </Checkbox>
      <div class="fix-position-tooltip">
        <CustomTooltip>
          <div class="tooltip" slot="content">
            선택시, 해당 승객의 정보를 항공사에 제공하지 않습니다. <br />승객정보 미제공 시, 운항 변경 및 긴급 상황
            발생에 안내가 불가함을 승객에게 고지가 필요합니다.
          </div>
        </CustomTooltip>
      </div>
    </div>
  </div>
{/if}
