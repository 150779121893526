<script>
  import { DataTable, Toolbar, InlineLoading, Checkbox } from 'carbon-components-svelte';
  import { has, map, isEqual, uniqWith } from 'lodash';
  import { onMount } from 'svelte';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';
  import { formatInfoSegment, getPassengerNamePtc } from 'src/utils/appHelper.js';
  import { APP_EVENT_NAME, CODE_SUCCESS, EMPTY } from 'src/constants/app.js';
  import { postOrderChange } from 'src/service/reservation';
  import appEvent from 'src/store/appEventStore';
  import { PrimaryButton } from 'src/components/common/button';
  import { message, screenIsNotEvent } from 'src/store';

  export let resOrderRetrieve;
  export let airlineID;
  export let isPayLater;
  const headers = [
    {
      key: 'airlineID',
      value: $_('layout.pages.reservationManagement.ssrTable.dataTable.headers.airlineID', {
        default: '항공사',
      }),
      width: '12%',
    },
    {
      key: 'givenNameAndPtc',
      value: $_('layout.pages.reservationManagement.ssrTable.dataTable.headers.givenNameAndPtc', {
        default: '탑승객명(PTC)',
      }),
      width: '20%',
    },
    {
      key: 'name',
      value: $_('layout.pages.reservationManagement.ssrTable.dataTable.headers.name', {
        default: 'SSR',
      }),
      width: '20%',
    },
    {
      key: 'status',
      value: $_('layout.pages.reservationManagement.ssrTable.dataTable.headers.status', {
        default: '상태',
      }),
      width: '8%',
    },
    {
      key: 'segments',
      value: $_('layout.pages.reservationManagement.ssrTable.dataTable.headers.flightNumberAirportCodeDate', {
        default: 'Segments',
      }),
      width: '20%',
    },
    {
      key: 'checked',
      value: EMPTY,
      width: '5%',
    },
  ];

  const airLineCase1 = ['LH', 'LX', 'LXA', 'OS', 'EK'];
  const airLineCase2 = ['AF', 'KL'];

  let ssrTable = [];
  let hasStatusWarn = false;
  let isLoading = false;

  const sortData = (dataTable) => {
    return dataTable.sort((a, b) => {
      if (a['Ptc'] === b['Ptc']) {
        return a['PaxID'].localeCompare(b['PaxID']);
      }
      return a['Ptc'].localeCompare(b['Ptc']);
    });
  };

  const handleOrderChange = async (dataTable) => {
    let Amount = 0;
    let CurCode = EMPTY;
    let OrderItemIDList = [];
    dataTable.forEach(({ OrderItemID, checked, FareDetail }) => {
      if (checked) {
        OrderItemIDList.push(OrderItemID);
        FareDetail.forEach((fare) => {
          Amount += fare?.BaseAmount?.Amount ?? 0;
          if (!CurCode) CurCode = fare?.BaseAmount?.CurCode;
        });
      }
    });

    try {
      isLoading = true;
      const OrderID = resOrderRetrieve?.data?.Order?.OrderID || EMPTY;
      const payload = {
        query: {
          OrderID,
          PaymentList: [
            {
              Type: 'Cash',
              Amount,
              CurCode,
              OrderItemID: OrderItemIDList,
              PaxRefID: [],
            },
          ],
          requestType: 'accept',
        },
      };
      const response = await postOrderChange(payload);
      if (response?.data?.ResultMessage?.Code === CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'success',
            title: 'Success',
            subtitle: '서비스 추가가 완료되었습니다',
            caption: EMPTY,
          },
        ]);
        appEvent.action(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_SSRTABLE}${OrderID}`, {
          detail: { resOrderChange: response?.data, endMessage: EMPTY },
        });
      }
      if (response?.data?.ResultMessage?.Code !== CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'error',
            title: 'Error',
            subtitle: `${response?.data?.ResultMessage?.Code} ${response?.data?.ResultMessage?.Message}`,
            caption: EMPTY,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      isLoading = false;
    }
  };

  const getSsrData = (resOrderRetrieve) => {
    let newSsrTable = [];
    const { Order, DataLists } = resOrderRetrieve?.data || {};
    const { PaxSegmentList, PaxList } = DataLists || {};
    const serviceList = uniqWith(
      Order?.OrderItem?.flatMap(
        (item) =>
          item?.Service.map((elm) => ({ ...elm, OrderItemID: item.OrderItemID, FareDetail: item.FareDetail })) ?? []
      ),
      isEqual
    );
    //AF,KL
    const ServiceRefIDsByTicketDocList = DataLists?.TicketDocList?.flatMap((ticketDoc) => {
      return ticketDoc.TicketDocument.flatMap(({ CouponInfos }) =>
        CouponInfos.flatMap((coupItem) => coupItem.ServiceRefID)
      );
    });

    newSsrTable = map(serviceList ?? [], (item, index) => {
      const findPaxSegment = PaxSegmentList.find((paxSegment) => paxSegment.PaxSegmentID === item.PaxSegmentRefID);
      const findPax = PaxList.find((pax) => pax.PaxID === item.PaxRefID);
      let checked = false;
      if (['AF', 'KL'].includes(airlineID)) {
        if (!ServiceRefIDsByTicketDocList.includes(item.ServiceID)) checked = true;
      }
      const name = !has(item, 'Definition')
        ? 'Segment'
        : `${item?.Definition?.Name} ${item?.SelectedSeat?.Row ?? EMPTY}${item?.SelectedSeat?.Column ?? EMPTY}`;
      if (['HD', 'HN'].includes(item?.Status)) hasStatusWarn = true;

      return {
        id: index,
        airlineID: findPaxSegment?.MarketingCarrier?.AirlineID ?? item?.Owner ?? EMPTY,
        givenNameAndPtc: getPassengerNamePtc(findPax),
        name,
        status: item?.Status ?? EMPTY,
        segments: [formatInfoSegment(findPaxSegment)],
        Ptc: findPax?.Ptc ?? EMPTY,
        PaxID: item['PaxRefID'],
        OrderItemID: item.OrderItemID,
        FareDetail: item.FareDetail,
        checked,
      };
    });
    return newSsrTable;
  };
  const groupData = (dataTable) => {
    let newDataTable = [];
    for (let item of dataTable) {
      const checkHasItem = newDataTable.find(
        (it) =>
          it.PaxID === item.PaxID &&
          it.status === item.status &&
          it.name === item.name &&
          it.OrderItemID === item.OrderItemID
      );
      if (!checkHasItem) {
        newDataTable.push(item);
        continue;
      }
      checkHasItem.segments = uniqWith(checkHasItem.segments.concat(item.segments), isEqual);
    }
    return newDataTable;
  };
  const checkDisabledPurchase = (data) => {
    if ([...airLineCase1, ...airLineCase2].includes(airlineID)) {
      const checkHasChecked = data.find((row) => row.checked);
      if (checkHasChecked) return false;
    }
    return true;
  };
  const checkHiddenButton = (data) => {
    if (airLineCase1.includes(airlineID)) {
      const checkHasStatusHD = data.find((row) => row.status === 'HD');
      if (checkHasStatusHD) return false;
    }
    if (airLineCase2.includes(airlineID)) {
      const checkHasChecked = data.find((row) => row.checked);
      if (checkHasChecked) return false;
    }
    return true;
  };

  const handleSelectedPurchase = (event, currentRow) => {
    ssrTable = ssrTable.map((row) => {
      if (isEqual(row, currentRow)) row.checked = event.detail;
      return row;
    });
  };
  const handleSelectedAllPurchase = (event) => {
    const isUnCheck =
      ssrTable.filter((item) => item.status === 'HD').length ===
      ssrTable.filter((item) => item.status === 'HD' && item.checked).length;

    ssrTable = ssrTable.map((row) => {
      if (event.detail) row.checked = event.detail;
      else {
        if (isUnCheck) row.checked = event.detail;
      }
      return row;
    });
  };
  onMount(() => {
    ssrTable = groupData(getSsrData(resOrderRetrieve));
  });
  $: screenIsNotEvent.set(isLoading);
</script>

{#if $isLocaleLoaded}
  <DataTable id="table-management" {headers} rows={sortData(ssrTable)}>
    <Toolbar size="sm" class="table-toolbar">
      <div class="title-table">
        <h4 class="title-table-content">
          {$_('layout.pages.reservationManagement.ssrTable.toolbar.titleTable', {
            default: 'SSR 정보',
          })}
        </h4>
      </div>

      <div class="g-wrapper-toolbar-button">
        {#if hasStatusWarn}
          <div style="color: red; padding-left: 0.5rem">구매 대기 중인 부가서비스가 있습니다.</div>
        {/if}
        <div class="button-action">
          {#if [...airLineCase1, ...airLineCase2].includes(airlineID) && !isPayLater}
            {#if !checkHiddenButton(ssrTable)}
              <PrimaryButton
                kind="secondary"
                size="small"
                on:click={() => handleOrderChange(ssrTable)}
                height="32"
                width="180"
                disabled={checkDisabledPurchase(ssrTable)}
              >
                {#if isLoading}
                  <div class="g-custom-loading-button loading-box">
                    <InlineLoading description="Loading" />
                  </div>
                {:else}
                  구매확정
                {/if}
              </PrimaryButton>
            {/if}
          {/if}
        </div>
      </div>
    </Toolbar>
    <div slot="cell-header" let:header>
      {#if header.key === 'checked'}
        {#if airLineCase1.includes(airlineID) && !checkHiddenButton(ssrTable) && !isPayLater}
          {@const isAllChecked = !ssrTable.find((item) => item.status === 'HD' && item.checked === false)}
          <Checkbox hideLabel on:check={handleSelectedAllPurchase} checked={isAllChecked} />
        {/if}
      {:else}
        {header.value}
      {/if}
    </div>
    <div slot="cell" let:cell let:row>
      {#if ['segments'].includes(cell.key)}
        <ul>
          {#each cell.value as valueItem}
            <li>
              {valueItem}
            </li>
          {/each}
        </ul>
      {:else if cell.key === 'status'}
        {cell.value}
      {:else if cell.key === 'checked'}
        {#if airLineCase1.includes(airlineID) && !isPayLater}
          {#if row.status === 'HD'}
            <Checkbox hideLabel checked={row.checked} on:check={(event) => handleSelectedPurchase(event, row)} />
          {/if}
        {/if}
      {:else}
        {cell.value}
      {/if}
    </div>
  </DataTable>
{:else}
  <p>Loading...</p>
{/if}

<style>
  .g-wrapper-toolbar-button {
    align-items: center;
  }
  .button-action {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
  }
</style>
