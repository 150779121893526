import App from './App.svelte';
import * as Sentry from '@sentry/svelte';
Sentry.init({
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || '',
  dsn: import.meta.env.VITE_SENTRY_DNS || '',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.0,
});
const app = new App({
  target: document.getElementById('app'),
});

export default app;
