<script>
  import { DataTable } from 'carbon-components-svelte';
  import { _ } from '../../../lib/i18n.js';
  export let InConnectionDocNbr;
  export let data = [];
  const conjMaxLength = 13;
  const headers = [
    {
      key: 'CouponNumber',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.couponNumber', {
        default: '순번',
      }),
    },
    {
      key: 'AirlineID',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.airlineID', {
        default: '항공사',
      }),
    },
    {
      key: 'FlightNumber',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.flightNumber', {
        default: '편명',
      }),
    },
    {
      key: 'DepartureAirportCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.departureAirportCode', {
        default: '출발지',
      }),
    },
    {
      key: 'ArrivalAirportCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.arrivalAirportCode', {
        default: '도착지',
      }),
    },
    {
      key: 'DepartureDateTime',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.departureDateTime', {
        default: '출발일시',
      }),
    },
    {
      key: 'ArrivalDateTime',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.arrivalDateTime', {
        default: '도착일시',
      }),
    },
    {
      key: 'CabinCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.cabinCode', {
        default: '좌석등급',
      }),
    },
    {
      key: 'Name',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.name', {
        default: 'PriceClass',
      }),
    },
    {
      key: 'FareBasisCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.fareBasisCode', {
        default: 'FareBasis',
      }),
    },
    {
      key: 'Baggage',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.baggage', {
        default: '수하물',
      }),
    },
    {
      key: 'Rbd',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.rbd', {
        default: 'RBD',
      }),
    },
    {
      key: 'CodeShare',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.codeShare', {
        default: '코드쉐어',
      }),
    },
    {
      key: 'AircraftCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.aircraftCode', {
        default: '기종',
      }),
    },
    {
      key: 'Status',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupTicket.status', {
        default: '쿠폰상태',
      }),
    },
  ];
</script>

{#if InConnectionDocNbr.length}
  <DataTable
    class="popup-ticket-table"
    {headers}
    rows={data}
    expandable={true}
    nonExpandableRowIds={data.map((item) => item.id).slice(0, data.length - 1)}
    expandedRowIds={[data[data.length - 1]?.id]}
  >
    <svelte:fragment slot="expanded-row" let:row>
      <p>
        Conj티켓: {InConnectionDocNbr.length > conjMaxLength
          ? InConnectionDocNbr.substring(0, conjMaxLength)
          : InConnectionDocNbr}
      </p>
    </svelte:fragment>
  </DataTable>
{:else}
  <DataTable {headers} rows={data} />
{/if}
