<script>
  import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-svelte';
  import { onDestroy, onMount } from 'svelte';
  import { user } from 'src/store';

  let modalMessage = {
    open: false,
    type: 'WARN_TEN_MINUTES', //'LOGIN_EXPIRATION'
  };
  let warn;

  let timeExpired;
  let currentTime;

  const idTime = setInterval(() => {
    const now = new Date();
    currentTime = Math.floor(now.getTime() / 1000);
  }, 1000);

  function handleNavigateLogin() {
    modalMessage.open = false;
    user.set('LOGOUT');
    window.location.reload();
    clearInterval(idTime);
  }
  function handleCloseWarn() {
    if (modalMessage.type === 'WARN_TEN_MINUTES') modalMessage.open = false;
    if (modalMessage.type === 'LOGIN_EXPIRATION') handleNavigateLogin();
  }
  $: {
    if (currentTime > timeExpired && !window.location.href.includes('sign-in')) {
      modalMessage = {
        open: true,
        type: 'LOGIN_EXPIRATION',
      };
      clearInterval(idTime);
    }
    if (currentTime === timeExpired - 10 * 60 && warn === false && !window.location.href.includes('sign-in')) {
      modalMessage = {
        open: true,
        type: 'WARN_TEN_MINUTES',
      };
      user.update((value) => {
        return { ...value, warn: true };
      });
    }
  }
  onMount(() => {
    user.subscribe((value) => {
      try {
        const now = new Date();
        const expirationDate = new Date(value.expirationTime + 'Z');
        warn = value.warn;
        timeExpired = Math.floor(expirationDate.getTime() / 1000);
        currentTime = Math.floor(now.getTime() / 1000);
      } catch {}
    });
  });
  onDestroy(() => {
    clearInterval(idTime);
  });
</script>

<ComposedModal
  style="z-index: var(--z-super)"
  preventCloseOnClickOutside={true}
  bind:open={modalMessage.open}
  modalHeading="로그인 만료"
  on:close={handleCloseWarn}
>
  <ModalHeader title="로그인 만료" />
  <ModalBody>
    {#if modalMessage.type === 'WARN_TEN_MINUTES'}
      로그인이 10분 후에 만료됩니다. 계속해서 활동하시려면 다시 로그인해주세요.
    {:else if modalMessage.type === 'LOGIN_EXPIRATION'}
      인증이 만료되었습니다. 로그인하여 계속 이용하세요.
    {/if}
  </ModalBody>
  <ModalFooter>
    {#if modalMessage.type === 'WARN_TEN_MINUTES'}
      <Button kind="secondary" on:click={handleCloseWarn}>닫기</Button>
      <Button on:click={handleNavigateLogin}>다시 로그인</Button>
    {:else if modalMessage.type === 'LOGIN_EXPIRATION'}
      <Button kind="secondary" on:click={handleNavigateLogin}>확인</Button>
    {/if}
  </ModalFooter>
</ComposedModal>
