import OcnContent from 'src/components/reservation-management-report/OcnContent.svelte';
import BookingContent from 'src/components/reservation-management-report/BookingContent.svelte';
import ManagementContent from 'src/components/reservation-management-report/ManagementContent.svelte';
import DsrList from 'src/components/reservation-management-report/DsrList.svelte';
import ReservationTicketing from 'src/components/reservation-management-report/reservation-ticketing/index.svelte';

export const DEFAULT_TAB_WIDTH = 160;
export const TAB_WIDTH_MIDDLE = 121;
export const TAB_WIDTH_MIN = 94;
export const SPACE_TAB = 74;

export const TAB_COMPONENT = {
  listOcn: OcnContent,
  listReservation: BookingContent,
  reservationManagement: ManagementContent,
  listDsr: DsrList,
  reservationTicketing: ReservationTicketing,
};
