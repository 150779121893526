<!-- svelte-ignore a11y-click-events-have-key-events -->
<script>
  import { cloneDeep } from 'lodash';
  import { createEventDispatcher } from 'svelte';
  import { ExpandableTile } from 'carbon-components-svelte';
  import { Checkbox } from 'carbon-components-svelte';
  import { EMPTY } from 'src/constants/app';
  import ChevronDown from 'carbon-icons-svelte/lib/ChevronDown.svelte';
  import 'src/styles/expand-checkbox.scss';

  export let title = EMPTY;
  export let data = [];

  export let width = '100%';
  export let type = 'multiple'; // single|multiple
  let expanded = true;

  const dispatch = createEventDispatcher();
  const handleClickExpand = () => {
    expanded = !expanded;
  };

  const getDefaultData = () => {
    return data.map((item) => ({ ...item, checked: false }));
  };

  let _data = getDefaultData();

  const invokeEventOnChange = (dataChecked) => {
    dispatch('change', { selected: dataChecked });
  };
  export const handleReset = () => {
    _data = getDefaultData();
  };
  const handleChange = (isChecked, id) => {
    let newData;
    if (type === 'single') newData = getDefaultData();
    else if (type === 'multiple') newData = cloneDeep(_data);
    else if (!newData) return;

    for (const item of newData) {
      if (item.id === id) item.checked = isChecked;
    }

    _data = newData;
    invokeEventOnChange(
      newData
        .filter((dataItem) => dataItem.checked)
        .map((itemIsChecked) => {
          return {
            id: itemIsChecked.id,
            text: itemIsChecked.text,
            value: itemIsChecked.value,
          };
        })
    );
  };
</script>

<div id="expand-check-box" class="expand" style="width:{width};">
  <button class="expand__head" on:click={handleClickExpand} class:expand__head--show={expanded}>
    <p>
      {title}
    </p>
    <span class="expand__head__icon">
      <ChevronDown />
    </span>
  </button>
  <ExpandableTile {expanded}>
    <ul slot="below" on:click|stopPropagation class="expand__options">
      {#each _data as { id, text, value, checked } (id)}
        {#key value}
          <li>
            <Checkbox {checked} on:change={(event) => handleChange(event.target.checked, id)}>
              <p slot="labelText" class="labelText" on:click|preventDefault>{text}</p>
            </Checkbox>
          </li>
        {/key}
      {/each}
    </ul>
  </ExpandableTile>
</div>
