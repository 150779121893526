<script>
  import { Toolbar, Grid, Row, Column } from 'carbon-components-svelte';
  import { onMount } from 'svelte';
  import { map, filter, join, isEmpty } from 'lodash';
  import { isLocaleLoaded, _ } from 'src/lib/i18n';
  import { EMPTY } from 'src/constants/app';
  import { formatDate } from 'src/utils/appHelper';
  import { convertToKST } from 'src/utils/DateTime';
  import { WarnButton } from 'src/components/common/button';
  import './index.scss';

  export let orderRetrieve;
  export let managementOrderRes;
  export let isPayLater;
  let dataInfo;

  function getReservationInfoData(orderRetrieve, managementOrder) {
    const MainOrderItem = orderRetrieve?.Order?.OrderItem[0] ?? {};
    const orderStatus = orderRetrieve?.Order?.OrderStatus;
    const findBookingReference = orderRetrieve?.Order?.BookingReference.find(
      (bookingReference) => bookingReference.AirlineID
    );
    const airlineID = findBookingReference?.AirlineID;
    const airlineName = findBookingReference?.AirlineName ?? EMPTY;
    const orderID = orderRetrieve?.Order?.OrderID;
    const createDate = managementOrder?.data?.Response?.MatchedOrder[0]?.CreateDate || EMPTY;
    const ticketingDate = managementOrder?.data?.Response?.MatchedOrder?.[0]?.TicketingDate || EMPTY;
    const BookingReferenceIsNotAssociated = filter(
      orderRetrieve?.Order?.BookingReference,
      (it) => it?.Type !== 'ASSOCIATED_BOOKING'
    );
    function formatId(bookRef) {
      return map(bookRef, (it) => {
        const { AirlineID, Id } = it;
        return `${Id}(${!AirlineID ? 'OTHER' : AirlineID})`;
      });
    }

    const getId = () => {
      const textHasAirlineId = formatId(filter(BookingReferenceIsNotAssociated, (it) => !!it?.AirlineID));
      const textNotHasAirlineId = formatId(filter(BookingReferenceIsNotAssociated, (it) => !it?.AirlineID));
      return join([...textHasAirlineId, ...textNotHasAirlineId], ' / ');
    };

    return {
      orderStatus,
      airlineID,
      airlineName,
      id: getId(),
      orderID,
      createDate: createDate ? formatDate(createDate, 'datetime') : EMPTY,
      ticketingDate: ticketingDate ? formatDate(ticketingDate, 'datetime') : EMPTY,
      PaymentTimeLimit: convertToKST(MainOrderItem?.PaymentTimeLimit),
      TicketTimeLimit: convertToKST(MainOrderItem?.TicketTimeLimit),
      PriceGuaranteeTimeLimit: convertToKST(MainOrderItem?.PriceGuaranteeTimeLimit),
    };
  }

  onMount(() => {
    dataInfo = getReservationInfoData(orderRetrieve?.data, managementOrderRes);
  });
</script>

{#if $isLocaleLoaded}
  <div class="reservation" id="table-management">
    <Toolbar size="sm" class="table-toolbar">
      <div class="title-table">
        <h4 class="title-table-content">
          {$_('layout.pages.reservationManagement.reservationInformationTable.toolbar.titleTable', {
            default: '예약 정보',
          })}
        </h4>
        <div class="g-wrapper-toolbar-button local-toolbar">
          <div>
            {#if !isEmpty(orderRetrieve?.data?.ResultMessage?.MessageDetail?.Warnings)}
              <WarnButton
                Warnings={orderRetrieve?.data?.ResultMessage?.MessageDetail?.Warnings}
                pattern={['Code', 'Message', 'ShortText']}
              />
            {/if}
          </div>
        </div>
      </div>
    </Toolbar>
    <Grid fullWidth={true} id="reservation-information-table">
      <Row padding>
        <Column class="border-right">
          <Row narrow>
            <Column lg={4}>
              <div class="content title">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.orderStatus', {
                  default: '예약상태',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <div class="content">{dataInfo?.orderStatus || EMPTY}</div>
            </Column>
          </Row>
          <Row narrow>
            <Column lg={4}>
              <div class="content title">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.airlineID', {
                  default: '항공사',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <div class="content">{dataInfo?.airlineID || EMPTY}</div>
            </Column>
          </Row>
          {#if isPayLater}
            <Row narrow>
              <Column lg={4}>
                <div class="content title">
                  {$_('layout.pages.reservationManagement.reservationInformationTable.content.paymentTimeLimit', {
                    default: '결제TL',
                  })}
                </div>
              </Column>
              <Column lg={12}>
                <div class="content">{dataInfo?.PaymentTimeLimit || EMPTY}</div>
              </Column>
            </Row>
          {/if}
        </Column>
        <Column class="border-right">
          <Row narrow>
            <Column lg={4}>
              <div class="content title p-28">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.createDate', {
                  default: '예약일시',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <div class="content">{dataInfo?.createDate || EMPTY}</div>
            </Column>
          </Row>
          <Row narrow>
            <Column lg={4}>
              <div class="content title p-28">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.id', {
                  default: 'PNR',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <!-- fix -->
              <div class="content">{dataInfo?.id || EMPTY}</div>
            </Column>
          </Row>
          {#if isPayLater}
            <Row narrow>
              <Column lg={4}>
                <div class="content title p-28">
                  {$_('layout.pages.reservationManagement.reservationInformationTable.content.ticketTimeLimit', {
                    default: '발권TL',
                  })}
                </div>
              </Column>
              <Column lg={12}>
                <!-- fix -->
                <div class="content">{dataInfo?.TicketTimeLimit ?? EMPTY}</div>
              </Column>
            </Row>
          {/if}
        </Column>
        <Column>
          <Row narrow>
            <Column lg={4}>
              <div class="content title p-28">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.ticketingDate', {
                  default: '발권일시',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <div class="content">{dataInfo?.ticketingDate || EMPTY}</div>
            </Column>
          </Row>
          <Row narrow>
            <Column lg={4}>
              <div class="content title p-28">
                {$_('layout.pages.reservationManagement.reservationInformationTable.content.orderID', {
                  default: 'OrderID',
                })}
              </div>
            </Column>
            <Column lg={12}>
              <div class="content">{dataInfo?.orderID || EMPTY}</div>
            </Column>
          </Row>
          {#if isPayLater}
            <Row narrow>
              <Column lg={4}>
                <div class="content title p-28">
                  {$_(
                    'layout.pages.reservationManagement.reservationInformationTable.content.priceGuaranteeTimeLimit',
                    {
                      default: '요금보장TL',
                    }
                  )}
                </div>
              </Column>
              <Column lg={12}>
                <div class="content">{dataInfo?.PriceGuaranteeTimeLimit ?? EMPTY}</div>
              </Column>
            </Row>
          {/if}
        </Column>
      </Row>
    </Grid>
  </div>
{:else}
  <div>loading ....</div>
{/if}

<style>
  .local-toolbar {
    display: flex;
    flex-direction: row-reverse;
  }
</style>
