<script>
  import { join, uniqBy, map, find, filter, forEach } from 'lodash';
  import { Plane, FlightRoute, Arrow } from 'src/assets/icons';
  import { SEAT_CLASS_COLOR } from 'src/constants/itineraryInquiry.js';
  import { getUrlLogoAirline, getCabinName } from 'src/utils/appHelper';
  import { EMPTY } from 'src/constants/app';
  import './index.scss';

  export let data = [];
  export let activeBorder = false;

  let checkArlineIcon = 'SINGLE'; //MULTI
  let showFullList = false;
  function handleClickShowToggleList() {
    showFullList = !showFullList;
  }
  function handleToggleList() {
    if (!showFullList) {
      return [getAlJourneys()];
    }
    return [getAlJourneys(), ...data];
  }
  function getNameCabinCode(airlineID, cabinCode) {
    if (cabinCode?.length) {
      return map(cabinCode, (it) => getCabinName(airlineID, it));
    }
    return find(SEAT_CLASS_COLOR, (classColorItem) => classColorItem.name === getCabinName(airlineID, cabinCode))?.name;
  }

  function getColorCabinCode(cabinItem) {
    const { airlineID, cabinCode } = cabinItem;
    return find(SEAT_CLASS_COLOR, (classColorItem) => classColorItem.name === getCabinName(airlineID, cabinCode))
      ?.color;
  }

  function handleImageError(event) {
    event.target.style.visibility = 'collapse';
  }

  function getAlJourneys() {
    const arrAirlineIDuniqBy = uniqBy(
      map(data, (journey) => journey.airlineID),
      (it) => it
    );
    if (arrAirlineIDuniqBy.length > 1) checkArlineIcon = 'MULTI';
    const airlineID = join(arrAirlineIDuniqBy, ',');
    const flightNumber = join(
      uniqBy(
        map(data, (journey) => journey.flightNumber),
        (it) => it
      ),
      ','
    );

    const departureTime = data[0].departureTime;
    const arrivalTime = data[data.length - 1].arrivalTime;
    const onPointDepartureName = data[0].onPointDepartureName;
    const offPointArrivalName = data[data.length - 1].offPointArrivalName;
    const flightTime = data[0].flightTime;
    const cabinCode = map(data, (journey) => journey.cabinCode);
    const cabinItem = map(data, (journey) => journey.cabinItem);
    const operatingCarrierAirlineID = join(
      filter(
        map(data, (journey) => journey.operatingCarrierAirlineID),
        (itCarrierAirlineID) => itCarrierAirlineID !== EMPTY
      ),
      ','
    );
    const rbd = map(data, (journey) => journey.rbd);
    let legs = [];

    forEach(data, (dataItem) => {
      if (dataItem.legs !== EMPTY) legs = [...legs, dataItem.legs];
    });

    const getCountRefId = () => {
      const countRefId = data[0].countRefId;
      if (countRefId === 1) return '직항';
      if (countRefId > 1 && countRefId < 4) return `경유+${countRefId - 1}`;
      return EMPTY;
    };
    const priceClassInfoName = map(data, (journey) => journey.priceClassInfoName);
    return {
      airlineID,
      flightNumber,
      departureTime,
      arrivalTime,
      offPointArrivalName,
      onPointDepartureName,
      flightTime,
      countRefId: getCountRefId(),
      cabinCode,
      cabinItem,
      rbd,
      operatingCarrierAirlineID,
      legs: join(legs, ','),
      priceClassInfoName,
    };
  }
</script>

{#key showFullList}
  <div class="item-ItineraryInquiry">
    <div class="controller">
      <div>
        <Plane />
      </div>
      {#if data?.length > 1}
        <button class:show-full-list={showFullList} on:click={handleClickShowToggleList}>
          <Arrow />
        </button>
      {/if}
    </div>
    <ul class:active-border={activeBorder}>
      {#each handleToggleList() as { airlineID, flightNumber, airlineName, journeyCount, arrivalTime, departureTime, onPointDepartureName, offPointArrivalName, flightTime, countRefId, cabinCode, cabinItem, operatingCarrierAirlineID, legs, rbd, priceClassInfoName }, index (index)}
        <li class:visible-border-top={index !== 0}>
          <div class="korea-image">
            {#if (checkArlineIcon === 'SINGLE' && index === 0) || (checkArlineIcon === 'MULTI' && index !== 0)}
              <img src={getUrlLogoAirline(airlineID)} alt="logo-airline" on:error={handleImageError} />
            {/if}
          </div>
          <div class="space-white korean-air">
            <div class="korean-air-id">
              {airlineID}
            </div>
            <div>{flightNumber}</div>
          </div>
          <div class="space-white location-airport">
            <div class="time-trip">{departureTime}</div>
            <div class="point-trip">{onPointDepartureName}</div>
          </div>
          <div class="flightRoute">
            {#if index === 0}
              <div>{flightTime}</div>
            {/if}
            <FlightRoute />
            {#if index === 0}
              <div>{countRefId}</div>
            {/if}
          </div>
          <div class="space-white location-airport">
            <div class="time-trip">{arrivalTime}</div>
            <div class="point-trip">{offPointArrivalName}</div>
          </div>
          <div class="cross-path-y" />
          <div class="space-white rbd-space">
            <div>{rbd}</div>
            {#if index !== 0}
              <div style:--bg-cabinCode={getColorCabinCode(cabinItem)} class="rbd-code">
                {getNameCabinCode(airlineID, cabinCode)}
              </div>
            {:else}
              <div class="group-main-circle">
                {#each cabinItem as cbItem}
                  <div class="rbd-circle" style:--bg-cabinCode-circle={getColorCabinCode(cbItem)} />
                {/each}
              </div>
            {/if}
          </div>
          <div class="space-white tag-text">
            <div>{EMPTY}</div>
            <div>{priceClassInfoName}</div>
          </div>
          <div class="baggage-support">{EMPTY}</div>
          <div class="space-white air-name">
            <div>{operatingCarrierAirlineID && `${operatingCarrierAirlineID} 공동운항`}</div>
            <div>{legs && `Stops: ${legs}`}</div>
          </div>
        </li>
      {/each}
    </ul>
  </div>
{/key}
