<script>
  import { cloneDeep, isEqual } from 'lodash';
  import {
    Form,
    FormGroup,
    Checkbox,
    RadioButtonGroup,
    RadioButton,
    ComboBox,
    MultiSelect,
  } from 'carbon-components-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { MultiSelectPtc } from 'src/components/common/multi-select';
  import FlightSchedule from './FlightSchedule.svelte';
  import FfnPromotionCode from './FFNPromotionCode.svelte';
  import { getAgencyProviderAirlineCode } from 'src/service/reservation';
  import { map, sortBy } from 'lodash';

  import './Condition.scss';

  const dispatch = createEventDispatcher();
  const TYPE_FLIGHT_WAY = [
    {
      id: 1,
      text: '왕복',
      value: 'TWO_WAY',
    },
    {
      id: 2,
      text: '편도',
      value: 'ONE_WAY',
    },
    {
      id: 3,
      text: '다구간',
      value: 'MULTI_WAY',
    },
  ];

  const optionsSelectPtc = [
    {
      id: 1,
      text: '성인',
      value: 0,
      key: 'ADT',
    },
    {
      id: 2,
      text: '소아',
      value: 0,
      key: 'CHD',
    },
    {
      id: 3,
      text: '유아',
      value: 0,
      key: 'INF',
    },
  ];

  const defaultFormFields = {
    flightWay: {
      type: TYPE_FLIGHT_WAY[0].value,
      data: {},
    },
    paxList: [],
    direct: false,
    cabin: 'Y',
    airlineCode: [],
    ffnList: {
      ADT: [],
      CHD: [],
      INF: [],
    },
    promotionCodeList: {
      special: [],
      program: [],
    },
  };
  let optionsAirlineCode = [];
  let formFields = cloneDeep(defaultFormFields);
  let flightScheduleRef;
  let multiSelectPtcRef;
  let itemsSelectPtc = cloneDeep(optionsSelectPtc);

  const handleReset = () => {
    formFields = cloneDeep({
      ...defaultFormFields,
      flightWay: {
        type: formFields.flightWay.type,
        data: {},
      },
    });
    flightScheduleRef?.handleReset();
    multiSelectPtcRef?.handleReset();
    itemsSelectPtc = cloneDeep(optionsSelectPtc);
    dispatch('reset', formFields);
  };
  const handleSubmit = () => {
    dispatch('submit', formFields);
  };

  const handleUpdatePaxList = (refItemsSelectPtc) => {
    let newPaxList = [];
    let index = 1;
    refItemsSelectPtc.forEach(({ value, key }) => {
      for (let i = 1; i <= value; i++) {
        newPaxList.push({
          id: index,
          Ptc: key,
          PaxID: `PAX${index}`,
          count: i,
        });
        index++;
      }
    });
    if (!isEqual(formFields.paxList, newPaxList)) {
      formFields.ffnList = {
        ADT: [],
        CHD: [],
        INF: [],
      };
    }
    formFields.paxList = newPaxList;
  };
  onMount(async () => {
    const listAirline = await getAgencyProviderAirlineCode();
    const Airline = listAirline?.data?.Response?.AgencyProvider[0]?.Airline || [];
    optionsAirlineCode = map(
      sortBy(Airline, (itAirline) => itAirline.AirlineCode),
      ({ AirlineCode }) => {
        return {
          id: AirlineCode,
          text: AirlineCode,
        };
      }
    );
  });

  $: dispatch('change', formFields);
  $: handleUpdatePaxList(itemsSelectPtc);
  $: if (!formFields.cabin) formFields.cabin = 'Y';
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<Form id="reservation-ticketing-condition">
  <div class="condition__form">
    <div>
      <FormGroup noMargin>
        <RadioButtonGroup bind:selected={formFields.flightWay.type} hideLegend>
          {#each TYPE_FLIGHT_WAY as { id, text, value } (id)}
            <RadioButton labelText={text} {value} />
          {/each}
        </RadioButtonGroup>
      </FormGroup>
    </div>
    <div class="condition__form-layout">
      <div class="condition__form-layout-input">
        <FlightSchedule
          bind:this={flightScheduleRef}
          type={formFields.flightWay.type}
          on:change={(event) => (formFields.flightWay.data = event.detail)}
        />

        <div class="condition__form-layout-container">
          <div class="condition__form-layout-input-container">
            <div class="combobox">
              <MultiSelectPtc bind:this={multiSelectPtcRef} bind:items={itemsSelectPtc} />
            </div>
            <ComboBox
              titleText="좌석등급"
              placeholder=""
              items={[
                { id: 'Y', text: '일반석' },
                { id: 'W', text: '프리미엄 일반석' },
                { id: 'C', text: '비즈니스석' },
                { id: 'F', text: '일등석' },
              ]}
              class="combobox"
              bind:selectedId={formFields.cabin}
            />
            <MultiSelect
              titleText="항공사"
              placeholder={`${formFields.airlineCode.length > 0 ? '검색' : '전체'}`}
              filterable
              items={optionsAirlineCode}
              bind:selectedIds={formFields.airlineCode}
            />
            <div class="condition__box-promotion-code">
              <!-- <MoreButton text="Promotion Code" /> -->
              <Checkbox labelText="직항" bind:checked={formFields.direct}>
                <p slot="labelText" style="font-size: 14px;" on:click|preventDefault>직항</p>
              </Checkbox>
            </div>
            <FormGroup noMargin>
              <PrimaryButton
                kind="secondary"
                on:click={(event) => {
                  event.preventDefault();
                  handleReset();
                }}>초기화</PrimaryButton
              >
              <PrimaryButton on:click={handleSubmit}>검색</PrimaryButton>
            </FormGroup>
          </div>

          <div
            class="condition__form-layout-ffn-code-container multi-way"
            class:g-visible-hidden={formFields.flightWay.type !== 'MULTI_WAY'}
          >
            <FfnPromotionCode
              bind:promotionCodeList={formFields.promotionCodeList}
              bind:ffnList={formFields.ffnList}
              bind:paxList={formFields.paxList}
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="condition__form-layout-ffn-code-container"
      class:g-visible-hidden={formFields.flightWay.type === 'MULTI_WAY'}
    >
      <FfnPromotionCode
        bind:promotionCodeList={formFields.promotionCodeList}
        bind:ffnList={formFields.ffnList}
        bind:paxList={formFields.paxList}
      />
    </div>
  </div>
</Form>
