<script>
  import { DataTable } from 'carbon-components-svelte';
  import { _ } from '../../../lib/i18n.js';
  export let data = [];
  const headers = [
    {
      key: 'CouponNumber',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.couponNumber', {
        default: '순번',
      }),
    },
    {
      key: 'AirlineID',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.airlineID', {
        default: '항공사',
      }),
    },
    {
      key: 'FlightNumber',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.flightNumber', {
        default: '편명',
      }),
    },
    {
      key: 'DepartureAirportCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.departureAirportCode', {
        default: '출발지',
      }),
    },
    {
      key: 'ArrivalAirportCode',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.arrivalAirportCode', {
        default: '도착지',
      }),
    },
    {
      key: 'DepartureDateTime',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.departureDateTime', {
        default: '출발일시',
      }),
    },
    {
      key: 'EMDdetails',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.emdDetails', {
        default: 'EMD상세',
      }),
    },
    {
      key: 'relatedTickets',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.relatedTickets', {
        default: '관련티켓',
      }),
    },
    {
      key: 'Status',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDA.status', {
        default: '쿠폰상태',
      }),
    },
  ];
</script>

<DataTable {headers} rows={data} />
