<script>
  import { join } from 'lodash';
  import { Trash } from 'src/assets/icons';
  import { EMPTY } from 'src/constants/app.js';
  import { formatDate } from 'src/utils/appHelper.js';

  export let tab;
  function getPnr(bookingReference) {
    let pnrs = [];
    if (!bookingReference) {
      return '';
    }
    for (let i = 0; i < bookingReference.length; i++) {
      const { AirlineID, Id, Type, OtherID } = bookingReference[i];
      if (Type === 'ASSOCIATED_BOOKING') continue;
      if (Type === 'THIRD_PARTY_REFERENCE' || !OtherID) pnrs = [`${Id}(${AirlineID})`, ...pnrs];
      if (OtherID) pnrs = [...pnrs, `${Id}(OTHER)`];
    }
    return join(pnrs, '/');
  }
</script>

<div class="tab-content-wrapper" id="cancel-request-order">
  <div class="wrapper-container">
    <div class="wrapper-trash">
      <div class="wrapper-trash__icon">
        <Trash />
      </div>
    </div>
    <div class="wrapper-info">
      <div class="wrapper-info-block">
        <div class="wrapper-info__pnr">PNR: {getPnr(tab.bookingReference)}</div>
        <div class="wrapper-info__modify">상태: {tab.orderStatus !== 'OK' ? '취소' : EMPTY}</div>
      </div>
      <div class="wrapper-info__check-time">처리일시: {formatDate(tab.cancelDate, 'datetime')}</div>
    </div>
  </div>
</div>
