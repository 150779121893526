<script>
  import { Tabs, Tab, TabContent } from 'carbon-components-svelte';
  import { filter, has, findIndex } from 'lodash';
  import { location, push } from 'svelte-spa-router';
  import { onMount, onDestroy } from 'svelte';
  import Header from 'src/components/layouts/Header/index.svelte';
  import { CloseIcon, ArrowUpIcon, SplitState, DotMark, RecordingFilled, DotMarkMiddle } from 'src/assets/icons';
  import { v4 as uuidv4 } from 'uuid';
  import { bookingService } from 'src/service';
  import { EMPTY } from 'src/constants/app.js';
  import { BackgroundPattern as srcBgHome, HeaderPage as stx } from 'src/assets/img';
  import { CancelPnrButton } from 'src/components/common/button';

  import {
    DEFAULT_TAB_WIDTH,
    TAB_WIDTH_MIDDLE,
    TAB_WIDTH_MIN,
    SPACE_TAB,
    TAB_COMPONENT,
  } from 'src/constants/reservationManagementReport.js';
  import { user, currentTab } from 'src/store';

  const typeMapping = {
    listOcn: '예약관리',
    listReservation: '예약관리',
    listDsr: 'DSR리스트',
    reservationTicketing: '예약/발권',
  };
  let selected = -1;
  let wordTitle = EMPTY;
  let isOnTop = false;
  let tabs = [];
  let innerWidth;
  let tabWidth = DEFAULT_TAB_WIDTH;
  let tabComponent = [];
  currentTab.subscribe((tab) => {
    if (tab.orderId) {
      const findIndexPnr = tabs.findIndex((elm) => elm?.orderId === tab.orderId);
      if (findIndexPnr > -1) {
        selected = findIndexPnr;
        handleReloadContentPnr();
      } else {
        // 48: pading left-right, 94: current tab-with (48 + 94 = 142)
        const tabsNotEmpty = tabs.filter((tab) => !tab.isDeleted);
        if (tabsNotEmpty.length * tabWidth > innerWidth - 142 && tabWidth === TAB_WIDTH_MIN) {
          const indexTabDel = tabs.findIndex((tab) => tab.orderId === tabsNotEmpty[0].orderId);
          if (indexTabDel > -1) {
            tabs[indexTabDel].isDeleted = true;
            tabs[indexTabDel].orderId = uuidv4();
          }
        }
        tabs = [...tabs, tab];
        selected = tabs.length - 1;
      }
      wordTitle = findIndexPnr > -1 ? tabs[findIndexPnr]?.tabName : tab.tabName;
      updateQueryStr(findIndexPnr > -1 ? tabs[findIndexPnr] : tab);
    }
  });

  function updateQueryStr(tabSelected) {
    const typeTabSelected = tabSelected ? tabSelected.type : 'reservationManagement';
    const newQueryString = `?type=${typeTabSelected || 'reservationManagement'}&orderId=${tabSelected.orderId}`;
    push($location + newQueryString);
  }

  const handleTabClose = (tab) => {
    let newSelected = findIndex(tabs, (tabsItem) => tabsItem.orderId === tab.orderId);
    tabs[newSelected].isDeleted = true;
    tabComponent[tabs[newSelected].orderId] = null;
    tabs[newSelected].orderId = uuidv4();
    const isTabsNotEmpty = tabs.some((tab) => !tab.isDeleted);
    if (!isTabsNotEmpty) {
      newSelected = -1;
    } else {
      while (tabs[newSelected].isDeleted && newSelected > 0) {
        newSelected = newSelected - 1;
      }
      while (tabs[newSelected].isDeleted) {
        newSelected = newSelected + 1;
      }
    }

    selected = newSelected;
    wordTitle = tabs.length && selected >= 0 ? tabs[selected]?.tabName : EMPTY;
    updateQueryStr(selected >= 0 ? tabs[selected] : EMPTY);
  };

  function onchangeTab(orderId) {
    const findCurrentTab = tabs.find((tab) => tab.orderId === orderId);
    if (findCurrentTab) wordTitle = findCurrentTab.tabName;
    updateQueryStr(findCurrentTab);
  }

  function handleActionVoidRefund() {
    const currenTab = tabs[selected];
    tabComponent[currenTab.orderId].handleActionVoidRefund(currenTab);
  }

  function handleReloadContentPnr() {
    const currenTab = tabs[selected];
    if (!['listOcn', 'listReservation', 'listDsr', 'reservationTicketing'].includes(currenTab?.type)) {
      tabComponent[currenTab?.orderId]?.reloadDataPnr(currenTab);
    }
  }

  function updateInfoTab(event) {
    const result = event.detail;

    const findIndex = tabs.findIndex((tab) => tab.orderId === result.orderId);
    if (findIndex > -1) {
      if (result?.child) {
        tabs[findIndex].pnrChild = result?.child;
      }
      if (result?.parent) {
        tabs[findIndex].pnrParent = result?.parent;
      }
      tabs[findIndex].tabName = result.pnrMain;
      tabs[findIndex].isPayLater = result.isPayLater;
    }
  }

  async function getInfoOrderId(orderId) {
    const resGetPnr = await bookingService.getManagementOrder({
      order_id: orderId,
    });
    const { OrderID, OrderStatus, CancelDate } = resGetPnr?.data?.Response?.MatchedOrder[0];
    let findB = resGetPnr?.data?.Response?.MatchedOrder[0].BookingReference.filter(
      (booking) => booking.AirlineID === 'LJ'
    );
    if (!findB.length) {
      findB = filter(
        resGetPnr?.data?.Response?.MatchedOrder[0].BookingReference,
        (itB) => !has(itB, 'OtherID') && !has(itB, 'Type')
      );
    }
    return {
      tabName: findB.length ? findB[0]?.Id : EMPTY,
      orderId: OrderID,
      airlineId: findB.length ? findB[0]?.AirlineID : EMPTY,
      type: 'reservationManagement',
      isDeleted: false,
      orderStatus: OrderStatus,
      cancelDate: CancelDate,
    };
  }

  async function openNewTab(tab) {
    const infoOrderIdOrderChange = await getInfoOrderId(tab.orderId);
    currentTab.set(infoOrderIdOrderChange);
  }

  async function addNewTab(event) {
    const { closeCurrentTab, orderId, tab } = event.detail;
    if (closeCurrentTab) handleTabClose(tab);
    const findOrderId = tabs.find((tab) => !tab.isDeleted && tab.orderId === orderId);
    if (!findOrderId && orderId) {
      const infoOrderIdOrderChange = await getInfoOrderId(orderId);
      currentTab.set(infoOrderIdOrderChange);
    }
  }
  function handlePopState(event) {
    if (event.state) {
      user.set('LOGOUT');
    }
  }
  function handleUpdateTabsOnCancelSuccess(data) {
    const tab = data.detail;
    const indexTab = tabs.findIndex((elm) => elm.orderId === tab.orderId);
    tabs[indexTab] = tab;
  }
  onMount(() => {
    window.addEventListener('popstate', handlePopState);
  });
  onDestroy(() => {
    window.removeEventListener('popstate', handlePopState);
  });
  $: if (innerWidth) {
    const tabsNotEmpty = tabs.filter((tab) => !tab.isDeleted);
    const MAX_TAB_DEFAULT = Math.floor((innerWidth - SPACE_TAB) / DEFAULT_TAB_WIDTH);
    const MAX_TAB_MIDDLE = Math.floor((innerWidth - SPACE_TAB) / TAB_WIDTH_MIDDLE);
    const MAX_TAB_MIN = Math.floor((innerWidth - SPACE_TAB) / TAB_WIDTH_MIN);

    let findTabWith = tabWidth;
    if (tabsNotEmpty.length <= MAX_TAB_DEFAULT) {
      findTabWith = DEFAULT_TAB_WIDTH;
    }
    if (MAX_TAB_DEFAULT < tabsNotEmpty.length && tabsNotEmpty.length <= MAX_TAB_MIDDLE) {
      findTabWith = TAB_WIDTH_MIDDLE;
    }
    if (MAX_TAB_MIDDLE < tabsNotEmpty.length && tabsNotEmpty.length <= MAX_TAB_MIN) {
      findTabWith = TAB_WIDTH_MIN;
    }
    tabWidth = findTabWith;
  }
</script>

<Header />

<div
  class="app-page-content"
  class:btn-arrow-top-active={isOnTop}
  class:isHiddenContent={!filter(tabs, (tab) => tab.isDeleted === false).length}
>
  <img src={stx} alt="background header" class="video-head-reservation" />
  <div class="app__info">
    <div class="info-wrapper">
      {#if ['listOcn', 'listReservation', 'listDsr', 'reservationTicketing'].includes(tabs[selected]?.type)}
        <div class="title" style="display: inherit">{typeMapping[tabs[selected]?.type]}</div>
      {:else}
        <div class="title">
          <span> PNR: </span>
          <button
            on:click={handleReloadContentPnr}
            class="g-button pnr"
            class:g-cursor-unset={tabs[selected]?.orderStatus.toLowerCase() === 'x'}
            disabled={tabs[selected]?.type === 'reservationManagement' &&
              tabs[selected]?.orderStatus.toLowerCase() === 'x'}
          >
            {wordTitle}
          </button>
          {#if tabs[selected]?.pnrParent?.length || tabs[selected]?.pnrChild?.length}
            <p class="parent-child-pnr">
              {#if tabs[selected]?.pnrParent?.length}
                {#each tabs[selected]?.pnrParent as item}
                  <button class="g-button item" on:click={() => openNewTab(item)}>{item.tabName}</button>
                {/each}
                <SplitState />
              {/if}
              {#if wordTitle.length}
                <button class="g-button item selected" on:click={handleReloadContentPnr}>{wordTitle}</button>
              {/if}
              {#if tabs[selected]?.pnrChild?.length}
                <SplitState />
                {#each tabs[selected]?.pnrChild as item}
                  <button class="g-button item" on:click={() => openNewTab(item)}>{item.tabName}</button>
                {/each}
              {/if}
            </p>
          {/if}
        </div>
        {#if tabs[selected]?.type === 'reservationManagement' && tabs[selected].orderStatus.toLowerCase() !== 'x' && $currentTab.airlineId !== 'LJ'}
          {#if has(tabs[selected], 'isPayLater') && tabs[selected]['isPayLater'] !== EMPTY}
            {#if tabs[selected]?.isPayLater === true}
              <CancelPnrButton pnr={tabs[selected]?.tabName} orderId={tabs[selected].orderId} />
            {:else}
              <button class="g-button button" on:click={handleActionVoidRefund}>void/refund</button>
            {/if}
          {/if}
        {/if}
      {/if}
    </div>
  </div>
  <div class="view-content-table">
    <button class="btn-arrow-top" on:click={() => (isOnTop = !isOnTop)}>
      <ArrowUpIcon />
    </button>
    <Tabs type="container" bind:selected>
      {#each tabs as tab, index}
        <Tab
          style="--app-tab-width:{tabWidth}px"
          class={tab.isDeleted ? 'tabs-container none-tab' : 'tabs-container'}
          key={index}
          on:click={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onchangeTab(tab.orderId);
          }}
        >
          <div class="tab-navigate">
            {#if tab?.pnrParent && tab?.pnrChild}
              <DotMarkMiddle />
            {:else if tab?.pnrParent}
              <DotMark />
            {:else if tab?.pnrChild}
              <RecordingFilled />
            {/if}
            <div class="tab-name">
              {tab.tabName}
              {#if tab.orderStatus.toLowerCase() === 'x'}
                (취소)
              {/if}
            </div>
            {#if tabs.length && index === selected}
              <button class="btn-close" on:click|preventDefault|stopPropagation={() => handleTabClose(tab)}>
                <CloseIcon />
              </button>
            {/if}
          </div>
        </Tab>
      {/each}
      <svelte:fragment slot="content">
        {#each tabs as tab, index}
          <TabContent key={index} class={tab.isDeleted && 'none-tab'}>
            <svelte:component
              this={TAB_COMPONENT[tab.type]}
              bind:this={tabComponent[tab.orderId]}
              orderId={tab.orderId}
              {tab}
              on:add-tab={addNewTab}
              on:update-info-tab={updateInfoTab}
              on:primary-modal-cancellation-success={handleUpdateTabsOnCancelSuccess}
            />
          </TabContent>
        {/each}
      </svelte:fragment>
    </Tabs>
  </div>
</div>

<div style="background-image: url({srcBgHome});" class="app-bg-home" />
<svelte:window bind:innerWidth />
