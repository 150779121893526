<script>
  import { TextInput } from 'carbon-components-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { EMPTY, InputOptions, InputOptionsInvalid } from 'src/constants/app';

  export let options = [];
  export let invalidOptions = []; //{condition,notify}
  export let value;
  export let ignoreRmSpace = false;

  const dispatch = createEventDispatcher();
  let notify = EMPTY;
  let prevValue = EMPTY;
  let ref;

  const PATTERN_KOREAN = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  const PATTERN_ORDER_ID = /[^a-zA-Z0-9-]/g;

  const handleInput = (event) => {
    prevValue = value;
    if (!ignoreRmSpace) value = event.target.value.replace(/\s/g, EMPTY);
    if (PATTERN_KOREAN.test(value)) {
      value = value.replace(PATTERN_KOREAN, EMPTY);
      event.target.blur();
      return;
    }
    options.forEach((flag) => {
      switch (flag) {
        case InputOptions.RM_KOREAN:
          if (PATTERN_KOREAN.test(value)) {
            value = value.replace(PATTERN_KOREAN, EMPTY);
            event.target.blur();
          }
          break;
        case InputOptions.ORDER_ID:
          value = value.replace(PATTERN_ORDER_ID, EMPTY);
          break;
        case InputOptions.ONLY_LATIN_NUMBER:
          value = value.replace(/[^a-zA-Z0-9]/g, EMPTY);
          break;
        case InputOptions.ONLY_NUMBER:
          value = value.replace(/[^0-9]/g, EMPTY);
          break;
        case InputOptions.ONLY_LATIN:
          value = value.replace(/[^a-zA-Z]/g, EMPTY);
          break;
        case InputOptions.UPPER_CASE:
          value = value.toUpperCase();
          break;
        case InputOptions.LOWER_CASE:
          value = value.toLowerCase();
          break;
        default:
          break;
      }
    });
    dispatch('input', value);
  };
  const handleCheckInvalid = (_text) => {
    const text = _text;
    notify = EMPTY;
    invalidOptions.forEach((item) => {
      switch (item.condition) {
        case InputOptionsInvalid.MATCH_KOREAN:
          if (PATTERN_KOREAN.test(text)) notify = item.notify;
          break;
        default:
          break;
      }
    });
  };
  onMount(() => {
    ref.addEventListener('input', handleInput);
    return () => ref.removeEventListener('input', handleInput);
  });
  $: handleCheckInvalid(prevValue);
</script>

<TextInput bind:ref bind:value invalid={notify !== EMPTY} invalidText={notify} {...$$props} />
