<script>
  import {
    Form,
    FormGroup,
    Button,
    DataTable,
    DatePicker,
    DatePickerInput,
    ComboBox,
    Pagination,
    Toolbar,
    ToolbarContent,
    MultiSelect,
    Checkbox,
  } from 'carbon-components-svelte';
  import Send from 'carbon-icons-svelte/lib/Send.svelte';
  import Download from 'carbon-icons-svelte/lib/Download.svelte';
  import { onMount } from 'svelte';
  import { AddAlt, SubtractAlt } from 'carbon-icons-svelte';
  import { sortBy, map, cloneDeep, find } from 'lodash';
  import { message } from 'src/store';
  import { getDateTimeNow } from 'src/utils/DateTime.js';
  import { getAgencyProviderAirlineCode } from 'src/service/reservation.js';
  import { ocnService } from 'src/service';
  import { EMPTY, PAGE_TABLE_SIZES_OCN_BOOKING, InputOptions, InputOptionsInvalid } from 'src/constants/app.js';
  import { bookingReference } from 'src/store';
  import {
    formatQueryParams,
    exportExcelFileName,
    standardObj,
    formatDate,
    getMainBookingReference,
    getOtherPnrByBookingReference,
  } from 'src/utils/appHelper.js';
  import { _ } from 'src/lib/i18n.js';
  import { CustomLoading } from 'src/components/common/loading';
  import { TextOverflowTooltip } from 'src/components/common/tooltip';
  import { CustomTextInput } from 'src/components/common/input';

  const INIT_FORM_OPTION = {
    send_effective: EMPTY,
    send_expiration: EMPTY,
    receive_effective: getDateTimeNow(),
    receive_expiration: getDateTimeNow(),
    airline_id: [],
    booking_reference: EMPTY,
    order_id: EMPTY,
    ocn_type: [],
    ack_from_agency: EMPTY,
  };

  const listAckFromAgency = [
    { id: 0, text: 'Y' },
    { id: 1, text: 'N' },
  ];

  const listOcnType = [
    {
      id: 0,
      text: 'Change',
    },
    {
      id: 1,
      text: 'Cancel',
    },
    {
      id: 2,
      text: 'etc',
    },
  ];

  const headers = [
    {
      key: 'Action',
      value: EMPTY,
    },
    {
      key: 'airlineDateTime',
      value: $_('layout.pages.listOcn.headerTable.airlineDateTime', {
        default: '항공사 OCN 수신일시',
      }),
    },
    {
      key: 'travelDateTime',
      value: $_('layout.pages.listOcn.headerTable.travelDateTime', {
        default: '여행사 OCN 수신일시',
      }),
    },
    {
      key: 'airline',
      value: $_('layout.pages.listOcn.headerTable.airline', {
        default: '항공사',
      }),
    },
    {
      key: 'typeOcn',
      value: $_('layout.pages.listOcn.headerTable.typeOcn', {
        default: 'OCN유형',
      }),
    },
    {
      key: 'pnr',
      value: $_('layout.pages.listOcn.headerTable.pnr', {
        default: 'PNR',
      }),
    },
    {
      key: 'otherPnr',
      value: $_('layout.pages.listOcn.headerTable.otherPnr', {
        default: 'Other PNR',
      }),
    },
    {
      key: 'orderID',
      value: $_('layout.pages.listOcn.headerTable.orderID', {
        default: 'OrderID',
      }),
    },
    {
      key: 'receiveOcn',
      value: $_('layout.pages.listOcn.headerTable.receiveOcn', {
        default: 'OCN수신여부',
      }),
    },
  ];

  const checkDateTime = ['receive_effective', 'receive_expiration', 'send_effective', 'send_expiration'];
  const textInputValidOptions = [
    {
      condition: InputOptionsInvalid.MATCH_KOREAN,
      notify: '*한글은 입력할 수 없습니다',
    },
  ];
  let dataTable = [];
  let formOption = { ...INIT_FORM_OPTION };
  let airlineCode = [];
  let hideToggleDateTime = true;
  let pageSize = PAGE_TABLE_SIZES_OCN_BOOKING[0];
  let page = 1;
  let selectedRowIds = [];
  let selectedIdsAirlineId = [];
  let selectedOcnType = [];
  let selectedAckFromAgency;
  let isLoading = false;
  let invalidWarnInput = {
    booking_reference: false,
    order_id: false,
  };

  function enableCheckBox(row) {
    const { airline, actionType, context, receiveOcn } = row;
    const checkAirlineTypeOne = ['LH', 'LX', 'LXA', 'OS', 'AA', 'EK', 'HA', 'HAA'];
    const checkAirlineTypeTwo = ['SQ', 'QR'];

    if (receiveOcn === 'N') {
      if (!checkAirlineTypeOne.includes(airline) && !checkAirlineTypeTwo.includes(airline)) return true;
      else if (checkAirlineTypeOne.includes(airline) && actionType === 'ASC') return true;
      else if (checkAirlineTypeTwo.includes(airline) && context !== '9') return true;
      return false;
    }
    return false;
  }
  const handleSelectRowTable = (e, row) => {
    const status = e.detail;
    const id = row.id;
    if (status) {
      if (selectedRowIds.length === 0) {
        selectedRowIds = [id];
      }
    } else {
      selectedRowIds = [];
    }
  };

  const handleGetDataTable = async () => {
    isLoading = true;
    dataTable = [];
    const params = formatQueryParams(formOption, checkDateTime);
    const res = await ocnService.getOcnData(params);
    isLoading = false;
    const MatchedOcn = res?.data?.Response?.MatchedOcn || [];
    dataTable = sortBy(
      map(MatchedOcn, (it, key) => {
        const {
          OcnReceiveDateTime,
          OcnSendDateTime,
          Type,
          BookingReference,
          OrderID,
          IsAckFromAgency,
          OcnID,
          Amendment,
        } = it;
        const findB = getMainBookingReference(BookingReference);
        return standardObj({
          id: key,
          airlineDateTime: OcnReceiveDateTime,
          travelDateTime: OcnSendDateTime,
          airline: findB?.AirlineID,
          typeOcn: Type,
          pnr: findB?.Id,
          otherPnr: getOtherPnrByBookingReference(BookingReference),
          orderID: OrderID,
          ocnId: OcnID,
          receiveOcn: IsAckFromAgency,
          actionType: Amendment[0]?.ActionType || EMPTY,
          context: Amendment[0]?.Context || EMPTY,
        });
      }),
      (it) => it.airlineDateTime
    ).reverse();
    dataTable = map(dataTable, (dataTableItem) => {
      dataTableItem.airlineDateTime = formatDate(dataTableItem.airlineDateTime, 'datetime');
      dataTableItem.travelDateTime = formatDate(dataTableItem.travelDateTime, 'datetime');
      return dataTableItem;
    });
    if (!dataTable.length) {
      message.update(() => [
        {
          subtitle: '검색결과가 없습니다',
          caption: EMPTY,
        },
      ]);
      return;
    }
    selectedRowIds = [];
  };

  const handleResetData = async () => {
    selectedIdsAirlineId = [];
    selectedOcnType = [];
    selectedAckFromAgency = undefined;
    formOption = cloneDeep(INIT_FORM_OPTION);
    hideToggleDateTime = true;
  };

  const handleClickOderId = (row) => {
    bookingReference.set({
      booking_reference: row.pnr,
      order_id: row.orderID,
    });
  };
  const handleClickOcnManualSend = async () => {
    const { orderID, ocnId } = find(dataTable, (dataTableItem) => selectedRowIds.includes(dataTableItem.id));
    const res = await ocnService.actionOcnManualSend(
      formatQueryParams(
        {
          order_id: orderID,
          ocn_id: ocnId,
        },
        []
      )
    );

    if (res && res.code === 200) {
      if (res.data.ResultCode === 'SUCCESS' && res.data.ResultMessage === 'SUCCESS') {
        message.set([
          {
            title: 'API 호출',
            type: 'success',
            subtitle: 'OCN수동발송이 완료되었습니다',
            caption: EMPTY,
          },
        ]);
      }
      if (res.data.ResultCode === 'ERROR') {
        message.set([
          {
            title: 'API 호출',
            type: 'error',
            subtitle: 'OCN 수동발송에 실패했습니다. 다시 시도해주세요',
            caption: EMPTY,
          },
        ]);
      }
    }
    selectedRowIds = [];
    await handleGetDataTable();
  };

  async function handleClickExportExcel() {
    const params = formatQueryParams(formOption, checkDateTime);
    const dataExcel = await ocnService.exportExcelOcn(params);
    exportExcelFileName(dataExcel, 'OCN리스트');
  }

  $: {
    const matchedOcnType = [];
    for (const item of listOcnType) {
      if (selectedOcnType.includes(item.id)) {
        matchedOcnType.push(item.text);
      }
    }
    const matchedAirlineCode = [];
    for (const item of airlineCode) {
      if (selectedIdsAirlineId.includes(item.id)) {
        matchedAirlineCode.push(item.text);
      }
    }
    formOption = {
      ...formOption,
      ack_from_agency: selectedAckFromAgency !== undefined ? listAckFromAgency[selectedAckFromAgency].text : EMPTY,
      ocn_type: matchedOcnType,
      airline_id: matchedAirlineCode,
    };
  }

  onMount(async () => {
    const listAirline = await getAgencyProviderAirlineCode();
    const Airline = listAirline?.data?.Response?.AgencyProvider[0]?.Airline || [];
    const airlineCodeApi = map(
      sortBy(Airline, (itAirline) => itAirline.AirlineCode),
      (it, index) => {
        const { AirlineCode } = it;
        return {
          id: index + 1,
          text: AirlineCode,
        };
      }
    );
    airlineCode = [...airlineCodeApi];
  });
</script>

<div id="ocn-content-wrapper">
  <Form class="form-wrapper">
    <FormGroup legendText="">
      <DatePicker
        datePickerType="range"
        bind:valueFrom={formOption.receive_effective}
        bind:valueTo={formOption.receive_expiration}
        dateFormat="d/m/Y"
      >
        <DatePickerInput labelText="항공사 OCN수신일" placeholder="dd/mm/yyyy" />
        <DatePickerInput labelText=" " placeholder="dd/mm/yyyy" />
      </DatePicker>
    </FormGroup>
    <FormGroup class="input-wrapper">
      <div class="ocn--w-160">
        <MultiSelect
          filterable
          titleText="항공사"
          label="전체"
          placeholder="전체"
          items={airlineCode}
          sortItem={(a, b) => a.id - b.id}
          bind:selectedIds={selectedIdsAirlineId}
        />
      </div>
      <div class="ocn--w-150">
        <CustomTextInput
          labelText="PNR"
          placeholder=""
          bind:value={formOption.booking_reference}
          options={[InputOptions.ONLY_LATIN_NUMBER]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <div class="ocn--w-160">
        <CustomTextInput
          labelText="OrderID"
          placeholder=""
          bind:value={formOption.order_id}
          options={[InputOptions.PATTERN_ORDER_ID]}
          invalidOptions={textInputValidOptions}
        />
      </div>
      <div class="ocn--w-160">
        <MultiSelect
          filterable
          titleText="OCN유형"
          label="전체"
          placeholder="전체"
          bind:selectedIds={selectedOcnType}
          sortItem={(a, b) => a.id - b.id}
          items={listOcnType}
        />
      </div>
      <ComboBox
        class="ocn--w-160"
        titleText="OCN수신여부"
        placeholder="전체"
        bind:selectedId={selectedAckFromAgency}
        items={listAckFromAgency}
      />
    </FormGroup>
    <FormGroup class="form-action">
      <Button
        class="action__btn"
        on:click={() => {
          selectedRowIds = [];
          handleGetDataTable();
        }}
      >
        {$_('layout.pages.listOcn.btnGetData', {
          default: '검색',
        })}
      </Button>
      <Button kind="secondary" class="action__btn" on:click={handleResetData}>
        {$_('layout.pages.listOcn.btnResetFilter', {
          default: '초기화',
        })}
      </Button>
    </FormGroup>
    <FormGroup class="form-toggle--datetime">
      {#if !hideToggleDateTime}
        <DatePicker
          datePickerType="range"
          dateFormat="d/m/Y"
          bind:valueFrom={formOption.send_effective}
          bind:valueTo={formOption.send_expiration}
        >
          <DatePickerInput placeholder="dd/mm/yyyy" labelText="여행사 OCN수신일 " />
          <DatePickerInput placeholder="dd/mm/yyyy" labelText=" " />
        </DatePicker>
      {/if}
      <div class:btn-toggle-wrapper={!hideToggleDateTime}>
        {#if hideToggleDateTime}
          <Button
            kind="ghost"
            class="btn-toggle"
            on:click={(e) => {
              hideToggleDateTime = false;
              formOption = { ...formOption, send_effective: getDateTimeNow(), send_expiration: getDateTimeNow() };
            }}
          >
            <AddAlt />
          </Button>
        {:else}
          <Button
            kind="ghost"
            class="btn-toggle"
            on:click={(e) => {
              hideToggleDateTime = true;
              formOption = { ...formOption, send_effective: EMPTY, send_expiration: EMPTY };
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SubtractAlt />
          </Button>
        {/if}
      </div>
    </FormGroup>
  </Form>
  <div class="ocn-content-table">
    {#key dataTable}
      <DataTable {headers} rows={dataTable} {pageSize} {page} {selectedRowIds} stickyHeader id="ocn_content">
        <svelte:fragment slot="cell" let:cell let:row>
          {#if cell.key === 'orderID'}
            <TextOverflowTooltip
              text={cell.value}
              on:click={() => handleClickOderId(row)}
              class="g-button color-outstand-cell p-b-14"
            >
              {cell.value}
            </TextOverflowTooltip>
          {:else if cell.key === 'Action'}
            <div class:pointer-none={selectedRowIds.length && !selectedRowIds.includes(row.id)}>
              {#key row}
                <Checkbox
                  class="checkbox"
                  disabled={!enableCheckBox(row)}
                  on:check={(e) => handleSelectRowTable(e, row)}
                  on:click={() => {
                    if (selectedRowIds.length === 1 && !selectedRowIds.includes(row.id)) {
                      message.update(() => [
                        {
                          title: 'Error',
                          subtitle: 'OCN수동발송은 한번에 한 개의 예약건만 선택 가능합니다',
                          caption: EMPTY,
                        },
                      ]);
                    }
                  }}
                />
              {/key}
            </div>
          {:else}
            <TextOverflowTooltip text={cell.value}>
              {cell.value}
            </TextOverflowTooltip>
          {/if}
        </svelte:fragment>
        <Toolbar class="table-toolbar">
          <ToolbarContent>
            <div class="title-table">
              <h4 class="title-table-content">
                {$_('layout.pages.listOcn.titleTable', {
                  default: '검색결과',
                })}
              </h4>
            </div>
            <Button
              size="small"
              kind="secondary"
              class="custom-button-download"
              disabled={!selectedRowIds.length}
              on:click={handleClickOcnManualSend}
            >
              <span style="margin-right: 4px;">
                {$_('layout.pages.listOcn.btnOcnManualSending', {
                  default: 'OCN 수동발송',
                })}
              </span>
              <Send />
            </Button>
            <Button
              size="small"
              kind="secondary"
              class="custom-button-download ocn--w-160 ocn--ml-1"
              disabled={!dataTable.length}
              on:click={handleClickExportExcel}
            >
              <span>
                {$_('layout.pages.listOcn.btnDownload', {
                  default: '엑셀 다운로드',
                })}
              </span>
              <span>
                <Download />
              </span>
            </Button>
          </ToolbarContent>
        </Toolbar>
      </DataTable>
    {/key}
    {#if dataTable?.length}
      <Pagination
        pageSizes={PAGE_TABLE_SIZES_OCN_BOOKING}
        bind:page
        totalItems={dataTable.length}
        bind:pageSize
        itemsPerPageText=""
      />
    {/if}
    {#if isLoading}
      <div class="loading-booking_ocn">
        <CustomLoading />
      </div>
    {/if}
  </div>
</div>
