<script>
  import { DataTable, ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-svelte';
  import { has, filter } from 'lodash';
  import { EMPTY } from 'src/constants/app.js';
  import { createEmptyArray } from 'src/utils/appHelper.js';
  import { PrimaryButton } from 'src/components/common/button';
  import { createEventDispatcher } from 'svelte';
  import Portal from 'src/components/common/Portal.svelte';

  import 'src/styles/modal/rule-covered-services-modal.scss';

  export let open;
  export let headers = [];
  export let rows = [];
  export let title = '규정 및 포함 서비스';

  const dispatch = createEventDispatcher();
  let count = {
    change: 0,
    cancel: 0,
    refund: 0,
    lj: 0,
    noShow: 0,
  };

  const countData = (obj) => {
    let count = 0;
    for (const key in obj) {
      if (typeof obj[key] === 'object' && Array.isArray(obj[key])) {
        count += obj[key].length;
      }
    }
    return count;
  };
  $: {
    for (const key in rows) {
      if (key === '0') {
        const obj = rows[0];
        for (const key in obj) {
          if (key.startsWith('COL_')) {
            const { change, cancel, refund, lj } = obj[key];
            const countChange = change ? countData(change) : 0;
            const countCancel = cancel ? countData(cancel) : 0;
            const countRefund = refund ? countData(refund) : 0;
            const countLj = lj ? countData(lj) : 0;
            count = {
              change: countChange > count.change ? countChange : count.change,
              cancel: countCancel > count.cancel ? countCancel : count.cancel,
              refund: countRefund > count.refund ? countRefund : count.refund,
              lj: countLj > count.lj ? countLj : count.lj,
            };
          }
        }
      }
    }
    const rowCheckEmptyAll = filter(rows, (row) => ['ROW_2', 'ROW_3'].includes(row.id));
    for (const row of rowCheckEmptyAll) {
      let checkEmpty = true;
      for (const key in row) {
        if (key.startsWith('COL_') && row[key].length) {
          checkEmpty = false;
          break;
        }
      }
      if (checkEmpty === true) {
        rows = rows.filter((item) => item.id !== row.id);
      }
    }
  }
  const handleCloseModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch('close');
    open = false;
  };
</script>

<Portal idBox="box-modal-children">
  <div id="RuleCoveredServicesModal">
    <ComposedModal bind:open preventCloseOnClickOutside={true} on:close={handleCloseModal} size="lg">
      <ModalHeader>
        <h4>{title}</h4>
      </ModalHeader>
      <ModalBody>
        <DataTable {headers} {rows} id="master-table">
          <div slot="cell-header" let:header>
            <div>
              {#each header.value?.label as txt}
                <h2>
                  {txt || EMPTY}
                </h2>
              {/each}
              <ul>
                {#each header.value.subLabel as txt}
                  <li class:hidden={txt?.length === 0}>
                    {txt || EMPTY}
                  </li>
                {/each}
              </ul>
            </div>
          </div>

          <div slot="cell" let:cell let:row>
            {#if cell.key === 'LABEL'}
              <div>
                <h2>
                  {cell.value}
                </h2>
              </div>
            {:else if row.id === 'ROW_1'}
              <div class="p-8px">
                {#if has(cell.value, 'change')}
                  <h2 class="mb-8px">
                    {cell.value.change.label}
                  </h2>
                  <ul>
                    {#each cell.value.change.data as txt}
                      <li>
                        {txt || EMPTY}
                      </li>
                    {/each}
                    {#each createEmptyArray(count.change - cell.value.change.data.length) as txt}
                      <li class="hidden">
                        {txt}
                      </li>
                    {/each}
                  </ul>
                {/if}

                {#if has(cell.value, 'cancel')}
                  <hr />
                  <h2 class="mb-8px">
                    {cell.value.cancel.label}
                  </h2>
                  <ul>
                    {#each cell.value.cancel.data as txt}
                      <li>
                        {txt || EMPTY}
                      </li>
                    {/each}
                    {#each createEmptyArray(count.cancel - cell.value.cancel.data.length) as txt}
                      <li class="hidden">
                        {txt}
                      </li>
                    {/each}
                  </ul>
                {/if}
                {#if has(cell.value, 'noShow')}
                  <hr />
                  <h2 class="mb-8px">
                    {cell.value.noShow.label}
                  </h2>
                  <ul>
                    {#each cell.value.noShow.data as txt}
                      <li>
                        {txt || EMPTY}
                      </li>
                    {/each}
                    {#each createEmptyArray(count.noShow - cell.value.noShow.data.length) as txt}
                      <li class="hidden">
                        {txt}
                      </li>
                    {/each}
                  </ul>
                {/if}
                {#if has(cell.value, 'lj')}
                  <hr />
                  <h2 class="mb-8px">
                    {cell.value.lj.label}
                  </h2>
                  <ul>
                    {#each cell.value.lj.data as txt}
                      <li>
                        {txt || EMPTY}
                      </li>
                    {/each}
                    {#each createEmptyArray(count.lj - cell.value.lj.data.length) as txt}
                      <li class="hidden">
                        {txt}
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            {:else if ['ROW_2', 'ROW_3'].includes(row.id)}
              <div class="p-8px">
                <ul>
                  {#each cell.value as txt}
                    <li>{txt || EMPTY}</li>
                  {/each}
                </ul>
              </div>
            {:else}
              <div class="p-8px">
                <ul>
                  {#each cell.value as txt}
                    <li class:hidden={!txt?.length}>
                      {txt || EMPTY}
                    </li>
                  {/each}
                </ul>
              </div>
            {/if}
          </div>
        </DataTable>
      </ModalBody>
      <ModalFooter>
        <footer>
          <div class="check-button">
            <PrimaryButton kind="secondary" width="100%" on:click={handleCloseModal}>확인</PrimaryButton>
          </div>
        </footer>
      </ModalFooter>
    </ComposedModal>
  </div>
</Portal>
