<script>
  import Information from 'carbon-icons-svelte/lib/Information.svelte';
  import { tooltip } from 'svooltip';
  import 'svooltip/styles.css'; // In
  import 'src/styles/custom-tooltip.scss';
  export let align = 'start';
  export let direction = 'top';

  let html;
</script>

<div
  class="btn"
  use:tooltip={{
    content: `${html}`,
    html: true,
    placement: `${direction}-${align}`,
    delay: 0,
  }}
>
  <slot name="btn">
    <Information />
  </slot>
</div>

<div class="content-trick" bind:innerHTML={html} contenteditable="true">
  <p><slot name="content" /></p>
</div>

<style>
  .content-trick {
    display: none;
  }
  p {
    min-width: 180px;
  }
</style>
