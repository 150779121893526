<script>
  import { CustomTextInput } from 'src/components/common/input';
  import { AddAlt, SubtractAlt } from 'carbon-icons-svelte';
  import {
    ComposedModal,
    Form,
    FormGroup,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
  } from 'carbon-components-svelte';
  import { filter, isEmpty } from 'lodash';
  import { v4 as uuidv4 } from 'uuid';
  import { EMPTY, PROMOTION_PROGRAM_AIRLINES, PROMOTION_SPECIAL_FARE_AIRLINES, InputOptions } from 'src/constants/app';
  import CustomComboBox from 'src/components/common/combobox/CustomComboBox.svelte';
  import { message } from 'src/store';

  export let open = false;
  export let promotionCodeList = {
    special: [],
    program: [],
  };

  let specialFares = [];
  let programList = [];
  let disabledSave = false;
  let childComboboxFare = [];
  let childComboboxProgram = [];

  const TYPE = { FARE: '0', PRO: '1' };
  function addNewItem(type) {
    const id = uuidv4();
    let newItem = { id, airlineId: EMPTY, accountId: EMPTY };
    if (type === TYPE.PRO) programList = [{ contractId: EMPTY, typeCode: EMPTY, ...newItem }, ...programList];
    else specialFares = [newItem, ...specialFares];
  }

  function handleFormatInputValue(e, key, item, type, id) {
    let value = e?.detail || EMPTY;
    if (type === TYPE.FARE) {
      specialFares = specialFares.map((el) => {
        if (el.id === item.id) return { ...el, [key]: value };
        return el;
      });
    } else {
      programList = programList.map((el) => {
        if (key == 'accountId' && id === el.id) {
          return { ...el, [key]: value };
        }
        if (el.airlineId === item.airlineId && key !== 'accountId') return { ...el, [key]: value };
        return el;
      });
    }
  }

  function openPromotionCodeModal() {
    specialFares = promotionCodeList.special;
    programList = promotionCodeList.program;
  }

  function savePromotionCode() {
    promotionCodeList.special = specialFares;
    promotionCodeList.program = programList;
    open = false;

    message.set([
      {
        type: 'info',
        title: 'Success',
        subtitle: 'Promotion Code가 입력되었습니다',
      },
    ]);
  }

  $: {
    const validatedSpecialFares = specialFares.every((el) => !isEmpty(el.airlineId) && !isEmpty(el.accountId));
    const validatedProgram = programList.every((el) => !isEmpty(el.airlineId) && !isEmpty(el.accountId));
    disabledSave =
      (specialFares.length === 0 && programList.length === 0) || !validatedSpecialFares || !validatedProgram;
  }
</script>

<ComposedModal
  bind:open
  id="modal-pc"
  primaryButtonText="입력"
  preventCloseOnClickOutside={true}
  on:open={openPromotionCodeModal}
>
  <ModalHeader title="Promotion Code" />
  <ModalBody>
    <div class="modal-pc__container">
      <div class="modal-pc__item">
        <div class="modal-pc__item--header">
          <p>Special Fare</p>
          <button id="default-btn" type="button" on:click={() => addNewItem(TYPE.FARE)}><AddAlt /></button>
        </div>
        <div
          class="modal-pc__item--content"
          on:scroll={() => {
            childComboboxFare.forEach((child) => {
              child?.closeListBox();
            });
          }}
        >
          {#each specialFares as specialFare, i (specialFare.id)}
            <Form class="form-wrapper">
              <FormGroup class="form-group-pc">
                <div>
                  <CustomComboBox
                    bind:this={childComboboxFare[i]}
                    titleText="Airline ID"
                    placeholder="Select"
                    bind:selectedId={specialFare.airlineId}
                    items={PROMOTION_SPECIAL_FARE_AIRLINES}
                  />
                </div>
                <div>
                  <CustomTextInput
                    labelText="Account ID"
                    bind:value={specialFare.accountId}
                    placeholder="Account ID"
                    options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
                    on:input={(e) => handleFormatInputValue(e, 'accountId', specialFare, TYPE.FARE)}
                    maxLength={15}
                  />
                </div>
                <button
                  id="default-btn"
                  type="button"
                  on:click={() => (specialFares = filter(specialFares, (item) => item.id !== specialFare.id))}
                  ><SubtractAlt /></button
                >
              </FormGroup>
            </Form>
          {/each}
        </div>
      </div>
      <div class="modal-pc__item">
        <div class="modal-pc__item--header">
          <p>Program</p>
          <button id="default-btn" type="button" on:click={() => addNewItem(TYPE.PRO)}><AddAlt /></button>
        </div>
        <div
          class="modal-pc__item--content"
          on:scroll={() => {
            childComboboxProgram.forEach((child) => {
              child?.closeListBox();
            });
          }}
        >
          {#each programList as program, i (program.id)}
            <Form class="form-wrapper">
              <FormGroup class="form-group-pc">
                <div>
                  <CustomComboBox
                    bind:this={childComboboxProgram[i]}
                    titleText="Airline ID"
                    placeholder="Select"
                    bind:selectedId={program.airlineId}
                    items={PROMOTION_PROGRAM_AIRLINES}
                  />
                </div>
                <div>
                  <CustomTextInput
                    labelText="Contract ID"
                    bind:value={program.contractId}
                    placeholder="선택사항"
                    options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
                    on:input={(e) => handleFormatInputValue(e, 'contractId', program, TYPE.PRO)}
                  />
                </div>
                <div>
                  <CustomTextInput
                    labelText="Type Code"
                    bind:value={program.typeCode}
                    placeholder="선택사항"
                    options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
                    on:input={(e) => handleFormatInputValue(e, 'typeCode', program, TYPE.PRO)}
                  />
                </div>
                <div>
                  <CustomTextInput
                    labelText="Account ID"
                    bind:value={program.accountId}
                    placeholder="Account ID"
                    options={[InputOptions.ONLY_LATIN_NUMBER, InputOptions.UPPER_CASE]}
                    on:input={(e) => handleFormatInputValue(e, 'accountId', program, TYPE.PRO, program.id)}
                    maxLength={15}
                  />
                </div>
                <button
                  id="default-btn"
                  type="button"
                  on:click={() => (programList = filter(programList, (item) => item.id !== program.id))}
                  ><SubtractAlt /></button
                >
              </FormGroup>
            </Form>
          {/each}
        </div>
      </div>
    </div>
  </ModalBody>

  <ModalFooter>
    <footer>
      <Button kind="secondary" on:click={() => (open = false)}>닫기</Button>
      <Button kind="primary" disabled={disabledSave} on:click={savePromotionCode}>입력</Button>
    </footer>
  </ModalFooter>
</ComposedModal>
