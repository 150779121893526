import request from './request.js';
import { generateUUID, getTransactionId } from '../utils/requestHelper.js';
import { user } from 'src/store';
import { get } from 'svelte/store';

const agencyID = get(user)?.agencyId;

export const getOrderRetrieve = async (params) => {
  const transactionID = generateUUID();
  sessionStorage.setItem('transactionID', transactionID);
  try {
    const response = await request({
      url: '/v1/ndc/order/retrieve',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
            AgencyName: 'string',
            ContactInfoRefID: [],
          },
        },
        PointOfSale: 'KR',
        TransactionID: transactionID,
        Query: {
          OrderID: params.order_id,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAgencyProviderAirlineCode = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/agency/provider',
      method: 'GET',
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postOrderReshop = async (query) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/reshop',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOrderCancel = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/cancel',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: {
          OrderID: params.orderId,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const actionOrderChangeDivide = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/change',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
            ContactInfoRefID: [],
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: {
          OrderID: params.orderId,
          ReasonCode: 'DIV',
          ChangeOrderChoice: {
            UpdatePax: params.newPaxRefIds,
          },
          PaxList: params.paxList,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const actionOrderChangeScheduleChange = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/change',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
            ContactInfoRefID: [],
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: {
          OrderID: params.orderId,
          ChangeOrderChoice: {
            AcceptSelectedQuotedOfferList: {
              SelectedPricedOffer: params.acceptOffer,
            },
          },
          PaymentList: params.paymentList,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const actionOrderReshop = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/reshop',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: {
          OrderID: params.orderId,
          UpdateOrder: {
            ReshopOrder: {
              ServiceOrder: {
                Add: {
                  OriginDestList: params.originDestList,
                  Cabin: params.cabin,
                },
                Delete: params.delServiceOrder,
              },
            },
          },
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const actionOrderReshopReprice = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/reshop',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: {
          OrderID: params.orderId,
          UpdateOrder: {
            ReshopOrder: {
              ServiceOrder: {
                Add: {
                  ShoppingResponse: params?.shoppingResponse,
                },
                Delete: params?.delServiceOrder,
              },
            },
          },
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postOrderChange = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/order/change',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
            ContactInfoRefID: [],
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: params.query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postSeatAvailability = async (params) => {
  try {
    const response = await request({
      url: '/v1/ndc/seatAvailability',
      method: 'POST',
      data: {
        Sender: {
          TravelAgency: {
            AgencyID: agencyID,
            SiteCode: '01',
          },
        },
        PointOfSale: 'KR',
        TransactionID: getTransactionId(),
        Query: params.query,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
