<script>
  export let items = [];
  export let itemToString = (item) => item.text || item.id;
  export let itemToInput = (item) => {};
  export let selectedIds = [];
  export let value = '';
  export let size = undefined;
  export let type = 'default';
  export let direction = 'bottom';
  export let selectionFeedback = 'top-after-reopen';
  export let disabled = false;
  export let filterable = false;
  export let filterItem = (item, value) => item.text.toLowerCase().includes(value.trim().toLowerCase());
  export let open = false;
  export let light = false;
  export let locale = 'en';
  export let placeholder = '';
  export let sortItem = (a, b) => a.text.localeCompare(b.text, locale, { numeric: true });
  export let translateWithId = undefined;
  export let translateWithIdSelection = undefined;
  export let titleText = '';
  export let useTitleInItem = false;
  export let invalid = false;
  export let invalidText = '';
  export let warn = false;
  export let warnText = '';
  export let helperText = '';
  export let label = '';
  export let hideLabel = false;
  export let id = 'ccs-' + Math.random().toString(36);
  export let name = undefined;
  export let inputRef = null;
  export let multiSelectRef = null;
  export let fieldRef = null;
  export let selectionRef = null;
  export let highlightedId = null;

  import { afterUpdate, createEventDispatcher, setContext } from 'svelte';
  import WarningFilled from 'carbon-icons-svelte/lib/WarningFilled.svelte';
  import WarningAltFilled from 'carbon-icons-svelte/lib/WarningAltFilled.svelte';
  import {
    Checkbox,
    ListBox,
    ListBoxField,
    ListBoxMenu,
    ListBoxMenuIcon,
    ListBoxMenuItem,
    ListBoxSelection,
  } from 'carbon-components-svelte';

  const dispatch = createEventDispatcher();

  let initialSorted = false;
  let highlightedIndex = -1;
  let prevChecked = [];

  setContext('MultiSelect', {
    declareRef: ({ key, ref }) => {
      switch (key) {
        case 'field':
          fieldRef = ref;
          break;
        case 'selection':
          selectionRef = ref;
          break;
      }
    },
  });

  function change(direction) {
    let index = highlightedIndex + direction;
    const length = filterable ? filteredItems.length : items.length;
    if (length === 0) return;
    if (index < 0) {
      index = length - 1;
    } else if (index >= length) {
      index = 0;
    }

    let disabled = items[index].disabled;

    while (disabled) {
      index = index + direction;

      if (index < 0) {
        index = items.length - 1;
      } else if (index >= items.length) {
        index = 0;
      }

      disabled = items[index].disabled;
    }

    highlightedIndex = index;
  }

  function sort() {
    return [...(checked.length > 1 ? checked.sort(sortItem) : checked), ...unchecked.sort(sortItem)];
  }

  afterUpdate(() => {
    if (checked.length !== prevChecked.length) {
      if (selectionFeedback === 'top') {
        sortedItems = sort();
      }
      prevChecked = checked;
      selectedIds = checked.map(({ id }) => id);
      dispatch('select', {
        selectedIds,
        selected: checked,
        unselected: unchecked,
      });
    }

    if (!open) {
      if (!initialSorted || selectionFeedback !== 'fixed') {
        sortedItems = sort();
        initialSorted = true;
      }

      highlightedIndex = -1;
      value = '';
    }

    items = sortedItems;
  });

  $: menuId = `menu-${id}`;
  $: inline = type === 'inline';
  $: ariaLabel = $$props['aria-label'] || 'Choose an item';
  $: sortedItems = items.map((item) => ({
    ...item,
    checked: selectedIds.includes(item.id),
  }));
  $: checked = sortedItems.filter(({ checked }) => checked);
  $: unchecked = sortedItems.filter(({ checked }) => !checked);
  $: filteredItems = sortedItems.filter((item) => filterItem(item, value));
  $: highlightedId =
    highlightedIndex > -1 ? (filterable ? filteredItems : sortedItems)[highlightedIndex]?.id ?? null : null;
</script>

<svelte:window
  on:click={({ target }) => {
    if (open && multiSelectRef && !multiSelectRef.contains(target)) {
      open = false;
    }
  }}
/>

<div
  bind:this={multiSelectRef}
  class:bx--multi-select__wrapper={true}
  class:bx--list-box__wrapper={true}
  class:bx--multi-select__wrapper--inline={inline}
  class:bx--list-box__wrapper--inline={inline}
  class:bx--multi-select__wrapper--inline--invalid={inline && invalid}
>
  {#if titleText}
    <label for={id} class:bx--label={true} class:bx--label--disabled={disabled} class:bx--visually-hidden={hideLabel}>
      {titleText}
    </label>
  {/if}
  <ListBox
    role={undefined}
    {disabled}
    {invalid}
    {invalidText}
    {open}
    {light}
    {size}
    {warn}
    {warnText}
    class="bx--multi-select {direction === 'top' && 'bx--list-box--up'} {filterable && 'bx--combo-box'}
        {filterable && 'bx--multi-select--filterable'}
        {invalid && 'bx--multi-select--invalid'}
        {inline && 'bx--multi-select--inline'}
        {checked.length > 0 && 'bx--multi-select--selected'}"
  >
    <!-- {#if invalid}
      <WarningFilled class="bx--list-box__invalid-icon" />
    {/if} -->
    {#if !invalid && warn}
      <WarningAltFilled class="bx--list-box__invalid-icon bx--list-box__invalid-icon--warning" />
    {/if}
    <ListBoxField
      role="button"
      tabindex="0"
      aria-expanded={open}
      on:click={() => {
        if (disabled) return;
        if (filterable) {
          open = true;
          inputRef.focus();
        } else {
          open = !open;
        }
      }}
      on:keydown={(e) => {
        if (filterable) {
          return;
        }
        const key = e.key;
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(key)) {
          e.preventDefault();
        }
        if (key === ' ') {
          open = !open;
        } else if (key === 'Tab') {
          if (selectionRef && checked.length > 0) {
            selectionRef.focus();
          } else {
            open = false;
            fieldRef.blur();
          }
        } else if (key === 'ArrowDown') {
          change(1);
        } else if (key === 'ArrowUp') {
          change(-1);
        } else if (key === 'Enter') {
          if (highlightedIndex > -1) {
            sortedItems = sortedItems.map((item, i) => {
              if (i !== highlightedIndex) return item;
              return { ...item, checked: !item.checked };
            });
          }
        } else if (key === 'Escape') {
          open = false;
        }
      }}
      on:focus={() => {
        if (filterable) {
          open = true;
          if (inputRef) inputRef.focus();
        }
      }}
      on:blur={(e) => {
        if (!filterable) dispatch('blur', e);
      }}
      {id}
      {disabled}
      {translateWithId}
    >
      {#if checked.length > 0}
        <ListBoxSelection
          selectionCount={checked.length}
          on:clear
          on:clear={() => {
            sortedItems = sortedItems.map((item) => ({
              ...item,
              checked: false,
            }));
            if (fieldRef) fieldRef.blur();
          }}
          translateWithId={translateWithIdSelection}
          {disabled}
        />
      {/if}
      {#if filterable}
        <input
          bind:this={inputRef}
          bind:value
          {...$$restProps}
          role="combobox"
          tabindex="0"
          autocomplete="off"
          aria-autocomplete="list"
          aria-expanded={open}
          aria-activedescendant={highlightedId}
          aria-disabled={disabled}
          aria-controls={menuId}
          class:bx--text-input={true}
          class:bx--text-input--empty={value === ''}
          class:bx--text-input--light={light}
          on:keydown
          on:keydown|stopPropagation={({ key }) => {
            if (key === 'Enter') {
              if (highlightedId) {
                const filteredItemIndex = sortedItems.findIndex((item) => item.id === highlightedId);
                sortedItems = sortedItems.map((item, i) => {
                  if (i !== filteredItemIndex) return item;
                  return { ...item, checked: !item.checked };
                });
              }
            } else if (key === 'Tab') {
              open = false;
              inputRef.blur();
            } else if (key === 'ArrowDown') {
              change(1);
            } else if (key === 'ArrowUp') {
              change(-1);
            } else if (key === 'Escape') {
              open = false;
            } else if (key === ' ') {
              if (!open) open = true;
            }
          }}
          on:keyup
          on:focus
          on:blur
          on:paste
          {disabled}
          {placeholder}
          {id}
          {name}
        />
        {#if invalid}
          <WarningFilled class="bx--list-box__invalid-icon" />
        {/if}
        {#if value}
          <ListBoxSelection
            on:clear={() => {
              value = '';
              open = false;
            }}
            translateWithId={translateWithIdSelection}
            {disabled}
            {open}
          />
        {/if}
        <ListBoxMenuIcon
          style="pointer-events: {open ? 'auto' : 'none'}"
          on:click={(e) => {
            e.stopPropagation();
            open = !open;
          }}
          {translateWithId}
          {open}
        />
      {/if}
      {#if !filterable}
        <span class:bx--list-box__label={true}>{label}</span>
        <ListBoxMenuIcon {open} {translateWithId} />
      {/if}
    </ListBoxField>
    {#if open}
      <ListBoxMenu aria-label={ariaLabel} {id} aria-multiselectable="true">
        {#if $$slots['expanded-list']}
          <slot name="expanded-list" {items} />
        {:else}
          {#each filterable ? filteredItems : sortedItems as item, i (item.id)}
            <ListBoxMenuItem
              id={item.id}
              role="option"
              aria-labelledby="checkbox-{item.id}"
              aria-selected={item.checked}
              active={item.checked}
              highlighted={highlightedIndex === i}
              disabled={item.disabled}
              on:click={(e) => {
                if (item.disabled) {
                  e.stopPropagation();
                  return;
                }
                sortedItems = sortedItems.map((_) => (_.id === item.id ? { ..._, checked: !_.checked } : _));
                fieldRef.focus();
              }}
              on:mouseenter={() => {
                if (item.disabled) return;
                highlightedIndex = i;
              }}
            >
              <Checkbox
                name={item.id}
                title={useTitleInItem ? itemToString(item) : undefined}
                {...itemToInput(item)}
                readonly
                tabindex="-1"
                id="checkbox-{item.id}"
                checked={item.checked}
                disabled={item.disabled}
                on:blur={() => {
                  if (i === filteredItems.length - 1) open = false;
                }}
              >
                <slot slot="labelText" {item} index={i}>
                  {itemToString(item)}
                </slot>
              </Checkbox>
            </ListBoxMenuItem>
          {/each}
        {/if}
      </ListBoxMenu>
      <div />
    {/if}
  </ListBox>
  {#if !inline && !invalid && !warn && helperText}
    <div class:bx--form__helper-text={true} class:bx--form__helper-text--disabled={disabled}>
      {helperText}
    </div>
  {/if}
</div>
