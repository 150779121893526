<script>
  import {
    ComposedModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    InlineLoading,
    RadioButton,
    DataTable,
    Toolbar,
    ToolbarContent,
  } from 'carbon-components-svelte';
  import { CustomTextInput } from 'src/components/common/input';
  import Portal from 'src/components/common/Portal.svelte';
  import { PrimaryButton } from 'src/components/common/button';
  import { postOrderChange } from 'src/service/reservation';
  import { APP_EVENT_NAME, CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { formatPriceNumber } from 'src/utils/appHelper.js';
  import { message } from 'src/store';
  import appEvent from 'src/store/appEventStore';
  import { screenIsNotEvent } from 'src/store';

  export let airline = EMPTY;
  export let totalAmount = {
    Amount: 0,
    CurCode: EMPTY,
  };
  export let payload = {};
  export let open = false;
  export let orderId;

  let isLoading = false;
  let checkedValue = EMPTY;

  const headers = [
    {
      key: 'head',
      value: '결제방식',
      width: '400px',
    },
    {
      key: 'action',
      value: '결제수단',
    },
  ];

  const rows = [
    {
      id: 1,
      head: '즉시결제',
      action: null,
    },
  ];
  const handleChangeCheckedValue = (currentValue) => {
    checkedValue = checkedValue === currentValue ? EMPTY : currentValue;
  };
  const handleActionPayment = async () => {
    isLoading = true;
    try {
      const response = await postOrderChange(payload);
      if (response?.data?.ResultMessage?.Code === CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'success',
            title: 'Success',
            subtitle: '서비스 추가가 완료되었습니다',
            caption: EMPTY,
          },
        ]);
        appEvent.action(`${APP_EVENT_NAME.RELOAD_CURRENT_PNR_CHANGEPAYMENTPNRMODAL}${orderId}`, {
          detail: { resOrderChange: response?.data, endMessage: EMPTY },
        });
      }
      if (response?.data?.ResultMessage?.Code !== CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'error',
            title: 'Error',
            subtitle: `${response?.data?.ResultMessage?.Code} ${response?.data?.ResultMessage?.Message}`,
            caption: EMPTY,
          },
        ]);
      }
      open = false;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading = false;
    }
    isLoading = false;
  };
  $: screenIsNotEvent.set(isLoading);
</script>

<Portal>
  <ComposedModal bind:open preventCloseOnClickOutside={true} id="baggage-information">
    <ModalHeader title="결제 정보" />
    <ModalBody>
      <h5 class="title-content" style="color: #0f62fe">
        결제 대상 금액 {formatPriceNumber(totalAmount.Amount)}
        {totalAmount.CurCode}
      </h5>
      <DataTable {headers} {rows} class="g-custom-table" id="paymentInformationTable" size="short">
        <Toolbar size="sm">
          <ToolbarContent>
            <div class="table-toolbar">
              <div class="wapper-actions-button" />
            </div>
          </ToolbarContent>
        </Toolbar>
        <div slot="cell" let:row let:cell class="cell-custom">
          {#if cell.key === 'action'}
            {#if row.id === 1}
              <div class="voucher-box">
                <di class="voucher-cash">
                  <RadioButton
                    labelText="Cash"
                    value="cash"
                    checked={checkedValue === 'cash'}
                    on:change={() => handleChangeCheckedValue('cash')}
                  />
                </di>
                <!-- {#if ['AF', 'KL'].includes(airline)}
                  <div>
                    <RadioButton
                      labelText="Voucher"
                      value="voucher"
                      on:change={() => handleChangeCheckedValue('voucher')}
                      checked={checkedValue === 'voucher'}
                    />
                    <div class="voucher-input">
                      <CustomTextInput labelText="Voucher Number" placeholder="Voucher Number" />
                    </div>
                  </div>
                  <div>
                    <RadioButton
                      labelText="혼합결제 (Voucher + Cash)"
                      value="cash_voucher"
                      checked={checkedValue === 'cash_voucher'}
                      on:change={() => handleChangeCheckedValue('cash_voucher')}
                    />
                    <div class="voucher-input">
                      <CustomTextInput labelText="Voucher Number" placeholder="Voucher Number" />
                    </div>
                  </div>
                {/if} -->
              </div>
            {:else if row.id === 2}
              <div class="voucher-box">
                <div>
                  <RadioButton
                    labelText="Pay Later"
                    value="pay_later"
                    checked={checkedValue === 'pay_later'}
                    on:change={() => handleChangeCheckedValue('pay_later')}
                  />
                  <div class="notify">※ 결제가 되지 않으며 PNR만 생성됩니다</div>
                </div>
              </div>
            {/if}
          {:else}
            {cell.value}
          {/if}
        </div>
      </DataTable>
    </ModalBody>
    <ModalFooter>
      <footer>
        <PrimaryButton kind="secondary" width="100%" on:click={() => (open = false)}>닫기</PrimaryButton>
        <PrimaryButton width="100%" disabled={!checkedValue} on:click={handleActionPayment}
          >결제&발행
          {#if isLoading}
            <div class="g-custom-loading-button loading-box">
              <InlineLoading description="Loading ..." />
            </div>
          {/if}
        </PrimaryButton>
      </footer>
    </ModalFooter>
  </ComposedModal>
</Portal>
