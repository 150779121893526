<script>
  import { has } from 'lodash';
  import { AmountInformationTable } from 'src/components/common/table';
  import { ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading, Button } from 'carbon-components-svelte';
  import Portal from 'src/components/common/Portal.svelte';
  import DifferenceInformationModal from 'src/components/modal/DifferenceInformationModal.svelte';
  import ItineraryInformationTable from 'src/components/modal/payment-information-modal/ItineraryInformationTable.svelte';
  import PassengerInformation from 'src/components/reservation-management-report/passenger-information-table/index.svelte';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { postOrderReshop, postOrderChange } from 'src/service/reservation';
  import { formatPriceNumber } from 'src/utils/appHelper.js';
  import { screenIsNotEvent, bookingReference, message } from 'src/store';
  import { PrimaryButton } from 'src/components/common/button';
  import PaymentInformationTable from 'src/components/modal/payment-information-modal/PaymentInformationTable.svelte';
  import ErrorApi from 'src/components/modal/ErrorApi.svelte';

  export let isPayLaterButtonDisable = false;
  export let DataLists = {};
  export let PricedOffer = {};
  export let Order = {};
  export let orderRetrieve = {};
  export let airlineID = EMPTY;
  export let orderId = EMPTY;
  export let pnr = EMPTY;

  let isLoading = false;
  let isLoadingPaymentOrIssue = false;
  let open = false;
  let differenceInformation = {
    isOpen: false,
    data: [],
  };
  let RepricedData = {
    RepricedOffer: {},
    DataLists: {},
  };
  let paramsOfferQuery = {};
  let errorApi = {
    isOpen: false,
    message: EMPTY,
  };
  const handleUpdateParamsOfferQuery = (event) => {
    paramsOfferQuery = { ...paramsOfferQuery, ...event.detail };
  };

  const handleAction = async () => {
    if (!['AF', 'KL'].includes(airlineID)) {
      isLoading = true;
      screenIsNotEvent.set(true);
      try {
        const responseOrderShop = await postOrderReshop({
          OrderID: orderId,
          UpdateOrder: { RepricedOrderRefID: orderId },
        });
        handleDifferenceInformation(responseOrderShop);
      } catch (error) {
        console.log('error', error);
      } finally {
        isLoading = false;
        screenIsNotEvent.set(false);
      }
    } else {
      open = true;
    }
  };

  const handleDifferenceInformation = (responseOrderShop) => {
    const { code, data } = responseOrderShop;
    if (code === 200) {
      if (data.ResultMessage.Code !== CODE_SUCCESS) {
        errorApi = {
          isOpen: true,
          message: `[${pnr} ${data.ResultMessage.Code} ${data.ResultMessage.Message}]`,
        };
        return;
      }

      open = true;
      RepricedData = {
        DataLists: data?.DataLists,
        RepricedOffer: data?.RepricedOffer,
      };
      const orderBaseAmount = Order?.TotalPrice?.TotalBaseAmount?.Amount || 0;
      const orderShopBaseAmount = data?.RepricedOffer?.TotalPrice?.TotalBaseAmount?.Amount || 0;
      const orderTaxAmount = Order?.TotalPrice?.TotalTaxAmount?.Amount || 0;
      const orderShopTaxAmount = data?.RepricedOffer?.TotalPrice?.TotalTaxAmount?.Amount || 0;
      const orderCurCode = Order?.TotalPrice?.TotalBaseAmount?.CurCode ?? Order?.TotalPrice?.TotalAmount?.CurCode;
      const orderShopCurCode =
        data?.RepricedOffer?.TotalPrice?.TotalBaseAmount?.CurCode ??
        data?.RepricedOffer?.TotalPrice?.TotalAmount?.CurCode;
      if (data?.NoPriceChangeInd) return;
      if (orderBaseAmount !== orderShopBaseAmount || orderTaxAmount !== orderShopTaxAmount) {
        differenceInformation = {
          isOpen: true,
          data: [
            {
              id: 1,
              division: 'BaseAmount',
              beforeChange: `${formatPriceNumber(orderBaseAmount)} ${orderCurCode}`,
              after: `${formatPriceNumber(orderShopBaseAmount)} ${orderShopCurCode}`,
              variableAmount: `${formatPriceNumber(orderShopBaseAmount - orderBaseAmount)} ${orderCurCode}`,
            },
            {
              id: 2,
              division: 'TotalTax',
              beforeChange: `${formatPriceNumber(orderTaxAmount)} ${orderCurCode}`,
              after: `${formatPriceNumber(orderShopTaxAmount)} ${orderShopCurCode}`,
              variableAmount: `${formatPriceNumber(orderShopTaxAmount - orderTaxAmount)} ${orderCurCode}`,
            },
            {
              id: 3,
              division: 'TotalFare',
              beforeChange: `${formatPriceNumber(orderBaseAmount + orderTaxAmount)} ${orderCurCode}`,
              after: `${formatPriceNumber(data?.RepricedOffer?.TotalPrice?.TotalAmount?.Amount)} ${
                data?.RepricedOffer?.TotalPrice?.TotalAmount?.CurCode
              }`,
              variableAmount: `${formatPriceNumber(
                data?.RepricedOffer?.TotalPrice?.TotalAmount?.Amount - (orderBaseAmount + orderTaxAmount)
              )} ${orderShopCurCode}`,
            },
          ],
        };
      }
    }
  };

  const handlePaymentOrIssue = async () => {
    if (['AF', 'KL'].includes(airlineID)) {
      paramsOfferQuery = {
        OrderID: Order.OrderID,
        ChangeOrderChoice: {
          AcceptRepricedOrder: {
            OfferRefID: [Order.OrderID],
          },
        },
        ...paramsOfferQuery,
      };
    } else {
      paramsOfferQuery = {
        OrderID: Order.OrderID,
        ChangeOrderChoice: {
          AcceptRepricedOrder: {
            OfferRefID: [RepricedData.RepricedOffer.OfferID ?? Order.OrderID],
          },
        },
        requestType: 'heldbooking',
        ...paramsOfferQuery,
      };
    }

    try {
      screenIsNotEvent.set(true);
      isLoadingPaymentOrIssue = true;
      const { code, data } = await postOrderChange({ query: paramsOfferQuery });
      if (code === 200) {
        if (data?.ResultMessage?.Code === CODE_SUCCESS) {
          bookingReference.set({
            booking_reference: pnr,
            order_id: data.Order.OrderID,
          });
          message.set([
            {
              type: 'success',
              title: 'Success',
              subtitle: '발권이 되었습니다',
            },
          ]);
        } else {
          errorApi = {
            isOpen: true,
            message: `[${pnr} ${data.ResultMessage.Code} ${data.ResultMessage.Message}]`,
          };
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      screenIsNotEvent.set(false);
      isLoadingPaymentOrIssue = false;
      open = false;
    }
  };
  $: if (!open) {
    paramsOfferQuery = {};
  }
</script>

<Button size="small" kind="secondary" disabled={isPayLaterButtonDisable} on:click={handleAction}>
  {#if isLoading}
    <div class="g-custom-loading-button loading-box">
      <InlineLoading description="Loading" />
    </div>
  {:else}
    결제&발행
  {/if}
</Button>

<Portal idBox="box-modal">
  <ComposedModal bind:open size="lg" id="PassengerInformationModal" preventCloseOnClickOutside>
    <ModalHeader title="결제 정보" />
    <ModalBody>
      <div class="wrapper-layout-content">
        <ItineraryInformationTable {DataLists} PricedOffer={Order ? Order : PricedOffer} />
      </div>
      <div class="wrapper-layout-content">
        <PassengerInformation {orderRetrieve} {airlineID} {orderId} isHiddenBtn={true} />
      </div>
      <div class="wrapper-layout-content">
        <AmountInformationTable {DataLists} {Order} {airlineID} />
      </div>
      <div class="wrapper-layout-content">
        {#key open}
          {#if ['AF', 'KL'].includes(airlineID)}
            <PaymentInformationTable typePayInformation="rePrice" on:change={handleUpdateParamsOfferQuery} {Order} />
          {:else}
            <PaymentInformationTable
              typePayInformation="rePrice"
              on:change={handleUpdateParamsOfferQuery}
              RepricedOffer={RepricedData?.RepricedOffer}
            />
          {/if}
        {/key}
      </div>
      <DifferenceInformationModal
        bind:open={differenceInformation.isOpen}
        on:close={(_event) => {}}
        bind:data={differenceInformation.data}
      />
    </ModalBody>
    <ModalFooter>
      <footer>
        <div class="action-button">
          <PrimaryButton width="100%" on:click={handlePaymentOrIssue} disabled={!has(paramsOfferQuery, 'PaymentList')}
            >결제&발행
            {#if isLoadingPaymentOrIssue}
              <div class="g-custom-loading-button">
                <InlineLoading description="Loading ..." />
              </div>
            {/if}
          </PrimaryButton>
        </div>
        <div class="action-button">
          <PrimaryButton kind="secondary" on:click={() => (open = false)} width="100%">닫기</PrimaryButton>
        </div>
      </footer>
    </ModalFooter>
  </ComposedModal>
  <ErrorApi bind:openErrorApi={errorApi.isOpen}>{errorApi.message}</ErrorApi>
</Portal>
