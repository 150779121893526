<script>
  import { createEventDispatcher } from 'svelte';
  import { Modal } from 'carbon-components-svelte';
  import { EMPTY } from 'src/constants/app';
  export let isOpenErrorVoidRefund;
  const dispatch = createEventDispatcher();
</script>

<Modal
  danger
  id="modal-error-void-refund"
  modalHeading="취소요청"
  bind:open={isOpenErrorVoidRefund}
  primaryButtonText="예약취소"
  secondaryButtons={[{ text: 'hidden' }, { text: '닫기' }]}
  preventCloseOnClickOutside={true}
  on:click:button--secondary={({ detail }) => {
    if (detail.text === '닫기') {
      dispatch('close-modal-error-void-refund');
    }
  }}
  on:click:button--primary={() => {
    dispatch('primary-modal-error-void-refund');
  }}
  on:close={(_e) => {
    dispatch('close-modal-error-void-refund');
  }}
  size="sm"
>
  <p>PNR {isOpenErrorVoidRefund.Id || EMPTY} 의 예약을 취소합니다.</p>
  <p>티켓이 발권된 경우, 티켓을 VOID하고 예약을 취소합니다 .</p>
</Modal>
