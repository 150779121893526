<script>
  import { keys } from 'lodash';
  import { CustomTooltip } from 'src/components/common/tooltip';
  import { EMPTY } from 'src/constants/app';
  import { formatPriceNumber } from 'src/utils/appHelper';

  export let data;
  export let align = 'start';
  export let direction = 'top';

  const title = {
    ADT: '성인 일반',
    CHD: '소아 일반',
    INF: '유아 일반',
  };
</script>

<CustomTooltip {align} {direction}>
  <div class="wrapper-info-payment" slot="content">
    <ul>
      {#each keys(data) as keyId (keyId)}
        {#if data[keyId] !== EMPTY}
          <li>
            <div class="header flex-row-global">
              <span>{title[keyId]}</span>
              <span class="price-payment">
                {formatPriceNumber(data[keyId].baseAmount + data[keyId].totalTax)}
                {data[keyId].curCode} <span class="price-payment-note">X {data[keyId].ptcCount}</span></span
              >
            </div>
            <div class="content flex-row-global">
              <span>Base Amount</span>
              <span class="price-payment"> {formatPriceNumber(data[keyId].baseAmount)} {data[keyId].curCode}</span>
            </div>
            <div class="content flex-row-global">
              <span>Total Tax</span>
              <span class="price-payment"> {formatPriceNumber(data[keyId].totalTax)} {data[keyId].curCode}</span>
            </div>
          </li>
        {/if}
      {/each}
    </ul>
  </div>
</CustomTooltip>

<style>
  .wrapper-info-payment {
    background-color: #393939;
    padding: 8px;
    width: 280px;
  }
  ul {
    background-color: #4c4c4c;
  }
  ul li {
    display: flex;
    flex-direction: column;
  }
  .header {
    background-color: #e0e0e0;
    color: black;
    padding: 8px 8px;
  }
  .flex-row-global {
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 8px 8px;
  }
  .price-payment {
    padding-right: 20px;
    position: relative;
  }
  .price-payment-note {
    position: absolute;
    right: -4px;
  }
</style>
