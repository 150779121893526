<script>
  import PrimaryButton from './PrimaryButton.svelte';
  import { Modal } from 'carbon-components-svelte';
  import { has, uniqWith, isEqual, isEmpty } from 'lodash';
  import Portal from 'src/components/common/Portal.svelte';

  export let contactInfoList = [];
  let open;
  let postalAddressHasLabel = [];
  $: {
    let postalAddressList = contactInfoList.flatMap((item) => {
      if (has(item, 'PostalAddress')) return item.PostalAddress;
      return [];
    });
    postalAddressHasLabel = postalAddressList.filter((item) => has(item, 'Label'));
    postalAddressHasLabel = uniqWith(postalAddressHasLabel, isEqual);
    console.log(postalAddressHasLabel);
  }
</script>

{#if !isEmpty(postalAddressHasLabel)}
  <PrimaryButton kind="ghost" width="auto" height="32px" on:click={() => (open = true)}>
    <span class="underline-button"> 체류지정보 </span>
  </PrimaryButton>
{/if}

<Portal idBox="box-modal-children">
  <Modal passiveModal bind:open size="sm" preventCloseOnClickOutside>
    <h4 slot="heading">체류지정보</h4>
    {#each postalAddressHasLabel as { CityName, CountryCode, Label, PostalCode, Street }}
      <ul>
        <li>Country Code: {CountryCode}</li>
        <li>City Name: {CityName}</li>
        <li>PostalCode: {PostalCode}</li>
        <li>Street: {Street.join(',')}</li>
      </ul>
    {/each}
  </Modal>
</Portal>

<style>
  h4 {
    font-weight: 500;
  }
  .underline-button {
    text-decoration: underline;
    font-size: 16px;
    display: flex;
  }
  ul {
    list-style-type: none;
    padding-left: 20px;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
  }
  ul li::before {
    content: '-';
    padding-right: 8px;
  }
</style>
