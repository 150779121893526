<script>
  import { createEventDispatcher } from 'svelte';
  import { Modal } from 'carbon-components-svelte';
  export let openErrorApi;
  export let size;
  const dispatch = createEventDispatcher();
</script>

<Modal
  preventCloseOnClickOutside={true}
  id="modal-api-error"
  modalHeading="API 호출"
  primaryButtonText="확인"
  bind:open={openErrorApi}
  on:click:button--primary={(_e) => {
    openErrorApi = false;
    dispatch('close-modal-api-error');
  }}
  on:close={(_e) => {
    dispatch('close-modal-api-error');
  }}
  size={size ? size : 'xs'}
>
  <p>API 호출에 실패했습니다. 다시 시도해주세요.</p>
  <slot />
</Modal>
