<div role="presentation" id="loading">
  <div class="indicator">
    <svg class="svg-circle" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none">
      <circle class="ani-circle" stroke-linecap="round" cx="50%" cy="50%" r="10" stroke="lightgray" />
    </svg>

    <svg class="box-r2" height="52" width="55">
      <circle id="point" cx="25" cy="26" r="4" fill="lightgray">
        <animate
          attributeName="cx"
          from="25"
          to="50"
          dur="2s"
          repeatCount="indefinite"
          values="25 ; 25 ; 50 ; 50 ; 25"
          keyTimes="0 ; 0.1 ; 0.3 ; 0.9; 1"
        >
          <mpath href="#point" />
        </animate></circle
      >
    </svg>
    <span class="message">탑승객 분리중...</span>
  </div>
</div>

<style scoped>
  #loading {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5));
  }
  .indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .svg-circle {
    position: absolute;
    left: 0;
    top: 26px;
    transform: translate(-50%, -50%);
  }

  .ani-circle {
    fill: transparent;
    stroke-width: 5px;
    stroke-dasharray: 80;
    animation: ani 2s linear infinite;
    transform: rotate(-90deg);
    transform-origin: center;
  }
  .box-r2 {
    position: absolute;
    left: -25px;
  }
  .message {
    position: absolute;
    width: 160px;
    left: 38px;
    top: 18px;
    color: #e6e6e6;
  }

  @keyframes ani {
    0% {
      stroke-dashoffset: -80;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 80;
    }
  }
</style>
