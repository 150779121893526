import request from './request.js';
import qs from 'qs';

const getOcnData = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/ocn',
      method: 'GET',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const exportExcelOcn = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/ocn/excel',
      method: 'GET',
      params,
      responseType: 'blob',

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const actionOcnManualSend = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/ocn?' + new URLSearchParams(params).toString(),
      method: 'POST',
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  getOcnData,
  exportExcelOcn,
  actionOcnManualSend,
};
