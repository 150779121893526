import axios from 'axios';
import { user, message } from 'src/store';
import { CODE_SUCCESS, EMPTY } from '../constants/app';
import { get } from 'svelte/store';
const URL_IGNORE = ['/v1/ndc/air-shopping', '/v1/ndc/order/retrieve'];

const URL_WARN_IGNORE = ['/v1/ndc/air-shopping', '/v1/ndc/order/retrieve'];

function processMessages(data, messageType) {
  const EMPTY = '';
  const messageDetail = data?.data?.ResultMessage?.MessageDetail;

  if (messageDetail && messageDetail[messageType]?.length > 0) {
    const messages = messageDetail[messageType];
    const processedMessages = messages.map((messageItem) => {
      const { Owner, Code, ShortText, Type, Message } = messageItem;
      const arrMessage = [Owner, Code, ShortText, Type, Message];
      return {
        title: messageType === 'Errors' ? 'Error' : 'Warnings',
        type: messageType === 'Errors' ? 'error' : 'warning',
        subtitle: `${arrMessage.filter((item) => ![undefined, null, EMPTY].includes(item)).join(', ')}`,
      };
    });
    message.set(processedMessages);
  }
}

export const instanceAxios = axios.create({
  baseURL: import.meta.env.VITE_URL_API || 'https://dedge.ndcaggregator.kr/luna/api/',
});

/* get accessToken in sessionStorage */
let accessToken = EMPTY;
const userSessionStorage = sessionStorage.getItem('user');
if (userSessionStorage) {
  const userParse = JSON.parse(userSessionStorage);
  accessToken = userParse?.accessToken || EMPTY;
  instanceAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}
/* end */

instanceAxios.defaults.headers.common['Content-Type'] = 'application/json';
instanceAxios.defaults.headers.common['AgencyId'] = get(user)?.agencyId;
instanceAxios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.code && +data.code !== 200) {
      message.update(() => [
        {
          subtitle: data.message,
          caption: EMPTY,
        },
      ]);
    }

    if (!URL_IGNORE.includes(response.config.url)) {
      if (data?.data?.ResultMessage?.MessageDetail?.Errors?.length > 0) {
        processMessages(data, 'Errors');
      }
    }
    if (!URL_WARN_IGNORE.includes(response.config.url)) {
      if (data?.data?.ResultMessage?.MessageDetail?.Warnings?.length > 0) {
        processMessages(data, 'Warnings');
      }
    }
    return response;
  },
  (error) => {
    const { url } = error.config;
    const isUrlLogin = url === '/sign-in';
    if (error.response.status === 401 && !isUrlLogin) {
      user.set('LOGOUT');
      window.location.reload();
    }
    console.error('error', error);
  }
);

export default function request(options) {
  return instanceAxios(options);
}
