<script>
  import { createEventDispatcher } from 'svelte';
  import { TextInput, DatePicker, DatePickerInput, Select, SelectItem } from 'carbon-components-svelte';
  import { CABIN_TYPE, EMPTY, EXISTING_ITINERARY } from '../../../../constants/app';
  export let data;
  let selectCabinCode = true;
  const dispatch = createEventDispatcher();
  let currentInfoItinerary = {
    departureAirportCode: EMPTY,
    arrivalAirportCode: EMPTY,
    departureTime: EMPTY,
    arrivalTime: EMPTY,
    cabinCode: EMPTY,
    airlineId: EMPTY,
    flightNumber: EMPTY,
    aircraftCode: EMPTY,
    name: EMPTY,
    rbd: EMPTY,
    maxWeightAllowance: EMPTY,
    typeAirlineId: EMPTY,
  };
  let itemSearch;

  $: {
    itemSearch = data.searchWord;
    const currentTab = data;
    const departureAirportCode = currentTab?.OnPoint;
    const arrivalAirportCode = currentTab?.OffPoint;
    const departureTime = currentTab.items[0]?.departureTime;
    const arrivalTime = currentTab.items[currentTab.items.length - 1]?.arrivalTime;

    let cabinCode = [];
    let airlineId = [];
    let flightNumber = [];
    let aircraftCode = [];
    let name = [];
    let rbd = [];
    let maxWeightAllowance = [];
    let typeAirlineId = [];

    for (const item of currentTab.items) {
      if (item.cabinCode) cabinCode.push(item.cabinCode);
      if (item.airlineId) airlineId.push(item.airlineId);
      if (item.flightNumber) flightNumber.push(item.flightNumber);
      if (item.aircraftCode) aircraftCode.push(item.aircraftCode);
      if (item.name) name.push(item.name);
      if (item.rbd) rbd.push(item.rbd);
      if (item.maxWeightAllowance) maxWeightAllowance.push(item.maxWeightAllowance);
      if (item.typeAirlineId) typeAirlineId.push(item.typeAirlineId);
    }

    currentInfoItinerary = {
      departureAirportCode,
      arrivalAirportCode,
      departureTime,
      arrivalTime,
      cabinCode,
      airlineId,
      flightNumber,
      aircraftCode,
      name,
      rbd,
      maxWeightAllowance,
      typeAirlineId,
    };
  }
  $: {
    dispatch('update-params-search', {
      itemSearch: itemSearch,
      paxJourneyID: data.PaxJourneyID,
      id: data.id,
    });
  }
</script>

{#if itemSearch}
  <div class="point_start-end">
    <div class="input-point-start">
      <TextInput
        labelText="출발지"
        placeholder="공항3코드"
        bind:value={itemSearch.onPoint}
        maxlength={3}
        on:input={(e) => {
          itemSearch.onPoint = itemSearch.onPoint?.replace(/\s/g, EMPTY);
        }}
      />
    </div>
    <div class="input-point-end">
      <TextInput
        labelText="도착지"
        placeholder="공항3코드"
        bind:value={itemSearch.offPoint}
        maxlength={3}
        on:input={(e) => {
          itemSearch.offPoint = itemSearch.offPoint?.replace(/\s/g, EMPTY);
        }}
      />
    </div>
  </div>
  <DatePicker datePickerType="single" class="departure-date" bind:value={itemSearch.departureDate} dateFormat="Y-m-d">
    <DatePickerInput labelText="출발일" placeholder="YYYY-MM-DD" />
  </DatePicker>
  <Select
    labelText="좌석등급 {!selectCabinCode ? '(여정1에서 선택해주세요.)' : EMPTY}"
    class="seat-class"
    bind:selected={itemSearch.cabinCode}
    disabled={selectCabinCode}
  >
    {#each CABIN_TYPE as cabin}
      <SelectItem value={cabin.key} text={cabin.value} />
    {/each}
  </Select>

  <div class="form-content">
    <h4>기존 여정</h4>
    {#each EXISTING_ITINERARY as item}
      <ul>
        {#each item as { label, key }}
          <li>
            <div class="existingItinerary-label">
              {label}
            </div>
            <p class="existingItinerary-value">
              {currentInfoItinerary[key]}
            </p>
          </li>
        {/each}
      </ul>
    {/each}
  </div>
{/if}
