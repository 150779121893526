<script>
  import { find, isEqual, map, reduce, filter, isEmpty, uniqWith, has, join, uniq } from 'lodash';
  import PopupTicket from './PopupTicket.svelte';
  import PopupEMDA from './PopupEMDA.svelte';
  import PopupEMDS from './PopupEMDS.svelte';
  import TableTicket from './TableTicket.svelte';
  import TicketingInformation from './TicketingInformation.svelte';
  import {
    formatCabinCode,
    formatDate,
    formatPriceNumber,
    nameTypeTicket,
    statusTickKet,
    getMaxWeightAllowance,
    getPassengerNamePtc,
    formatInfoSegment,
    getMainBookingReference,
  } from 'src/utils/appHelper.js';
  import { EMPTY, POPUP_TYPE_EMDA, POPUP_TYPE_EMDS, POPUP_TYPE_TICKET } from 'src/constants/app.js';
  import './ticket.scss';

  export let dataTicket;
  let openModal = {
    isOpen: false,
    type: 'none',
  };

  let selectedRow;
  let dataPopup;
  let dataTicketTable = [];

  const POPUP = [
    {
      name: POPUP_TYPE_TICKET,
      title: '티켓상세',
      render: PopupTicket,
    },
    {
      name: POPUP_TYPE_EMDA,
      title: '티켓상세-EMD-A',
      render: PopupEMDA,
    },
    {
      name: POPUP_TYPE_EMDS,
      title: '티켓상세-EMD-S',
      render: PopupEMDS,
    },
  ];

  function getEmd(service) {
    if (!service || !has(service, 'Definition') || isEmpty(service?.Definition)) return EMPTY;
    if (has(service, 'SelectedSeat') && !isEmpty(service)) {
      return `${service?.Definition?.Name} ${service.SelectedSeat.Row}${service.SelectedSeat.Column}`;
    }

    let descText = [];
    for (const desc of service?.Definition?.Desc ?? []) {
      if (!descText.includes(desc.Text)) descText = [...descText, desc.Text];
    }
    return `${service?.Definition?.Name} ${join(descText, '-')}`;
  }

  function getTotalFarePaymentType(PaxRefID, OrderItem, PaymentList, Type, TotalFareTicket) {
    let paymentListWithOrderId = [];
    let paymentIndex = 0;
    let paymentListItem;
    const orderItemId = OrderItem?.OrderItemID || EMPTY;
    const ownerOrderItem = OrderItem?.Owner || EMPTY;
    if (ownerOrderItem && ['SQ', 'QR'].includes(ownerOrderItem)) {
      paymentListWithOrderId = PaymentList.filter((payment) => payment?.OrderItemID.includes(orderItemId));
      paymentIndex = paymentListWithOrderId[0]?.PaxRefID?.findIndex((paxId) => paxId === PaxRefID) || EMPTY;
      paymentListItem = paymentListWithOrderId[paymentIndex];
    } else {
      paymentListItem = PaymentList.find((payment) => payment?.OrderItemID.includes(orderItemId));
    }
    if ([POPUP_TYPE_TICKET, POPUP_TYPE_EMDA].includes(Type)) {
      return {
        TotalFare: TotalFareTicket,
        PaymentType: paymentListItem?.Type || EMPTY,
      };
    } else {
      let FareDetail = OrderItem?.FareDetail.find((item) => item.PaxRefID.includes(PaxRefID));
      if (!FareDetail) {
        FareDetail = OrderItem?.PaxRefID.includes(PaxRefID) ? OrderItem?.FareDetail[0] : EMPTY;
      }
      const priceTotalFareTicket = FareDetail?.TaxTotal?.Amount + FareDetail?.BaseAmount?.Amount ?? EMPTY;
      const priceCurCode = FareDetail?.BaseAmount?.CurCode ?? 'KRW';

      return {
        TotalFare:
          priceTotalFareTicket === EMPTY ? EMPTY : `${formatPriceNumber(priceTotalFareTicket)} ${priceCurCode}`,
        PaymentType: paymentListItem?.Type || EMPTY,
      };
    }
  }

  function getCodeShare(OperatingCarrier, MarketingCarrier) {
    let typeAirlineId = EMPTY;
    if (OperatingCarrier) {
      const airlineIdOperatingCarrier = OperatingCarrier?.AirlineID || EMPTY;
      const airlineIdMarketingCarrier = MarketingCarrier?.AirlineID || EMPTY;
      if (airlineIdOperatingCarrier !== airlineIdMarketingCarrier) {
        if (airlineIdOperatingCarrier) typeAirlineId = airlineIdOperatingCarrier;
        else typeAirlineId = OperatingCarrier?.Name || EMPTY;
      } else {
        typeAirlineId = EMPTY;
      }
    }
    return typeAirlineId;
  }

  function getEdmTick(Type, CouponInfos, OrderItem) {
    if (Type === POPUP_TYPE_TICKET) return [];
    else {
      const serviceRefIDList = uniq(CouponInfos.flatMap((coupon) => coupon.ServiceRefID));
      const arrEdm = reduce(
        OrderItem,
        (total, it) => {
          const svArrHasSeverRef = filter(it.Service, (sv) => {
            return serviceRefIDList.includes(sv.ServiceID);
          });
          return [
            ...total,
            map(svArrHasSeverRef, (svItem) => {
              if (has(svItem, 'SelectedSeat')) {
                return `${svItem.Definition?.Name ?? EMPTY} ${svItem.SelectedSeat.Row}${svItem.SelectedSeat.Column}`;
              }
              let descText = [];
              for (const desc of svItem?.Definition?.Desc ?? []) {
                if (!descText.includes(desc.Text)) descText = [...descText, desc?.Text];
              }
              return `${svItem.Definition?.Name ?? EMPTY} ${join(descText, '-')}`;
            }),
          ];
        },
        []
      );
      return uniq(arrEdm.flatMap((item) => item));
    }
  }

  const getDataTableTicket = (DataLists, Order) => {
    const { TicketDocList, PaxSegmentList, PaxJourneyList, PaymentList } = DataLists;
    const { OrderItem, JourneyOverview, BaggageAllowance, OrderID, BookingReference } = Order;
    const ticketDocumentFlat = TicketDocList.flatMap(({ IssuingAirlineInfo, PaxRefID, TicketDocument }) => {
      return TicketDocument.map((docItem) => ({
        IssuingAirlineInfo,
        PaxRefID,
        ...docItem,
      }));
    });
    return ticketDocumentFlat.map((TicketDoc, index) => {
      const { PaxRefID } = TicketDoc;
      const { TicketDocNbr, Status, ReportingType, CouponInfos } = TicketDoc;
      const Pax = DataLists.PaxList.find((paxItem) => paxItem.PaxID === PaxRefID);
      const Type = nameTypeTicket(TicketDoc?.Type);
      let segments = [];
      if (Type === POPUP_TYPE_TICKET) {
        segments = CouponInfos.map((coupon) => {
          const findPaxSegmentItem = find(
            PaxSegmentList,
            (paxSegment) => paxSegment.PaxSegmentID === coupon.PaxSegmentRefID
          );
          return formatInfoSegment(findPaxSegmentItem);
        });
      }

      if (Type === POPUP_TYPE_EMDS) {
        segments = map(CouponInfos, (coupon) => {
          let PaxSegmentRefID = EMPTY;
          const findTicketDocListItem = TicketDocList.find((item) =>
            item.TicketDocument.some((doc) => doc.TicketDocNbr === coupon?.InConnectionWithInfo?.InConnectionDocNbr)
          );
          const couponInfosTmp = findTicketDocListItem?.TicketDocument[0]?.CouponInfos || EMPTY;
          if (couponInfosTmp.length) {
            const couponInfoItem = couponInfosTmp.find(
              (cp) => cp.CouponNumber === coupon?.InConnectionWithInfo?.InConnectionCpnNbr || EMPTY
            );
            PaxSegmentRefID = couponInfoItem?.PaxSegmentRefID;
          }
          const findPaxSegmentItem = PaxSegmentList.find((paxSegment) => paxSegment.PaxSegmentID === PaxSegmentRefID);
          return formatInfoSegment(findPaxSegmentItem);
        });
      }

      if (Type === POPUP_TYPE_EMDA) {
        segments = map(CouponInfos, (coupon) => {
          const { ServiceRefID, PaxSegmentRefID } = coupon;
          const ServiceAllInOrderItem = uniqWith(
            OrderItem?.flatMap((item) => item.Service),
            isEqual
          );
          let ServiceOrderItemMapping = [];
          if (!isEmpty(ServiceRefID)) {
            ServiceOrderItemMapping = filter(ServiceAllInOrderItem, (it) => ServiceRefID.includes(it.ServiceID));
          } else if (PaxSegmentRefID) {
            ServiceOrderItemMapping = filter(ServiceAllInOrderItem, (it) => PaxSegmentRefID === it.PaxSegmentRefID);
          }
          const PaxSegment = filter(PaxSegmentList, (it) => {
            const checkHasPaxSegmentID = filter(
              ServiceOrderItemMapping,
              (serviceIt) => serviceIt.PaxSegmentRefID === it.PaxSegmentID
            );
            return checkHasPaxSegmentID.length > 0;
          });
          return map(PaxSegment, (it) => formatInfoSegment(it));
        });
      }
      let findOrderItem;
      if (Type === POPUP_TYPE_TICKET) {
        for (const currentOrderItem of OrderItem) {
          if (
            currentOrderItem.PaxRefID.includes(PaxRefID) &&
            find(currentOrderItem.Service, (sv) => !has(sv, 'Definition'))
          ) {
            findOrderItem = currentOrderItem;
            break;
          }
        }
      } else {
        // TODO: OrderItem with type EMD-S, EMD-A
        const ServiceRefID = CouponInfos[0]?.ServiceRefID ?? [];
        findOrderItem = OrderItem.find((item) => {
          if (item.Service) {
            return item.Service.some((service) => ServiceRefID.includes(service.ServiceID));
          }
          return false;
        });
      }
      let findFareDetail = find(findOrderItem?.FareDetail, (it) => it.PaxRefID.includes(PaxRefID));
      if (!findFareDetail) {
        findFareDetail = findOrderItem?.PaxRefID.includes(PaxRefID) ? findOrderItem.FareDetail[0] : EMPTY;
      }

      const getTotalFareTicket = () => {
        //get Total Fare with type ema
        if (Type === POPUP_TYPE_EMDA) {
          let arrFareDetail = [];
          let totalFare = 0;
          for (const currentOrderItem of dataTicket?.Order?.OrderItem) {
            for (const currentCouponInfos of CouponInfos) {
              for (const svItem of currentOrderItem.Service) {
                if (currentCouponInfos?.ServiceRefID.includes(svItem.ServiceID)) {
                  arrFareDetail.push(...currentOrderItem.FareDetail);
                  break;
                }
              }
            }
          }
          arrFareDetail=uniqWith(arrFareDetail,isEqual)
          
          for (const arrFareDetailItem of arrFareDetail) {
            const { BaseAmount, TaxTotal } = arrFareDetailItem;
            totalFare += (BaseAmount?.Amount ?? 0) + (TaxTotal?.Amount ?? 0);
          }
          return `${formatPriceNumber(totalFare)} ${arrFareDetail[0]?.BaseAmount?.CurCode ?? 'KRW'}`;
        }
        // get Total Fare type ticket
        if ((CouponInfos?.length && CouponInfos[0].Status === 'E') || !findFareDetail) return EMPTY;
        const priceTotalFareTicket = findFareDetail?.TaxTotal?.Amount + findFareDetail?.BaseAmount?.Amount ?? EMPTY;
        const priceCurCode = findFareDetail?.BaseAmount?.CurCode ?? 'KRW';
        if (priceTotalFareTicket === EMPTY) return EMPTY;
        return `${formatPriceNumber(priceTotalFareTicket)} ${priceCurCode}`;
      };
      const getBaseAmount = () => {
        const priceAmount = findFareDetail?.BaseAmount?.Amount ?? EMPTY;
        const priceCurCode = findFareDetail?.BaseAmount?.CurCode ?? 'KRW';
        if (priceAmount === EMPTY) return EMPTY;
        return `${formatPriceNumber(priceAmount)} ${priceCurCode}`;
      };
      const getTaxTotal = () => {
        const priceTaxTotal = findFareDetail?.TaxTotal?.Amount ?? EMPTY;
        const priceCurCode = findFareDetail?.TaxTotal?.CurCode ?? 'KRW';
        if (priceTaxTotal === EMPTY) return EMPTY;
        return `${formatPriceNumber(priceTaxTotal)} ${priceCurCode}`;
      };
      return {
        id: index,
        TicketDocNbr: TicketDocNbr ? TicketDocNbr.substring(0, 13) : EMPTY,
        TicketStatus: statusTickKet(Status, Type),
        CouponInfos,
        ReportingType,
        PassengerName: getPassengerNamePtc(Pax),
        TicketingDate: formatDate(`${TicketDoc?.IssueDate ?? EMPTY} ${TicketDoc?.IssueTime ?? EMPTY}`, 'datetime'),
        Type: Type,
        Pax,
        BaseAmount: getBaseAmount(),
        TaxTotal: getTaxTotal(),
        TotalFareTicket: getTotalFareTicket(),
        segments,
        PaxSegmentList,
        PaxJourneyList,
        JourneyOverview,
        BaggageAllowance,
        OrderItem: findOrderItem,
        OrderID,
        BookingReference,
        Tax: findFareDetail?.Tax || EMPTY,
        PaymentList,
        Edm: getEdmTick(Type, CouponInfos, OrderItem),
        PaxRefID,
        Ptc: Pax?.Ptc || EMPTY,
        FareDetail: findFareDetail,
        IssuedDate: TicketDoc?.IssuedDate || EMPTY,
        InConnectionDocNbr: TicketDoc?.InConnectionDocNbr ?? EMPTY,
        TicketDoc,
      };
    });
  };

  const sortDataTableTicket = (data) => {
    return data.sort((a, b) => {
      const CouponInfoA = a?.CouponInfos ?? [];
      const CouponInfoB = b?.CouponInfos ?? [];
      if (a.Type !== b.Type) {
        if (a.Type === POPUP_TYPE_TICKET) return -1;
        if (b.Type === POPUP_TYPE_TICKET) return 1;
        if ([POPUP_TYPE_EMDA, POPUP_TYPE_EMDS].includes(a.Type)) return -1;
        if ([POPUP_TYPE_EMDA, POPUP_TYPE_EMDS].includes(b.Type)) return 1;
      }

      if (CouponInfoA[0].PaxSegmentRefID !== CouponInfoB[0].PaxSegmentRefID) {
        if (!CouponInfoA[0].PaxSegmentRefID) return 1;
        if (!CouponInfoB[0].PaxSegmentRefID) return -1;
      }

      if (a.Ptc !== b.Ptc) {
        return a.Ptc.localeCompare(b.Ptc);
      }

      return a['PaxRefID'].localeCompare(b['PaxRefID']) ? 1 : -1;
    });
  };

  $: if (selectedRow && openModal.type === 'TicketDocNbr') {
    const {
      Type,
      PaxRefID,
      CouponInfos,
      PaxJourneyList,
      OrderItem,
      JourneyOverview,
      BaggageAllowance,
      PaxSegmentList,
      TicketDocNbr,
      ReportingType,
      PassengerName,
      TicketStatus,
      OrderID,
      TicketingDate,
      BookingReference,
      TaxTotal,
      BaseAmount,
      Tax,
      IssuedDate,
      PaymentList,
      InConnectionDocNbr,
      TotalFareTicket,
      TicketDoc,
    } = selectedRow;

    const { title, render } = POPUP.find((it) => it.name === Type);
    const findBookingReference = BookingReference.find((bookingReference) => bookingReference.AirlineID);
    const dataPopupHeader = {
      PassengerName,
      TicketDocNbr,
      ReportingType,
      OrderID,
      TicketingDate: formatDate(TicketingDate, 'datetime'),
      AirlineID: findBookingReference?.AirlineID || EMPTY,
      ID: findBookingReference?.Id || EMPTY,
      TicketStatus,
    };

    // TODO: Data Footer in Popup
    const { TotalFare, PaymentType } = getTotalFarePaymentType(PaxRefID, OrderItem, PaymentList, Type, TotalFareTicket);

    function getCommission(commission) {
      const priceCommission = commission?.Amount?.Amount ?? EMPTY;
      const priceCurrencyCode = commission?.Amount?.CurrencyCode ?? 'KRW';
      const price = priceCommission === EMPTY ? EMPTY : `${formatPriceNumber(priceCommission)} ${priceCurrencyCode}`;
      if (price && commission?.Percentage) {
        return `${price}/${commission?.Percentage}`;
      } else if (commission?.Amount && !commission?.Percentage) {
        return price;
      } else if (!commission?.Amount && commission?.Percentage) {
        return commission?.Percentage ?? EMPTY;
      }
      return EMPTY;
    }
    let dataPopupFooter;
    if (selectedRow.Type === POPUP_TYPE_TICKET && CouponInfos.length && CouponInfos[0].Status === 'E') {
      dataPopupFooter = {
        TaxTotal: EMPTY,
        BaseAmount: EMPTY,
        Tax: EMPTY,
        TotalFare: EMPTY,
        PaymentType: EMPTY,
        Commission: EMPTY,
      };
    } else {
      const getTax = () => {
        let taxData = [];
        for (const taxItem of Tax) {
          if (has(taxItem, 'Amount') && has(taxItem.Amount, 'Amount') && has(taxItem.Amount, 'CurCode')) {
            taxData.push({
              TaxCode: taxItem?.TaxCode ?? EMPTY,
              Amount: `${formatPriceNumber(taxItem.Amount.Amount)} ${taxItem.Amount.CurCode ?? EMPTY}`,
            });
          }
        }
        return taxData;
      };
      dataPopupFooter = {
        TaxTotal,
        BaseAmount,
        Tax: getTax(),
        TotalFare,
        PaymentType,
        Commission: getCommission(OrderItem?.Commission),
      };
    }
    // TODO: End

    // TODO: Function handle Data in table Popup
    const handle = (CouponInfo, index) => {
      const { CouponNumber, PaxSegmentRefID, Status, ServiceRefID } = CouponInfo;
      let paxSegment = PaxSegmentRefID;
      let findService;
      // TODO: find PaxSegmentRefID with ServiceRefID
      const services = dataTicket?.Order?.OrderItem.reduce((acc, item) => {
        if (item.Service && item.Service.length > 0) {
          acc = [...acc, ...item.Service];
        }
        return acc;
      }, []);

      if (services.length) {
        findService = services.find((service) => ServiceRefID.includes(service.ServiceID));
        if (findService && !paxSegment) {
          paxSegment = findService.PaxSegmentRefID;
        }
      }

      if ([POPUP_TYPE_TICKET, POPUP_TYPE_EMDA].includes(Type)) {
        // TODO: logic get data in table in popup TICKET, EMD-A
        if (!paxSegment) return null;
        const { PaxJourneyID } = find(PaxJourneyList, (it) => it.PaxSegmentRefID.includes(paxSegment));
        const { MarketingCarrier, Departure, Arrival, Equipment, OperatingCarrier, Legs } = find(
          PaxSegmentList,
          (it) => it.PaxSegmentID === paxSegment
        );
        const PriceClassInfo = find(JourneyOverview, (it) => it.PaxJourneyRefID === PaxJourneyID)?.PriceClassInfo;
        let findFareBasis;
        if (OrderItem && OrderItem.FareDetail.length && OrderItem.FareDetail[0]?.FareComponent) {
          const findFareDetail = OrderItem.FareDetail.find((item) => item.PaxRefID.includes(PaxRefID));
          findFareBasis = find(findFareDetail?.FareComponent, (it) =>
            it.PaxSegmentRefID.includes(paxSegment)
          )?.FareBasis;
        }

        const getAircraftCode = () => {
          if (Equipment) return Equipment?.AircraftCode ?? EMPTY;
          if (Legs) {
            const findEquipment = find(Legs, (leg) => has(leg, 'Equipment'));
            if (findEquipment) return findEquipment?.Equipment?.AircraftCode ?? EMPTY;
          }
          return EMPTY;
        };
        if (Type === POPUP_TYPE_TICKET) {
          return {
            id: index,
            CouponNumber,
            Name: PriceClassInfo?.Name || EMPTY,
            Status,
            AirlineID: MarketingCarrier?.AirlineID,
            FlightNumber: MarketingCarrier?.FlightNumber.padStart(4, '0'),
            DepartureAirportCode: Departure?.AirportCode,
            ArrivalAirportCode: Arrival?.AirportCode,
            DepartureDateTime: formatDate(`${Departure?.Date} ${Departure?.Time}`, 'datetime'),
            ArrivalDateTime: formatDate(`${Arrival?.Date} ${Arrival?.Time}`, 'datetime'),
            CabinCode: formatCabinCode(findFareBasis?.CabinType[0]?.CabinCode || EMPTY),
            FareBasisCode: findFareBasis?.FareBasisCode ?? EMPTY,
            Baggage: getMaxWeightAllowance(BaggageAllowance, PaxJourneyID),
            Rbd: findFareBasis?.CabinType[0]?.Rbd || EMPTY,
            CodeShare: getCodeShare(OperatingCarrier, MarketingCarrier),
            AircraftCode: getAircraftCode(),
          };
        } else if (Type === POPUP_TYPE_EMDA) {
          const getRelatedTickets = () => {
            const { InConnectionDocNbr, CouponInfos } = TicketDoc;
            const InConnectionWithInfo = CouponInfos[0]?.InConnectionWithInfo;
            if (InConnectionWithInfo?.InConnectionCpnNbr) {
              return `${InConnectionDocNbr || InConnectionWithInfo?.InConnectionDocNbr || EMPTY} (${
                InConnectionWithInfo?.InConnectionCpnNbr || EMPTY
              })`;
            } else {
              const findCouponInfos = find(dataTicketTable, (ticketListItem) =>
                InConnectionDocNbr?.includes(ticketListItem.TicketDocNbr)
              );
              if (!findCouponInfos) return InConnectionWithInfo?.InConnectionDocNbr || EMPTY;
              const currentCouponNumber = find(
                findCouponInfos.CouponInfos,
                (CouponInfosItem) => CouponInfosItem.PaxSegmentRefID === paxSegment
              )?.CouponNumber;
              return `${InConnectionDocNbr || InConnectionWithInfo?.InConnectionDocNbr || EMPTY} (${
                currentCouponNumber || EMPTY
              })`;
            }
          };
          return {
            id: index,
            CouponNumber,
            Status,
            AirlineID: MarketingCarrier?.AirlineID,
            FlightNumber: MarketingCarrier?.FlightNumber.padStart(4, '0'),
            DepartureAirportCode: Departure?.AirportCode,
            ArrivalAirportCode: Arrival?.AirportCode,
            DepartureDateTime: formatDate(`${Departure?.Date} ${Departure?.Time}`, 'datetime'),
            EMDdetails: getEmd(findService),
            relatedTickets: getRelatedTickets(),
          };
        }
      } else if (Type === POPUP_TYPE_EMDS) {
        const getRelatedTickets = () => {
          const { InConnectionDocNbr, CouponInfos } = TicketDoc;
          if (InConnectionDocNbr) return InConnectionDocNbr;
          else {
            const filterInConnectionWithInfo = filter(CouponInfos, (itCouponInfos) =>
              has(itCouponInfos, 'InConnectionWithInfo')
            );
            return filterInConnectionWithInfo[0]?.InConnectionWithInfo.InConnectionDocNbr || EMPTY;
          }
        };
        return {
          id: index,
          CouponNumber,
          Status,
          serviceDate: formatDate(IssuedDate, 'datetime'),
          code: findService?.Definition?.ServiceCode || EMPTY,
          EMDdetails: getEmd(findService),
          relatedTickets: getRelatedTickets(),
        };
      }
    };
    // TODO: End

    const dataTable = filter(map(CouponInfos, handle), (item) => !isEqual(item, null));
    dataPopup = {
      title,
      render,
      dataTable,
      dataPopupHeader,
      dataPopupFooter,
      InConnectionDocNbr,
      popupType: CouponInfos[0]?.Status ?? EMPTY,
    };
  }

  $: {
    const { DataLists, Order } = dataTicket;
    if (!isEmpty(Order) && !isEmpty(DataLists)) {
      dataTicketTable = getDataTableTicket(DataLists, Order);
    }
  }
  const handleClickCellOpenModal = (dataCell, dataRow) => {
    selectedRow = dataRow;
    openModal = {
      isOpen: true,
      type: dataCell.key,
    };
  };

  const closeModal = () => {
    openModal.isOpen = false;
    openModal.type = 'none';
  };
</script>

<TableTicket
  {handleClickCellOpenModal}
  ticketList={sortDataTableTicket(dataTicketTable)}
  airlineID={getMainBookingReference(dataTicket?.Order?.BookingReference)?.AirlineID ?? EMPTY}
/>

{#if dataPopup}
  <TicketingInformation type={selectedRow.Type} {dataPopup} {openModal} {closeModal} />
{/if}
