<script>
  import { PrimaryButton } from 'src/components/common/button';
  import { keys, sortBy, uniq, cloneDeep, isEmpty } from 'lodash';
  import FlightTable from 'src/components/reservation-management-report/reservation-ticketing/FlightTable/index.svelte';
  import { getAirShopping } from 'src/service/reservationTicketing';
  import Condition from './Condition/index.svelte';
  import ConditionFilterClient from './ConditionFilterClient/index.svelte';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app';
  import { screenIsNotEvent, message } from 'src/store';
  import ArrowRight from 'carbon-icons-svelte/lib/ArrowRight.svelte';
  import {
    filterByAirline,
    filterByTimeRange,
    filterDirectOrViaFlights,
    filterOfferByMatchResult,
    formatDataTableAirShopping,
    getAirlineOffer,
  } from './handleAirShopping';
  import { formatDateTime } from 'src/utils/DateTime.js';
  import { formatCabinCode, formatPriceNumber } from 'src/utils/appHelper';
  import ArrowUp from 'carbon-icons-svelte/lib/ArrowUp.svelte';
  import { CustomLoading } from 'src/components/common/loading';
  import PaymentInformationButton from 'src/components/common/button/PaymentInformationButton.svelte';
  import FlightDetailTicket from './FlightDetailTicket/index.svelte';
  import { WarnButton } from 'src/components/common/button';

  const headersTable = [
    {
      key: 'logo',
      value: '항공사',
      width: '132px',
    },
    {
      key: 'airline',
      value: '편명',
    },
    {
      key: 'departureTime',
      value: '출발시간',
    },
    {
      key: 'arrivalTime',
      value: '도착시간',
    },
    {
      key: 'via',
      value: '경유',
    },
    {
      key: 'flightTime',
      value: '비행시간',
    },
    {
      key: 'rbdFareBasis',
      value: 'RBD',
    },
    {
      key: 'seatClass',
      value: '좌석등급',
    },
    {
      key: 'priceClass',
      value: 'PriceClass',
    },
    {
      key: 'baggage',
      value: '수하물',
    },
    {
      key: 'emphysema',
      value: '기종',
    },
    {
      key: 'codeShare',
      value: '코드쉐어',
    },
    {
      key: 'hiddenStopover',
      value: '숨은경유지',
    },
    {
      key: 'totalFee',
      value: '총 요금',
      width: '180px',
    },
    {
      key: 'otherFares',
      value: '다른운임',
      width: '80px',
    },
  ];

  let dataTableAirShopping = [];
  let dataFilterTableAirShopping = [];
  let conditionParams = EMPTY;
  let flightTableRef;
  let isHiddenBtnScrollTop = true;
  let airlines = [];
  let conditions = EMPTY;
  let statusPage = 'NONE'; // 'LOADING_DATA'|'SUCCESS_DATA'
  let flightTableSelectedData = EMPTY;
  let flightDetailTicket = {
    open: false,
    data: EMPTY,
  };
  let warningList = [];

  const formatParams = (conditionQuery) => {
    const { airlineCode, cabin, direct, paxList, ffnList, promotionCodeList } = conditionQuery;
    let OriginDestList = getCurrentSearchByCondition(conditionQuery);
    OriginDestList = OriginDestList.map((item) => {
      return {
        ...item,
        DepartureDate: formatDateTime(item.DepartureDate),
      };
    });

    const getPaxList = () => {
      return paxList.map((paxItem) => {
        const ffn = ffnList[paxItem.Ptc].find((it) => it.PaxID === paxItem.PaxID);
        return {
          Ptc: paxItem.Ptc,
          PaxID: paxItem.PaxID,
          LoyaltyProgramAccount: ffn
            ? [
                {
                  ProgramCode: ffn.ffnType,
                  AccountNumber: ffn.ffnCode,
                },
              ]
            : [],
        };
      });
    };
    const SpecialFareCriteria = promotionCodeList.special.map((specialItem) => ({
      AirlineID: specialItem.airlineId,
      AccountID: specialItem.accountId,
    }));
    let ProgramCriteria = [];

    for (const { contractId, typeCode, airlineId, accountId } of promotionCodeList.program) {
      const isHasAirlineId = ProgramCriteria.find(
        (it) => it.ContractID === contractId && it.AirlineID === airlineId && it.ContractID === contractId
      );
      if (!isHasAirlineId)
        ProgramCriteria.push({
          ContractID: contractId,
          AirlineID: airlineId,
          TypeCode: typeCode,
          AccountID: [accountId],
        });
      else isHasAirlineId.AccountID.push(accountId);
    }
    ProgramCriteria = ProgramCriteria.map((programCriteriaItem) => {
      if (programCriteriaItem.TypeCode === EMPTY) delete programCriteriaItem.TypeCode;
      return programCriteriaItem;
    });

    const params = {
      Query: {
        PaxList: getPaxList(),
        Cabin: cabin,
        Criteria: {
          Direct: direct,
          AirlinePreference: {
            AirlineID: airlineCode,
          },
          SpecialFareCriteria,
          ProgramCriteria,
        },

        OriginDestList,
      },
    };
    return params;
  };

  const getAirlines = (Offer, DataLists) => {
    return uniq(Offer.map((item) => getAirlineOffer(item, DataLists)?.AirlineID));
  };

  async function searchAirShopping(event) {
    dataTableAirShopping = [];
    dataFilterTableAirShopping = [];
    airlines = [];
    conditionParams = event.detail;
    conditions = EMPTY;
    flightTableSelectedData = EMPTY;
    try {
      statusPage = 'LOADING_DATA';
      screenIsNotEvent.set(true);
      isHiddenBtnScrollTop = true;
      const params = formatParams(event.detail);
      const response = await getAirShopping(params);
      statusPage = 'SUCCESS_DATA';

      const { ResultMessage = {}, DataLists = {}, Offer = {} } = response?.data;
      warningList = ResultMessage?.MessageDetail?.Warnings ?? [];
      screenIsNotEvent.set(false);
      if (ResultMessage?.Code && ResultMessage?.Code !== CODE_SUCCESS) {
        message.update(() => [
          {
            title: 'Error',
            subtitle: `${ResultMessage?.Code} ${ResultMessage?.Message}` || EMPTY,
            type: 'error',
            caption: EMPTY,
          },
        ]);
        return;
      }
      /* ONE_WAY: data does not need to check Offer/MatchResult */
      const offerList = conditionParams.flightWay.type === 'ONE_WAY' ? Offer : filterOfferByMatchResult(Offer, 'Full');
      airlines = getAirlines(offerList, DataLists);
      dataTableAirShopping = formatDataTableAirShopping(DataLists, offerList);
    } catch (e) {
      screenIsNotEvent.set(false);
      console.error('Error fetching data AirShopping', e);
    }
  }
  const getCurrentSearchByCondition = (conditionQuery) => {
    const { data, type } = conditionQuery.flightWay;
    let dataHistory = [];
    if (type === 'TWO_WAY') {
      dataHistory = [
        {
          OriginCode: data.OriginCode1,
          DestCode: data.DestCode1,
          DepartureDate: data[type].DepartureDate1,
        },
        {
          OriginCode: data.DestCode1,
          DestCode: data.OriginCode1,
          DepartureDate: data[type].DepartureDate2,
        },
      ];
    }
    if (type === 'ONE_WAY') {
      dataHistory.push({
        OriginCode: data.OriginCode1,
        DestCode: data.DestCode1,
        DepartureDate: data[type].DepartureDate1,
      });
    }
    if (type === 'MULTI_WAY') {
      for (let i = 1; i <= data.multiWayCount; i++) {
        dataHistory.push({
          OriginCode: data[`OriginCode${i}`],
          DestCode: data[`DestCode${i}`],
          DepartureDate: data[type][`DepartureDate${i}`],
        });
      }
    }
    return dataHistory;
  };

  const getDataFlightByAirShopping = (dataNeedConvert) => {
    const EMPTY_CELL = [];
    let dataTable = [];

    dataTable = sortBy(dataNeedConvert, ({ totalFee }) => totalFee).map(
      ({ cardsTicket, data, logo, totalFee, infoPayment, offerID, airlineName, detailPrice, owner }) => {
        const newData = data.map(({ main, children }) => {
          //  get newMain
          const newMain = {
            airline: main.airline,
            departureTime: [main.departureTime.onPoint, main.departureTime.date, main.departureTime.time],
            arrivalTime: [main.arrivalTime.offPoint, main.arrivalTime.date, main.arrivalTime.time],
            flightTime: [main.flightTime],
            priceClass: [main.priceClass],
            via: [main.via],
            rbdFareBasis: [main.rbdFareBasis],
            seatClass: main.seatClass,
            baggage: [main.baggage],
            emphysema: [main.emphysema],
            codeShare: main.codeShare,
            hiddenStopover: [main.hiddenStopover],
          };

          const newChildren = children.map((chItem) => {
            return {
              airline: [chItem.airline],
              departureTime: [chItem.departureTime.onPoint, chItem.departureTime.date, chItem.departureTime.time],
              arrivalTime: [chItem.arrivalTime.offPoint, chItem.arrivalTime.date, chItem.arrivalTime.time],
              flightTime: [chItem.flightTime],
              priceClass: EMPTY_CELL,
              via: EMPTY_CELL,
              rbdFareBasis: [`${chItem.rbdFareBasis.rbd}`],
              seatClass: [formatCabinCode(chItem.seatClass)],
              baggage: EMPTY_CELL,
              emphysema: [chItem.emphysema],
              codeShare: [chItem.codeShare],
              hiddenStopover: [chItem.hiddenStopover],
            };
          });

          return {
            main: newMain,
            children: newChildren,
          };
        });
        return {
          logo,
          totalFee: `${formatPriceNumber(totalFee)} ${detailPrice.curCode}`,
          data: newData,
          cardsTicket,
          infoPayment,
          offerID,
          airlineName,
          owner,
        };
      }
    );
    return dataTable;
  };

  const handleFilterAirShoppingClient = (_conditions) => {
    let data = cloneDeep(dataTableAirShopping);
    conditions = _conditions;
    if (conditions) {
      const { direct = [], airline = [], departureTime = [], arrivalTime = [] } = conditions || EMPTY;
      if (direct.length) {
        data = filterDirectOrViaFlights(data, direct);
      }
      if (airline.length) {
        data = filterByAirline(data, airline);
      }

      if (departureTime.length) {
        data = filterByTimeRange(data, departureTime, (el) => el.data[0].main.departureTime.time || EMPTY);
      }

      if (arrivalTime.length) {
        data = filterByTimeRange(data, arrivalTime, (el) => el.data[el.data.length - 1].main.arrivalTime.time || EMPTY);
      }
    }
    dataFilterTableAirShopping = data;
  };
  const getFlightTableByEvent = (event) => {
    if (event.detail == EMPTY) return EMPTY;
    const { type, data } = event.detail;
    const { offerID, owner } = data;
    if (type === 'GROUP') {
      return dataTableAirShopping?.find((item) => item.offerID === offerID && item.owner === owner);
    }
    if (type === 'COUPON') return data;
  };
</script>

<div id="reservation-ticketing-wrapper">
  <div class="condition">
    <Condition on:reset={(event) => console.log({ reset: event })} on:submit={searchAirShopping} />
  </div>

  <div class="sticky-container">
    {#if statusPage === 'SUCCESS_DATA'}
      <div class="history">
        <h4>검색결과</h4>
        {#key conditionParams}
          {#if conditionParams !== EMPTY}
            <ul>
              {#each getCurrentSearchByCondition(conditionParams) as item, index}
                <li>
                  <span>
                    구간{index + 1}:
                  </span>
                  <span>
                    {item.OriginCode}
                  </span>
                  <ArrowRight />
                  <span>
                    {item.DestCode}
                  </span>
                  <span>
                    {item.DepartureDate}
                  </span>
                </li>
              {/each}
            </ul>
          {/if}
        {/key}
      </div>
      {#if !isEmpty(warningList)}
        <div class="layout-warning">
          <WarnButton Warnings={warningList} pattern={['Owner', 'Message']} />
        </div>
      {/if}
    {/if}
    <div class="container">
      {#if statusPage === 'LOADING_DATA'}
        <div class="loading-table-for-reservation-ticketing">
          <CustomLoading />
        </div>
      {:else if statusPage === 'SUCCESS_DATA'}
        <div class="container__options-content">
          <ConditionFilterClient
            {airlines}
            on:filter-client-airshopping={(event) => handleFilterAirShoppingClient(event.detail)}
          />
        </div>
        <div class="container__view-content">
          {#key dataTableAirShopping || dataFilterTableAirShopping}
            <FlightTable
              bind:this={flightTableRef}
              headers={headersTable}
              tableData={getDataFlightByAirShopping(!conditions ? dataTableAirShopping : dataFilterTableAirShopping)}
              tableHeight="100%"
              on:scroll={(event) => {
                isHiddenBtnScrollTop = event.detail.scrollTop < 100;
              }}
              on:row-group-select={(event) => {
                flightTableSelectedData = getFlightTableByEvent(event);
                console.log(flightTableSelectedData);
              }}
              on:detail-ticket={(event) => {
                flightDetailTicket = {
                  open: true,
                  data: getFlightTableByEvent(event),
                };
              }}
            />
          {/key}
        </div>
      {/if}
    </div>
  </div>
</div>

<button
  on:click={() => {
    flightTableRef?.handleScrollTop();
  }}
  class="flightTable__btn-scroll-top"
  class:flightTable__btn-scroll-top--hidden={isHiddenBtnScrollTop}><ArrowUp /></button
>

{#if flightTableSelectedData !== EMPTY}
  <div class="flightTable__actions-box">
    <PrimaryButton
      kind="secondary"
      on:click={() => {
        flightTableRef.handleResetSelected();
      }}>취소</PrimaryButton
    >
    <PaymentInformationButton dataRowSelected={flightTableSelectedData} />
  </div>
{/if}

<FlightDetailTicket bind:ruleData={flightDetailTicket} />
