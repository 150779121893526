<svg width="100%" height="100%" viewBox="0 0 398 436" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_5388_514176" fill="white">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M208.329 316.647L254.298 425.931H393.172L209.297 4.33838H179.296L127.037 124.308L208.329 316.647ZM158.288 351.83C158.288 395.703 122.856 431.269 79.1476 431.269C35.4394 431.269 0.00694275 395.703 0.00694275 351.83C0.00694275 307.958 35.4394 272.392 79.1476 272.392C122.856 272.392 158.288 307.958 158.288 351.83Z"
    />
  </mask>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M208.329 316.647L254.298 425.931H393.172L209.297 4.33838H179.296L127.037 124.308L208.329 316.647ZM158.288 351.83C158.288 395.703 122.856 431.269 79.1476 431.269C35.4394 431.269 0.00694275 395.703 0.00694275 351.83C0.00694275 307.958 35.4394 272.392 79.1476 272.392C122.856 272.392 158.288 307.958 158.288 351.83Z"
    fill="#2F303B"
  />
  <path
    d="M254.298 425.931L252.667 426.616L253.123 427.699H254.298V425.931ZM208.329 316.647L209.959 315.961L209.958 315.958L208.329 316.647ZM393.172 425.931V427.699H395.873L394.793 425.224L393.172 425.931ZM209.297 4.33838L210.918 3.63131L210.455 2.56971H209.297V4.33838ZM179.296 4.33838V2.56971H178.137L177.675 3.63204L179.296 4.33838ZM127.037 124.308L125.415 123.601L125.112 124.297L125.408 124.996L127.037 124.308ZM255.928 425.245L209.959 315.961L206.699 317.333L252.667 426.616L255.928 425.245ZM393.172 424.162H254.298V427.699H393.172V424.162ZM207.675 5.04545L391.55 426.638L394.793 425.224L210.918 3.63131L207.675 5.04545ZM179.296 6.10705H209.297V2.56971H179.296V6.10705ZM128.658 125.014L180.918 5.04472L177.675 3.63204L125.415 123.601L128.658 125.014ZM209.958 315.958L128.666 123.619L125.408 124.996L206.7 317.336L209.958 315.958ZM79.1476 433.038C123.839 433.038 160.057 396.674 160.057 351.83H156.52C156.52 394.733 121.873 429.501 79.1476 429.501V433.038ZM-1.76173 351.83C-1.76173 396.674 34.4563 433.038 79.1476 433.038V429.501C36.4225 429.501 1.77561 394.733 1.77561 351.83H-1.76173ZM79.1476 270.623C34.4563 270.623 -1.76173 306.987 -1.76173 351.83H1.77561C1.77561 308.928 36.4225 274.16 79.1476 274.16V270.623ZM160.057 351.83C160.057 306.987 123.839 270.623 79.1476 270.623V274.16C121.873 274.16 156.52 308.928 156.52 351.83H160.057Z"
    fill="#2F303B"
    mask="url(#path-1-inside-1_5388_514176)"
  />
</svg>
