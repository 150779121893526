import { writable } from 'svelte/store';

const initEventValue = { eventName: '', payload: null };
const { subscribe, set } = writable(initEventValue);
let whiteListEvent = [];
const listen = (eventName, callBack) => {
  const unsubscribe = subscribe((value) => {
    if (value.eventName === eventName) {
      Promise.resolve().then(() => callBack(value.payload));
    }
  });
  whiteListEvent.push({
    eventName,
    unsubscribe,
  });
};

const action = (eventName, payload) => {
  set({
    eventName,
    payload,
  });
};

const remove = (eventName) => {
  const blackEvent = whiteListEvent.find((event) => event.eventName === eventName);
  if (blackEvent) blackEvent.unsubscribe();
  whiteListEvent = whiteListEvent.filter((event) => event.eventName !== eventName);
};

const appEvent = {
  listen,
  action,
  remove,
};
export default appEvent;
