<script>
  import { Loading } from 'carbon-components-svelte';
  export let title;
</script>

<div class="size-loading">
  <Loading withOverlay={false} small />
  {#if title}
    <span class="description-loading"> {title}</span>
  {/if}
</div>

<style>
  .size-loading {
    transform: scale(2.5);
    display: flex;
  }
  .description-loading {
    padding-left: 4px;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
