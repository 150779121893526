<script>
  import { Form, FormGroup, Button, PasswordInput, ToastNotification } from 'carbon-components-svelte';
  import { Logo as LunaIcon } from 'src/assets/icons';
  import { EMPTY } from 'src/constants/app';
  import { signIn } from 'src/service/auth';
  import { user } from 'src/store';
  import { CustomTextInput } from 'src/components/common/input';
  import 'src/styles/pages/signIn.page.scss';

  let agencyId = EMPTY;
  let username = EMPTY;
  let password = EMPTY;
  let errorLogin = EMPTY;
  user.useSessionStorage();
  async function onFormSubmit() {
    const res = await signIn({ agencyId, username, password });
    if (res?.code === 200) {
      const accessToken = res.data.accessToken;
      const expirationTime = res.data.expirationTime;

      user.set({
        agencyId,
        username,
        password,
        accessToken,
        expirationTime,
        warn: false,
      });
      window.location.href = '/';
    } else {
      user.set('LOGOUT');
      errorLogin = res.message;
    }
  }
</script>

<div class="app-sign-in">
  <Form
    class="form-sign-in"
    on:submit={(e) => {
      e.preventDefault();
      onFormSubmit();
    }}
  >
    <div class="form-logo"><LunaIcon /></div>
    <div class="text-input">
      <FormGroup>
        <CustomTextInput labelText="Agency ID" required bind:value={agencyId} placeholder="Enter Agency ID" />
      </FormGroup>
    </div>
    <div class="text-input">
      <FormGroup>
        <CustomTextInput labelText="User ID" required bind:value={username} placeholder="Enter User ID" />
      </FormGroup>
    </div>
    <div class="text-input">
      <FormGroup>
        <PasswordInput labelText="Password" required bind:value={password} placeholder="Enter password" />
      </FormGroup>
    </div>
    <Button class="submit-input" type="submit">login</Button>
  </Form>
  <!-- svelte-ignore a11y-img-redundant-alt -->
  <img src="https://cdn.ndcaggregator.kr/image/luna_login.gif" alt="login image" class="videoWrapper ytplayer" />
  <div class="toast-sign-in">
    {#key errorLogin}
      {#if errorLogin.length > 0}
        <ToastNotification
          class="toast-content"
          lowContrast
          role="alert"
          kind="error"
          title="Error"
          subtitle={errorLogin}
          on:close={() => (errorLogin = EMPTY)}
        />
      {/if}
    {/key}
  </div>
</div>
