<script>
  import Add from 'carbon-icons-svelte/lib/Add.svelte';
  import PromotionCode from 'src/components/modal/PromotionCode.svelte';
  import FfnInfomation from 'src/components/modal/FFNInfomation.svelte';
  import { message } from 'src/store';
  import { EMPTY } from 'src/constants/app.js';
  import './Condition.scss';

  export let paxList = [];
  let openModalPromotionCode = false;
  let openModalFFNInformation = false;
  let ffnInfoList = [];

  export let ffnList = {
    ADT: [],
    CHD: [],
    INF: [],
  };

  export let promotionCodeList = {
    special: [],
    program: [],
  };

  function handleOpenModalPromotionCode() {
    openModalPromotionCode = true;
  }

  function handleOpenModalFFNInformation() {
    // TODO: Validate first, if user not choose Passenger, show error
    const notChoosePaxList = paxList.every((el) => el.value === 0);
    if (notChoosePaxList) {
      message.set([{ title: 'Error', type: 'error', subtitle: '승객을 선택해주세요', caption: EMPTY }]);
    } else {
      openModalFFNInformation = true;
    }
  }
</script>

<PromotionCode bind:open={openModalPromotionCode} bind:promotionCodeList />
<FfnInfomation bind:open={openModalFFNInformation} {ffnInfoList} bind:ffnList bind:paxList />

<div class="ffn-promtion-code-container">
  <div class="ffn-container">
    <button type="button" class="ffn-btn" on:click={handleOpenModalFFNInformation}><span>FFN 정보</span> <Add /></button
    >
    <div class="ffn-wrapper">
      <div class="ffn-list">
        {#each ffnList.ADT as ffn (ffn.id)}
          <div class="ffn-item">
            <div class="passenger-name">
              <p>{ffn.Ptc} {ffn.count}</p>
            </div>
            <div class="account-id">
              <p>{ffn.ffnCode}</p>
            </div>
          </div>
        {/each}
      </div>
      <div class="ffn-list">
        {#each ffnList.CHD as ffn (ffn.id)}
          <div class="ffn-item">
            <div class="passenger-name">
              <p>{ffn.Ptc} {ffn.count}</p>
            </div>
            <div class="account-id">
              <p>{ffn.ffnCode}</p>
            </div>
          </div>
        {/each}
      </div>
      <div class="ffn-list">
        {#each ffnList.INF as ffn (ffn.id)}
          <div class="ffn-item">
            <div class="passenger-name">
              <p>{ffn.Ptc} {ffn.count}</p>
            </div>
            <div class="account-id">
              <p>{ffn.ffnCode}</p>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
  <div class="line" />
  <div class="promotion-code-container">
    <button type="button" class="promotion-code-btn" on:click={handleOpenModalPromotionCode}>
      <span>Promotion Code</span>
      <Add />
    </button>
    <div class="promotion-code-wrapper">
      <div class="promotion-code-list">
        {#each [...promotionCodeList.special, ...promotionCodeList.program] as promotionCode, i (promotionCode.id)}
          <div class="promotion-code-item">
            <div class="passenger-name">
              <p>{promotionCode.airlineId}</p>
            </div>
            <div class="account-id">
              <p>{promotionCode.accountId}</p>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>
