<script>
  import { ExpandCheckBox } from 'src/components/common/checkbox';
  import { PrimaryButton } from 'src/components/common/button';
  import { EMPTY } from 'src/constants/app';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let airlines = [];

  let options = [
    {
      title: '직항/경유',
      variableName: 'direct',
      data: [
        {
          id: 1,
          text: '직항',
          value: 'directFlight',
        },
        {
          id: 2,
          text: '경유',
          value: 'via',
        },
      ],
    },
    {
      title: '항공사',
      variableName: 'airline',
      data: [],
    },
    {
      title: '출발시간',
      variableName: 'departureTime',
      data: [
        {
          id: 1,
          text: '00:00 ~ 05:59',
          value: ['0000', '0559'],
        },
        {
          id: 2,
          text: '06:00 ~ 11:59',
          value: ['0600', '1159'],
        },
        {
          id: 3,
          text: '12:00 ~ 17:59',
          value: ['1200', '1759'],
        },
        {
          id: 4,
          text: '18:00 ~ 23:59',
          value: ['1800', '2359'],
        },
      ],
    },
    {
      title: '도착시간',
      variableName: 'arrivalTime',
      data: [
        {
          id: 1,
          text: '00:00 ~ 05:59',
          value: ['0000', '0559'],
        },
        {
          id: 2,
          text: '06:00 ~ 11:59',
          value: ['0600', '1159'],
        },
        {
          id: 3,
          text: '12:00 ~ 17:59',
          value: ['1200', '1759'],
        },
        {
          id: 4,
          text: '18:00 ~ 23:59',
          value: ['1800', '2359'],
        },
      ],
    },
  ];
  let conditions = EMPTY;
  let refExpandCheckBox = {};
  const handleSelectedFilter = (event, variableName) => {
    const { selected } = event.detail;
    conditions = { ...conditions, [variableName]: selected };
  };
  const handleFilter = () => {
    dispatch('filter-client-airshopping', conditions);
  };

  const handleResetFilter = () => {
    conditions = EMPTY;
    for (const key in refExpandCheckBox) {
      refExpandCheckBox[key].handleReset();
    }
    dispatch('filter-client-airshopping', conditions);
  };
  $: {
    options = options.map((item) => {
      if (item.variableName === 'airline') {
        if (!airlines.length) item.data = [];
        else {
          item.data = airlines.map((item) => {
            return { id: item, text: item, value: item };
          });
        }
      }
      return item;
    });
  }
</script>

<div>
  <div>
    {#key options}
      {#each options as item (item.title)}
        <ExpandCheckBox
          bind:this={refExpandCheckBox[item.variableName]}
          title={item.title}
          data={item.data}
          variableName={item.variableName}
          on:change={(event) => handleSelectedFilter(event, item.variableName)}
        />
      {/each}
    {/key}
  </div>
  <div class="filter-reset">
    <PrimaryButton kind="secondary" width="100%" on:click={handleFilter}
      ><div class="button-text">결과 내 재검색</div></PrimaryButton
    >
  </div>
  <div>
    <PrimaryButton kind="secondary" width="100%" on:click={handleResetFilter}
      ><div class="button-text">필터 초기화</div></PrimaryButton
    >
  </div>
</div>

<style>
  .filter-reset {
    margin-top: 12px;
    margin-bottom: 1px;
  }
  .button-text {
    text-align: center;
    width: 100%;
  }
</style>
