<script>
  import { getContext, createEventDispatcher, onMount, onDestroy } from 'svelte';
  import {
    Button,
    DataTable,
    Toolbar,
    ToolbarContent,
    ProgressIndicator,
    ProgressStep,
    ComposedModal,
    ModalHeader,
    InlineLoading,
  } from 'carbon-components-svelte';
  import { map, cloneDeep, find, has, intersection, isEmpty } from 'lodash';
  import { CODE_SUCCESS, EMPTY, POPUP_TYPE_TICKET, CABIN_TYPE } from 'src/constants/app.js';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';
  import ChooseAChangeItinerary from './ChooseAChangeItinerary.svelte';
  import ItineraryInquiry from './ItineraryInquiry/index.svelte';
  import CheckAndChangeItinerary from './CheckAndChangeItinerary.svelte';
  import { eventStore, screenIsNotEvent } from 'src/store';
  import RuleCoveredServices from '../ticket-infomation-table/RuleCoveredServices.svelte';
  import { formatDate, getCabinName, getMainBookingReference, getMaxWeightAllowance } from 'src/utils/appHelper.js';
  import { getServiceList } from 'src/service/reservationTicketing.js';
  import BaggageInformation from 'src/components/modal/baggage-information-modal/index.svelte';
  import { SeatButton } from 'src/components/common/button';
  import { splitConvertArrayId } from 'src/utils/appHelper';
  import './index.scss';

  export let orderRetrieve;
  export let isPayLater;
  export let orderId = EMPTY;
  export let airlineID = EMPTY;

  const tab = getContext('tab-info');
  const MIN_STEP = 1;
  const MAX_STEP = 3;
  const headerTable = [
    {
      key: 'airlineId',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.airlineId', {
        default: '항공사',
      }),
    },
    {
      key: 'flightNumber',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.flightNumber', {
        default: '편명',
      }),
    },
    {
      key: 'departureAirportCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.departureAirportCode', {
        default: '출발지',
      }),
    },
    {
      key: 'arrivalAirportCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.arrivalAirportCode', {
        default: '도착지',
      }),
    },
    {
      key: 'departureDate',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.departureDate', {
        default: '출발일',
      }),
    },
    {
      key: 'departureTime',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.departureTime', {
        default: '출발시간',
      }),
    },
    {
      key: 'arrivalDate',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.arrivalDate', {
        default: '도착일',
      }),
    },
    {
      key: 'arrivalTime',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.arrivalTime', {
        default: '도착시간',
      }),
    },
    {
      key: 'cabinCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.cabinCode', {
        default: '좌석등급',
      }),
    },
    {
      key: 'name',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.name', {
        default: 'PriceClass',
      }),
    },
    {
      key: 'rbd',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.rbd', {
        default: 'RBD',
      }),
    },
    {
      key: 'maxWeightAllowance',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.maxWeightAllowance', {
        default: '수하물',
      }),
    },
    {
      key: 'aircraftCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.aircraftCode', {
        default: '기종',
      }),
    },
    {
      key: 'typeAirlineId',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.typeAirlineId', {
        default: '코드쉐어',
      }),
    },
    {
      key: 'seg',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.seg', {
        default: '숨은경유지',
      }),
    },
    {
      key: 'status',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.dataTable.headers.status', {
        default: '상태',
      }),
    },
  ];
  const dispatch = createEventDispatcher();
  const STEP_CONTENT = [
    {
      stepId: 1,
      component: ChooseAChangeItinerary,
      rowSelected: [],
      paxJourneyTable: [],
    },
    {
      stepId: 2,
      component: ItineraryInquiry,
      dataItineraryInquiry: [],
      tabsData: [],
      toolbarData: [],
    },
    {
      stepId: 3,
      component: CheckAndChangeItinerary,
      data: [],
      rowSelected: [],
      dataLastStep: [],
      paxJourneyTable: [],
    },
  ];
  let serviceList = {};
  let isLoadingServiceList = false;
  let openChangeOfItinerary = false;
  let currentStep = MIN_STEP;
  let openRulesCoveredServices = {
    isOpen: false,
    type: 'none',
    popupCoveredServices: {
      headers: [],
      rows: [],
    },
  };
  let stepContent = cloneDeep(STEP_CONTENT);
  let itineraryInfo = [];
  let openModalBaggage = false;

  function getItineraryInfo(orderRetrieve) {
    let itineraryInfo = [];
    let OrderItem = orderRetrieve?.Order?.OrderItem;
    let fareComponent = [];
    const paxIdListADTorCHD = orderRetrieve.DataLists.PaxList.filter((item) => ['ADT', 'CHD'].includes(item.Ptc)).map(
      ({ PaxID }) => PaxID
    );

    for (let i = 0; i < OrderItem?.length; i++) {
      const check = find(OrderItem[i].Service, (sv) => !has(sv, 'Definition'));
      if (check) {
        const PaxRefID = splitConvertArrayId(OrderItem[i].PaxRefID);
        if (isEmpty(intersection(paxIdListADTorCHD, PaxRefID))) continue;
        OrderItem[i]?.FareDetail.forEach((item) => (fareComponent = [...fareComponent, ...item.FareComponent]));
      }
    }
    fareComponent = fareComponent.filter((item) => has(item, 'FareBasis'));

    const paxSegmentList = orderRetrieve?.DataLists?.PaxSegmentList;
    const journeyOverview = orderRetrieve?.Order?.JourneyOverview;
    const baggageAllowance = orderRetrieve?.Order?.BaggageAllowance;
    const PaxJourneyList = orderRetrieve?.DataLists?.PaxJourneyList;

    itineraryInfo =
      paxSegmentList &&
      paxSegmentList.map((item, index) => {
        const findFareComponent = fareComponent.find((fare) => fare['PaxSegmentRefID'].includes(item['PaxSegmentID']));
        const currentPaxJourneyID = find(PaxJourneyList, (it) =>
          it.PaxSegmentRefID.includes(item?.PaxSegmentID)
        )?.PaxJourneyID;
        const findJourneyOverview = journeyOverview.find((journey) => journey?.PaxJourneyRefID === currentPaxJourneyID);
        let typeAirlineId = EMPTY;
        if (item?.OperatingCarrier) {
          const airlineIdOperatingCarrier = item?.OperatingCarrier?.AirlineID || EMPTY;
          const airlineIdMarketingCarrier = item?.MarketingCarrier?.AirlineID || EMPTY;
          if (airlineIdOperatingCarrier !== airlineIdMarketingCarrier) {
            if (airlineIdOperatingCarrier) typeAirlineId = airlineIdOperatingCarrier;
            else typeAirlineId = item?.OperatingCarrier?.Name || EMPTY;
          } else {
            typeAirlineId = EMPTY;
          }
        }

        const getStatus = () => {
          let listService = [];
          for (const orderIt of OrderItem) {
            for (const sv of orderIt.Service) {
              if (sv.PaxSegmentRefID === item.PaxSegmentID && !has(sv, 'Definition') && !has(sv, 'SelectedSeat'))
                listService.push(sv);
            }
          }
          return listService[0]?.Status || EMPTY;
        };
        const getSeg = () => {
          if (!item.Legs.length) return EMPTY;
          return item.Legs[0]?.Arrival?.AirportCode ?? EMPTY;
        };
        return {
          id: index + 1,
          airlineId: item?.MarketingCarrier?.AirlineID || EMPTY,
          flightNumber: item?.MarketingCarrier?.FlightNumber.padStart(4, '0') || EMPTY,
          departureAirportCode: item?.Departure?.AirportCode || EMPTY,
          arrivalAirportCode: item?.Arrival?.AirportCode || EMPTY,
          departureDate: formatDate(item?.Departure?.Date || EMPTY, 'date'),
          departureTime: item?.Departure?.Time || EMPTY,
          arrivalDate: formatDate(item?.Arrival?.Date || EMPTY, 'date'),
          arrivalTime: item?.Arrival?.Time || EMPTY,
          cabinCode: getCabinName(
            airlineID,
            findFareComponent?.FareBasis?.CabinType.find((cabin) => cabin.PaxSegmentRefID === item['PaxSegmentID'])
              ?.CabinCode || EMPTY
          ),
          name: findJourneyOverview?.PriceClassInfo?.Name || EMPTY,
          rbd:
            findFareComponent?.FareBasis?.CabinType.find((cabin) => cabin.PaxSegmentRefID === item['PaxSegmentID'])
              ?.Rbd || EMPTY,
          maxWeightAllowance: getMaxWeightAllowance(baggageAllowance, currentPaxJourneyID),
          aircraftCode: item?.Equipment?.AircraftCode || EMPTY,
          typeAirlineId: typeAirlineId,
          seg: getSeg(),
          status: getStatus(),
        };
      });
    return itineraryInfo;
  }
  function handleClickOpenChangeOfItinerary() {
    openChangeOfItinerary = true;
    currentStep = MIN_STEP;
  }

  function handleUpdateNextStepOne(event) {
    const { currentStepId, rowSelected, paxJourneyTable } = event.detail;
    if (currentStepId === MIN_STEP) {
      const findIdxCurrentStep = stepContent.findIndex((step) => step.stepId === currentStepId);
      stepContent[findIdxCurrentStep].rowSelected = rowSelected;
      stepContent[findIdxCurrentStep].paxJourneyTable = paxJourneyTable;
      stepContent[1].tabsData = map(rowSelected, (rowSelectedItem) => {
        return {
          ...rowSelectedItem,
          searchWord: {
            onPoint: EMPTY,
            offPoint: EMPTY,
            departureDate: EMPTY,
            cabinCode: CABIN_TYPE.find((item) => item.value === rowSelectedItem.items[0]?.cabinCode)?.key ?? 'M',
          },
        };
      });
    }
    currentStep = currentStepId + 1;
  }

  function handleUpdateNextStepTwo(event) {
    const { currentStepId, dataItineraryInquiry, dataLastStep, toolbarData } = event.detail;
    if (currentStepId > MIN_STEP && currentStepId < MAX_STEP) {
      const findIdxCurrentStep = stepContent.findIndex((step) => step.stepId === currentStepId);
      stepContent[findIdxCurrentStep + 1].rowSelected = stepContent[MIN_STEP - 1].rowSelected;
      stepContent[findIdxCurrentStep + 1].dataLastStep = dataLastStep;
      stepContent[findIdxCurrentStep].dataItineraryInquiry = dataItineraryInquiry;
      stepContent[findIdxCurrentStep].toolbarData = toolbarData;
      stepContent[findIdxCurrentStep + 1].paxJourneyTable = stepContent[MIN_STEP - 1].paxJourneyTable;
    }
    currentStep = currentStepId + 1;
  }

  function handleUpdatePrevStepTwo(event) {
    const { currentStepId } = event.detail;
    if (currentStepId > MIN_STEP && currentStepId < MAX_STEP) {
      const findIdxCurrentStep = stepContent.findIndex((step) => step.stepId === currentStepId);
      stepContent[findIdxCurrentStep].dataItineraryInquiry = [];
      stepContent[findIdxCurrentStep].tabsData = [];
      stepContent[findIdxCurrentStep].toolbarData = [];
    }
    currentStep = currentStepId - 1;
  }

  function handleNextLastStep() {
    openChangeOfItinerary = false;
    dispatch('reload-current-tab');
  }

  function handleUpdatePrevStepLast(event) {
    const { currentStepId } = event.detail;
    currentStep = currentStepId - 1;
  }

  function handleCloseChangeOfItinerary() {
    stepContent = cloneDeep(STEP_CONTENT);
    openChangeOfItinerary = false;
  }

  function closeModalRulesCoveredServices() {
    eventStore.set(null);
    openRulesCoveredServices.isOpen = false;
    openRulesCoveredServices.type = 'none';
    openRulesCoveredServices.popupCoveredServices = {
      headers: [],
      rows: [],
    };
  }

  async function openBaggageModal() {
    try {
      isLoadingServiceList = true;
      screenIsNotEvent.set(true);
      const response = await getServiceList({
        Order: {
          OrderID: orderId,
        },
      });
      if (response?.data?.ResultMessage?.Code === CODE_SUCCESS) {
        serviceList = response;
        openModalBaggage = true;
      }
    } catch (error) {
      console.log(error);
    } finally {
      isLoadingServiceList = false;
      screenIsNotEvent.set(false);
    }
  }

  onMount(() => {
    if (orderRetrieve?.data?.ResultMessage?.Code === CODE_SUCCESS) {
      airlineID = getMainBookingReference(orderRetrieve?.data?.Order?.BookingReference ?? [])?.AirlineID;
      itineraryInfo = getItineraryInfo(orderRetrieve?.data);
    }

    const unsubscribe = eventStore.subscribe((value) => {
      if (value && value.eventName === 'event-rules-covered-services') {
        openRulesCoveredServices.isOpen = true;
        openRulesCoveredServices.type = 'Action';
        openRulesCoveredServices.popupCoveredServices = value.popupCoveredServices;
      } else {
        openRulesCoveredServices.isOpen = false;
        openRulesCoveredServices.type = 'none';
        openRulesCoveredServices.popupCoveredServices = {
          headers: [],
          rows: [],
        };
      }
    });
    return unsubscribe;
  });

  onDestroy(() => {
    eventStore.set(null);
  });
</script>

{#if $isLocaleLoaded}
  <DataTable id="table-management" headers={headerTable} rows={itineraryInfo}>
    <Toolbar size="sm" class="table-toolbar">
      <ToolbarContent>
        <div class="title-table">
          <h4 class="title-table-content">
            {$_('layout.pages.reservationManagement.itineraryInformationTable.toolbar.titleTable', {
              default: '여정 정보',
            })}
          </h4>
        </div>

        <div class="g-wrapper-toolbar-button">
          {#if (['AF', 'KL', 'SQ', 'QR', 'LH', 'LX', 'OS', 'EK', 'AA'].includes(airlineID) && !isPayLater) || (['AF', 'KL'].includes(airlineID) && isPayLater)}
            <SeatButton {orderRetrieve} {orderId} {airlineID} />
          {/if}
          {#if (['AF', 'KL', 'SQ', 'QR', 'LH', 'LX', 'OS', 'EK', 'LXA'].includes(airlineID) && !isPayLater) || (['AF', 'KL'].includes(airlineID) && isPayLater)}
            <Button size="small" kind="secondary" on:click={openBaggageModal}>
              {#if isLoadingServiceList}
                <div class="g-custom-loading-button loading-box">
                  <InlineLoading description="Loading" />
                </div>
              {:else}
                {$_('layout.pages.reservationManagement.itineraryInformationTable.toolbar.buttonService', {
                  default: '서비스',
                })}
              {/if}
            </Button>
          {/if}

          {#if (['SQ', 'QR', 'AF', 'KL'].includes(airlineID) && !isPayLater) || (['AF', 'KL'].includes(airlineID) && isPayLater)}
            <Button size="small" kind="secondary" on:click={handleClickOpenChangeOfItinerary}
              >{$_('layout.pages.reservationManagement.itineraryInformationTable.toolbar.buttonAgreeOcn', {
                default: '여정 변경',
              })}
            </Button>
          {/if}
        </div>
      </ToolbarContent>
    </Toolbar>
  </DataTable>
{:else}
  <p>Loading...</p>
{/if}

{#key serviceList}
  <BaggageInformation bind:open={openModalBaggage} {orderRetrieve} {airlineID} {serviceList} {orderId} />
{/key}
<ComposedModal
  hasScrollingContent
  bind:open={openChangeOfItinerary}
  modalHeading={`여정변경:  PNR ${tab.tabName}`}
  on:close={handleCloseChangeOfItinerary}
  class="changeOfItinerary"
  secondaryButtons={[{ text: '취소' }, { text: '다음' }]}
  preventCloseOnClickOutside={true}
  primaryButtonIcon={InlineLoading}
>
  <ModalHeader title="여정변경:  PNR {tab.tabName}">
    <header>
      {#key currentStep}
        <ProgressIndicator class="step-progress" spaceEqually preventChangeOnClick={true}>
          <ProgressStep complete={currentStep === MIN_STEP} label="Step1" secondaryLabel="변경 여정 선택" />
          <ProgressStep complete={currentStep > MIN_STEP} label="Step2" secondaryLabel="여정 조회" />
          <ProgressStep complete={currentStep === MAX_STEP} label="Step3" secondaryLabel="여정 확인 및 변경" />
        </ProgressIndicator>
      {/key}
    </header>
  </ModalHeader>
  {#key openChangeOfItinerary}
    {#if currentStep === MIN_STEP}
      <ChooseAChangeItinerary
        stepId={currentStep}
        stepData={stepContent[currentStep - 1]}
        {orderRetrieve}
        on:next-step-one={handleUpdateNextStepOne}
        on:close-modal-itinerary={handleCloseChangeOfItinerary}
      />
    {:else if currentStep > MIN_STEP && currentStep < MAX_STEP}
      <ItineraryInquiry
        stepId={currentStep}
        stepData={stepContent[currentStep - 1]}
        {orderRetrieve}
        on:next-step-two={handleUpdateNextStepTwo}
        on:prev-step-two={handleUpdatePrevStepTwo}
        on:close-modal-itinerary={handleCloseChangeOfItinerary}
      />
    {:else if currentStep === MAX_STEP}
      <CheckAndChangeItinerary
        stepId={currentStep}
        stepData={stepContent[currentStep - 1]}
        {orderRetrieve}
        on:close-modal-itinerary={handleCloseChangeOfItinerary}
        on:prev-step-last={handleUpdatePrevStepLast}
        on:close-modal-of-last-step={handleNextLastStep}
        {isPayLater}
      />
    {/if}
  {/key}
</ComposedModal>

{#if openRulesCoveredServices.isOpen}
  <RuleCoveredServices
    className={'height-modal'}
    type={POPUP_TYPE_TICKET}
    openModal={openRulesCoveredServices}
    closeModal={closeModalRulesCoveredServices}
    popupCoveredServices={openRulesCoveredServices.popupCoveredServices}
  />
{/if}
