<script>
  import { cloneDeep } from 'lodash';
  import Router, { push } from 'svelte-spa-router';
  import { setupI18n, isLocaleLoaded, dir } from './lib/i18n.js';
  import { ReservationManagementReport, NotFound, SignIn } from 'src/pages';
  import { instanceAxios } from './service/request.js';
  import { location } from 'svelte-spa-router';
  import BotSupport from 'src/components/bot-support/index.svelte';
  import { user, screenIsNotEvent } from 'src/store';
  import 'carbon-components-svelte/css/all.css';
  import 'src/styles/app.scss';

  $: if (!$isLocaleLoaded) {
    // TODO set default lang - 'ko'
    setupI18n({ withLocale: 'ko' });
  }
  $: {
    document.dir = $dir;
  }

  function logOut() {
    user.set('LOGOUT');
    push('/sign-in');
  }

  function routeLoaded(event) {
    const auth = cloneDeep($user);
    if (auth?.accessToken) {
      instanceAxios.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`;
      if (event.detail.route === '/sign-in') {
        push('/');
      }
    } else {
      logOut();
    }
  }
</script>

{#if $isLocaleLoaded}
  {#if !$location.includes('sign-in')}
    <BotSupport />
  {/if}
  <Router
    routes={{
      '/': ReservationManagementReport,
      '/sign-in': SignIn,
      '*': NotFound,
    }}
    on:routeLoaded={routeLoaded}
  />
{:else}
  <p>Loading...</p>
{/if}
{#if $screenIsNotEvent}
  <div class="g-screen-transparent-isnot-event" />
{/if}

<div id="box-modal">
  <div id="box-modal-children" />
</div>
