<script>
  import { DataTable, Modal } from 'carbon-components-svelte';
  import { has, find, remove, filter } from 'lodash';
  import { EMPTY } from '../../../constants/app.js';
  import { createEmptyArray } from '../../../utils/appHelper.js';
  export let openModal;
  export let closeModal;
  export let popupCoveredServices;
  export let className;
  let count = {
    change: 0,
    cancel: 0,
    refund: 0,
    lj: 0,
  };
  const countData = (obj) => {
    let count = 0;
    for (const key in obj) {
      if (typeof obj[key] === 'object' && Array.isArray(obj[key])) {
        count += obj[key].length;
      }
    }
    return count;
  };
  $: {
    if (popupCoveredServices) {
      for (const key in popupCoveredServices.rows) {
        if (key === '0') {
          const obj = popupCoveredServices.rows[0];
          for (const key in obj) {
            if (key.startsWith('COL_')) {
              const { change, cancel, refund, lj } = obj[key];
              const countChange = change ? countData(change) : 0;
              const countCancel = cancel ? countData(cancel) : 0;
              const countRefund = refund ? countData(refund) : 0;
              const countLj = lj ? countData(lj) : 0;
              count = {
                change: countChange > count.change ? countChange : count.change,
                cancel: countCancel > count.cancel ? countCancel : count.cancel,
                refund: countRefund > count.refund ? countRefund : count.refund,
                lj: countLj > count.lj ? countLj : count.lj,
              };
            }
          }
        }
      }
      const rowCheckEmptyAll = filter(popupCoveredServices.rows, (row) => ['ROW_2', 'ROW_3'].includes(row.id));
      for (const row of rowCheckEmptyAll) {
        let checkEmpty = true;
        for (const key in row) {
          if (key.startsWith('COL_') && row[key].length) {
            checkEmpty = false;
            break;
          }
        }
        if (checkEmpty === true) {
          remove(popupCoveredServices.rows, (rowItem) => rowItem.id === row.id);
        }
      }
    }
  }
</script>

<Modal
  modalHeading="규정 및 포함서비스"
  class={`management-custom-popup management-popup__covered-services ${className?.length ? 'height-modal' : EMPTY}`}
  open={openModal.isOpen && openModal.type === 'Action'}
  primaryButtonText="닫기"
  on:click:button--primary={closeModal}
  on:close={closeModal}
  hasScrollingContent
>
  <div class="covered-services__container">
    <DataTable headers={popupCoveredServices.headers} rows={popupCoveredServices.rows}>
      <svelte:fragment slot="cell-header" let:header>
        <div>
          {#each header.value?.label as txt}
            <h2>
              {txt || EMPTY}
            </h2>
          {/each}
          <ul>
            {#each header.value.subLabel as txt}
              <li class:hidden={txt?.length === 0}>
                {txt || EMPTY}
              </li>
            {/each}
          </ul>
        </div>
      </svelte:fragment>

      <svelte:fragment slot="cell" let:cell let:row>
        {#if cell.key === 'LABEL'}
          <div>
            <h2>
              {cell.value}
            </h2>
          </div>
        {:else if row.id === 'ROW_1'}
          <div class="p-8px">
            {#if has(cell.value, 'change')}
              <h2 class="mb-8px">
                {cell.value.change.label}
              </h2>
              <ul>
                {#each cell.value.change.data as txt}
                  <li>
                    {txt || EMPTY}
                  </li>
                {/each}
                {#each createEmptyArray(count.change - cell.value.change.data.length) as txt}
                  <li class="hidden">
                    {txt}
                  </li>
                {/each}
              </ul>
              <hr />
            {/if}
            {#if has(cell.value, 'cancel')}
              <h2 class="mb-8px">
                {cell.value.cancel.label}
              </h2>
              <ul>
                {#each cell.value.cancel.data as txt}
                  <li>
                    {txt || EMPTY}
                  </li>
                {/each}
                {#each createEmptyArray(count.cancel - cell.value.cancel.data.length) as txt}
                  <li class="hidden">
                    {txt}
                  </li>
                {/each}
              </ul>
              <hr />
            {/if}
            {#if has(cell.value, 'lj')}
              <h2 class="mb-8px">
                {cell.value.lj.label}
              </h2>
              <ul>
                {#each cell.value.lj.data as txt}
                  <li>
                    {txt || EMPTY}
                  </li>
                {/each}
                {#each createEmptyArray(count.lj - cell.value.lj.data.length) as txt}
                  <li class="hidden">
                    {txt}
                  </li>
                {/each}
              </ul>
            {/if}
            {#if has(cell.value, 'lj')}
              <hr />
              <h2 class="mb-8px">
                {cell.value.lj.label}
              </h2>
              <ul>
                {#each cell.value.lj.data as txt}
                  <li>
                    {txt || EMPTY}
                  </li>
                {/each}
                {#each createEmptyArray(count.lj - cell.value.lj.data.length) as txt}
                  <li class="hidden">
                    {txt}
                  </li>
                {/each}
              </ul>
            {/if}
          </div>
        {:else if ['ROW_2', 'ROW_3'].includes(row.id)}
          <div class="p-8px">
            <ul>
              {#each cell.value as txt}
                <li>{txt || EMPTY}</li>
              {/each}
            </ul>
          </div>
        {:else}
          <div class="p-8px">
            <ul>
              {#each cell.value as txt}
                <li class:hidden={!txt?.length}>
                  {txt || EMPTY}
                </li>
              {/each}
            </ul>
          </div>
        {/if}
      </svelte:fragment>
    </DataTable>
  </div>
</Modal>
