<script>
  import { Button } from 'carbon-components-svelte';
  import { createEventDispatcher } from 'svelte';

  export let width = '160px';
  export let height = '40px';
  export let style = '';

  const dispatch = createEventDispatcher();
  const handleClick = (event) => {
    dispatch('click', event);
  };
</script>

<Button
  style="min-height: unset; height: {height};width:{width};padding:0 16px;{style}"
  on:click={handleClick}
  {...$$props}
>
  <slot />
</Button>
