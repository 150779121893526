export const TYPE_TICKET = [
  {
    key: 'T',
    value: 'TICKET',
  },
  {
    key: 'INF',
    value: 'TICKET',
  },
  {
    key: '702',
    value: 'TICKET',
  },
  {
    key: 'J',
    value: 'EMD-A',
  },
  {
    key: 'Y',
    value: 'EMD-S',
  },
];

export const EMPTY = '';
export const POPUP_TYPE_TICKET = 'TICKET';
export const POPUP_TYPE_EMDA = 'EMD-A';
export const POPUP_TYPE_EMDS = 'EMD-S';
export const PAGE_TABLE_SIZES_OCN_BOOKING = [15, 30, 50, 100];
export const CODE_SUCCESS = '00000';
export const CABIN_TYPE = [
  {
    key: 'M',
    value: '일반석',
  },
  {
    key: 'W',
    value: '프리미엄일반석',
  },
  {
    key: 'C',
    value: '비즈니스석',
  },
  {
    key: 'F',
    value: '일등석',
  },
];
export const EXISTING_ITINERARY = [
  [
    {
      label: '출발지',
      key: 'departureAirportCode',
    },
    {
      label: '도착지',
      key: 'arrivalAirportCode',
    },
    {
      label: '출발일시',
      key: 'departureTime',
    },
    {
      label: '도착일시',
      key: 'arrivalTime',
    },
    {
      label: '좌석등급',
      key: 'cabinCode',
    },
  ],
  [
    {
      label: '항공사',
      key: 'airlineId',
    },
    {
      label: '편명',
      key: 'flightNumber',
    },
    {
      label: '기종',
      key: 'aircraftCode',
    },
    {
      label: 'PriceClass',
      key: 'name',
    },
    {
      label: 'RBD',
      key: 'rbd',
    },
    {
      label: '수하물',
      key: 'maxWeightAllowance',
    },
    {
      label: '코드쉐어',
      key: 'typeAirlineId',
    },
  ],
];

export const SPECIAL_AIRLINE = ['LH', 'EK', 'AA', 'HA', 'AF', 'KL', 'BA'];
export const AIRLINES_MARKETING_CARRIER = ['LH', 'LX', 'OS'];

export const APP_EVENT_NAME = {
  CANCEL_PNR: 'ManagementContent/handleOrderCancel',
  RELOAD_CURRENT_PNR_CHANGEPAYMENTPNRMODAL:
    'ManagementContent/ItineraryInformation/BaggageInformation/ChangePaymentPnrModal/handleActionPayment',
  RELOAD_CURRENT_PNR_SSRTABLE: 'ManagementContent/Ssr/handleOrderChange',
};

export const PROMOTION_SPECIAL_FARE_AIRLINES = [
  { id: 'AA', text: 'AA' },
  { id: 'BA', text: 'BA' },
  { id: 'EK', text: 'EK' },
  { id: 'HA', text: 'HA' },
  { id: 'LH', text: 'LH' },
];

export const PROMOTION_PROGRAM_AIRLINES = [
  { id: 'AF', text: 'AF' },
  { id: 'KL', text: 'KL' },
  { id: 'QR', text: 'QR' },
  { id: 'SQ', text: 'SQ' },
];

export const InputOptions = Object.freeze({
  RM_KOREAN: 'remove_korean_character',
  ONLY_LATIN_NUMBER: 'only_latin_number',
  ONLY_NUMBER: 'only_number',
  ONLY_LATIN: 'only_latin',
  UPPER_CASE: 'to_upper_case',
  LOWER_CASE: 'to_lower_case',
  ORDER_ID: 'orderId_modal',
});

export const InputOptionsInvalid = Object.freeze({
  MATCH_KOREAN: 'match_korean_character',
});

export const SEAT_CLASS = [
  {
    id: 1,
    key: 'standard_seat',
    label: 'Standard seat',
    color: '#46B25C',
    names: ['STANDARD SEAT', 'DEFAULT FREE SEAT', 'Regular', 'STANDARD ZONE', 'PREMIUM SEAT', 'FREE SEAT'],
  },
  {
    id: 2,
    key: 'preferred_zone_seat',
    label: 'Preferred Zone Seat',
    color: '#0043CE',
    names: ['PREFERRED ZONE SEAT', 'Preferred Lower Deck', 'FORWARD ZONE'],
  },
  {
    id: 3,
    key: 'extra_legroom_seat',
    label: 'Extra Legroom seat',
    color: '#F6D652',
    names: ['EXTRA LEGROOM SEAT', 'Extra Legroom'],
    codes: ['L'],
  },
  {
    id: 4,
    key: 'restricted',
    label: 'Restricted',
    color: '#C6C6C6',
    codes: ['R', '1'],
  },
  {
    id: 5,
    key: 'occupied',
    label: 'Occupied',
    color: '#8D8D8D',
    // codes: ['T', 'O'],
  },
  {
    id: 6,
    key: 'available',
    label: 'Available',
    color: '#9AABCE',
    names: ['Available'],
  },
  {
    id: 7,
    key: 'selected',
    label: 'Selected',
    color: '#DA1E28',
    names: ['selected'],
  },
];

export const SEAT_CHARACTERISTICS_CODE = {
  1: 'Restricted seat',
  2: 'Leg rest available',
  3: 'Individual video screen - Choice of movies',
  4: 'Near galley seat',
  5: 'Near toilet seat',
  6: 'Near galley seat',
  7: 'Near toilet seat',
  8: 'No seat at this location',
  9: 'Center seat (not window, not aisle)',
  10: 'Seat designated for RBD "A"',
  11: 'Seat designated for RBD "B"',
  12: 'Seat designated for RBD "C"',
  13: 'Seat designated for RBD "D"',
  14: 'Seat designated for RBD "F"',
  15: 'Seat designated for RBD "H"',
  16: 'Seat designated for RBD "J"',
  17: 'Seat designated for RBD "K"',
  18: 'Seat designated for RBD "L"',
  19: 'Seat designated for RBD "M"',
  20: 'Seat designated for RBD "P"',
  21: 'Seat designated for RBD "Q"',
  22: 'Seat designated for RBD "R"',
  23: 'Seat designated for RBD "S"',
  24: 'Seat designated for RBD "T"',
  25: 'Seat designated for RBD "V"',
  26: 'Seat designated for RBD "W"',
  27: 'Seat designated for RBD "Y"',
  28: 'Not fitted',
  A: 'Aisle seat',
  AA: 'All available aisle seats',
  AB: 'Seat adjacent to bar',
  AC: 'Seat adjacent to - closet',
  AG: 'Seat adjacent to galley',
  AJ: 'Adjacent aisle seats',
  AL: 'Seat adjacent to lavatory',
  AM: 'Individual movie screen - No choice of movie selection',
  AR: 'No seat - airphone',
  AS: 'Individual airphone',
  AT: 'Seat adjacent to table',
  AU: 'Seat adjacent to stairs to upper deck',
  AV: 'Only available seats',
  AW: 'All available window seats',
  B: 'Seat with bassinet facility',
  BA: 'No seat - bar',
  BK: 'Blocked Seat for preferred passenger in adjacent seat',
  C: 'Crew seat',
  CC: 'Center section seat(s)',
  CH: 'Chargeable seat',
  CL: 'No seat - closet',
  CS: 'Conditional seat-contact airline',
  D: 'No seat - exit door',
  DE: 'Deportee',
  E: 'Exit and emergency exit',
  EA: 'Not on exit seat',
  EC: 'Electronic connection for lap top or FAX machine',
  EK: 'Economy comfort seat',
  EX: 'No seat - emergency Exit',
  F: 'Added seat',
  FC: 'Front of cabin class/compartment',
  G: 'Seat at forward end of cabin',
  GF: 'General facility',
  GN: 'No seat - galley',
  GR: 'Group seat - offered to travellers belonging to a group',
  H: 'Seat with facilities for handicapped/incapacitated passenger',
  I: 'Seat suitable for adult with an infant',
  IA: 'Inside aisle seats',
  IE: 'Seat not suitable for child',
  J: 'Rear facing seat',
  K: 'Bulkhead seat',
  KA: 'Bulkhead seat with movie screen',
  KN: 'Bulkhead, no seat',
  L: 'Leg space seat',
  LA: 'No seat – lavatory',
  LB: 'Rear facing lie flat seat',
  LE: 'Left facing lie flat seat more left angle',
  LF: 'Lie flat seat',
  LG: 'No seat - luggage storage',
  LH: 'Restricted seat - offered on long-haul segments',
  LL: 'Left facing lie flat seat',
  LR: 'Right facing lie flat seat',
  LS: 'Left side of aircraft',
  LT: 'Right facing lie flat seat more right angle',
  M: 'Seat without a movie view',
  MA: 'Medically OK to travel',
  MS: 'Middle seat',
  N: 'No smoking seat',
  O: 'Preferential seat',
  OW: 'Overwing seat(s)',
  P: 'Extra seat for comfort - arm rest can be raised',
  PC: 'Pet cabin',
  Q: 'Seat in a quiet zone',
  RS: 'Right side of aircraft',
  S: 'Smoking seat',
  SC: 'Skycouch',
  SO: 'No seat - storage space',
  ST: 'No seat - stairs to upper deck',
  T: 'Rear/Tail section of aircraft',
  TA: 'No seat - table',
  U: 'Seat suitable for unaccompanied minors',
  UP: 'Upper deck',
  V: 'Seat to be left vacant or offered last',
  W: 'Window seat',
  WA: 'Window and aisle together',
  X: 'No facility seat (indifferent seat)',
  Z: 'Buffer zone seat',
  '1A': 'Seat not allowed for infant',
  '1B': 'Seat not allowed for medical',
  '1C': 'Seat not allowed for unaccompanied minor',
  '1D': 'Restricted recline seat',
  '1M': 'Seat with movie view',
  '1W': 'Window seat without window',
  '3A': 'Individual video screen - No choice of movie',
  '3B': 'Individual video screen-Choice of movies, games, information, etc',
  '6A': 'In front of galley seat',
  '6B': 'Behind galley seat',
  '7A': 'In front of toilet seat',
  '7B': 'Behind toilet seat',
  70: 'Individual video screen - services unspecified',
  71: 'No seat --access to handicapped lavatory',
  72: 'Undesirable seat',
};
