<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    DataTable,
    ComboBox,
    Button,
    InlineLoading,
    ModalFooter,
    ModalBody,
    TooltipIcon,
  } from 'carbon-components-svelte';
  import { map, find, forEach, uniqBy, has, isEmpty } from 'lodash';
  import { Ticket } from 'src/assets/icons';
  import {
    formatDate,
    formatPriceNumber,
    getDataColsRuleCoveredServices,
    getMainBookingReference,
    getMaxWeightAllowance,
    getCabinName,
  } from 'src/utils/appHelper.js';
  import { CODE_SUCCESS, EMPTY } from 'src/constants/app.js';
  import { message, eventStore, screenIsNotEvent } from 'src/store';
  import { postOrderChange } from 'src/service/reservation.js';
  import { isLocaleLoaded, _ } from 'src/lib/i18n.js';

  import './index.scss';
  import { Information } from 'carbon-icons-svelte';
  import PrimaryButton from 'src/components/common/button/PrimaryButton.svelte';

  export let stepId;
  export let stepData;
  export let orderRetrieve;
  export let isPayLater;
  let airlineID = EMPTY;
  let orderId = EMPTY;
  let existingItineraryData = [];
  let newItineraryData = [];
  let differenceInformationHeaderData = [];
  let isLoading = false;
  let isLoadingPriceCalculate = false;
  let disableChangeItineraryButton = true;
  let dataPriceCalculate = EMPTY;

  const specialAirline = ['AF', 'KL'];
  const dispatch = createEventDispatcher();

  const existingItineraryHeader = [
    {
      key: 'airlineId',
      with: '5%',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.airlineId', {
        default: '항공사',
      }),
    },
    {
      key: 'flightNumber',
      with: '5%',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.flightNumber', {
        default: '편명',
      }),
    },
    {
      key: 'departureAirportCode',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.departureAirportCode',
        {
          default: '출발지',
        }
      ),
    },
    {
      key: 'arrivalAirportCode',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.arrivalAirportCode',
        {
          default: '도착지',
        }
      ),
    },
    {
      key: 'departureTime',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.departureTime', {
        default: '출발일시',
      }),
    },
    {
      key: 'arrivalTime',
      value: '도착일시',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.arrivalTime', {
        default: '도착일시',
      }),
    },
    {
      key: 'cabinCode',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.cabinCode', {
        default: '좌석등급',
      }),
    },
    {
      key: 'name',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.name', {
        default: 'priceClass',
      }),
    },
    {
      key: 'rbd',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.rbd', {
        default: 'RBD',
      }),
    },
    {
      key: 'maxWeightAllowance',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.maxWeightAllowance',
        {
          default: '수하물',
        }
      ),
    },
    {
      key: 'typeAirlineId',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.typeAirlineId', {
        default: '코드쉐어',
      }),
    },
    {
      key: 'rule',
      value: $_('layout.pages.reservationManagement.itineraryInformationTable.checkAndChangeItinerary.rule', {
        default: '규정',
      }),
    },
  ];
  const newItineraryHeader = [...existingItineraryHeader];
  const differenceInformationHeader = [
    {
      key: 'totalOriginalOrderItem',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.differenceInformationHeader.totalAmountOriginalOrderItem',
        {
          default: '기존 여정 총금액',
        }
      ),
    },
    {
      key: 'totalNewOfferItem',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.differenceInformationHeader.totalAmountNewOfferItem',
        {
          default: '신규 여정 총 금액',
        }
      ),
    },
    {
      key: 'totalPenalty',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.differenceInformationHeader.totalAmountPenalty',
        {
          default: '패널티',
        }
      ),
    },
    {
      key: 'totalReshopDue',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.differenceInformationHeader.totalAmountReshopDue',
        {
          default: '차액',
        }
      ),
    },
    {
      key: 'totalPrice',
      value: $_(
        'layout.pages.reservationManagement.itineraryInformationTable.differenceInformationHeader.totalAmountPrice',
        {
          default: '최종 지불 금액',
        }
      ),
    },
    {
      key: 'calculate',
      value: EMPTY,
      width: '120px',
    },
  ];

  function closeModalItinerary() {
    dispatch('close-modal-itinerary');
  }

  function handlePrevStep() {
    dispatch('prev-step-last', {
      currentStepId: stepId,
    });
  }

  async function handleNextStep() {
    isLoading = true;
    let query = getQueryWithNextStep();
    try {
      const response = await postOrderChange({ query });
      const code = response?.data?.ResultMessage?.Code || EMPTY;
      const mess = response?.data?.ResultMessage?.Message || EMPTY;
      if (code !== CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'error',
            title: 'Error',
            subtitle: `API 호출에 실패했습니다. 다시 시도해주세요\n[${code} ${mess}]`,
            caption: EMPTY,
          },
        ]);
        return;
      }
      if (code === CODE_SUCCESS) {
        message.update(() => [
          {
            type: 'success',
            title: 'success',
            subtitle: `여정변경이 완료되었습니다.`,
            caption: EMPTY,
          },
        ]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      isLoading = false;
      dispatch('close-modal-of-last-step');
    }
    return;
  }

  const getQueryWithNextStep = () => {
    let query;
    const acceptOffer = stepData.dataLastStep?.ReshopOffers.map((reshopItem) => {
      const OfferItems = reshopItem.AddOfferItem.map((item) => {
        return {
          OfferItemID: item.OfferItemID,
          PaxRefID: item.PaxRefID,
        };
      });
      return {
        OfferID: reshopItem.OfferID,
        Owner: reshopItem.Owner,
        ResponseID: reshopItem.ResponseID,
        OfferItems: OfferItems,
      };
    });

    if (specialAirline.includes(airlineID)) {
      if (dataPriceCalculate === EMPTY) {
        query = {
          OrderID: orderId,
          ChangeOrderChoice: {
            AcceptSelectedQuotedOfferList: {
              SelectedPricedOffer: acceptOffer,
            },
          },
          PaymentList: [],
        };
      } else {
        query = {
          OrderID: orderId,
          PaymentList: [
            {
              Type: 'Cash',
              Amount: dataPriceCalculate?.Order?.TotalPrice?.TotalAmount?.Amount ?? 0,
              CurCode: dataPriceCalculate?.Order?.TotalPrice?.TotalAmount?.CurCode ?? EMPTY,
              OrderItemID: dataPriceCalculate?.Order?.OrderItem.map((item) => item.OrderItemID) ?? [],
              OfferItemID: [],
              PaxRefID: [],
            },
          ],
          ChangeOrderChoice: {
            AcceptRepricedOrder: {
              OfferRefID: [orderId],
            },
          },
        };
      }
    } else {
      const PaymentList = stepData.dataLastStep?.ReshopOffers.map((reshopItem) => {
        let Amount = 0;
        let CurCode = EMPTY;
        reshopItem.AddOfferItem.forEach((item) => {
          Amount += item.Price.TotalAmount.Amount * item.PaxRefID.length;
          if (CurCode !== EMPTY) CurCode = item.Price?.TotalAmount?.CurCode;
        });
        return {
          Type: 'Cash',
          Amount,
          CurCode,
          OrderItemID: [],
          PaxRefID: [],
        };
      });

      query = {
        OrderID: orderId,
        ChangeOrderChoice: {
          AcceptSelectedQuotedOfferList: {
            SelectedPricedOffer: acceptOffer,
          },
        },
        PaymentList,
      };
    }
    return query;
  };
  function generatePopupCoveredServices(fareComponent, PaxSegmentList, JourneyOverview, PaxJourneyID) {
    let popupCoveredServices;
    let headers = [
      {
        key: 'LABEL',
        value: {
          label: ['여정'],
          subLabel: ['FareBasis', 'PriceClass'],
        },
      },
    ];
    let rows = [];
    let ROW_1 = {
        id: 'ROW_1',
        LABEL: '페널티',
      },
      ROW_2 = {
        id: 'ROW_2',
        LABEL: '최소 체류일',
      },
      ROW_3 = {
        id: 'ROW_3',
        LABEL: '최대 체류일',
      },
      ROW_4 = {
        id: 'ROW_4',
        LABEL: '포함 서비스',
      };
    for (let index = 0; index < fareComponent.length; index++) {
      const { PaxSegmentRefID, FareBasis, Penalty, Remarks } = fareComponent[index];
      if (!PaxSegmentRefID.length || (!has(Penalty, 'Change') && !has(Penalty, 'Refund'))) break;
      const label = map(PaxSegmentRefID, (paxSegRefIdItem) => {
        const PaxSegment = find(PaxSegmentList, (paxSegItem) => paxSegItem.PaxSegmentID === paxSegRefIdItem);
        return `${PaxSegment?.Departure?.AirportCode}-${PaxSegment?.Arrival?.AirportCode}`;
      });
      let subLabel = [];
      let findJourneyOverview = EMPTY;
      if (PaxJourneyID) {
        findJourneyOverview = find(JourneyOverview, (JourneyItem) => JourneyItem.PaxJourneyRefID === PaxJourneyID);
      }
      subLabel = [...subLabel, FareBasis?.FareBasisCode || EMPTY, findJourneyOverview?.PriceClassInfo?.Name || EMPTY];
      headers = [
        ...headers,
        {
          key: `COL_${index}`,
          value: {
            label,
            subLabel,
          },
        },
      ];

      const [COL_INDEX_ROW_1, COL_INDEX_ROW_2, COL_INDEX_ROW_3, COL_INDEX_ROW_4] = getDataColsRuleCoveredServices(
        Penalty,
        Remarks,
        airlineID,
        findJourneyOverview
      );
      ROW_1 = {
        ...ROW_1,
        [`COL_${index}`]: COL_INDEX_ROW_1,
      };
      ROW_2 = {
        ...ROW_2,
        [`COL_${index}`]: COL_INDEX_ROW_2,
      };
      ROW_3 = {
        ...ROW_3,
        [`COL_${index}`]: COL_INDEX_ROW_3,
      };
      ROW_4 = {
        ...ROW_4,
        [`COL_${index}`]: COL_INDEX_ROW_4,
      };
    }
    rows = [ROW_1, ROW_2, ROW_3, ROW_4];
    return (popupCoveredServices = {
      headers,
      rows,
    });
  }

  function handleClickExistingItineraryRow(dataRow) {
    let popupCoveredServices;
    const { PaxSegmentList } = orderRetrieve.data?.DataLists;
    const { JourneyOverview, OrderItem } = orderRetrieve.data?.Order;
    const findOrderItem = OrderItem.find((order) => order.PaxJourneyRefID.includes(dataRow.PaxJourneyID));
    const fareComponent = findOrderItem?.FareDetail[0]?.FareComponent || [];
    popupCoveredServices = generatePopupCoveredServices(
      fareComponent,
      PaxSegmentList,
      JourneyOverview,
      dataRow.PaxJourneyID
    );
    eventStore.set({ eventName: 'event-rules-covered-services', popupCoveredServices });
  }

  function findReshopOfferItem(reshopOffers, PaxSegmentID) {
    const result = reshopOffers.find((item) => {
      if (item.AddOfferItem && item.AddOfferItem.length > 0) {
        return item.AddOfferItem.some((offerItem) => {
          if (offerItem.FareDetail && offerItem.FareDetail.length > 0) {
            return offerItem.FareDetail.some((fareDetail) => {
              if (fareDetail.FareComponent && fareDetail.FareComponent.length > 0) {
                return fareDetail.FareComponent.some((fareComponent) => {
                  return fareComponent.PaxSegmentRefID && fareComponent.PaxSegmentRefID.includes(PaxSegmentID);
                });
              }
              return false;
            });
          }
          return false;
        });
      }
      return false;
    });
    return result;
  }

  function handleClickNewItineraryRow(dataRow) {
    if (!dataRow.rule) return;
    let popupCoveredServices;
    const { PaxList, PaxSegmentList } = stepData.dataLastStep?.DataLists;
    const paxItem = PaxList.find((item) => item.Ptc === 'ADT') || PaxList.find((item) => item.Ptc === 'CHD');
    const findReshopOffer = findReshopOfferItem(stepData.dataLastStep.ReshopOffers, dataRow.PaxSegmentID);
    const { JourneyOverview, AddOfferItem } = findReshopOffer;
    const findAddOfferItem = AddOfferItem.find((item) => item.PaxRefID.includes(paxItem?.PaxID));
    const fareComponent = findAddOfferItem?.FareDetail[0]?.FareComponent || [];
    popupCoveredServices = generatePopupCoveredServices(
      fareComponent,
      PaxSegmentList,
      JourneyOverview,
      dataRow.PaxJourneyID
    );
    eventStore.set({ eventName: 'event-rules-covered-services', popupCoveredServices });
  }
  const handlePriceCalculate = async () => {
    const { OfferID, Owner, ResponseID, AddOfferItem } = stepData.dataLastStep.ReshopOffers[0];
    const query = {
      OrderID: orderId,
      ChangeOrderChoice: {
        AcceptSelectedQuotedOfferList: {
          SelectedPricedOffer: [
            {
              OfferID,
              Owner,
              ResponseID,
              OfferItems: AddOfferItem.map((item) => ({
                OfferItemID: item.OfferItemID,
                PaxRefID: item.PaxRefID,
              })),
            },
          ],
        },
      },
      PaymentList: [],
    };

    try {
      isLoadingPriceCalculate = true;
      const { code, data } = await postOrderChange({ query });
      if (data?.ResultMessage?.Code === CODE_SUCCESS && code === 200) {
        disableChangeItineraryButton = false;
        dataPriceCalculate = data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoadingPriceCalculate = false;
    }
  };
  onMount(async () => {
    airlineID = getMainBookingReference(orderRetrieve?.data?.Order?.BookingReference)?.AirlineID ?? EMPTY;
    orderId = orderRetrieve?.data?.Order?.OrderID;
    const getExistingItineraryData = () => {
      let existingData = [];
      forEach(stepData.paxJourneyTable, (row) => {
        for (const elm of row.items) {
          elm.PaxJourneyID = row.PaxJourneyID;
        }
        existingData = [...existingData, ...row.items];
      });
      return existingData;
    };
    existingItineraryData = map(getExistingItineraryData(), (existingItinerary, index) => {
      return {
        id: index,
        ...existingItinerary,
        rule: index === 0,
        cabinCode: getCabinName(airlineID, existingItinerary.cabinCode),
      };
    });

    const { ReshopOffers, DataLists } = stepData.dataLastStep;

    const getDateTime = (prams) => {
      return `${formatDate(`${prams?.Date} ${prams?.Time}`, 'datetime')}`;
    };
    const getAirportCode = (prams) => {
      return prams?.AirportCode;
    };
    newItineraryData = map(DataLists?.PaxSegmentList ?? [], (paxSegmentItem, index) => {
      const { Departure, Arrival, MarketingCarrier, OperatingCarrier, PaxSegmentID } = paxSegmentItem;
      const getCabinCodeNameAndRbd = () => {
        let listFareComponent = [];
        let listJourneyOverview = [];
        let listBaggageAllowance = [];
        forEach(ReshopOffers, (itemReshopOffers) => {
          listJourneyOverview = [...listJourneyOverview, ...itemReshopOffers.JourneyOverview];
          listBaggageAllowance = [...listBaggageAllowance, ...itemReshopOffers.BaggageAllowance];
          if (itemReshopOffers?.AddOfferItem) {
            forEach(itemReshopOffers?.AddOfferItem, (addOfferItem) => {
              if (addOfferItem?.FareDetail) {
                forEach(addOfferItem?.FareDetail, (itemFareDetail) => {
                  listFareComponent = [...listFareComponent, ...itemFareDetail.FareComponent];
                });
              }
            });
          }
        });

        listFareComponent = uniqBy(listFareComponent, 'PaxSegmentRefID');
        listJourneyOverview = uniqBy(listJourneyOverview, 'PaxJourneyRefID');
        listBaggageAllowance = uniqBy(listBaggageAllowance, 'PaxJourneyRefID');

        const paxJourneyBySegment = DataLists.PaxJourneyList.find(({ PaxSegmentRefID }) =>
          PaxSegmentRefID.includes(PaxSegmentID)
        );
        const maximumWeightAllowance = getMaxWeightAllowance(
          listBaggageAllowance,
          paxJourneyBySegment?.PaxJourneyID ?? EMPTY
        );

        const currentFareComponent = find(listFareComponent, (itemFareComponent) =>
          itemFareComponent?.PaxSegmentRefID.includes(PaxSegmentID)
        );
        let journeyOverview;
        for (const paxJourneyItem of DataLists.PaxJourneyList) {
          if (paxJourneyItem.PaxSegmentRefID.includes(currentFareComponent.PaxSegmentRefID[0] ?? EMPTY)) {
            for (const JourneyOverviewItem of listJourneyOverview) {
              if (paxJourneyItem.PaxJourneyID === JourneyOverviewItem.PaxJourneyRefID) {
                journeyOverview = JourneyOverviewItem;
                break;
              }
            }
            if (journeyOverview) break;
          }
        }
        const currentCabinType = currentFareComponent?.FareBasis?.CabinType?.find(
          ({ PaxSegmentRefID }) => PaxSegmentRefID === PaxSegmentID
        );
        return {
          cabinCode: getCabinName(airlineID, currentCabinType?.CabinCode),
          rbd: currentCabinType?.Rbd || EMPTY,
          name: journeyOverview?.PriceClassInfo?.Name ?? EMPTY,
          PaxJourneyID: journeyOverview?.PaxJourneyRefID ?? EMPTY,
          maxWeightAllowance: maximumWeightAllowance,
        };
      };

      return {
        id: index,
        PaxSegmentID,
        airlineId: MarketingCarrier?.AirlineID,
        flightNumber: MarketingCarrier?.FlightNumber?.padStart(4, '0') || EMPTY,
        departureAirportCode: getAirportCode(Departure),
        arrivalAirportCode: getAirportCode(Arrival),
        departureTime: getDateTime(Departure),
        arrivalTime: getDateTime(Arrival),
        ...getCabinCodeNameAndRbd(),
        typeAirlineId: OperatingCarrier?.AirlineID || EMPTY,
        rule: index === 0,
      };
    });
    differenceInformationHeaderData = map(ReshopOffers, (itemReshopOffers, index) => {
      let initValue = {
        totalOriginalOrderItem: {
          Amount: 0,
          CurCode: EMPTY,
        },
        totalNewOfferItem: {
          Amount: 0,
          CurCode: EMPTY,
        },
        totalPenalty: {
          Amount: 0,
          CurCode: EMPTY,
        },
        totalReshopDue: {
          Amount: 0,
          CurCode: EMPTY,
        },
        totalPrice: {
          Amount: 0,
          CurCode: EMPTY,
        },
        msgNotProvided: '항공사 미제공',
      };

      forEach(itemReshopOffers.AddOfferItem, (addOfferItem) => {
        const { OriginalOrderItem, NewOfferItem, PenaltyAmount, Price, PaxRefID } = addOfferItem;
        const multiplier = ['SQ', 'QR'].includes(airlineID) ? 1 : PaxRefID.length;
        initValue.totalOriginalOrderItem.Amount += (OriginalOrderItem?.Total?.Amount ?? 0) * multiplier;
        initValue.totalOriginalOrderItem.CurCode = OriginalOrderItem?.Total?.CurCode ?? EMPTY;

        initValue.totalNewOfferItem.Amount += (NewOfferItem?.Total?.Amount ?? 0) * multiplier;
        initValue.totalNewOfferItem.CurCode = NewOfferItem?.Total?.CurCode ?? EMPTY;

        initValue.totalPenalty.Amount += (PenaltyAmount?.Total?.Amount ?? 0) * multiplier;
        initValue.totalPenalty.CurCode = PenaltyAmount?.Total?.CurCode ?? EMPTY;

        initValue.totalReshopDue.Amount +=
          ((NewOfferItem?.Total?.Amount ?? 0) - (OriginalOrderItem?.Total?.Amount ?? 0)) * multiplier;
        initValue.totalReshopDue.CurCode = NewOfferItem?.Total?.CurCode ?? OriginalOrderItem?.Total?.CurCode ?? EMPTY;

        initValue.totalPrice.Amount += (Price?.TotalAmount?.Amount ?? 0) * multiplier;
        initValue.totalPrice.CurCode = Price?.TotalAmount?.CurCode ?? EMPTY;
      });

      return {
        id: index,
        ...initValue,
      };
    });
  });
  $: if (!isEmpty(differenceInformationHeaderData)) {
    if (
      !(
        specialAirline.includes(airlineID) &&
        (isPayLater || (!isPayLater && differenceInformationHeaderData[0].totalPrice.Amount > 0))
      )
    ) {
      disableChangeItineraryButton = false;
    }
  }
  $: screenIsNotEvent.set(isLoading || isLoadingPriceCalculate);
</script>

<ModalBody class="step_three">
  <div class="checkAndChangeItinerary">
    <div class="wrapper-content">
      <h4>기존 여정</h4>
      <DataTable headers={existingItineraryHeader} rows={existingItineraryData}>
        <svelte:fragment slot="cell" let:row let:cell>
          {#if cell.key === 'rule'}
            {#if cell.value === true}
              <button on:click={() => handleClickExistingItineraryRow(row)} class="g-button">
                <Ticket />
              </button>
            {:else}
              {EMPTY}
            {/if}
          {:else}
            {cell.value}
          {/if}
        </svelte:fragment>
      </DataTable>
    </div>
    <div class="wrapper-content">
      <h4>신규 여정</h4>
      <DataTable headers={newItineraryHeader} rows={newItineraryData}>
        <svelte:fragment slot="cell" let:row let:cell>
          {#if cell.key === 'rule' && cell.value}
            <button class="g-button" on:click={() => handleClickNewItineraryRow(row)}> <Ticket /></button>
          {:else if cell.key !== 'rule'}
            {cell.value}
          {/if}
        </svelte:fragment>
      </DataTable>
    </div>
    <div class="wrapper-content">
      <h4>차액정보</h4>
      <DataTable headers={differenceInformationHeader} rows={differenceInformationHeaderData}>
        <div slot="cell" let:cell let:row>
          {#if cell.key === 'calculate'}
            {#if specialAirline.includes(airlineID) && (isPayLater || (!isPayLater && row.totalPrice.Amount > 0))}
              <PrimaryButton
                height="32px"
                width="100px"
                on:click={handlePriceCalculate}
                disabled={!disableChangeItineraryButton}
              >
                {#if isLoadingPriceCalculate}
                  <div class="g-custom-loading-button">
                    <InlineLoading description="Loading" />
                  </div>
                {:else}
                  확인
                {/if}
              </PrimaryButton>
            {/if}
          {:else if cell.value.CurCode === EMPTY}
            {row.msgNotProvided}
          {:else}
            {formatPriceNumber(cell.value.Amount)}
            {cell.value.CurCode}
          {/if}
        </div>
      </DataTable>
    </div>
    <div class="wrapper-content">
      <div class="info-payment">
        <h4>결제정보</h4>
        <TooltipIcon icon={Information} direction="top" align="start">
          <ul slot="tooltipText">
            <li>차액이 있으면 추가 결제가 필요합니다.</li>
            <li>금액이 마이너스(-)인 경우 차액은 최초 발권 결제수단으로 환불됩니다.</li>
            <li>차액이 없는 경우 0원 결제됩니다.</li>
          </ul>
        </TooltipIcon>
      </div>
      <div class="payment-method">
        <ComboBox
          selectedId={'0'}
          disabled
          titleText="결제수단"
          items={[
            { id: '0', text: 'BSP CASH' },
            { id: '1', text: 'Email' },
            { id: '2', text: 'Fax' },
          ]}
        />
      </div>
    </div>
  </div>
</ModalBody>
<ModalFooter>
  <footer>
    <div class="step-three">
      <Button kind="secondary" on:click={closeModalItinerary}>취소</Button>
      <Button kind="secondary" on:click={handlePrevStep}>이전</Button>
      <Button on:click={handleNextStep} disabled={disableChangeItineraryButton}
        >여정변경 <div class="loading-steptwo">
          {#if isLoading}
            <InlineLoading description="Loading ..." />
          {/if}
        </div></Button
      >
    </div>
  </footer>
</ModalFooter>
