<script>
  import { DataTable } from 'carbon-components-svelte';
  import { _ } from '../../../lib/i18n.js';
  export let data = [];
  const headers = [
    {
      key: 'CouponNumber',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDS.couponNumber', {
        default: '순번',
      }),
    },
    {
      key: 'code',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDS.code', {
        default: '코드',
      }),
    },
    {
      key: 'EMDdetails',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDS.emdDetails', {
        default: '서비스 상세',
      }),
    },
    {
      key: 'relatedTickets',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDS.relatedTickets', {
        default: '관련티켓',
      }),
    },
    {
      key: 'Status',
      value: $_('layout.pages.reservationManagement.ticketInformationTable.popupEMDS.status', {
        default: '쿠폰상태',
      }),
    },
  ];
</script>

<DataTable {headers} rows={data} />
