<script>
  import { EMPTY, CODE_SUCCESS } from 'src/constants/app';
  import { getOfferPrice } from 'src/service/reservationTicketing';
  import RuleCoveredServicesModal from 'src/components/modal/RuleCoveredServicesModal.svelte';
  import { getMainOfferItem } from 'src/components/reservation-management-report/reservation-ticketing/handleAirShopping.js';
  import { Loading } from 'carbon-components-svelte';
  import { generatePopupCoveredServices } from 'src/utils/appHelper.js';
  export let ruleData = {
    open: false,
    data: EMPTY,
  };
  import { screenIsNotEvent } from 'src/store';

  let ruleCoveredServicesModalData = {
    headers: [],
    rows: [],
  };
  let isLoading = false;
  const handleGetOfferPrice = async (_ruleData) => {
    const { data } = _ruleData;
    ruleCoveredServicesModalData = EMPTY;
    isLoading = true;
    screenIsNotEvent.set(true);
    if (data !== EMPTY) {
      const params = {
        Query: data.queryOfferPrice,
      };
      try {
        const { code, data } = await getOfferPrice(params);
        const { PricedOffer, DataLists, ResultMessage } = data;
        if (code === 200 && ResultMessage?.Code === CODE_SUCCESS) {
          const { JourneyOverview } = PricedOffer;
          const { PaxSegmentList, PaxJourneyList } = DataLists;
          const FareComponent = getMainOfferItem(PricedOffer, DataLists)?.FareDetail[0]?.FareComponent ?? [];
          const airlineID = PricedOffer.Owner;
          ruleCoveredServicesModalData = generatePopupCoveredServices(
            PaxSegmentList,
            JourneyOverview,
            PaxJourneyList,
            FareComponent,
            airlineID
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        screenIsNotEvent.set(false);
        isLoading = false;
      }
    }
  };

  $: if (ruleData.open) {
    handleGetOfferPrice(ruleData);
  }
</script>

{#if !isLoading && ruleCoveredServicesModalData !== EMPTY}
  <RuleCoveredServicesModal
    bind:open={ruleData.open}
    on:close={() => {
      ruleData.data = EMPTY;
    }}
    bind:headers={ruleCoveredServicesModalData.headers}
    bind:rows={ruleCoveredServicesModalData.rows}
  />
{:else if isLoading}
  <Loading class="g-custom-loading" />
{/if}
