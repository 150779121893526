<script>
  import { isEmpty } from 'lodash';
  import { EMPTY } from 'src/constants/app.js';
  import { RadioButton, DataTable, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { CustomTextInput } from 'src/components/common/input';

  export let pricedOffer = {};
  export let Order = {};
  export let RepricedOffer = {};
  export let typePayInformation = 'createPrice'; //'rePrice'
  let checkedValue = EMPTY;

  const headers = [
    {
      key: 'head',
      value: '결제방식',
      width: '400px',
    },
    {
      key: 'action',
      value: '결제수단',
    },
  ];

  let rows = [];

  const dispatch = createEventDispatcher();

  const handleChangeCheckedValue = (currentValue) => {
    checkedValue = checkedValue === currentValue ? EMPTY : currentValue;
  };
  $: if (typePayInformation !== EMPTY) {
    let PaymentList = [];
    if (['cash', 'voucher', 'cash_voucher'].includes(checkedValue)) {
      if (!isEmpty(pricedOffer)) {
        PaymentList = [
          {
            Type: 'Cash',
            Amount: pricedOffer?.TotalPrice?.TotalAmount?.Amount,
            CurCode: pricedOffer?.TotalPrice?.TotalAmount?.CurCode,
            OrderItemID: [],
            PaxRefID: [],
          },
        ];
      }
      if (!isEmpty(Order)) {
        PaymentList = [
          {
            Type: 'Cash',
            Amount: Order?.TotalPrice?.TotalAmount?.Amount,
            CurCode: Order?.TotalPrice?.TotalAmount?.CurCode,
            OrderItemID: Order?.OrderItem.map((item) => item.OrderItemID),
            OfferItemID: [],
            PaxRefID: [],
          },
        ];
      }
      if (!isEmpty(RepricedOffer)) {
        PaymentList = [
          {
            Type: 'Cash',
            Amount: RepricedOffer?.TotalPrice?.TotalAmount?.Amount,
            CurCode: RepricedOffer?.TotalPrice?.TotalAmount?.CurCode,
            OrderItemID: [],
            OfferItemID: [],
            PaxRefID: [],
          },
        ];
      }
    }
    dispatch('change', { PaymentList });
  }

  onMount(() => {
    if (typePayInformation === 'createPrice') {
      rows = [
        {
          id: 1,
          head: '즉시결제',
          action: null,
        },
        {
          id: 2,
          head: '나중결제',
          action: null,
        },
      ];
    }
    if (typePayInformation === 'rePrice') {
      rows = [
        {
          id: 1,
          head: '즉시결제',
          action: null,
        },
      ];
    }
  });
</script>

<DataTable {headers} {rows} class="g-custom-table paymentInformationTable" size="medium">
  <Toolbar size="sm">
    <ToolbarContent>
      <div class="table-toolbar">
        <div class="wrapper-title">
          <h4 class="title-content">결제 정보</h4>
        </div>
        <div class="wapper-actions-button" />
      </div>
    </ToolbarContent>
  </Toolbar>
  <div slot="cell" let:row let:cell class="cell-custom">
    {#if cell.key === 'action'}
      {#if row.id === 1}
        <div class="voucher-box">
          <di class="voucher-cash">
            <RadioButton
              labelText="Cash"
              value="cash"
              checked={checkedValue === 'cash'}
              on:change={() => handleChangeCheckedValue('cash')}
            />
          </di>
          {#if ['AF', 'KL'].includes(pricedOffer?.Owner)}
            <div>
              <RadioButton
                labelText="Voucher"
                value="voucher"
                on:change={() => handleChangeCheckedValue('voucher')}
                checked={checkedValue === 'voucher'}
              />
              <div class="voucher-input">
                <CustomTextInput labelText="Voucher Number" placeholder="Voucher Number" />
              </div>
            </div>
            <div>
              <RadioButton
                labelText="혼합결제 (Voucher + Cash)"
                value="cash_voucher"
                checked={checkedValue === 'cash_voucher'}
                on:change={() => handleChangeCheckedValue('cash_voucher')}
              />
              <div class="voucher-input">
                <CustomTextInput labelText="Voucher Number" placeholder="Voucher Number" />
              </div>
            </div>
          {/if}
        </div>
      {:else if row.id === 2}
        <div class="voucher-box">
          <div>
            <RadioButton
              labelText="Pay Later"
              value="pay_later"
              checked={checkedValue === 'pay_later'}
              on:change={() => handleChangeCheckedValue('pay_later')}
            />
            <div class="notify">※ 결제가 되지 않으며 PNR만 생성됩니다</div>
          </div>
        </div>
      {/if}
    {:else}
      {cell.value}
    {/if}
  </div>
</DataTable>
