import request from './request.js';
import qs from 'qs';

const exportExcelBooking = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order/excel',
      method: 'GET',
      params,
      responseType: 'blob',
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const getManagementOrder = async (params) => {
  try {
    const response = await request({
      url: '/v1/management/order',
      method: 'GET',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  getManagementOrder,
  exportExcelBooking,
};
