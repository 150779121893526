<script>
  import { DataTable, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { difference, isEmpty } from 'lodash';
  import { getCabinName, getMaxWeightAllowance } from 'src/utils/appHelper';
  import { EMPTY } from 'src/constants/app';
  export let DataLists = {};
  export let PricedOffer = {};
  const headers = [
    {
      key: 'airline',
      value: '항공사',
    },
    {
      key: 'flightNumber',
      value: '편명',
    },
    {
      key: 'startingPoint',
      value: '출발지',
    },
    {
      key: 'destination',
      value: '도착지',
    },
    {
      key: 'departureDate',
      value: '출발일',
    },
    {
      key: 'departureTime',
      value: '출발시간',
    },
    {
      key: 'arrivalDate',
      value: '도착일',
    },
    {
      key: 'arrivalTime',
      value: '도착시간',
    },
    {
      key: 'rbd',
      value: 'RBD',
    },
    {
      key: 'fareBasis',
      value: 'FareBasis',
    },
    {
      key: 'seatClass',
      value: '좌석등급',
    },
    {
      key: 'priceClass',
      value: 'PriceClass',
    },
    {
      key: 'baggage',
      value: '수하물',
    },
    {
      value: '기종',
      key: 'emphysema',
    },
    {
      key: 'codesShare',
      value: '공동운항',
    },
    {
      key: 'hiddenStopover',
      value: '숨은경유지',
    },
  ];

  let rows = [];

  function getFareComponentByPaxSegment(FareComponent, PaxSegmentID) {
    return FareComponent.find((elm) => elm.PaxSegmentRefID.includes(PaxSegmentID));
  }

  function getCabinTypeByPaxSegment(fareComponent, PaxSegmentID) {
    return fareComponent?.FareBasis?.CabinType?.find((elm) => elm.PaxSegmentRefID === PaxSegmentID);
  }

  function findPaxJourneyItem(PaxJourneyList, PaxSegmentID) {
    return PaxJourneyList.find((elm) => elm.PaxSegmentRefID.includes(PaxSegmentID));
  }

  function findJourneyOverviewItem(JourneyOverview, PaxJourneyID) {
    return JourneyOverview.find((elm) => elm.PaxJourneyRefID === PaxJourneyID);
  }

  function getCodeShare(OperatingCarrier, MarketingCarrier) {
    return OperatingCarrier !== MarketingCarrier ? OperatingCarrier : EMPTY;
  }

  function getHiddenStopover(Legs) {
    return Legs && Legs.length > 0 ? Legs[0].Arrival.AirportCode : EMPTY;
  }

  function getOfferItem(PricedOffer, DataLists) {
    const OfferItem = PricedOffer?.OfferItem || PricedOffer?.OrderItem || [];
    const Owner = OfferItem[0]?.Owner || EMPTY;
    const { PaxList = [] } = DataLists;
    if (['LH', 'EK', 'AA', 'HA', 'AF', 'KL', 'BA'].includes(Owner)) {
      const paxIdADT = PaxList.filter((pax) => pax.Ptc === 'ADT').map((elm) => elm.PaxID);
      const paxIdCHD = PaxList.filter((pax) => pax.Ptc === 'CHD').map((elm) => elm.PaxID);
      const result = OfferItem.find((elm) => {
        if (paxIdADT?.length) {
          return difference(elm.PaxRefID, paxIdADT).length === 0;
        } else if (paxIdCHD?.length) {
          return difference(elm.PaxRefID, paxIdCHD).length === 0;
        }
      });
      return result || {};
    }
    return OfferItem[0] || {};
  }

  function groupPaxSegment(FareComponent = []) {
    let result = [];
    for (const fareItem of FareComponent) {
      const { PaxSegmentRefID } = fareItem;
      const findIndexPaxSegment = result?.findIndex((item) => {
        return difference(item.PaxSegmentRefID, PaxSegmentRefID).length === 0;
      });
      if (findIndexPaxSegment > -1) {
        result[findIndexPaxSegment] = {
          ...result[findIndexPaxSegment],
          ...fareItem,
          PaxSegmentRefID,
        };
      } else {
        result = [...result, fareItem];
      }
    }
    return result;
  }

  $: if (!isEmpty(DataLists) && !isEmpty(PricedOffer)) {
    const PaxSegmentList = DataLists?.PaxSegmentList || [];
    const PaxJourneyList = DataLists?.PaxJourneyList || [];
    const OfferItem = getOfferItem(PricedOffer, DataLists);
    const JourneyOverview = PricedOffer?.JourneyOverview || [];
    const BaggageAllowance = PricedOffer?.BaggageAllowance || [];
    const FareDetail = OfferItem.FareDetail?.[0] || {};
    const FareComponent = groupPaxSegment(FareDetail.FareComponent) || [];
    rows = PaxSegmentList.map((item) => {
      const fareComponent = getFareComponentByPaxSegment(FareComponent, item.PaxSegmentID);
      const { FareBasis = {} } = fareComponent;
      const cabinType = getCabinTypeByPaxSegment(fareComponent, item.PaxSegmentID);
      const paxJourneyItem = findPaxJourneyItem(PaxJourneyList, item.PaxSegmentID);
      const JourneyOverviewItem = findJourneyOverviewItem(JourneyOverview, paxJourneyItem.PaxJourneyID);
      const codesShare = getCodeShare(
        item?.OperatingCarrier?.AirlineID || EMPTY,
        item?.MarketingCarrier?.AirlineID || EMPTY
      );
      const hiddenStopover = getHiddenStopover(item.Legs);
      return {
        id: item.PaxSegmentID,
        airline: item?.MarketingCarrier?.AirlineID || EMPTY,
        flightNumber: (item?.MarketingCarrier?.FlightNumber || EMPTY).padStart(4, '0'),
        startingPoint: item?.Departure?.AirportCode || EMPTY,
        destination: item?.Arrival?.AirportCode || EMPTY,
        departureDate: item?.Departure?.Date || EMPTY,
        departureTime: item?.Departure?.Time || EMPTY,
        arrivalDate: item?.Arrival?.Date || EMPTY,
        arrivalTime: item?.Arrival?.Time || EMPTY,
        rbd: cabinType?.Rbd || EMPTY,
        fareBasis: FareBasis?.FareBasisCode || EMPTY,
        seatClass: getCabinName(item?.MarketingCarrier?.AirlineID, cabinType?.CabinCode || EMPTY),
        priceClass: JourneyOverviewItem?.PriceClassInfo?.Name || EMPTY,
        baggage: getMaxWeightAllowance(BaggageAllowance, paxJourneyItem.PaxJourneyID),
        emphysema: item?.Equipment?.AircraftCode || EMPTY,
        codesShare,
        hiddenStopover,
      };
    });
  }
</script>

<DataTable size="medium" {headers} {rows} class="g-custom-table">
  <Toolbar size="sm">
    <ToolbarContent>
      <div class="table-toolbar">
        <div class="wrapper-title">
          <h4 class="title-content">여정 정보</h4>
        </div>
        <div class="wapper-actions-button" />
      </div>
    </ToolbarContent>
  </Toolbar>
</DataTable>
